import React from "react";
import MuiModal from "../../../shared/Modal";
import Tooltip from "../../../shared/Tooltip";

import CloseIcon from "../../../assets/close-red-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { cancelRequest } from "../../../services/request";
import { getCurrentRequest } from "../../../services/consumerCategory";
import { useNavigate } from "react-router";
import { useState } from "react";

export default function CancelRequest({
  open,
  closeModal,
  id,
  actionTab,
  isRejectPopup,
}) {
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);
  const { data, userType } = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState(false);
  // const handleClick = () => {
  //     const payload = {
  //         request_id: id,
  //         is_deleted: true
  //     }
  //     dispatch(cancelRequest(payload))
  //     if (actionTab) {
  //         dispatch(getCurrentRequest({ status: actionTab, ...(userType === "consumer" ? { user_id: data.id } : {}) }));
  //     } else {
  //         navigate(-1)
  //     }
  //     closeModal()
  // }

  const handleClick = () => {
    if (!comment.trim()) {
      setCommentError(true);
      return;
    }
    const payload = {
      request_id: id,
      partner_status: 'rejected',
      reason: comment,
    };
    dispatch(cancelRequest(payload)).then(() => {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false); // Hide the success message
        window.location.reload(); // Reload the page
      
        // Navigate to the appropriate page based on userType
        if (userType === "consumer" || userType === "admin") {
          navigate(`/request-tracking?id=${id}&status=${actionTab}`);
        }
      }, 2000);
    });
    if (actionTab) {
      dispatch(
        getCurrentRequest({
          status: actionTab,
          ...(userType === "consumer" ? { user_id: data.id } : {}),
        })
      );
    }
    closeModal();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    if (e.target.value.trim()) {
      setCommentError(false);
    }
  };

  return (
    <>
      {showSuccess && (
        <MuiModal
          modalSize="sm"
          modalOpen={showSuccess}
          modalClose={() => setShowSuccess(false)}
          noheader
        >
          <div className="flex items-center justify-center p-4">
            <p className="text-primary-red font-medium text-[18px]">
              Request is Successfully Rejected
            </p>
          </div>
        </MuiModal>
      )}

      <MuiModal
        modalSize="md"
        modalOpen={open}
        modalClose={closeModal}
        noheader
      >
        <div>
          <div
            className="px-2 py-3 title"
            style={{ borderBottom: "1px solid rgba(214, 214, 214, 1)" }}
          >
            <div className="flex items-center justify-between">
              <p className="text-primary-red font-medium text-[18px]">
                {isRejectPopup
                  ? "Reject reason"
                  : "Are You Sure Want to Cancel Request ?"}
              </p>
              <Tooltip title="Cancel">
                <img
                  src={CloseIcon}
                  className="cursor-pointer"
                  onClick={closeModal}
                  alt="CloseIcon"
                />
              </Tooltip>
            </div>
          </div>

          <div className="flex gap-5 px-5 py-6 ">
            {!isRejectPopup && (
              <div className="flex flex-col gap-3 w-[40%]">
                <h3>Reason</h3>
                <div className="relative flex flex-col gap-4">
                  <div className="flex items-center w-full gap-6">
                    <input
                      type="checkbox"
                      className="h-[30px] w-[30px]"
                      style={{ border: "1px solid rgba(210, 226, 255, 1)" }}
                    />
                    <p className="text-[16px]"> Incorrect Info</p>
                  </div>
                  <div className="flex items-center w-full gap-6">
                    <input
                      type="checkbox"
                      className="h-[30px] w-[30px]"
                      style={{ border: "1px solid rgba(210, 226, 255, 1)" }}
                    />
                    <p className="text-[16px]">Aid No Longer Needed</p>
                  </div>
                  <div className="flex items-center w-full gap-6">
                    <input
                      type="checkbox"
                      className="h-[30px] w-[30px]"
                      style={{ border: "1px solid rgba(210, 226, 255, 1)" }}
                    />
                    <p className="text-[16px]">Urgency Reduced</p>
                  </div>
                  <div className="flex items-center w-full gap-6">
                    <input
                      type="checkbox"
                      className="h-[30px] w-[30px]"
                      style={{ border: "1px solid rgba(210, 226, 255, 1)" }}
                    />
                    <p className="text-[16px]">Conflict of Needs</p>
                  </div>
                  <div className="flex items-center w-full gap-6">
                    <input
                      type="checkbox"
                      className="h-[30px] w-[30px]"
                      style={{ border: "1px solid rgba(210, 226, 255, 1)" }}
                    />
                    <p className="text-[16px]">Other</p>
                  </div>
                </div>
              </div>
            )}
            <div className={`w-full flex flex-col gap-3 ${isRejectPopup ? "pb-10" : ""}`}>
              <h3>
                {isRejectPopup
                  ? "Kindly mention the reason for Rejection"
                  : "comment"}
                <span className="text-primary-red">*</span>{" "}
                <span>
                  {commentError && (
                    <p className="text-sm text-primary-red">
                      {`${
                        isRejectPopup ? "Rejection reason" : "Comment"
                      } is mandatory.`}
                    </p>
                  )}
                </span>
              </h3>

              <textarea
                className={`w-full h-full px-2 py-3 focus:outline-none`}
                style={{ border: "1px solid rgba(214, 229, 238, 1)" }}
                placeholder={isRejectPopup ? "Type here ...":"Enter your valid reason for Cancelling Request"}
                value={comment}
                onChange={handleCommentChange}
              ></textarea>
            </div>
          </div>

          <div className="flex items-center justify-center gap-6 my-6">
            <button
              style={{
                border: "1px solid rgba(253, 200, 205, 1)",
                borderRadius: "3px",
              }}
              className="w-[150px] py-3"
              onClick={closeModal}
            >
              {isRejectPopup ? "Cancel" : "No"}
            </button>
            <button
              className="bg-primary-red text-white w-[150px] py-3"
              style={{ borderRadius: "3px" }}
              onClick={handleClick}
            >
              {isRejectPopup ? "Reject" : "Yes"}
            </button>
          </div>
        </div>
      </MuiModal>
    </>
  );
}
