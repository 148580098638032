import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

// export const getCurrentRequest = createAsyncThunk(
//   "getCurrentRequest",
//   async ({ status, page = 1, limit = 10, request_via  }, thunkAPI, ) => {

//     try {
//       const queryParams = new URLSearchParams();

//       if (status) queryParams.append("status", status);
//       queryParams.append("page", page);
//       queryParams.append("limit", limit);

//       if (request_via) queryParams.append("request_via", request_via);
//       const response = await api.get(`/agent/?${queryParams.toString()}`);

//       if (response.status === 200 && response.data.status === "success") {
//         const result = response.data.result.data;
//         return {
//           data: result,
//           total: response.data.result.total_count,
//           page,
//           limit,
//         };
//       } else {
//         throw new Error("Unexpected API response");
//       }
//     } catch (error) {
//       console.error("API Error:", error);
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );


export const createCurrentRequest = createAsyncThunk(
  "createCurrentRequest",
  async (page) => {
    const queryParams = new URLSearchParams();

    if (page) queryParams.append("page", page);
    const createCurrentRequestInfo = await api.get(`/agent?${queryParams.toString()}`);
    if (createCurrentRequestInfo.status === 200 && createCurrentRequestInfo.data) {
      return createCurrentRequestInfo.data
    }
    return createCurrentRequestInfo
  }
);

export const getSingleRequest = createAsyncThunk(
  "getSingleRequest",
  async (id) => {
    const createAgentRequestInfo = await api.get(`/agent/?request_id=${id}`);
    if (createAgentRequestInfo.status === 200 && createAgentRequestInfo.data) {
      return createAgentRequestInfo.data
    }
    return createAgentRequestInfo
  }
);


export const getCurrentRequest = createAsyncThunk(
  "getCurrentRequest",
  async ({ status, request_via, user_id, page, partner_id, search }, thunkAPI, ) => {

    try {
      const queryParams = new URLSearchParams(); 

      if (status) queryParams.append("status", status);
      if (request_via) queryParams.append("request_via", request_via);
      if (user_id) queryParams.append("user_id", user_id);
      if (partner_id) queryParams.append("partner_id", partner_id);
      if (page) queryParams.append("page", page);
      if (search) queryParams.append("search", search);
      const response = await api.get(`/agent/?${queryParams.toString()}`);

      if (response.status === 200 && response.data.status === "success") {
        const result = response.data.result.data;
        return {
          data: result,
          total_count: response.data.result.total_count,
          page: response.data.result.page,
          records_per_page: response.data.result.records_per_page,
          total_pages: response.data.result.total_pages,
        };
      } else {
        throw new Error("Unexpected API response");
      }
    } catch (error) {
      console.error("API Error:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);