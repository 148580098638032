import React, { useEffect, useState, useRef, useMemo } from "react";
import DataTable from "../../shared/DataGrid";
import {
  CurrentStatusColumn,
  FrequentRequestColumn,
} from "../../utills/tableFields";

import SearchIcon from "../../assets/input-search-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryListWithPagination } from "../../services/category";
import { getAllCurrentRequestList } from "../../services/request";
import { getRequestAdmin } from "../../services/adminRequest";
import { useNavigate } from "react-router";
import { Menu, MenuButton, MenuItems } from "@headlessui/react";
import MoreIcon from "../../assets/more-icon.svg";
import AddPartnersInCategories from "../Popups/AddPartnersInCategories";
import GreenOpt from "../../assets/green-opt.svg";
import RedOpt from "../../assets/red-opt.svg";
import OptIcon from "../../assets/opt.svg";
import ViaIcon from "../../assets/via.svg";
import MoreHorizIcon from "../../assets/moreHorizIcon.svg";
import moment from "moment";
import AddRequest from "../Popups/AddRequest";
import { titleCase } from "../../utills/castle";
import {
  Popper,
  MenuList,
  MenuItem,
  Paper,
  IconButton,
  Popover,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelRequest from "../Request/AllRequest/CancelRequest";
// import UpdateStatusDialog from "../Request/AllRequest/UpdateStatusDialog";
import StatusDialog from "../Request/AllRequest/StatusDialog";
import { getRequestCount } from "../../services/agent";
import ChangeInReview from "../Request/AllRequest/ChangeIntoInReview";
import useDebounce from "../../utills/debounce";
const RequestCard = ({
  borderColor,
  backgroundColor,
  count,
  label,
  navigate,
}) => (
  <div
    className="rounded-[5px] cursor-pointer"
    style={{
      border: `1px solid ${borderColor}`,
      background: backgroundColor,
    }}
    onClick={() => navigate(`/agentrequest?status=${label}`)}
  >
    <div className="flex flex-col items-start justify-end h-full gap-3 px-3 py-4">
      <p className="font-medium text-[58px]">{count}</p>
      <p className="capitalize">
        {label === "walk_in" ? "Walk-In Request" : `${label} Request`}
      </p>
    </div>
  </div>
);

export default function CommonDashboard() {
  const dispatch = useDispatch();
  const categoryData = useSelector((state) => state.categoryInfo.data.data);
  const { data: currentRequestData, loading } = useSelector(
    (state) => state.requestInfo
  );

  const total = useSelector(
    (state) => state.requestInfo?.data?.result?.total_pages
  );
  const agentInfo = useSelector((state) => state.agentInfo.details.data);
  console.log({ agentInfo });
  const requestData = useSelector((state) => state.admin.details);
  const navigate = useNavigate();
  const [pages, setPage] = useState({ page: 0, pageSize: 10 });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [filterOption, setFilterOption] = useState(null);
  const [categoryId, setCategoryId] = useState("");
  const buttonRef = useRef(null);
  const [activeRowId, setActiveRowId] = React.useState(null);
  const popoverRef = useRef(null);
  const [actionTab, setActiveTab] = useState();
  const [detailAction, setDetailAction] = useState({
    cancelModal: false,
    id: null,
    reject: false,
  });
  const [openReview, setOpenReview] = useState({
    open: false,
    id: null,
    details: null,
  });
  const [openPopup, setPopupOpen] = useState(false);
  const [isUpdateStatusDialog, setIsUpdateStatusDialog] = useState(false);
  const [searchText, setSearchText] = useState(""); // State for search input

  const searchValue = useDebounce(searchText, 500);

  const closeModal = () => {
    setDetailAction({ cancelModal: false, id: null, reject: false });
  };
  const actionOpen = Boolean(anchorEl);

  const toggleMenu = (e) => {
    e.stopPropagation();
    setMenuOpen((prev) => !prev);
  };

  const handleFilterChange = (option) => {
    setFilterOption((prevOption) => (prevOption === option ? null : option));
  };

  // const filteredRows = filterOption
  //   ? currentRequestData?.result?.data.filter(
  //       (row) => row.communication_preference === filterOption
  //     )
  //   : currentRequestData?.result?.data;

  const handleSearchChange = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  // Filter rows based on both the search input and the filter option
  const filteredRows = useMemo(() => {
    return (
      currentRequestData?.result?.data?.filter(
        (row) => !filterOption || row.communication_preference === filterOption
      ) || []
    );
  }, [currentRequestData, filterOption]);

  useEffect(() => {
    dispatch(getCategoryListWithPagination());
    dispatch(getRequestAdmin());
    dispatch(getRequestCount());
  }, []);

  useEffect(() => {
    dispatch(
      getAllCurrentRequestList({ page: pages.page + 1, search: searchValue })
    );
  }, [dispatch, pages, searchValue]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  // const handleClick = (event, data) => {
  //   setAnchorEl(event.currentTarget);
  //   setSelectedRow(data);
  // };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        anchorEl &&
        !anchorEl.contains(event.target)
      ) {
        setAnchorEl(null);
        setActiveRowId(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [anchorEl]);

  const handleClick = (event, rowId) => {
    if (activeRowId === rowId) {
      // Close if the same row is clicked
      setAnchorEl(null);
      setActiveRowId(null);
    } else {
      // Open for a new row
      setAnchorEl(event.currentTarget);
      setActiveRowId(rowId);
    }
  };

  const { data: userData, userType } = useSelector((state) => state.userInfo);

  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    const updateTimeAndDate = () => {
      const now = new Date();
      setTime(
        now.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      );

      // Format date as MM-DD-YYYY
      const formattedDate = `${String(now.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(now.getDate()).padStart(2, "0")}-${now.getFullYear()}`;
      setDate(formattedDate);
    };

    updateTimeAndDate();
    const intervalId = setInterval(updateTimeAndDate, 1000);

    return () => clearInterval(intervalId);
  }, []);
  const handleOpen = () => {
    setId(id);
    setIsUpdateStatusDialog(true);
  };
  const handleClose = () => {
    setIsUpdateStatusDialog(false);
  };
  const [anchorEl1, setAnchorEl1] = useState(null);
  const CurrentStatusColumns = [
    {
      field: "opt",
      headerName: "OPT",
      flex: 1,
      headerAlign: "center",
      // renderHeader: () => (
      //   <div className="flex items-center gap-[5px]">
      //     <p className="font-sans text-[#18283D] font-semibold">OPT</p>
      //     <img src={OptIcon} alt="opt" />
      //   </div>
      // ),
      renderHeader: () => (
        <div
          className=" flex items-center gap-[5px]"
          onClick={handleHeaderClick}
        >
          <p className="font-sans text-[#18283D] font-semibold">OPT</p>
          <img src={OptIcon} alt="opt" className="cursor-pointer" />
        </div>
      ),
      renderCell: (params) => {
        const via = params.row?.communication_preference?.toLowerCase();
        const optImage = via === "opt_in" ? GreenOpt : RedOpt;
        return (
          <div className="flex items-center justify-center h-full">
            <img src={optImage} alt="star icon" className="w-4 h-4" />
          </div>
        );
      },
    },
    {
      field: "first_name",
      headerName: "Client Name",
      flex: 1,
      renderCell: (params) => {
        return <div className="capitalize">{params.row.first_name}</div>;
      },
    },
    {
      field: "category_name",
      headerName: "Program",
      flex: 1,
      renderCell: (params) => {
        const category = params.row.category_name;
        return (
          <div className="flex items-center w-full h-full capitalize">
            {typeof category === "string" && category.trim() ? (
              titleCase(category)
            ) : (
              <img src={MoreHorizIcon} alt="More" />
            )}
          </div>
        );
      },
    },

    {
      field: "request_via",
      headerName: "Via",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-3">
          <p className="font-sans text-[#18283D] font-semibold">Via</p>
          <img src={ViaIcon} alt="via" />
        </div>
      ),
      renderCell: (params) => (
        <div className="capitalize">{params.row.request_via}</div>
      ),
    },
    {
      field: "created_at",
      headerName: "Intake Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.created_at).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "urgency_level",
      headerName: "Level",
      flex: 1,
      renderCell: (params) => (
        <div className="capitalize">{params.row.urgency_level}</div>
      ),
    },
    {
      field: "service_option",
      headerName: "Service Option",
      flex: 1,
      renderCell: (params) => {
        const isPaid = params.row.cost === 0;
        return (
          <div className="flex items-center justify-center w-full h-full">
            {isPaid ? <img src={MoreHorizIcon} alt="More" /> : "Paid"}
          </div>
        );
      },
    },
    ...(userType === "agent"
      ? [
          {
            field: "isPaid",
            headerName: "Payment Status",
            flex: 1,
            renderCell: (params) => {
              const isPaid = params.row.category_is_premium === "True";

              return (
                <div className="capitalize">
                  {isPaid ? (
                    params.row.is_paid ? (
                      <div className="text-[#278E1D]">Paid</div>
                    ) : (
                      <div className="text-[#FFCD11]">Pending</div>
                    )
                  ) : (
                    "..."
                  )}
                </div>
              );
            },
          },
        ]
      : []),
    {
      field: "updated_at",
      headerName: "Submitted Date & Time",
      flex: 1,
      renderCell: (params) => {
        return moment(params?.row?.updated_at).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "request_status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          completed: "text-[#278E1D]",
          "in review": "text-[#E26313]",
          resolved: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF]",
        };
        return (
          <div
            className={`capitalize ${colors[params.row.request_status] || ""}`}
          >
            {params.row.request_status || (
              <img src={MoreHorizIcon} alt="More" />
            )}
          </div>
        );
      },
    },
    {
      field: "partners_names",
      headerName: "Partner",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="flex items-center justify-center w-full h-full capitalize">
            {params.row.partners_names?.length > 0 ? (
              params.row.partners_names
            ) : (
              <img src={MoreHorizIcon} alt="More" className="w-6 h-6" />
            )}
          </div>
        );
      },
    },
    {
      field: "partner_status",
      headerName: "Partner Response",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          accepted: "text-[#278E1D]",
          completed: "text-[#278E1D]",
          resolved: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF]",
        };

        const isPartnerNamesNull =
          !params.row.partners_names || params.row.partners_names.length === 0;

        return (
          <div
            className={`capitalize flex justify-center items-center w-full h-full ${
              colors[params.row.partner_status] || ""
            }`}
          >
            {isPartnerNamesNull ? (
              <img src={MoreHorizIcon} alt="More" className="w-6 h-6" />
            ) : (
              params.row.partner_status
            )}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={(e) => handleClick(e, params.row.id)}
              ref={buttonRef}
              aria-controls={actionOpen ? "action-menu" : undefined}
              aria-haspopup="true"
            >
              <MoreVertIcon />
            </IconButton>

            <Popper
              open={actionOpen && activeRowId === params.row.id}
              ref={popoverRef}
              anchorEl={anchorEl}
              placement="bottom"
              disablePortal
              className="menu-before"
            >
              <Paper className="absolute right-5 z-50 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5">
                <MenuList>
                  {params.row.request_status === "new" && (
                    <MenuItem
                      onClick={() => {
                        setOpenReview({
                          open: true,
                          id: params.id,
                          details: params.row,
                        });
                      }}
                    >
                      {/* <a className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"> */}
                      Mark as In Review
                      {/* </a> */}
                    </MenuItem>
                  )}

                  {params.row.partner_status === "resolved" && (
                    <MenuItem
                      onClick={() => {
                        handleOpen(params.id);
                        setId(params.id);
                      }}
                      className="cursor-pointer"
                    >
                      Update Status
                    </MenuItem>
                  )}

                  {/* {(params.row.partner_status === "wait" ||
                    params.row.partners_names.length === 0) && (
                    <MenuItem
                      onClick={() => {
                        setOpen(params.id);
                        setId(params.id);
                        setAnchorEl(null);
                      }}
                    >
                      Assign Partner
                    </MenuItem>
                  )} */}
                  <MenuItem
                    onClick={() => {
                      navigate(`/view-request/${params.row.id}`);
                      setAnchorEl(null);
                    }}
                    className="cursor-pointer"
                  >
                    View Details
                  </MenuItem>

                  {params.row.partner_status === "rejected" &&
                    params.row.partners_names.length !== 0 &&
                    userType !== "consumer" && (
                      <MenuItem
                        onClick={() => {
                          setOpen(params.id);
                          setId(params.id);
                          setAnchorEl(null);
                          setCategoryId(params.row.category);
                        }}
                        className="cursor-pointer"
                      >
                        Reassign
                      </MenuItem>
                    )}

                  {/* {params.row.partner_status === "rejected" &&
                    userType !== "consumer" && (
                      <MenuItem
                        onClick={() => {
                          setOpen(params.id);
                          setId(params.id);
                          setAnchorEl(null);
                          setCategoryId(params.row.category);

                        }}
                      >
                        Reassign
                      </MenuItem>
                    )} */}

                  {userType !== "consumer" &&
                    params.row.partner_status !== "rejected" &&
                    params.row.partners_names.length === 0 && (
                      <MenuItem
                        onClick={() => {
                          setPopupOpen(params.id);
                          setId(params.id);
                          setCategoryId(params.row.category);
                          setAnchorEl(null);
                        }}
                        className="cursor-pointer"
                      >
                        Assign Request
                      </MenuItem>
                    )}

                  {params.row.partner_status !== "completed" && (
                    <MenuItem
                      onClick={() => {
                        navigate(
                          `/request-tracking?id=${params?.row?.id}&status=${params?.row?.request_status}`
                        );
                        setAnchorEl(null);
                      }}
                      className="cursor-pointer"
                    >
                      Track Request
                    </MenuItem>
                  )}

                  {(params.row.partner_status === "wait" ||
                    params.row.partners_names.length === 0) &&
                    params.row.partner_status !== "rejected" &&
                    userType !== "consumer" && (
                      <MenuItem
                        onClick={() => {
                          setDetailAction({
                            cancelModal: true,
                            id: params.row.id,
                            reject: true,
                          });
                          setAnchorEl(null);
                        }}
                        className="cursor-pointer"
                      >
                        Reject Request
                      </MenuItem>
                    )}

                  {params.row.partner_status === "completed" && (
                    <MenuItem
                      onClick={() => {
                        navigate(`/create-request?id=${params?.row?.id}`);
                        setAnchorEl(null);
                      }}
                    >
                      Request Again
                    </MenuItem>
                  )}

                  <MenuItem
                    onClick={() => {
                      navigate(`/request-history/${params?.row?.id}`);
                      setAnchorEl(null);
                    }}
                    className="cursor-pointer"
                  >
                    History
                  </MenuItem>
                </MenuList>
              </Paper>
            </Popper>
          </>
        );
      },
    },
  ];

  const colors = [
    {
      borderColor: "rgba(23, 144, 11, 1)",
      backgroundColor: "rgba(241, 255, 239, 1)",
    },
    {
      borderColor: "rgba(255, 213, 0, 1)",
      backgroundColor: "rgba(254, 255, 217, 1)",
    },
    {
      borderColor: "rgba(255, 123, 0, 1)",
      backgroundColor: "rgba(255, 245, 231, 1)",
    },
  ];

  const FrequentRequestColumns = [
    ...FrequentRequestColumn,

    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="flex justify-center items-center h-full">
            <button
              className="cursor-pointer h-[35px] rounded bg-[#1D5BBF] text-white px-2 flex justify-center items-center"
              onClick={() =>
                navigate(`/all-request?status=${params?.row?.request_status}`)
              }
            >
              View
            </button>
          </div>
        );
      },
    },
  ];

  const handleHeaderClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl1(null);
  };

  return (
    <div className="p-10">
      <div className="flex items-center font-medium text-[22px] mt-2 mb-8 gap-9">
        <p className="text-[#1D5BBF] capitalize">
          Welcome, {userData.username}
        </p>
        <p className="flex gap-3 text-[#353F4F]">
          <span
            className="text-[#353F4F]"
            style={{
              borderRight: "1px solid rgba(0, 0, 0, 1)",
              paddingRight: "10px",
            }}
          >
            {time}{" "}
          </span>
          <span>{date}</span>
        </p>
      </div>
      <div>
        <div className="grid grid-cols-7 gap-5 py-5">
          <div className="col-span-3 fstdiv ">
            <div className="custom-shadow rounded-[10px] text-[#353F4F] p-6 ">
              <p className="font-medium text-base text-[20px] pb-6">
                Total Count
              </p>
              <div className="grid grid-cols-2 gap-6">
                <div className="bg-[#ECF2FF] border border-[#1C65F3] rounded-[5px]">
                  <div
                    className="flex flex-col items-start justify-end h-full gap-3 px-3 py-4 cursor-pointer"
                    onClick={() => navigate(`/agentrequest`)}
                  >
                    <p className="text-[58px]" style={{ fontWeight: 500 }}>
                      {requestData?.total_request || 0}
                    </p>
                    <p>Total Request</p>
                  </div>
                </div>
                {requestData?.requests?.map((card, index) => (
                  <RequestCard
                    key={index}
                    borderColor={colors[index % colors.length].borderColor}
                    backgroundColor={
                      colors[index % colors.length].backgroundColor
                    }
                    count={card.request_count}
                    label={card.request_via}
                    navigate={navigate}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="col-span-4 secdiv ">
            <div className="custom-shadow p-6 rounded-[10px] ">
              <p
                className="pb-6 mb-6"
                style={{ borderBottom: "1px solid rgba(211, 211, 211, 1)" }}
              >
                Current Status
              </p>

              <DataTable
                rows={agentInfo?.requests || []}
                columns={FrequentRequestColumns}
                height={312}
                hideCheckbox
                hideFooter
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          className="px-6 py-2 mb-5"
          style={{
            boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "10px",
          }}
        >
          <div
            className="flex items-center justify-between py-3 mb-6"
            style={{ borderBottom: "1px solid rgba(211, 211, 211, 1)" }}
          >
            <p className=" pt-3 text-[#353F4F] font-medium text-lg mb-3">
              Current Request Status
            </p>

            <div className="flex items-center gap-8">
              <div className="relative">
                <input
                  type="text"
                  value={searchText}
                  onChange={handleSearchChange}
                  className="block w-full p-2 text-sm text-gray-900 outline-none rounded-[3px] h-[40px] placeholder:text-[#D6E4FF] "
                  placeholder="Search"
                  style={{
                    border: "1px solid rgba(29, 91, 191, 1)",
                    width: "200px",
                  }}
                />
                <div className="absolute right-4 top-[7px]">
                  <img
                    src={SearchIcon}
                    alt="SearchIcon"
                    className="w-[25px] h-[25px]"
                  />
                </div>
              </div>
              <div
                className="bg-[#D9E4F2] text-[#1D5BBF] text-base px-4 py-2 rounded-[3px] cursor"
                onClick={() => navigate("/all-request")}
              >
                View all
              </div>
            </div>
          </div>
          {currentRequestData?.result?.data && (
            <DataTable
              // rows={currentRequestData?.result?.data || []}
              rows={filteredRows || []}
              columns={CurrentStatusColumns}
              hideCheckbox
              height={450}
              pages={pages}
              totalRows={total}
              handlePageChange={handlePageChange}
              loading={loading}
            />
          )}

          <Popover
            open={Boolean(anchorEl1)}
            anchorEl={anchorEl1}
            onClose={closePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className=" z-10  w-36 bg-white border border-[#D9D9D9] rounded-[3px]">
              <ul className="p-3 space-y-3">
                <li className="flex items-center">
                  <input
                    type="checkbox"
                    id="opt-in"
                    name="opt-in"
                    className="mr-2"
                    // onChange={handleOptionChange}
                    checked={filterOption === "opt_in"}
                    onClick={() => handleFilterChange("opt_in")}
                  />
                  <label htmlFor="opt-in" className="text-sm text-black">
                    Opt In
                  </label>
                </li>
                <li className="flex items-center">
                  <input
                    type="checkbox"
                    id="opt-out"
                    name="opt-out"
                    className="mr-2"
                    // onChange={handleOptionChange}
                    checked={filterOption === "opt_out"}
                    onClick={() => handleFilterChange("opt_out")}
                  />
                  <label htmlFor="opt-out" className="text-sm text-black">
                    Opt Out
                  </label>
                </li>
              </ul>
            </div>
          </Popover>
        </div>
      </div>

      {/* <UpdateStatusDialog
        open={isUpdateStatusDialog}
        handleClose={() => setIsUpdateStatusDialog(false)}
        request={currentRequestData}
      /> */}

      <StatusDialog
        open={isUpdateStatusDialog}
        onClose={handleClose}
        id={id}
        refetch={() => dispatch(getAllCurrentRequestList({ page: pages.page , search: searchValue }))}
      />
      <CancelRequest
        open={detailAction.cancelModal}
        id={detailAction.id}
        closeModal={closeModal}
        actionTab={actionTab}
        isRejectPopup={detailAction.reject}
      />
      <AddPartnersInCategories
        open={openPopup}
        setOpen={setPopupOpen}
        id={id}
        fromRequest={true}
        setId={setId}
        categoryId={categoryId}
        getRequest={() => dispatch(getAllCurrentRequestList({ page: pages.page + 1, search: searchValue }))}
      />
      <AddRequest
        open={open}
        setOpen={setOpen}
        id={id}
        fromRequest={true}
        setId={setId}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        refetch={() => dispatch(getAllCurrentRequestList({ page: pages.page + 1, search: searchValue }))}
      />
      <ChangeInReview
        open={openReview.open}
        closeModal={() =>
          setOpenReview({ open: false, id: null, details: null })
        }
        id={openReview.id}
        details={openReview.details}
        refetch={() => dispatch(getAllCurrentRequestList({ page: pages.page, search: searchValue }))}
      />
      {/* <AddPartnersInCategories
        open={open}
        setOpen={setOpen}
        id={id}
        setId={setId}
        fromRequest={true}
        getRequest={() => dispatch(getAllCurrentRequestList({ page: pages.page + 1, search: searchValue }))}
      /> */}
    </div>
  );
}
