import React, { useState, useEffect } from "react";
import SearchIcon from "../../assets/input-search-icon.svg";
import AddIcon from "@mui/icons-material/Add";
import MuiModal from "../../shared/Modal";
import { useForm } from "react-hook-form";
import AddImageIcon from "../../assets/add-image-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateCategoryVisibility,
  getCategoryVisibilityLength,
} from "../../services/category";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { useNavigate } from "react-router";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

export default function CategoryVisibility() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();

  const [uploadModal, setUploadModal] = useState();
  const [rows, setRows] = useState([
    { id: 1, title: "1st Row", categories: [] },
  ]);
  const [nextRowId, setNextRowId] = useState(2);
  const [currentRowTitle, setCurrentRowTitle] = useState("");
  const [currentCategory, setCurrentCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null);
  const dispatch = useDispatch();
  const [showListbox, setShowListbox] = useState(false);
  const navigate = useNavigate();
  const categoryInfo = useSelector((state) => state.categoryInfo.data);
  const state1 = useSelector((state) => state);
  const { state, loading, error, status } = useSelector(
    (state) => state.categoryInfo
  );
  const [selectedCategories, setSelectedCategories] = useState({});

  const [searchTerm, setSearchTerm] = useState("");

  const filteredRows = searchTerm
    ? rows.filter((row) =>
        row.categories.some((category) =>
          category.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    : rows;

  useEffect(() => {
    dispatch(CreateCategoryVisibility());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCategoryVisibilityLength());
  }, [dispatch]);

  const handleCloseUploadModal = () => {
    reset();
    setUploadModal(false);
    setCurrentCategory("");
  };

  const handleOpenModalForCard = (rowId, title) => {
    const selectedRow = rows.find((row) => row.id === rowId);
    setCategories(
      selectedRow?.categories.map((category) => category.name) || []
    );
    setCurrentRowTitle(title);
    setEditingRowId(rowId);
    setUploadModal(true);
  };

  const handleAddCategoryToRow = () => {
    if (!currentCategory.trim()) return alert("Please enter a category!");

    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === editingRowId
          ? { ...row, categories: [...row.categories, currentCategory] }
          : row
      )
    );

    setCurrentCategory("");
    handleCloseUploadModal();
  };

  const handleAddRow = () => {
    if (!currentCategory.trim())
      return alert("Please enter a category for the new row!");
    const newRow = {
      id: nextRowId,
      title: `${nextRowId}th Row`,
      categories: [currentCategory],
    };
    setRows([...rows, newRow]);
    setNextRowId(nextRowId + 1);
    setCurrentCategory("");
    handleCloseUploadModal();
  };

  const handleRemoveRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleMoveRow = (id, direction) => {
    const currentIndex = rows.findIndex((row) => row.id === id);
    if (currentIndex === -1) return;

    const newRows = [...rows];
    const [movedRow] = newRows.splice(currentIndex, 1);

    const newIndex =
      direction === "up"
        ? Math.max(0, currentIndex - 1)
        : Math.min(rows.length, currentIndex + 1);
    newRows.splice(newIndex, 0, movedRow);

    setRows(newRows);
  };

  const handleSave = async () => {
    try {
      const updatedRows = rows.map((row) => ({
        row: row.id,
        categories: row.categories.map((category) => category.id),
      }));

      if (updatedRows.some((row) => row.categories.length === 0)) {
        alert("Please make sure each row has categories selected.");
        return;
      }

      const response = await dispatch(
        CreateCategoryVisibility({ rows: updatedRows })
      ).unwrap();

      console.log("Saved rows:", response);

      navigate("/allcategory");
    } catch (error) {
      console.error("Error saving categories:", error);
      alert("Failed to save categories. Please try again.");
    }
  };

  const handleAddCategory = async () => {
    if (categories.length === 0 && !editingRowId) {
      return;
    }

    try {
      const selectedRow = rows.find((row) => row.id === editingRowId);
      const existingCategories = selectedRow?.categories || [];

      // Filter out categories to be removed
      const remainingCategories = existingCategories.filter((existing) =>
        categories.includes(existing.name)
      );

      // Identify new categories to add
      const uniqueNewCategories = categories.filter(
        (category) =>
          !existingCategories.some((existing) => existing.name === category)
      );

      const newCategories = uniqueNewCategories.map((name, index) => ({
        id:
          index + 1 + rows.reduce((acc, row) => acc + row.categories.length, 0),
        name,
      }));

      let updatedRows = [...rows];
      let newRowId = null;

      if (editingRowId) {
        // Update existing row
        updatedRows = rows.map((row) =>
          row.id === editingRowId
            ? { ...row, categories: [...remainingCategories, ...newCategories] }
            : row
        );
      } else {
        // Add new row
        newRowId = nextRowId;
        updatedRows.push({
          id: newRowId,
          row: newRowId,
          title: currentRowTitle,
          categories: newCategories,
        });
        setNextRowId(nextRowId + 1);
      }

      setRows(updatedRows);
      setCategories([]);
      setUploadModal(false);

      const formattedData = {
        rows: updatedRows.map((row) => ({
          row: row.id,
          categories: row.categories.map((category) => category.id),
        })),
      };

      const response = await dispatch(
        CreateCategoryVisibility({ rows: formattedData.rows })
      ).unwrap();

      console.log("Saved rows:", response);
    } catch (error) {
      console.error("Error saving categories:", error);
      alert("Failed to save categories. Please try again.");
    }
  };

  const handleDeleteCategory = async (index) => {
    const categoryToDelete = categories[index];

    setCategories(categories.filter((_, i) => i !== index));

    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === editingRowId
          ? {
              ...row,
              categories: row.categories.filter(
                (category) => category.name !== categoryToDelete
              ),
            }
          : row
      )
    );

    try {
      const updatedRows = rows.map((row) => ({
        row: row.id,
        categories: row.categories.map((category) => category.id),
      }));

      await dispatch(CreateCategoryVisibility({ rows: updatedRows })).unwrap();
    } catch (error) {
      console.error("Error deleting Program from API:", error);
      alert("Failed to delete Program. Please try again.");
    }
  };

  // const handleSelectCategory = (category) => {
  //   setCategories([...categories, category]);
  //   setShowListbox(false);
  // };

  const handleSelectCategory = (categoryName) => {
    setCategories((prevCategories) => {
      if (prevCategories.includes(categoryName)) {
        return prevCategories.filter((name) => name !== categoryName);
      } else {
        return [...prevCategories, categoryName];
      }
    });
    setShowListbox(false);
  };

  const arrayMove = (arr, fromIndex, toIndex) => {
    const array = [...arr];
    const [element] = array.splice(fromIndex, 1);
    array.splice(toIndex, 0, element);
    return array;
  };

  const Handle = SortableHandle(({ tabIndex }) => (
    <div tabIndex={tabIndex}>Move</div>
  ));

  const handleCheckboxChange = (rowId, categoryName) => {
    setSelectedCategories((prev) => {
      const rowSelections = prev[rowId] || {};
      const isSelected = rowSelections[categoryName];
      return {
        ...prev,
        [rowId]: {
          ...rowSelections,
          [categoryName]: !isSelected, // Toggle selection
        },
      };
    });
  };

  const isAnyCategorySelected = (rowId) => {
    const rowSelections = selectedCategories[rowId] || {};
    return Object.values(rowSelections).some((selected) => selected);
  };

  const SortableItem = SortableElement(({ value: row, index }) => {
    const filteredCategories = row.categories.filter((category) =>
      category.name.toLowerCase().includes(searchTerm)
    );

    const isSelected = filteredCategories.some(
      (category) => selectedCategories[row.id]?.[category.name]
    );
    return (
      <div>
        <div className="flex">
          <div
            key={row.id}
            className="flex flex-col bg-white w-[293px] h-[400px] border border-[#2260D9] rounded-sm"
          >
            <div className="border-b bg-[#F1F6FF] text-start text-[#353F4F] text-[18px] p-4 rounded-t-lg border-[#2260D9]">
              {row.title}
            </div>
            <div className="flex flex-col gap-2 px-4 py-5">
              {filteredCategories.length > 0 ? (
                filteredCategories.map((category, index) => (
                  <div className="flex items-center gap-4" key={index}>
                    <input
                      type="checkbox"
                      checked={!!selectedCategories[row.id]?.[category.name]}
                      onChange={() =>
                        handleCheckboxChange(row.id, category.name)
                      }
                      className="cursor-pointer w-5 h-5 border text-white border-[#C0C0C0] rounded-sm peer checked:bg-[#2260D9]"
                    />
                    <p className="text-[14px] text-[#353F4F]">
                      {category.name}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-[14px] text-[#C6C6C6]">
                  No Programs added yet.
                </p>
              )}
            </div>
            <div className="mt-auto flex justify-center pb-4 gap-3">
              {(row.id !== 1 || isSelected) && (
                <>
                  <button
                    className="border-[#FF3F53] border text-[#FF3F53] text-center w-[80px] py-2 rounded-[3px] transition"
                    onClick={() => handleRemoveRow(row.id)}
                  >
                    Remove
                  </button>
                  <button className="bg-[#FF6711] text-white text-center w-[80px] py-2 rounded-[3px] transition">
                    <Handle tabIndex={index} />
                  </button>
                </>
              )}
              <button
                onClick={() => handleOpenModalForCard(row.id, row.title)}
                className="bg-[#2260D9] text-white text-center w-[80px] py-2 rounded-[3px] hover:bg-[#1b4ea8] transition"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const SortableList = SortableContainer(({ rows }) => {
    return (
      <div className="flex flex-wrap justify-start gap-4">
        {rows.map((row, index) => (
          <SortableItem key={`item-${row.id}`} index={index} value={row} />
        ))}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setRows((prevRows) => {
      const updatedRows = arrayMove(prevRows, oldIndex, newIndex);
      return updatedRows;
    });
  };

  return (
    <>
      <div className="px-8 mt-10">
        <div className="mb-6">
          <nav class="flex" aria-label="Breadcrumb">
            <span class="inline-flex items-center text-[22px] font-normal text-[#353F4F] ">
              Programs
            </span>
          </nav>
        </div>
        <div
          className="px-3 py-5 mb-5"
          style={{
            boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "5px",
          }}
        >
          <div className="flex justify-between p-6 mb-3">
            <div className="text-[#353F4F] text-[20px] font-sans">Programs</div>
          </div>

          <div className="details-info flex items-center gap-4 justify-end ">
            <div className="flex justify-between items-center border-[#2260D9] border-[0px] rounded-[5px] ">
              <div className="relative">
                <input
                  type="text"
                  id="search-navbar"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="block w-full p-2 text-sm text-gray-900 border-none rounded-[3px] focus:outline-none"
                  placeholder="Search"
                  style={{
                    border: "1px solid rgba(34, 96, 217, 1)",
                    height: "55px",
                    width: "300px",
                  }}
                />
                <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                  <img src={SearchIcon} alt="SearchIcon" />
                </div>
              </div>
            </div>
            <button
              className="bg-[#2260D9] text-white rounded-[2px] p-4 focus:outline-none"
              onClick={() => {
                setCurrentRowTitle(`${nextRowId}th Row`);
                setEditingRowId(null);
                setUploadModal(true);
              }}
            >
              <AddIcon className="" />
              Add Rows
            </button>
          </div>
          <div className="flex p-5 flex-wrap">
            <SortableList
              shouldUseDragHandle
              axis="xy"
              rows={filteredRows}
              onSortEnd={onSortEnd}
            />
          </div>

          <div className="flex w-full justify-center gap-6 mt-10">
            <button className="border-[#353F4F] border-[1px] text-[#353F4F] rounded-[3.9px] w-[200px] h-[55px] active:bg-[#2260D9] active:text-white">
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="bg-[#2260D9] border-[#353F4F] border-[1px] text-white rounded-[3.9px] w-[200px] h-[55px] active:bg-[#2260D9] active:text-white"
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <MuiModal
        modalSize="md"
        modalOpen={uploadModal}
        modalClose={() => setUploadModal(false)}
        title={currentRowTitle}
      >
        <div className="h-[10rem]">
          <h5 className="text-[#353F4F] text-[16px] font-normal">
            Add Programs
          </h5>

          <Listbox>
            <>
              <ListboxButton
                onClick={() => setShowListbox(true)}
                className="relative flex justify-between items-center w-full px-3 py-1 leading-[2.15] bg-[#F1F6FF] focus-within:outline-none text-sm h-[55px]"
              >
                <Stack direction="row" spacing={1} className="flex-grow">
                  {categories.map((category, index) => (
                    <Chip
                      key={index}
                      label={category}
                      onDelete={() => handleDeleteCategory(index)}
                      sx={{
                        backgroundColor: "#f0f0f0",
                        color: "#2260D9",
                        ".MuiChip-deleteIcon": {
                          color: "#FF0000",
                        },
                      }}
                    />
                  ))}
                </Stack>

                <div className="border border-dotted border-[#1D5BBF] flex items-center justify-center rounded p-1 cursor-pointer">
                  <AddIcon className="text-[#1D5BBF]" />
                </div>
              </ListboxButton>
              {showListbox && (
                <ListboxOptions className="absolute mt-1 w-[96.5%] bg-white border p-4 border-[#ccc] rounded-md shadow-lg max-h-48 overflow-auto">
                  {categoryInfo &&
                    categoryInfo?.map((category, id) => (
                      <ListboxOption key={id} value={category}>
                        {({ active, selected }) => (
                          <div className="flex items-center justify-start">
                            <input
                              type="checkbox"
                              className="w-6 h-6 checked:bg-[#2260D9] text-white"
                              checked={categories.includes(category.name)}
                              onClick={() =>
                                handleSelectCategory(category.name)
                              }
                            />
                            <p
                              className={`p-2 cursor-pointer ${
                                active ? "bg-blue-100" : ""
                              }`}
                            >
                              {category.name}
                            </p>
                          </div>
                        )}
                      </ListboxOption>
                    ))}
                </ListboxOptions>
              )}
            </>
          </Listbox>
        </div>
        <div className="flex w-full justify-center gap-6 mt-10">
          <button
            onClick={() => setUploadModal(false)}
            className="border-[#353F4F] border-[1px] text-[#353F4F] rounded-[3.9px] w-[200px] h-[55px] active:bg-[#2260D9] active:text-white"
          >
            Cancel
          </button>
          <button
            onClick={handleAddCategory}
            disabled={categories.length === 0}
            className={`border-[#353F4F] border-[1px] ${
              categories.length > 0 ? "text-[#353F4F]" : "text-gray-400"
            } rounded-[3.9px] w-[200px] h-[55px] ${
              categories.length > 0
                ? "active:bg-[#2260D9] active:text-white"
                : ""
            }`}
          >
            Save
          </button>
        </div>
      </MuiModal>
    </>
  );
}
