import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";


export const frequentlySearchCategory = createAsyncThunk(
    "frequentlySearchCategory",
    async (limit) => {
        const queryParams = new URLSearchParams();
        queryParams.append("frequently_searched", true);
        if (limit) queryParams.append("limit", limit);

        const frequentlySearchCategoryInfo = await api.get(`/agent/category-list/?${queryParams.toString()}`);
        if (frequentlySearchCategoryInfo.status === 200 && frequentlySearchCategoryInfo.data) {
            return frequentlySearchCategoryInfo.data
        }
        return frequentlySearchCategoryInfo
    }
);


export const frequentlySearchLimit = createAsyncThunk(
    "frequentlySearchLimit",
    async (limit) => {
        const queryParams = new URLSearchParams();
        queryParams.append("frequently_searched", true);
        if (limit) queryParams.append("limit", limit);
        const frequentlySearchCategoryInfo = await api.get(`/agent/category-list/?${queryParams.toString()}`);
        if (frequentlySearchCategoryInfo.status === 200 && frequentlySearchCategoryInfo.data) {
            return frequentlySearchCategoryInfo.data
        }
        return frequentlySearchCategoryInfo
    }
);

export const relatedCategories = createAsyncThunk(
    "relatedCategories",
    async ({id, limit}) => {
        console.log(limit,'limit')
        const queryParams = new URLSearchParams();
        if (id) queryParams.append("except_id", id);
        if (limit) queryParams.append("limit", limit);

        const relatedCategoriesInfo = await api.get(`/agent/category-list/?${queryParams.toString()}`);
        if (relatedCategoriesInfo.status === 200 && relatedCategoriesInfo.data) {
            return relatedCategoriesInfo.data
        }
        return relatedCategoriesInfo
    }
);


export const getCurrentRequest = createAsyncThunk(
    "getCurrentRequest",
    async ({ status, request_via }, thunkAPI,) => {

        try {
            const queryParams = new URLSearchParams();

            if (status) queryParams.append("status", status);
            if (request_via) queryParams.append("request_via", request_via);
            const response = await api.get(`/agent/?${queryParams.toString()}`);

            if (response.status === 200 && response.data.status === "success") {
                const result = response.data.result.data;
                return {
                    data: result,
                    total: response.data.result.total_count,
                };
            } else {
                throw new Error("Unexpected API response");
            }
        } catch (error) {
            console.error("API Error:", error);
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);