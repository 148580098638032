import { Chip } from "@mui/material";
import aidIcon from "../../assets/aid-icon.svg";
import chatIcon from "../../assets/chat.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "./Table";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getPantnerRequestDetail } from "../../services/request";
import moment from "moment";
import { ResponseCell } from "../PartnerDashboard/Grid";
import { updateStatusByRequestId } from "../../services/partner";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react";

function PartnerRequestDetailView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userInfo.data);

  const { id } = useParams();

  const currentRequestData = useSelector(
    (state) => state.requestInfo?.requestDetail?.result?.data?.[0]
  );
  console.log("currentRequestData", currentRequestData);

  useEffect(() => {
    if (id) {
      dispatch(getPantnerRequestDetail(id));
    }
  }, [dispatch, id]);

  const [selectedStatus, setSelectedStatus] = useState(
    currentRequestData?.partner_status || ""
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (currentRequestData?.partner_status) {
      setSelectedStatus(currentRequestData?.partner_status);
    }
  }, [currentRequestData?.partner_status]);

  const colors = {
    "in progress": "text-[#FFCD11]",
    accepted: "text-[#278E1D]",
    wait: "text-[#1D5BBF]",
    "on hold": "text-[#E26313]",
    rejected: "text-[#DC3545]",
    completed: "text-[#278E1D]",
  };

  const statusOptions = ["accepted", "on hold", "rejected", "completed"];

  const filteredOptions = statusOptions.filter((option) => {
    if (selectedStatus === "accepted") {
      return option !== "accepted";
    } else if (selectedStatus === "wait") {
      return option !== "completed";
    } else return option !== selectedStatus;
  });

  const handleChange = (newStatus) => {
    setSelectedStatus(newStatus);
    handleResponseChange(currentRequestData.id, newStatus);
  };

  const handleResponseChange = (id, newResponse) => {
    dispatch(
      updateStatusByRequestId({ request_id: +id, partner_status: newResponse })
    ).then(() => {
      if (userData?.id) {
        dispatch(getPantnerRequestDetail(id));
      }
    });
  };

  return (
    <main className="p-10 bg-[#FCFCFC]">
      <p>Request</p>
      <div className="flex flex-col gap-2 p-10 border rounded mt-7 border-light-gray">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-6">
            <img
              src={aidIcon}
              style={{ boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)" }}
              className="p-2 rounded shadow-2xl"
            />
            <h2 className="text-4xl font-semibold capitalize">
              {currentRequestData?.category_name} Request
            </h2>
            <span
              style={{
                border: `1px solid ${
                  {
                    "in progress": "#FFCD11",
                    accepted: "#278E1D",
                    wait: "#1D5BBF",
                    rejected: "#DC3545",
                    "on hold": "#E26313",
                    new: "#1D5BBF",
                    completed: "#278E1D",
                  }[currentRequestData?.request_status] || "#CCCCCC"
                }`,

                backgroundColor: `${
                  {
                    "in progress": "#FFF4D4",
                    accepted: "#EAF9EA",
                    wait: "#DCE8FF",
                    rejected: "#FDE7E9",
                    "on hold": "#FDEEE5",
                    new: "#DCE8FF",
                    completed: "#EAF9EA",
                  }[currentRequestData?.request_status] || "#FFFFFF"
                }`,
                color: `${
                  {
                    "in progress": "#FFCD11",
                    accepted: "#278E1D",
                    wait: "#1D5BBF",
                    rejected: "#DC3545",
                    "on hold": "#E26313",
                    new: "#1D5BBF",
                    completed: "#278E1D",
                  }[currentRequestData?.request_status] || "#000000"
                }`,
              }}
              className="px-8 py-2 capitalize rounded-full"
            >
              {currentRequestData?.request_status}
            </span>
          </div>
          <div className="flex gap-4">
            <button className="p-2 border rounded border-light-gray">
              <img src={chatIcon} />
            </button>

            <div className="relative w-40">
              <Listbox value={selectedStatus} onChange={handleChange}>
                {({ open }) => (
                  <>
                    <ListboxButton
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      className={`capitalize text-white  bg-primary-blue border-none rounded w-full cursor-pointer flex items-center justify-between py-2 px-4`}
                    >
                      {open ? (
                        <p className="text-white">Select Status</p>
                      ) : (
                        selectedStatus
                      )}
                      {open ? (
                        <KeyboardArrowUpIcon className="ml-2 text-white" />
                      ) : (
                        <KeyboardArrowDownIcon className="ml-2 text-white" />
                      )}
                    </ListboxButton>
                    {open && (
                      <ListboxOptions className="absolute z-10 w-full mt-2 bg-white border border-gray-200 divide-y divide-gray-100 shadow-lg rounded-b-md focus:outline-none">
                        {filteredOptions.map((option) => (
                          <ListboxOption
                            key={option}
                            value={option}
                            className={`block px-4 py-2 text-sm capitalize ${
                              colors[option] || ""
                            } hover:bg-gray-100`}
                          >
                            {option}
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    )}
                  </>
                )}
              </Listbox>
            </div>
          </div>
        </div>
        <div className="flex gap-4 ml-[92px]">
          <div className="flex gap-2">
            <span>Request ID: </span>
            <span className="underline text-primary-blue">
              {currentRequestData?.id || "-"}
            </span>
          </div>
          <div className="h-6 border-r-2 border-r-light-gray"></div>
          <div className="flex gap-2">
            <span>Request Date: </span>
            <span className="underline text-primary-blue">
              {moment(currentRequestData?.created_at).format("DD/MM/YYYY")}
            </span>
          </div>
          <div className="h-6 border-r-2 border-r-light-gray"></div>
          <div className="flex gap-2">
            <span>Accepted Date: </span>
            <span className="underline text-primary-blue">
              {moment(currentRequestData?.updated_at).format("DD/MM/YYYY")}
            </span>
          </div>
        </div>
        <Link
          to="/partner/requests/requestId/tracking"
          className="ml-[92px] underline text-primary-blue"
        >
          View Tracking Page
        </Link>

        <Table
          tableName="Requester Details"
          rows={[
            {
              label: "First Name",
              value: currentRequestData?.first_name,
            },
            {
              label: "Last Name",
              value: currentRequestData?.last_name,
            },
            {
              label: "Primary Contact Number",
              value: currentRequestData?.contact,
            },
            {
              label: "Secondary Contact Number",
              value: currentRequestData?.alternative_number,
            },
            {
              label: "Address",
              value: currentRequestData?.address,
            },
          ]}
        />

        <Table
          tableName="Request Details"
          rows={[
            { label: "Request Via", value: currentRequestData?.request_via },
            {
              label: "Request Program",
              value: currentRequestData?.request_type,
            },
            {
              label: "Number of Beneficiaries",
              value: currentRequestData?.beneficiaries,
            },
            {
              label: "Urgency Level",
              value: currentRequestData?.urgency_level,
            },
            {
              label: "Description",
              value: currentRequestData?.request_status_desc,
            },
          ]}
        />

        <Table
          tableName="Agent Details"
          rows={[
            {
              label: "Name",
              value:
                (currentRequestData?.agent?.first_name,
                currentRequestData?.agent?.last_name),
            },
            {
              label: "Contact",
              value: currentRequestData?.agent?.contact_number,
            },
            {
              label: "Email Id",
              value: currentRequestData?.agent?.alternative_number,
            },
            { label: "Address", value: currentRequestData?.agent?.address },
          ]}
        />

        <Table
          tableName="Consumer other Details"
          rows={[
            {
              label: "Identification Proff",
              value: currentRequestData?.id_proof,
            },
            {
              label: "Communication Intake",
              value: currentRequestData?.communication_preference,
            },
          ]}
        />

        <Table
          tableName="Accept Comment"
          rows={[
            {
              label: "Description",
              value: currentRequestData?.description,
            },
          ]}
        />

        <Table
          tableName="Completed Comment"
          rows={[
            {
              label: "Description",
              value: currentRequestData?.description,
            },
          ]}
        />

        <button
          className="self-center px-12 py-2 border rounded border-primary-blue text-primary-blue w-max"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </main>
  );
}

export default PartnerRequestDetailView;
