import React, { useEffect, useState } from "react";

import MuiModal from "../../shared/Modal";
import Tooltip from "../../shared/Tooltip";

import CloseIcon from "../../assets/close-red-icon.svg";
import PencilIcon from "../../assets/pencil-icon.svg";
import { useForm } from "react-hook-form";
import { ProfileFields } from "../../utills/formFields";
import { Button } from "../../shared/Button";
import { getUserType, profileDetails } from "../../services/userType";
import { useDispatch } from "react-redux";
import ProfileImageIcon from "../../assets/profile-image-icon.svg";
import ProfileImagePencilIcon from "../../assets/profile-image-pencil-icon.svg";

export default function MyProfile({ open, closeModal, data }) {
  const [actionMode, setActionMode] = useState("");
  const dispatch = useDispatch();
  const [profileImage, setProfileImage] = useState(ProfileImageIcon);
  const [file, setFile] = useState({});

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const onSubmit = (data) => {
    const { profile_photo, ...rest } = data;
    dispatch(profileDetails({ profileData: { ...rest } }))
      .then(() => {
        setActionMode("");
        dispatch(getUserType());
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      readFile(file);
      setFile(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      readFile(file);
    }
  };

  // Helper to read file as DataURL
  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setProfileImage(reader.result); // Update the profile image
    };
    reader.readAsDataURL(file);
  };

  return (
    <MuiModal modalSize="sm" modalOpen={open} modalClose={closeModal} noheader>
      <div
        style={{
          boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.05)",
          borderRadius: "5px",
          border: "1px solid rgba(34, 96, 217, 1)",
        }}
      >
        <div
          className="px-8 py-3 title"
          style={{ borderBottom: "1px solid rgba(34, 96, 217, 1)" }}
        >
          <div className="flex items-center justify-end">
            <Tooltip title="Cancel">
              <img
                src={CloseIcon}
                className="cursor-pointer"
                onClick={closeModal}
                alt="CloseIcon"
              />
            </Tooltip>
          </div>

          <div className="relative flex flex-col items-center justify-center gap-6">
            {/* Conditional image rendering */}
            {actionMode === "" ? (
              <>
                <div className="py-4">
                  <img
                    src={profileImage}
                    alt="ProfileImageIcon"
                    className="cursor-pointer object-cover rounded-full w-24 h-24"
                  />
                </div>
                <h2 className="text-[24px] font-bold capitalize">
                  {data.username}
                </h2>
                <p>Phone Number: {data.phone_number}</p>
                <p>Email id: {data.email}</p>
                <p>Address: {data.address}</p>
                <button
                  style={{
                    border: "1px solid rgba(34, 96, 217, 1)",
                    color: "#2563eb",
                    borderRadius: "4px",
                  }}
                  className="py-3 mt-5 mb-7 px-7 w-[150px]"
                  onClick={() => setActionMode("edit")}
                >
                  Edit
                </button>
              </>
            ) : (
              <div
                className="py-4 relative flex justify-center w-full"
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
              >
                <img
                  src={profileImage}
                  alt="ProfileImageIcon"
                  onClick={() => document.getElementById("fileInput").click()}
                  className="cursor-pointer object-cover rounded-full w-24 h-24"
                />

                <img
                  src={ProfileImagePencilIcon}
                  className="absolute top-[50%] cursor-pointer"
                  alt="ProfileImagePencilIcon"
                  onClick={() => document.getElementById("fileInput").click()}
                />

                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
              </div>
            )}

            {actionMode === "edit" && (
              <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-wrap gap-4">
                    {ProfileFields.map((field, index) => {
                      return (
                        <div
                          className={`relative mb-6 ${field.width}`}
                          key={index}
                        >
                          <label
                            className="block mb-2 text-xs font-normal tracking-wide text-gray-700"
                            htmlFor={field.label}
                          >
                            {field.label}{" "}
                            {/* <span style={{ color: "red" }}>
                              {field?.inputRules?.required ? "*" : ""}
                            </span> */}
                          </label>
                          <div className="relative">
                            {field.type === "input" ? (
                              <input
                                {...register(field.name, field.inputRules)}
                                type={field.fieldType}
                                className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                          focus-visible:outline-none text-[14px] h-[60px]"
                                placeholder={field.placeholder}
                                style={{
                                  color: "#232323",
                                  borderRadius: "3px",
                                }}
                                aria-invalid={!!errors[field.name]}
                              />
                            ) : (
                              <input
                                type="tel"
                                className={`w-full rounded px-3 py-[0.32rem] text-[14px] leading-[2.15] focus:outline-none h-[60px] ${
                                  errors["phone_number"]
                                    ? "focus:border-teal focus:outline-none focus:ring-0"
                                    : ""
                                }`}
                                placeholder="Ex: (555) 555-1234"
                                style={{
                                  color: "#232323",
                                  border: `1px solid ${
                                    errors["phone_number"]
                                      ? "rgb(239 68 68)"
                                      : "rgba(34, 96, 217, 1)"
                                  }`,
                                }}
                                {...register("phone_number", {
                                  required: "This field is required",
                                  pattern: {
                                    value: /^\(\d{3}\) \d{3}-\d{4}$/,
                                    message:
                                      "Please Enter Valid Mobile Number in (415) 555-1234 format",
                                  },
                                })}
                                aria-invalid={
                                  errors["phone_number"] ? "true" : "false"
                                }
                                onChange={(e) => {
                                  let input = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
                                  let formatted = "";

                                  if (input.length > 0)
                                    formatted = `(${input.substring(0, 3)}`;
                                  if (input.length > 3)
                                    formatted += `) ${input.substring(3, 6)}`;
                                  if (input.length > 6)
                                    formatted += `-${input.substring(6, 10)}`;
                                  e.target.value = formatted;
                                }}
                              />
                            )}

                            {errors[field.name] && (
                              <p className="error" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex justify-center gap-6 my-10 align-middle">
                    <Button
                      btnName="Cancel"
                      btnCategory="secondary"
                      btnCls="w-[200px]"
                      onClick={() => setActionMode("")}
                    />
                    <Button
                      btnType="submit"
                      id={"program-submit"}
                      btnCls="w-[200px]"
                      btnName={"Save"}
                      btnCategory="primary"
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </MuiModal>
  );
}
