import {
  createAsyncThunk,
} from "@reduxjs/toolkit";

import api from "./api";


export const getAllCategoryList = createAsyncThunk(
  "getAllCategoryList",
  async (search) => {
    const queryParams = new URLSearchParams();
    if (search) queryParams.append("search", search);
    const getAllCategoryInfo = await api.get(`/agent/category-list?${queryParams}`);
    if (getAllCategoryInfo.status === 200 && getAllCategoryInfo.data) {
      return getAllCategoryInfo.data
    }
    return getAllCategoryInfo
  }
);

export const getCategoryListWithPagination = createAsyncThunk(
  "getAllCategoryList",
  async () => {
    const getAllCategoryInfo = await api.get('/agent/category-list?limit=5');
    if (getAllCategoryInfo.status === 200 && getAllCategoryInfo.data) {
      return getAllCategoryInfo.data
    }
    return getAllCategoryInfo
  }
);

// Get All Category
export const getAllCategory = createAsyncThunk(
  "getAllCategory",
  async () => {
    const listAllCategoryList = await api.get('/agent/category/');
    if (listAllCategoryList.status === 200 && listAllCategoryList.data.result) {
      return listAllCategoryList.data.result
    }
    return listAllCategoryList
  }
);

// Get Category by Id
export const getCategoryById = createAsyncThunk(
  "getCategoryById",
  async (id) => {
    const getCategoryDetails = await api.get(`/agent/category/?category_id=${id}`);
    if (getCategoryDetails.status === 200 && getCategoryDetails.data.result) {
      return getCategoryDetails.data.result
    }
    return getCategoryDetails
  }
);

export const updateCategoryStatus = ({ status, category_id, reason }) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_PARTNER_REQUEST" });
    const response = await api.put(`/agent/category/?category_id=2`, { status, category_id, reason });
    dispatch({ type: "UPDATE_PARTNER_SUCCESS", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_PARTNER_FAILURE", payload: error.message });
  }
};

export const getAddNewCategory = createAsyncThunk(
  "getAddNewCategory",
  async (categoryData, { rejectWithValue }) => {
    try {
      const createCategory = await api.post(`/agent/category/`, categoryData);
      if (createCategory.status === 200 && createCategory.data) {
        return createCategory.data;
      }
      throw new Error("Failed to create category");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }

)

export const updateCategory = createAsyncThunk(
  "category/update",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/agent/category/?category_id=${id}`, updatedData);
      if (response.status === 200 && response.data.result) {
        return response.data.result;
      }
      return rejectWithValue("Failed to update category");
    } catch (error) {
      console.error("Error updating category:", error);
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const CreateCategoryVisibility = createAsyncThunk(
  "CreateCategoryVisibility",
  async (categoryVisibilityData, { rejectWithValue }) => {
    try {
      const createCategoryVisibility = await api.post(`/agent/category-rows/`, categoryVisibilityData);
      if (createCategoryVisibility.status === 200 && createCategoryVisibility.data) {
        return createCategoryVisibility.data.result;
      }
      throw new Error("Failed to Add category");
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }

)

export const getCategoryVisibilityLength = createAsyncThunk(
  "getCategoryVisibilityLength",
  async (id) => {
    const getCategoryVisibility = await api.get(`/agent/category-rows/?target_length=10`);
    if (getCategoryVisibility.status === 200 && getCategoryVisibility.data.result) {
      return getCategoryVisibility.data.result
    }
    return getCategoryVisibility
  }
);








