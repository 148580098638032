import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  AddNewCategoryFields,
  CategoryManagerDetailField,
} from "../../utills/formFields";
import { getCategoryById, getEditCategoryById } from "../../services/category";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router";
import { updateCategory } from "../../services/category";
import { categoryStatus } from "../../utills/formFields";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import SuccessDialog from "../SuccessDialog";
import AddImageIcon from "../../assets/add-image-icon.svg";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

const EditCategory = () => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location || {};
  const rowData = state?.rowData || {};
  console.log("rowData:", rowData);

  const { id } = useParams();

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    watch,
  } = useForm();

  const categoryDetails = useSelector((state) => state.categoryInfo.details);
  console.log("dej", categoryDetails);

  const [imageFiles, setImageFiles] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const updatedFiles = [...imageFiles, ...files];
    setImageFiles(updatedFiles);
  };

  const handleDelete = (fileToDelete) => {
    setImageFiles(imageFiles.filter((file) => file !== fileToDelete));
  };

  useEffect(() => {
    console.log("Current ID:", id);
    if (id) {
      dispatch(getCategoryById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    console.log("Fetched Category Data:", categoryDetails);
    if (categoryDetails) {
      reset({
        id: categoryDetails.id,
        category_title: categoryDetails.name,
        maximum_number_of_beneficiaries: categoryDetails.beneficiaries,
        category_type: categoryDetails.category_type,
        urgency_level: categoryDetails.urgency_level,
        category_description: categoryDetails.description,
        status: categoryDetails.status,
        reason:
          categoryDetails.status === "inactive" ? categoryDetails.reason : "",

        // image: categoryDetails.image,
      });
    }
  }, [categoryDetails, reset]);

  const onSubmit = async (data) => {
    if (data.status === "inactive" && !data.reason) {
      console.error("Reason is required when deactivating the agent.");
      return;
    }

    const editData = {
      id: data.id,
      name: data.category_title,
      beneficiaries: data.maximum_number_of_beneficiaries,
      category_type: data.category_type,
      urgency_level: data.urgency_level,
      description: data.category_description,
      status: data.status,
      // image: imageFiles,
      reason: data.status === "inactive" ? data.reason : "",
    };

    try {
      await dispatch(
        updateCategory({ id: data.id, updatedData: editData })
      ).unwrap();
      setOpen(true);
      setTimeout(() => {
        navigate("/allcategory");
        setOpen(false);
      }, 2000);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  return (
    <>
      <SuccessDialog open={open} onClose={() => setOpen(false)} />
      <div className="px-8 pt-10 bg-[#F9F9F9]">
        <BreadCumbs
          firstCumbName="Dashboard"
          secoundCumbName="Program"
          pathToRedirect="allcategory"
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white custom-shadow rounded-[5px]  w-full px-3 my-10 ">
            <div className="flex justify-between items-center py-6 border-b border-[#E4E6EF]">
              <div className="flex items-center pl-6">
                <p className="text-[#1D5BBF] font-semibold text-3xl border-r border-[#E4E6EF] pr-7 py-1">
                  Emergency Relief Aid
                </p>
                <p className="text-[#353F4F] text-xl font-medium pl-7">
                  Program Id: {id}
                </p>
              </div>
              <button
                type="submit"
                className="rounded-[3.9px] w-[195px] h-[45px] bg-[#2260D9] text-white"
              >
                Save
              </button>
            </div>
            <div className="px-8">
              <div className="flex flex-row w-full items-start gap-10 pt-10  justify-start ">
                <div className="flex  mb-4">
                  {categoryStatus.map((field, index) => {
                    return (
                      <div className="flex flex-col" key={index}>
                        <label
                          className="block tracking-wide text-xs font-normal"
                          htmlFor={field.label}
                        >
                          {field.label}{" "}
                          <span style={{ color: "red" }}>
                            {field?.inputRules?.required ? "*" : ""}
                          </span>
                        </label>
                        <div className="py-2 rounded-[5px] w-60">
                          {field.type === "dropdown" ? (
                            <>
                              <Controller
                                name={field.name}
                                control={control}
                                defaultValue={field.options[0]?.value || ""}
                                rules={{
                                  validate: (value) =>
                                    value === "inactive" && !getValues("reason")
                                      ? "Reason is required when status is 'inactive'."
                                      : true,
                                }}
                                render={({ field: controllerField }) => (
                                  <Listbox
                                    value={controllerField.value}
                                    onChange={(value) =>
                                      controllerField.onChange(value)
                                    }
                                  >
                                    <div className="relative">
                                      <ListboxButton
                                        className={`w-full flex justify-between items-center px-3 
                          focus-visible:outline-none font-semibold text-[14px] h-[60px] text-left border border-[#D9D9D9] bg-white rounded-[5px] ${
                            controllerField.value === "active"
                              ? "text-[#25861D]"
                              : controllerField.value === "inactive"
                              ? "text-[#D22735]"
                              : "text-gray-900"
                          }`}
                                      >
                                        <span className="flex items-center">
                                          {controllerField.value && (
                                            <span
                                              className={`w-3 h-3 rounded-full mr-2 ${
                                                controllerField.value ===
                                                "active"
                                                  ? "bg-[#25861D]"
                                                  : controllerField.value ===
                                                    "inactive"
                                                  ? "bg-[#D22735]"
                                                  : "bg-gray-400"
                                              }`}
                                            />
                                          )}
                                          {controllerField.value
                                            ? field.options.find(
                                                (option) =>
                                                  option.value ===
                                                  controllerField.value
                                              )?.name
                                            : "Select"}
                                        </span>
                                        <KeyboardArrowDownIcon sx={{color: "#353F4F"}}/>
                                      </ListboxButton>
                                      <ListboxOptions className="w-60 absolute z-10 mt-1 font-semibold bg-white rounded-md border border-[#D9D9D9] max-h-60 overflow-auto focus:outline-none">
                                        {field.options
                                          .filter(
                                            (option) =>
                                              option.value !==
                                              controllerField.value
                                          )
                                          .map((option, idx) => (
                                            <ListboxOption
                                              key={idx}
                                              value={option.value}
                                              className={({ active }) =>
                                                `cursor-pointer relative py-4 px-4 flex items-center ${
                                                  active
                                                    ? "bg-blue-600"
                                                    : option.value === "active"
                                                    ? "text-[#25861D]"
                                                    : option.value ===
                                                      "inactive"
                                                    ? "text-[#D22735]"
                                                    : "text-gray-900"
                                                }`
                                              }
                                            >
                                              <span
                                                className={`w-3 h-3 rounded-full mr-2 ${
                                                  option.value === "active"
                                                    ? "bg-[#25861D]"
                                                    : option.value ===
                                                      "inactive"
                                                    ? "bg-[#D22735]"
                                                    : "bg-gray-400"
                                                }`}
                                              />
                                              {option.name}
                                            </ListboxOption>
                                          ))}
                                      </ListboxOptions>
                                    </div>
                                  </Listbox>
                                )}
                              />
                              {errors[field.name] && (
                                <p className="error" role="alert">
                                  {errors[field.name].message}
                                </p>
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-col w-full md:w-1/2 gap-1.5">
                  {watch("status") === "inactive" && (
                    <>
                      <label
                        htmlFor="Comment"
                        className="text-[#353F4F] text-[12px] flex items-center"
                      >
                        Reason{" "}
                        <span className=" ml-1" style={{ color: "red" }}>
                          *
                        </span>
                      </label>
                      <textarea
                        id="reason"
                        name="reason"
                        rows={6}
                        {...register("reason", {
                          required:
                            "Reason is required when deactivating the agent.",
                        })}
                        className="block w-full rounded-md h-[60px] bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-[#D6E5EE] placeholder:text-gray-400 focus:outline-2 focus:outline-indigo-600 sm:text-sm"
                        placeholder="Enter your reason for changing the status"
                      />
                      {errors.reason && (
                        <p className="error" role="alert">
                          {errors.reason.message}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="pt-6">
                <div className="flex flex-wrap gap-4">
                  {AddNewCategoryFields.map((field, index) => {
                    return (
                      <div
                        className={`relative mb-6  ${field.width}`}
                        key={index}
                      >
                        <label
                          className="block tracking-wide text-gray-700 text-xs font-normal mb-2"
                          htmlFor={field.label}
                        >
                          {field.label}{" "}
                          <span style={{ color: "red" }}>
                            {field?.inputRules?.required ? "*" : ""}
                          </span>
                        </label>
                        {field.type === "input" ? (
                          <div className="relative">
                            <input
                              {...register(field.name, field.inputRules)}
                              type={field.fieldType}
                              className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]"
                              placeholder={field.placeholder}
                              style={{
                                color: "#232323",
                                borderRadius: "3px",
                              }}
                              aria-invalid={!!errors[field.name]}
                            />
                            {/* {field.icon && field.icon === "location" && (
                      <img
                        className="absolute top-4 right-4"
                        src={LocationIcon}
                        alt="LocationIcon"
                      />
                    )} */}

                            {errors[field.name] && (
                              <p className="error" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </div>
                        ) : (
                          <>
                            <select
                              {...register(field.name, field.inputRules)}
                              className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]"
                              placeholder={field.placeholder}
                              style={{
                                color: "#232323",
                                borderRadius: "3px",
                                borderRight: "16px solid transparent",
                              }}
                            >
                              <option value="">Select</option>
                              {field.options.map((option, index) => {
                                return (
                                  <option value={option.value} key={index}>
                                    {option.name}
                                  </option>
                                );
                              })}
                            </select>
                            {errors[field.name] && (
                              <p className="error" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div
                  className="pt-5 mb-5"
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  <div className="flex flex-wrap gap-4">
                    {CategoryManagerDetailField.filter(
                      (field) => field.name === "category_description"
                    ).map((field, index) => {
                      return (
                        <div
                          className={`relative mb-6 ${field.width} ${field.height}`}
                          key={index}
                        >
                          <label
                            className="block tracking-wide text-gray-700 text-xs font-normal mb-2"
                            htmlFor={field.label}
                          >
                            {field.label}{" "}
                            <span style={{ color: "red" }}>
                              {field?.inputRules?.required ? "*" : ""}
                            </span>
                          </label>
                          <textarea
                            {...register(field.name, field.inputRules)}
                            className={`w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                    focus-visible:outline-none text-[14px] ${field.height}`}
                            placeholder={field.placeholder}
                            style={{
                              color: "#232323",
                              borderRadius: "3px",
                            }}
                            aria-invalid={!!errors[field.name]}
                          ></textarea>
                          {errors[field.name] && (
                            <p className="error" role="alert">
                              {errors[field.name].message}
                            </p>
                          )}
                        </div>
                      );
                    })}
                    <div className="relative pt-10 w-full">
                      <label
                        className="block tracking-wide text-gray-700 text-xs font-normal mb-2 text-[#2260D9]"
                        htmlFor="fileInput"
                      >
                        Attachments
                      </label>
                      <div className="flex items-center justify-center w-full">
                        <label
                          htmlFor="fileInput"
                          className="flex flex-col items-center justify-center w-full h-64 cursor-pointer
                    bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100
                    dark:hover:bg-gray-600"
                          style={{
                            border: "2px dashed rgba(34, 96, 217, 1)",
                          }}
                        >
                          <div className="relative flex flex-col items-center justify-center gap-2 bg-[#F1F6FF] w-full h-full p-5 rounded cursor-pointer">
                            <Stack direction="row" spacing={1}>
                              {imageFiles.map((file, index) => (
                                <Chip
                                  key={index}
                                  label={file.name}
                                  onDelete={() => handleDelete(file)}
                                  sx={{
                                    backgroundColor: "#F0F0F0",
                                    color: "#2260D9",
                                    ".MuiChip-deleteIcon": {
                                      color: "#FF0000",
                                    },
                                  }}
                                />
                              ))}
                            </Stack>
                            <input
                              type="file"
                              name="image"
                              accept="image/*"
                              className="absolute inset-0 opacity-0 cursor-pointer"
                              onChange={handleImageChange}
                            />

                            <img
                              src={AddImageIcon}
                              alt="Placeholder Icon"
                              className="w-[40px] h-[40px] object-cover"
                            />
                            <p className="text-[#C6C6C6] text-[14px] text-center w-full">
                              Add / Browse
                            </p>
                          </div>

                          <input
                            id="fileInput"
                            type="file"
                            className="hidden"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex w-full justify-center gap-6 mt-16 pb-20">
                  <button className="border-[#353F4F] border-[1px] text-[#353F4F] rounded-[3.9px] w-[195px] h-[50px] active:bg-[#2260D9] active:text-white">
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="rounded-[3.9px] w-[195px] h-[50px] bg-[#2260D9] text-white"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditCategory;
