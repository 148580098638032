import React, { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DataTable from "../../shared/DataGrid";
import { useDispatch, useSelector } from "react-redux";
import ViaIcon from "../../assets/via.svg";
import {
  addPartnersRequest,
  getAllPartnerList,
  addPartnersInCategory,
  submitPartner,
} from "../../services/partner";
import { useNavigate } from "react-router";
import { Button } from "../../shared/Button";
import CloseIcon from "../../assets/close-red-icon.svg";
import SearchIcon from "../../assets/input-search-icon.svg";
import SilverBadge from "../../assets/silver-badge.svg";
import GoldenBadge from "../../assets/golden-badge.svg";
import BronzeBadge from "../../assets/bronze-badge.svg";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import Right from "../../assets/right.svg";
import NoBadge from "../../assets/nobadge.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AddPartnersInCategories({
  open,
  setOpen,
  id,
  categoryId,
  setId,
  fromRequest,
  getRequest,
}) {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.userInfo.userType);
  const partnerInfo = useSelector(
    (state) => state?.partnerInfo?.partnerData?.data
  );
  const total = useSelector(
    (state) => state?.partnerInfo?.partnerData?.total_pages
  );
  console.log("partnerInfo", partnerInfo);

  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [typeFilters, setTypeFilters] = useState({
    internal: true,
    external: false,
  });

  console.log({ partnerInfo });

  React.useEffect(() => {
    if (categoryId) {
      dispatch(getAllPartnerList({ status: null, category_id: categoryId }));
    }
  }, [categoryId]);
  console.log("category_id", categoryId);
  const handleClose = () => {
    setOpen(false);
  };

  const onChangeHandle = (isChecked, parterId) => {
    if (isChecked) {
      setSelected([...selected, parterId]);
    } else {
      const updatedIds = selected.filter((id) => id !== parterId);
      setSelected(updatedIds);
    }
  };

  const PartnersColumn = [
    // {
    //   field: "partners",
    //   headerName: "select partner",
    //   flex: 1,
    //   id: 4,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Checkbox
    //           onChange={(_, checked) => onChangeHandle(checked, params?.id)}
    //         />
    //       </>
    //     );
    //   },
    // },
    {
      field: "partners",
      headerName: "",
      maxWidth: 80,
      id: 4,
      renderCell: (params) => {
        return (
          <>
            <div className="flex items-center justify-center h-full">
              <input
                className="rounded-sm w-4 h-4 border-[#6F6F6F] text-[#607274] focus:ring-[#C6D8DA]"
                type="checkbox"
                // checked={(e) => onChangeHandle(e.target.checked, params?.id)}
                onChange={(e) => onChangeHandle(e.target.checked, params?.id)}
              />
            </div>
          </>
        );
      },
    },

    // {
    //   field: "partner_id",
    //   headerName: "Partner ID",
    //   flex: 1,
    //   id: 0,
    // },
    {
      field: "full_name",
      headerName: "Name",
      flex: 1,
      id: 1,
    },
    ...(fromRequest
      ? [
          {
            field: "email",
            headerName: "Email",
            flex: 1,
            id: 2,
          },
          {
            field: "contact_number",
            headerName: "Contact Number",
            flex: 1,
            id: 2,
          },
        ]
      : [
          {
            field: "badge",
            headerName: "Badge",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => {
              const badgeIcons = {
                Silver: SilverBadge,
                Gold: GoldenBadge,
                Bronze: BronzeBadge,
                "No Badge": NoBadge,
              };

              const badge = params.value;
              const badgeIcon = badgeIcons[badge];

              return (
                <div className="flex items-center h-full justify-center ">
                  {badgeIcon ? (
                    <img
                      src={badgeIcon}
                      alt={`${badge} badge`}
                      className="w-6 h-6"
                    />
                  ) : (
                    <span>{badge}</span>
                  )}
                </div>
              );
            },
          },

          {
            field: "category_name",
            headerName: "Program",
            flex: 1,
          },
          {
            field: "totle_employees",
            headerName: "Manage Request",
            headerAlign: "center",
            align: "center",
            flex: 1,
            id: 1,
          },
          {
            field: "",
            headerName: "View",
            headerAlign: "center",
            align: "center",
            flex: 1,
            id: 1,
            renderCell: (params) => (
              <button
                className="my-1"
                // onClick={() => handleViewProfile(params.row)}
              >
                <p className="px-4 h-10 text-[#007bff] bg-[#F0F5FF] text-[13px] font-medium">
                  View Profile
                </p>
              </button>
            ),
          },
        ]),
  ];

  const rows = Array.isArray(partnerInfo)
    ? partnerInfo?.map((partner) => ({
        id: partner.id,
        partner_id: partner.id,
        categoryId: partner.id,
        full_name: partner.first_name || partner.organization_name,
        badge: partner.badge,
        // partner_type: partner.partner_type,
        category_name: partner.name,
        last_update_date: partner.created_at,
        totle_employees: partner.total_org_employee,
        status: partner.status,
        email: partner.email,
        contact_number: partner.contact_number,
        partner_type: partner.partner_type,
      }))
    : [];
  // console.log('row',rows)

  const searchFilteredRows = useMemo(() => {
    let matchesSearch = rows;
    matchesSearch = matchesSearch.filter((row) => {
      // Type filter logic
      if(!searchQuery) return row
      const matchesSearch =
      !searchQuery ||
      row.full_name?.toLowerCase().includes(searchQuery.toLowerCase());

      return matchesSearch;
    });
    matchesSearch = matchesSearch.filter((row) => {
    //   // Type filter logic
      const matchesType =
        (!typeFilters.internal && !typeFilters.external) ||
        (typeFilters.internal && row.partner_type === "internal") ||
        (typeFilters.external && row.partner_type === "external");

      return  matchesType;
    });
    return matchesSearch;
  }, [rows, searchQuery, typeFilters]);

  console.log("rows", {searchFilteredRows});

  const handleTypeFilterChange = (type) => {
    setTypeFilters({
      internal: type === "internal", // Set true if "internal" is selected, otherwise false
      external: type === "external", // Set true if "external" is selected, otherwise false
    });
  };
  const onClickOnSubmit = async () => {
    let payload;
    if (fromRequest) {
      payload = {
        partners: selected,
        request_id: id,
        // "request_status": "in progress",
      };
    } else {
      payload = {
        partners_ids: selected,
        category_id: id,
        // "partner_status": "accepted",
      };
    }

    try {
      let response;
      if (fromRequest) {
        response = await dispatch(addPartnersRequest(payload)).unwrap();
      } else {
        response = await dispatch(addPartnersInCategory(payload)).unwrap();
      }
      if (fromRequest) {
        // navigate(`/request-tracking`);
        await getRequest();
      } else {
        navigate(`/externalcategory/${id}`);
      }
    } catch (error) {
      console.error("API call failed:", error);
    } finally {
      setId("");
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <BootstrapDialog onClose={handleClose} open={open} maxWidth="xl">
        <DialogContent>
          <div className="flex items-center justify-between border-b border-[#D9E4F2] py-3">
            <p className="text-[#353F4F] font-medium text-xl">Assign</p>
            <img
              src={CloseIcon}
              onClick={handleClose}
              className="cursor-pointer"
            />
          </div>
          <div className="relative flex justify-between py-7">
            <div className="flex justify-center items-center gap-4">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  value="internal"
                  className="hidden peer"
                  checked={typeFilters.internal}
                  onClick={() => handleTypeFilterChange("internal")}
                />
                <span className="w-5 h-5 cursor-pointer border-2 border-[#D2E2FF] rounded-[3px] flex items-center justify-center peer-checked:border-transparent">
                  {typeFilters.internal && <img src={Right} alt="right" />}
                </span>
                <span className="text-[14px]">Internal</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  value="external"
                  className="hidden peer1"
                  checked={typeFilters.external}
                  onClick={() => handleTypeFilterChange("external")}
                />
                <span className="w-5 h-5 cursor-pointer border-2 border-[#D2E2FF] rounded-[3px] flex items-center justify-center peer1-checked:border-transparent">
                  {typeFilters.external && <img src={Right} alt="right" />}
                </span>
                <span className="text-[14px]">External</span>
              </label>
            </div>
            <input
              type="text"
              id="search-navbar"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="block w-full  p-2 text-sm placeholder:text-[#C8C9C9] border-none focus:outline-none rounded-[3px]"
              placeholder="Search here"
              style={{
                border: "1px solid #2260D9",
                height: "40px",
                width: "40%",
              }}
            />
            <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
            {/* <p className="left-0 absolute bottom-0 text-[#A9A9A9] text-sm">
              Note : Only External Partner can be Added to External Programs
            </p> */}
          </div>
          {console.log({searchFilteredRows},'called')}
          <DataTable
            className="!w-[800px] !h-[350px]"
            rows={searchFilteredRows || []}
            columns={PartnersColumn}
            hideCheckbox
            hideFooter
          />
          <div className="flex justify-center gap-6 mt-4  align-middle">
            <Button
              btnName="Cancel"
              btnCategory="secondary"
              btnCls="w-[200px]"
              onClick={handleClose}
            />
            <Button
              id={"program-submit"}
              btnCls="w-[200px]"
              btnName={
                fromRequest || userType === "admin"
                  ? "Submit Request"
                  : "Add to Program"
              }
              btnCategory="primary"
              onClick={onClickOnSubmit}
            />
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
