import { createSlice } from "@reduxjs/toolkit";
import { loginData } from "../../services/login";
import { forgotPassword } from "../../services/login";
import { verifyResetCode } from "../../services/login";
import { setNewPassword } from "../../services/login";

const initialState = {
    userType: '',
    data: {},
    loading: false,
    status: '',
    error: null,
}

const loginSlice = createSlice ({
    name:"loginInfo",
    details:{},
    initialState,
    reducers: { },

    extraReducers: (builder) => {
        builder
        .addCase(loginData.pending, (state)=>{
           return{
            ...state,
            loading: true
           } 
        })
       
        .addCase(loginData.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload; 
            state.error = null;
          })
        .addCase(loginData.rejected, (state, action) => {
          
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        })

        builder
        .addCase(forgotPassword.pending, (state)=>{
           return{
            ...state,
            loading: true
           } 
        })
       
        .addCase(forgotPassword.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload; 
            state.error = "";
          })
        .addCase(forgotPassword.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.error.message,
            };
        })

        builder
        .addCase(verifyResetCode.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(verifyResetCode.fulfilled, (state,action) => {
          console.log('payload',action.payload)
          console.log('token',action.payload.token)
          state.loading = false;
          state.otpVerified = true;
          state.data = action.payload;
          state.token = action.payload.token
        })
        .addCase(verifyResetCode.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })


        builder
        .addCase(setNewPassword.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(setNewPassword.fulfilled, (state, action) => {
          state.loading = false;
          // state.token = action.payload.token;
          state.data = action.payload
        })
        .addCase(setNewPassword.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });

    
        // builder.addCase(loginData.fulfilled, (state, action) => {
        //     localStorage.setItem('userType',"admin")
            
        //     return {
        //         ...state,
        //         loading: false,
        //         data: action.payload,
        //         userType: 'admin',
        //         // status: '',
        //         error: ''
        //     }
        // })
    }

})

export default loginSlice.reducer;

