import React, { useState } from "react";
import SuccessImage from "../../assets/success.gif";
import CloseIcon from "../../assets/close-red-icon.svg";
import { useLocation, useNavigate } from "react-router";

const Success = () => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    setIsVisible(false);
  };

  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");
  const id = queryParams.get("id");

  if (!isVisible) return null;
  // const handleDownloadInvoice = async () => {
  //   if (onDownloadInvoice) {
  //     onDownloadInvoice();
  //   } else {
  //     try {
  //       const response = await fetch('/api/download-invoice');
  //       const blob = await response.blob();
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = 'invoice.pdf';
  //       document.body.appendChild(a);
  //       a.click();
  //       a.remove();
  //     } catch (error) {
  //       console.error('Error downloading invoice:', error);
  //     }
  //   }
  // };

  return (
    <div className=" bg-white flex items-center justify-center p-4 border border-gray-200 rounded-lg border-[#2260D9] mx-8 my-12 relative">
      {/* Close Icon */}
      <img
        src={CloseIcon}
        // onClick={() => navigate(`/request-tracking?id=${id}&status=${status}`)}
        onClick={() => navigate(`/request-tracking`)} // Add appropriate navigation logic
        // onClick={handleClose}
        alt="Close"
        className="absolute top-4 right-4 w-6 h-6 cursor-pointer"
      />
      {/* Container with border */}
      <div className="px-8 py-24 w-full flex flex-col items-center">
        {/* Success GIF */}
        <div className="mb-8">
          <img src={SuccessImage} alt="Payment Success" className="w-32 h-32" />
        </div>

        {/* Success Content */}
        <div className="text-center">
          {/* Success Message */}
          <h1 className="text-[#2260D9] text-4xl font-medium mb-6">
            Payment Success..!
          </h1>

          {/* Thank you Message */}
          <p className="text-gray-600 text-lg mb-12">
            Thank you for your trust and support!
          </p>

          {/* Download Button */}
          <button
            // onClick={handleDownloadInvoice}
            className="bg-[#2260D9] text-white px-8 py-3 rounded hover:bg-blue-700 transition-colors text-lg"
          >
            Download Invoice
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
