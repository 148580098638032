import React from 'react'
import Dialog from "@mui/material/Dialog";
import BlueTickicon from "../assets/images/bluetick-icon.jpg"
export const SuccessDialogagent = ({ open, onClose, message }) => {
   return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "10px", 
          },
        }}
      >
        <div className="flex flex-col px-16 py-8 items-center gap-5">
          <img
            src={BlueTickicon}
            alt="Tick Animation"
            width="61"
            height="80"
          />
  
          <p className="text-black font-medium text-base">Changes Saved Successfully.</p>
        </div>
      </Dialog>
    );
}
