import { createSlice } from "@reduxjs/toolkit";
import {
  createNewPartnerRequest,
  getPartnerList,
  getAllPartnerList,
  getTotalEmployeesColumnList,
  getParterDetailsbyId,
  addPartnersInCategory,
  addPartnersRequest,
  fetchPartnerCategories,
  createBgVerificationRequest,
  getRegisterPartnerList,
  getRegisterStaffList,
  getRegisterPartnerCount,
  getRegisterStaffCount,
  docuSign,
  getPartner,
} from "../../services/partner";

const initialState = {
  partnerdata: [],
  details: {},
  bgVerifications: {},
  partnerDetails: {},
  partnerList: [],
  registerPartnerList: [],
  registerPartnerCount:{},
  registerStaffCount:{},
  registerStaffList: [],
  document: {},
  loading: false,
  status: "",
  error: "",
};

export const partnerSlice = createSlice({
  name: "partnetInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNewPartnerRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewPartnerRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
        state.status = "Partner Created Successfully";
      })
      .addCase(createNewPartnerRequest.rejected, (state, action) => {
        state.loading = false;
        state.error =
          typeof action.payload === "object"
            ? action.payload
            : { message: action.payload || "Something went wrong!" };
      });

    builder
    .addCase(createBgVerificationRequest.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(createBgVerificationRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.bgVerifications = action.payload;
      state.status = "bg verification Successfully";
    })
    .addCase(createBgVerificationRequest.rejected, (state, action) => {
      state.loading = false;
      state.error =
        typeof action.payload === "object"
          ? action.payload
          : { message: action.payload || "Something went wrong!" };
    });

    builder
      .addCase(getAllPartnerList.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllPartnerList.fulfilled, (state, action) => {
        return {
          ...state,
          partnerData: action.payload,
          loading: false,
        };
      })
      .addCase(getAllPartnerList.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

    builder
      .addCase(getRegisterPartnerCount.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getRegisterPartnerCount.fulfilled, (state, action) => {
        return {
          ...state,
          registerPartnerCount: action.payload,
          loading: false,
        };
      })
      .addCase(getRegisterPartnerCount.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

    builder
      .addCase(getRegisterStaffCount.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getRegisterStaffCount.fulfilled, (state, action) => {
        return {
          ...state,
          registerStaffCount: action.payload,
          loading: false,
        };
      })
      .addCase(getRegisterStaffCount.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

    builder
      .addCase(getPartnerList.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getPartnerList.fulfilled, (state, action) => {
        return {
          ...state,
          partnerList: action.payload,
          loading: false,
        };
      })
      .addCase(getPartnerList.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

      builder
      .addCase(getPartner.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getPartner.fulfilled, (state, action) => {
        return {
          ...state,
          partnerDetails: action.payload,
          loading: false,
        };
      })
      .addCase(getPartner.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

    builder
      .addCase(getRegisterPartnerList.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getRegisterPartnerList.fulfilled, (state, action) => {
        return {
          ...state,
          registerPartnerList: action.payload,
          loading: false,
        };
      })
      .addCase(getRegisterPartnerList.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

      builder
      .addCase(getRegisterStaffList.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getRegisterStaffList.fulfilled, (state, action) => {
        return {
          ...state,
          registerStaffList: action.payload,
          loading: false,
        };
      })
      .addCase(getRegisterStaffList.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

    builder
      .addCase(getTotalEmployeesColumnList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTotalEmployeesColumnList.fulfilled, (state, action) => {
        state.details = Array.isArray(action.payload) ? action.payload : [];
        state.loading = false;
      })
      .addCase(getTotalEmployeesColumnList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(getParterDetailsbyId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getParterDetailsbyId.fulfilled, (state, action) => {
        state.details = action.payload;
        state.loading = false;
      })
      .addCase(getParterDetailsbyId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.error("Error fetching partner details:", action.error.message);
      });

    builder
      .addCase(addPartnersInCategory.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(addPartnersInCategory.fulfilled, (state, action) => {
        return {
          ...state,
          details: action.payload,
          loading: false,
        };
      })
      .addCase(addPartnersInCategory.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

    builder
      .addCase(addPartnersRequest.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(addPartnersRequest.fulfilled, (state, action) => {
        return {
          ...state,
          details: action.payload,
          loading: false,
        };
      })
      .addCase(addPartnersRequest.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });
      builder
      .addCase(fetchPartnerCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPartnerCategories.fulfilled, (state, action) => {
        console.log("Fetched Partner Categories:", action.payload);
        state.loading = false;
        state.details = action.payload || [];
      })
      .addCase(fetchPartnerCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

      builder
      .addCase(docuSign.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(docuSign.fulfilled, (state, action) => {
        state.loading = false;
        state.document = action.payload;
      })
      .addCase(docuSign.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

      
  },
});

export default partnerSlice.reducer;
