import React, { useEffect, useRef, useState } from "react";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import DocumentIcon from "../../assets/file-black-icon.svg";
import DownloadIcon from "../../assets/download-icon.svg";
import UploadIcon from "../../assets/upload-icon.svg";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import clsx from "clsx";

const people = [
  { id: 1, name: "All Partner Table" },
  { id: 2, name: "All Agent Table" },
  { id: 3, name: "All Request Table" },
  { id: 4, name: "All Admin Table" },
];

const BulkUpload = () => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const storedFiles = JSON.parse(localStorage.getItem("uploadedFiles")) || [];
    setUploadedFiles(storedFiles);
  }, []);

  const handleFileClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      console.log("Selected file:", file.name);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setUploadStatus("Please select a file before uploading.");
      return;
    }

    try {
      setIsUploading(true);
      setUploadStatus("Uploading...");

      // Simulate upload delay
      await new Promise((resolve) => setTimeout(resolve, 2000));

      setIsUploading(false);
      setUploadStatus("File uploaded successfully!");

      // Add file details to uploadedFiles array
      const fileDetails = {
        name: selectedFile.name,
        type: selectedFile.type.split("/")[1].toUpperCase(), // Extract type and format to uppercase
        size: (selectedFile.size / (1024 * 1024)).toFixed(2), // Convert bytes to MB with 2 decimal points
      };

      const updatedFiles = [...uploadedFiles, fileDetails];
      setUploadedFiles(updatedFiles);

      // Save updated files to localStorage
      localStorage.setItem("uploadedFiles", JSON.stringify(updatedFiles));

      setSelectedFile(null); // Clear the selected file
    } catch (error) {
      setIsUploading(false);
      setUploadStatus("Failed to upload the file.");
      console.error(error);
    }
  };

  return (
    <div className="px-8 pt-10 bg-[#FCFCFC]">
      <BreadCumbs
        firstCumbName="Dashboard"
        secoundCumbName="Import/Bulk Upload"
        pathToRedirect="allcategory"
      />
      <div className="bg-white custom-shadow rounded-[5px] px-9 my-10 py-10">
        <p className="font-medium text-[#18283D] text-[22px]">
          Import/Bulk Upload
        </p>
        <div className="w-full flex justify-center mt-20">
          <div className="w-[600px] ">
            <h4 className="text-[#353F4F] text-[16px] font-semibold font-sans">
              Select Target table
            </h4>
            <div>
              <p className="text-[#A9A9A9] text-[12px] font-normal mb-2 w-full text-end">
                Uploaded dataset will reflect in this targeted table
              </p>
              <div className="">
                <Listbox value={selected} onChange={setSelected}>
                  <ListboxButton
                    className={`w-full flex px-3 py-4 bg-[#FCFCFC] border border-[#A9A9A9] text-sm h-[60px] rounded-[10px] text-[#9ca3af]
                  }`}
                  >
                    {selected ? (
                      <p>{selected.name}</p>
                    ) : (
                      <p>
                        Select the destination table for data upload
                      </p>
                    )}
                  </ListboxButton>
                  <ListboxOptions
                    anchor="bottom"
                    transition
                    className="absolute w-[40%] z-10 mt-1 bg-white border border-[#F2F2F2] rounded-[3px] divide-y divide-[#D3D3D3]"
                  >
                    {people.map((person) => (
                      <ListboxOption
                        key={person.name}
                        value={person}
                        className=" flex cursor-default gap-2 py-4 px-3 border-b-[#F2F2F2] focus-outline-none  data-[focus]:bg-white/10"
                      >
                        <div className="text-[#353F4F]">{person.name}</div>
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </Listbox>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center my-12">
          <div className="flex flex-col items-center gap-5 border border-dashed border-[#9A9A9A] pt-28 pb-12 rounded-[10px] w-[600px] font-medium">
            <img src={UploadIcon} />
            <p
              className="text-[#18283D] text-lg cursor-pointer"
              onClick={handleFileClick}
            >
              Drag & Drop or{" "}
              <span className="text-[#2260D9] underline underline-offset-[3px] decoration-[1px]">
                Choose file
              </span>{" "}
              to upload
            </p>
            <p className="text-[#18283D] text-sm mt-4">
              {selectedFile
                ? `Selected File: ${selectedFile.name}`
                : "No file chosen"}
            </p>
            <p className="text-[#565656] text-sm">XLSX or CSV</p>
            {/* Hidden file input */}
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              accept=".xlsx, .csv"
              onChange={handleFileChange}
            />
          </div>
          <button
            className="flex items-center justify-center bg-[#2260D9] text-white font-semibold text-base rounded-[3px] w-[180px] h-[50px] mt-10 mb-20"
            onClick={handleFileUpload}
          >
            {isUploading ? "Uploading..." : "Upload"}
          </button>
          {uploadStatus && (
            <p
              className={`mt-2 ${
                uploadStatus.includes("Failed")
                  ? "text-red-500"
                  : "text-green-500"
              }`}
            >
              {uploadStatus}
            </p>
          )}
          <div>
            <div className="flex flex-col gap-8">
              <p className="text-[#353F4F] font-semibold text-base">
                Template File to Download
              </p>

              <div className="">
                {uploadedFiles.length === 0 ? (
                  <p className="text-[#B1B1B1] text-sm">
                    No files uploaded yet.
                  </p>
                ) : (
                  uploadedFiles.map((file, index) => (
                    <div
                      key={index}
                      className="bg-[#FCFCFC] flex items-center justify-between border border-[#A9A9A9] rounded-[10px] w-[600px] p-2 mt-2"
                    >
                      <div className="flex gap-4">
                        <div className="p-0.5 border border-[#E1E1E1] rounded-[5px]">
                          <div className="bg-[#E6FCEE] w-[46px] h-[49px] flex justify-center items-center rounded-[5px]">
                            <img src={DocumentIcon} />
                          </div>
                        </div>
                        <div className="flex flex-col justify-between py-0.5">
                          <p className="text-[#353F4F] text-base">
                            {file.name}
                          </p>
                          <div className="flex items-center gap-0.5 text-[#B1B1B1] text-xs">
                            <p>{file.type}</p>
                            <p className="font-semibold">⋅</p>
                            <p>{file.size}</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between gap-3 py-2 px-3 border border-[#E1E1E1] rounded-[5px]">
                        <img src={DownloadIcon} />
                        <p className="text-[#353F4F] text-xs">Download</p>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkUpload;
