import React, { useState, useEffect } from "react";
import Switch from "../../shared/Switch";
import { useDispatch, useSelector } from "react-redux";
import { getPartnerRequestById } from "../../services/partnerRequest";
import { useNavigate } from "react-router";
import { getUserType, profileDetails } from "../../services/userType";
import { getPartner, updatePartnerStatus } from "../../services/partner";

function Cards() {
  const navigate = useNavigate();

  const userData = useSelector((state) => state.userInfo.data);
  const id = userData.id;

  const dispatch = useDispatch();
  const requestData = useSelector((state) => state.admin.details.requests);
  const user = useSelector((state) => state.userTypeData.details);
  const partnerInfo = useSelector((state) => state.partnerInfo.partnerDetails);

  const partnerData = useSelector(
    (state) => state.partnerRequest.partnerRequest
  );

  useEffect(() => {
    if (id) {
      dispatch(getPartnerRequestById(id));
      dispatch(getPartner(id));
    }
  }, [dispatch, id]);

  const CardsData = [
    {
      id: 1,
      title: "Total Request",
      count: partnerData?.total_requests || 0,
      border: "border-[#353F4F]",
      bgColor: "bg-white",
      additionalClass: "bg-[#596A82]",
      status: "all",
    },

    {
      id: 2,
      title: "New Request",
      count: partnerData?.new_requests || 0,
      border: "border-[#1C65F3]",
      bgColor: "bg-[#ECF2FF]",
      additionalClass: "bg-[#2260D9]",
      status: "new",
    },

    {
      id: 3,
      title: "Completed Request",
      count: partnerData?.completed_requests || 0,
      border: "border-[#17900B]",
      bgColor: "bg-[#F1FFEF]",
      additionalClass: "bg-[#12B347]",
      status: "completed",
    },
  ];

  const handleChange = (value) => {
    dispatch(
      updatePartnerStatus({
        partner_id: partnerInfo.id,
        status: value ? "active" : "inactive",
        // reason: "inactive",
      })
    ).then(() => {
      dispatch(getPartner(id));
    });
  };

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-3 flex flex-col justify-center gap-3 text-[#1D5BBF] px-8 bg-[#D3E2FF] rounded custom-shadow">
        <h2 className="font-semibold text-[30px] capitalize">
          Welcome, {user.username}
        </h2>
        <span>Partner Organization</span>
      </div>

      <div className="col-span-6 grid grid-cols-3 gap-6">
        {CardsData && CardsData.length > 0 ? (
          CardsData.map((data) => (
            <div
              key={data.id}
              onClick={() =>
                navigate(`/requests?status=${data.status}&id=${id}`)
              }
              className={`col-span-1 px-1 rounded custom-shadow flex flex-col border ${data.border} ${data.bgColor}`}
            >
              <div className="px-4 flex justify-between gap-2 py-8  border-b border-[#D9D9D9]">
                <p className="font-medium text-[17px] text-wrap capitalize">
                  {data.title}
                </p>
                <div
                  className={`px-5 py-1.5 flex  justify-center rounded  ${data.additionalClass} text-white text-[30px]`}
                >
                  {data.count}
                </div>
              </div>
              <div className="text-xs px-4 py-1.5">
                <span>Metrics: </span>
                <span className="text-primary-blue">Current Month</span>
              </div>
            </div>
          ))
        ) : (
          <p className="col-span-3 text-center text-[#353F4F]">
            No request data available.
          </p>
        )}
      </div>
      <div className="col-span-3 px-5 rounded custom-shadow flex items-center bg-white justify-between">
        <div className="flex flex-col gap-1.5">
          <h2 className="font-medium text-4xl">Availability</h2>
          {/* <p className="text-[#353F4F]">
            Choose the option you want as your default setting.
          </p> */}
        </div>
        <span>
          <Switch
            value={partnerInfo?.status === "active"}
            onChange={handleChange}
          />
        </span>
      </div>
    </div>
  );
}

export default Cards;
