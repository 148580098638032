import React from 'react'

export const titleCase = (text) => {
    if (!text) return ""; // Handle null or undefined gracefully
    const smallWords = [
      "and", "or", "but", "the", "a", "an", "of", "in", "on", "at", "for", "to", "with",
    ];
    console.log({text})
    return text?.toString()
      ?.toLowerCase()
      ?.split(" ")
      ?.map((word, index) =>
        smallWords.includes(word) && index !== 0
          ? word // Keep small words lowercase (except for the first word)
          : word.charAt(0).toUpperCase() + word.slice(1) // Capitalize significant words
      )
      ?.join(" ");
  };
