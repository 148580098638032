import React, { useEffect, useState } from "react";
import DataTable from "../../shared/DataGrid";
import MoreIcon from "../../assets/more-icon.svg";
import { getPartnertList } from "../../services/partnerList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import SilverBadge from "../../assets/silver-badge.svg";
import GoldenBadge from "../../assets/golden-badge.svg";
import BronzeBadge from "../../assets/bronze-badge.svg";
import Tooltip from "../../shared/Tooltip";
import NoBadge from "../../assets/nobadge.svg";
const TopPartnerList = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    dispatch(getPartnertList());
  }, [dispatch]);

  const partnerData = useSelector((state) => state.partnerData.details);
  console.log("pdata", partnerData);

  const PartnerColumn = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        return <div className="capitalize">{params.row.name}</div>;
      },
    },

    {
      field: "badge",
      headerName: "Badge",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const badgeIcons = {
          Silver: SilverBadge,
          Gold: GoldenBadge,
          Bronze: BronzeBadge,
          // "No Badge":NoBadge
        };

        const badge = params.value;
        const badgeIcon = badgeIcons[badge];

        return (
          <div className="flex items-center justify-center h-full">
            {badgeIcon ? (
              <Tooltip title={badge} arrow>
                <img
                  src={badgeIcon}
                  alt={`${badge} badge`}
                  className="w-6 h-6"
                />
              </Tooltip>
            ) : (
              badge
            )}
          </div>
        );
      },
    },

    {
      field: "request",
      headerName: "Total Request",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },

    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Menu as="div" className="inline-block text-left mt-2">
            <div>
              <MenuButton className="cursor-pointer relative inline-flex w-full justify-center gap-x-1.5  px-3 py-2 text-sm font-semibold text-gray-900">
                <img
                  src={MoreIcon}
                  alt="MoreIcon"
                  onClick={(e) => handleClick(e, params.row)}
                />
              </MenuButton>
            </div>

            <MenuItems
              transition
              className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                <MenuItem
                  onClick={() => {
                    navigate(`/partner/${params.id}`);
                  }}
                >
                  <a
                    href="#"
                    className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                  >
                    View
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        );
      },
    },
  ];

  const rows =
    partnerData && partnerData.length > 0
      ? partnerData.map((item) => ({
          id: item.id,
          name: item.organization_name,
          badge: item.badge,
          request: item.request_count,
        }))
      : [];
  return (
    <div className="col-span-3 custom-shadow p-6 rounded-[5px] bg-[#FFFFFF]">
      <p className="font-medium text-[#353F4F] text-lg border-b border-[#D3D3D3] pb-6">
        Top Partner List
      </p>
      <div className="pt-6">
        <DataTable
          rows={rows}
          columns={PartnerColumn}
          hideCheckbox
          hideFooter
          height={450}
        />
      </div>
    </div>
  );
};

export default TopPartnerList;
