import React, { useState, useEffect } from "react";
import bagImage from "../../../assets/bug.jpeg";
import { colors } from "@mui/material";

const PaymentModal = ({ isOpen, onClose, amount = 0 }) => {
  const [paymentMethod, setPaymentMethod] = useState("card");

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const items = [
    { name: "Item 01", price: 4 },
    { name: "Item 02", price: 5 },
    { name: "Item 03", price: 1 },
    { name: "Item 04", price: 1.8 },
  ];

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Light gray overlay matching the reference image */}
      <div
        className="fixed inset-0"
        style={{ backgroundColor: "rgba(134, 137, 139, 0.51)" }}
        onClick={onClose}
      />

      {/* Modal Content */}
      <div className="relative z-50 bg-white rounded-lg max-w-5xl w-full max-h-[90vh] overflow-auto mx-4 shadow-lg">
        {/* Rest of the component remains exactly the same */}
        <div className="grid md:grid-cols-[45%,55%] p-8">
          {/* Left Side - Payment Form */}
          <div className="p-6 border-r border-gray-200">
            <div className="mb-6">
              <h2 className="text-xl font-semibold">Payment</h2>
            </div>

            <div>
              <div className="mb-6">
                <label className="block mb-2">Pay With:</label>
                <div className="flex gap-4">
                  {["card", "ACH bank"].map((method) => (
                    <label key={method} className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="paymentMethod"
                        value={method}
                        checked={paymentMethod === method}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        className="accent-blue-600"
                      />
                      <span className="capitalize">{method}</span>
                    </label>
                  ))}
                </div>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block mb-1">Card Number</label>
                  <input
                    type="text"
                    placeholder="1234 5678 9101 1121"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block mb-1">Expiration Date</label>
                    <input
                      type="text"
                      placeholder="MM/YY"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label className="block mb-1">CVV</label>
                    <input
                      type="text"
                      placeholder="123"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="saveCard"
                    className="accent-blue-600"
                  />
                  <label htmlFor="saveCard">Save card details</label>
                </div>

                <button
                  className="w-full text-white py-3 rounded-md"
                  style={{ backgroundColor: "#094ae3" }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#34D399")
                  } // Hover to green
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#094ae3")
                  } // Back to blue
                >
                  Pay USD{amount}
                </button>

                <p className="text-sm" style={{ colors: "#f9fafa" }}>
                  Your personal data will be used to process your order, support
                  your experience throughout this website, and for other
                  purposes described in our privacy policy.
                </p>
              </div>
            </div>
          </div>

          {/* Right Side - Summary */}
          <div className="p-6" style={{ backgroundColor: "#f9fafa" }}>
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-medium">Request Summary</h3>
              <button className="text-blue-600 text-sm hover:underline">
                Generate Receipt
              </button>
            </div>

            <div className="mb-6">
              <div className="flex items-center gap-3 mb-4 justify-between">
                <div className="w-12 h-12 bg-gray-200 rounded">
                  <img src={bagImage} alt="productImage" />
                </div>
                <h4 className="font-medium">AID Request</h4>
                <span className="text-lg font-medium">${amount}</span>
              </div>

              <div className="border-t border-gray-200 pt-4">
                <div className="flex justify-between mb-2">
                  <span className="font-medium text-blue-600">
                    Service Provided
                  </span>
                  <span className="font-medium text-blue-600">Price (USD)</span>
                </div>

                {items.map((item, index) => (
                  <div key={index} className="flex justify-between py-2">
                    <span>{item.name}</span>
                    <span>${item.price}</span>
                  </div>
                ))}

                <div className="flex justify-between py-2 border-t border-gray-200 font-medium text-blue-600">
                  <span>Total</span>
                  <span>${amount}</span>
                </div>
              </div>
            </div>

            <div className="border-t border-gray-200 pt-4">
              <div className="flex justify-between py-2">
                <span>Subtotal</span>
                <span>${amount}</span>
              </div>
              <div className="flex justify-between py-2 text-lg font-medium">
                <span>Total</span>
                <span>${amount}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>
      </div>
    </div>
  );
};

export default PaymentModal;
