import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
import {
  PrimaryRequestInfoFields,
  NewRequestDetailsFields,
  SeconaryRequestInfoFields,
  consumerDetailsFields,
  ClientAdditionalFields,
  ClientSecondaryInfoFields,
} from "../../../utills/formFields";
import UploadIcon from "../../../assets/upload-box-icon.svg";
import BreadCrumIcon from "../../../assets/breadcrumb-right-icon.svg";
import { Button } from "../../../shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../../services/category";
import { useSearchParams } from "react-router-dom";
import {
  createNewRequest,
  getAllCurrentPartnerRequestList,
} from "../../../services/request";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import CallIcon from "../../../assets/callicon.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import DataTable from "../../../shared/DataGrid";
import { getAllPartnerList } from "../../../services/partner";
import locationIcon from "../../../assets/location-red-icon.svg";
import DollarSymbol from "../../../assets/dollarsymbol.svg";
import { MenuItem, Select } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import BlueTickicon from "../../../assets/images/bluetick-icon.jpg";
import { ConsumerRequestInfoFields } from "../../../utills/formFields";
import { getSingleRequest } from "../../../services/currentRequest";
import CloseIcon from "../../../assets/close-red-icon.svg";
import Tooltip from "../../../shared/Tooltip";

export default function CreateRequest() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [selectedRequestVia, setSelectedRequestVia] = useState([]);
  const [sections, setSections] = useState({
    clientPrimary: true,
    requestDetails: true,
    clientSecondary: true,
    partnerDetails: true,
    additionalInformation: true,
  });

  const toggleSection = (section) => {
    setSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [imageFiles, setImageFiles] = useState([]);
  const {
    register,
    formState: { errors, defaultValues },
    handleSubmit,
    reset,
    getValues,
    trigger,
    control,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    shouldUnregister: false, // Keep state for unmounted inputs
  });

  const selectedAvailability = watch("availability");

  const checkBoxValue = watch("partner_request_via");
  const numberOfbeneficiaries = watch("beneficiaries");
  const dispatch = useDispatch();

  const name = searchParams.get("name");
  const money = searchParams.get("money");
  const requestId = searchParams.get("id");
  const categoryList = useSelector((state) => {
    return state.categoryInfo?.data?.data || state.categoryInfo?.data;
  });

  const userData = useSelector((state) => state.userInfo.data);
  const userType = useSelector((state) => state.userInfo.userType);
  console.log("ut", userType, errors);
  const [pages, setPage] = useState({ page: 0, pageSize: 10 });

  const userId = userData?.id;
  useEffect(() => {
    if (userType === "partner") {
      if (userId) {
        dispatch(getAllCurrentPartnerRequestList(userId));
      }
    }
    dispatch(getAllCategory());
  }, [dispatch, userId, userType]);

  useEffect(() => {
    if (userType === "agent" || userType === "admin") {
      dispatch(getAllPartnerList({ page: pages.page + 1 }));
    }
  }, [dispatch, userType, pages]);

  const details = useSelector(
    (state) => state.currentRequest.details?.data?.[0]
  );

  useEffect(() => {
    if (id) {
      dispatch(getSingleRequest(id));
    }
  }, [dispatch, id]);

  const partner = useSelector((state) => state.requestInfo.partners);
  const partnerId = partner?.result?.id;
  const partnerInfo = useSelector(
    (state) => state?.partnerInfo?.partnerData?.data
  );
  const partnerPagination = useSelector(
    (state) => state?.partnerInfo?.partnerData
  );

  const onSubmit = (data) => {
    const formattedData = {
      first_name: data.first_name,
      last_name: data.last_name,
      contact: data.contact,
      alternative_number: data.alternative_number,
      address: data.address1 || data.address,
      address_line: data.address_line,
      city: data.city,
      state: data.state,
      zip_code: data.zip_code,
      id_proof: data.id_proof,
      communication_preference: data.communication_preference,
      request_via: data.request_via,
      request_type: data.request_type,
      // partner_request_via: data.partner_request_via,
      partner_request_via: data.partner_request_via,
      category: categoryList?.find(
        (cat) => cat?.name?.toLowerCase() === data?.category?.toLowerCase()
      )?.id,
      beneficiaries: data.beneficiaries,
      expected_duration: data.expected_duration,
      urgency_level: data.urgency_level,
      request_status_desc: data.request_status_desc || "",
      request_status: data.request_status,
      description: data.description,
      attachment: data.attachment,
      contact_firstname: data.contact_firstname,
      contact_lastname: data.contact_lastname,
      contact_number: data.contact_number,
      contact_address: data.contact_address,
      partners:
        userData?.user_type === "partner"
          ? [+partnerId]
          : selectedRows.map((row) => row.id),
      refer_loop: data.refer_loop,
      comments: data.comments,
    };

    const adminPayload = {
      first_name: data.first_name,
      last_name: data.last_name,
      contact: data.contact,
      alternative_number: data.alternative_number,
      address: data.address,
      id_proof: data.id_proof,
      communication_preference: data.availability,
      request_via: data.request_via,
      partner_request_via: data.partner_request_via,
      request_type: data.request_type,
      category: categoryList?.find(
        (cat) => cat?.name?.toLowerCase() === data?.category?.toLowerCase()
      )?.id,
      name: data.name,
      beneficiaries: data.beneficiaries,
      expected_duration: data.expected_duration,
      urgency_level: data.urgency_level,
      request_status_desc: data.request_status_desc || "",
      request_status: data.request_status,
      request_via: data.request_via,
      description: data.description,
      attachment: data.attachment,
      contact_firstname: data.contact_firstname,
      contact_lastname: data.contact_lastname,
      contact_number: data.contact_number,
      contact_address: data.contact_address,
      partners: selectedRows.map((row) => row.id),
      refer_loop: data.refer_loop,
      comments: data.comments,
    };

    const consumerPayload = {
      first_name: data.first_name,
      last_name: data.last_name,
      contact: data.contact,
      alternative_number: data.alternative_number,
      address: data.address1,
      address_line: data.address_line,
      city: data.city,
      state: data.state,
      zip_code: data.zip_code,
      id_proof: null,
      category: categoryList?.find(
        (cat) => cat?.name?.toLowerCase() === data?.category?.toLowerCase()
      )?.id,
      beneficiaries: data.beneficiaries,
      urgency_level: data.urgency_level,
      request_status_desc: data.request_status_desc || "",
      description: data.description || "",
      communication_preference: data.availability,
      comments: data.comments,
    };
    console.log({ consumerPayload });

    dispatch(
      createNewRequest(
        userType === "consumer"
          ? consumerPayload
          : userData.user_type === "admin"
          ? adminPayload
          : formattedData
      )
    )
      .then(() => {
        if (userData.user_type === "partner") {
          navigate("/requests?status=wait");
        } else if (
          userData.user_type === "admin" ||
          userData.user_type === "agent" ||
          userType === "consumer"
        ) {
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
            navigate("/all-request");
          }, 2000);
        } else {
          navigate(`/request-tracking`);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      })
      .catch((err) => console.error("Error creating agent:", err));
  };

  useEffect(() => {
    reset({
      ...(userType === "consumer" ? { urgency_level: "low" } : {}),
      ...(userType === "admin" ? { urgency_level: "low" } : {}),
      ...(userType === "agent" ? { urgency_level: "low" } : {}),
      category: name
        ?.split(" ")
        ?.map(
          (word) =>
            [
              "and",
              "or",
              "but",
              "the",
              "a",
              "an",
              "of",
              "in",
              "on",
              "at",
              "for",
              "to",
              "with",
            ].includes(word.toLowerCase())
              ? word // Keep small words as is
              : word.charAt(0).toUpperCase() + word.slice(1) // Capitalize the first letter of significant words
        )
        .join(" "),
    });
  }, [name, userType]);
  useEffect(() => {
    if (
      details &&
      Object.keys(details).length > 0 &&
      categoryList.length > 0 &&
      id
    ) {
      reset({
        ...details,
        category: categoryList
          ?.find((cat) => cat?.id === details?.category)
          ?.name?.split(" ")
          ?.map((word) =>
            [
              "and",
              "or",
              "but",
              "the",
              "a",
              "an",
              "of",
              "in",
              "on",
              "at",
              "for",
              "to",
              "with",
            ].includes(word.toLowerCase())
              ? word // Keep small words as is
              : word.charAt(0).toUpperCase() + word.slice(1)
          )
          .join(" "),
      });
    }
  }, [details, categoryList, id]);

  const modifiedTheFields = useMemo(() => {
    if (userType === "consumer") {
      return consumerDetailsFields;
    } else {
      return NewRequestDetailsFields;
    }
  }, [userType]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const updatedFiles = [...imageFiles, ...files];
    setImageFiles(updatedFiles);
  };
  const handleDelete = (fileToDelete) => {
    setImageFiles(imageFiles.filter((file) => file !== fileToDelete));
  };

  const [selectedRows, setSelectedRows] = useState([]);
  console.log("pinfo", partnerInfo);

  const rows = Array.isArray(partnerInfo)
    ? partnerInfo?.map((partner) => ({
        id: partner?.id,
        partner_id: partner?.id,
        full_name: partner?.organization_name,
        email: partner?.email,
        // gender: partner?.gender,
        contact: partner?.contact_number,
      }))
    : [];
  const handleRowClick = (params) => {
    // console.log("a++");
    // console.log("Row clicked", params);
    handleRowSelect(params.row);
  };

  const handleRowSelect = (row) => {
    const isSelected = selectedRows.some(
      (selectedRow) => selectedRow.id === row.id
    );

    if (isSelected) {
      setSelectedRows((prevRows) => {
        const updatedRows = prevRows.filter(
          (selectedRow) => selectedRow.id !== row.id
        );
        // console.log("Row deselected:", row.id);
        return updatedRows;
      });
    } else {
      setSelectedRows((prevRows) => {
        const updatedRows = [...prevRows, row];
        // console.log("Row selected:", row.id);
        return updatedRows;
      });
    }
  };

  const ReferredColumn = [
    {
      field: "partners",
      headerName: "",
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <>
            <div className="flex items-center justify-center h-full">
              <input
                className="rounded-sm w-4 h-4 border-[#6F6F6F] text-[#607274] focus:ring-[#C6D8DA]"
                type="checkbox"
                checked={selectedRows.some((row) => row.id === params.row.id)}
              />
            </div>
          </>
        );
      },
    },
    {
      field: "full_name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "partner_id",
      headerName: "Partner ID",
      flex: 1,
    },

    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    // {
    //   field: "gender",
    //   headerName: "Gender",
    //   flex: 1,
    // },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
    },
  ];

  const handleDeleteChip = (id) => {
    setSelectedRows((prev) => prev.filter((row) => row.id !== id));
  };

  const availability = watch("availability");
  const category = watch("category");

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <>
      <div className="px-8 mt-10 dashboard-content">
        {!id && (
          <div className="">
            <nav class="flex" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                <li class="inline-flex items-center">
                  <span
                    class="inline-flex items-center text-sm text-[#353F4F] hover:text-blue-600 dark:text-gray-400 cursor-pointer dark:hover:text-white"
                    onClick={() => navigate("/")}
                  >
                    Dashboard
                  </span>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <img src={BreadCrumIcon} alt="BreadCrumIcon" />
                    <span class="ms-1 text-sm  md:ms-2 text-primary-blue">
                      New {name ? name : ""} Request
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
          </div>
        )}

        {userType === "agent" && (
          <div className="flex items-center justify-between  mt-10 mb-8 title">
            <div className="flex items-center gap-4">
              <h3 className="text-[20px] font-medium flex gap-2 text-[#353F4F]">
                {id ? "Re-Request" : `Add New ${name ? name : ""} Request`}
              </h3>
              {/* {userType === "consumer" && (
              <div className="p-[10px] flex items-center rounded-[10px] gap-2 border border-[#D1A639] text-sm">
                <p className="text-[#353F4F] text-[18px] font-semibold">
                  {money}
                </p>
                <img src={DollarSymbol} alt="" />
              </div>
            )} */}
            </div>
          </div>
        )}

        {(userType === "consumer" || userType === "admin") && (
          <div
            className="flex items-center justify-between px-8 mt-10 rounded-[5px] title custom-shadow"
            style={{
              ...(userType === "consumer" && {
                borderEndStartRadius: "unset",
                borderEndEndRadius: "unset",
                borderBottom: "unset",
                border: "1px solid rgba(34, 96, 217, 1)",
              }),
            }}
          >
            <div className="flex items-center gap-4">
              {userType === "consumer" && (
                <h3 className="text-[20px] font-medium flex gap-2 text-[#353F4F] pt-4 pb-4  capitalize">
                  {id
                    ? `New ${
                        name ? name.replace(/Request/gi, "").trim() : ""
                      } Request`
                    : `New ${
                        name ? name.replace(/Request/gi, "").trim() : ""
                      } Request`}
                </h3>
              )}
              {/* {userType === "consumer" && name && money && (
                <div className="p-[10px] flex items-center rounded-[10px] gap-2 border border-[#D1A639] text-sm">
                  <img src={DollarSymbol} alt="" />

                  <p className="text-[#353F4F] text-[18px] font-semibold">
                    {money}
                  </p>
                </div>
              )} */}
            </div>
            {/* <div className="flex items-center gap-20">
              <Tooltip title="Cancel">
                <img
                  className="cursor-pointer"
                  onClick={() => navigate("/request-tracking")}
                  src={CloseIcon}
                  alt="CloseIcon"
                />
              </Tooltip>
            </div> */}
          </div>
        )}

        <div
          style={{
            ...(userType === "consumer" && {
              borderTopLeftRadius: "unset",
              borderTopRightRadius: "unset",
            }),
            boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.05)",
            marginBottom: "20px",
            borderRadius: "5px",
            border: "1px solid rgba(34, 96, 217, 1)",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-8 py-4">
              <div
                className="flex items-center justify-between py-5 mb-8"
                style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
              >
                <div className="text-primary-blue text-[18px]">
                  Primary Information
                </div>
                <div
                  className="bg-[#F1F6FF] w-6 h-6 rounded-full text-center cursor-pointer"
                  onClick={() => toggleSection("clientPrimary")}
                >
                  {sections.clientPrimary ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </div>
              </div>
              {sections.clientPrimary && (
                <div className="flex flex-wrap gap-4">
                  {userType !== "consumer" && userType !== "agent" && (
                    <>
                      {PrimaryRequestInfoFields.map((field, index) => {
                        return (
                          <div
                            className={`relative mb-6  ${field.width}`}
                            key={index}
                          >
                            <label
                              className="block mb-2 text-xs font-normal tracking-wide text-[#353F4F]"
                              htmlFor={field.label}
                            >
                              {field.label}{" "}
                              <span style={{ color: "red" }}>
                                {field?.inputRules?.required ? "*" : ""}
                              </span>
                            </label>
                            {field.type === "input" ? (
                              <div className="relative">
                                <input
                                  {...register(field.name, field.inputRules)}
                                  type={field.fieldType}
                                  className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                                  placeholder={field.placeholder}
                                  style={{
                                    color: "#232323",
                                    borderRadius: "3px",
                                  }}
                                  aria-invalid={!!errors[field.name]}
                                />
                                {field.icon && (
                                  <img
                                    className="absolute top-4 right-4"
                                    src={locationIcon}
                                    alt="LocationIcon"
                                  />
                                )}

                                {errors[field.name] && (
                                  <p
                                    className="error text-[#FF0000] text-sm"
                                    role="alert"
                                  >
                                    {errors[field.name].message}
                                  </p>
                                )}
                              </div>
                            ) : field.type === "tel" ? (
                              <div className="relative">
                                <input
                                  {...register(field.name, field.inputRules)}
                                  type={field.fieldType}
                                  className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                          focus-visible:outline-none text-[14px] h-[60px]"
                                  placeholder={field.placeholder}
                                  style={{
                                    color: "#232323",
                                    borderRadius: "3px",
                                  }}
                                  aria-invalid={!!errors[field.name]}
                                  onChange={(e) => {
                                    let input = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    ); // Remove all non-digit characters
                                    let formatted = "";

                                    if (input.length > 0)
                                      formatted = `(${input.substring(0, 3)}`;
                                    if (input.length > 3)
                                      formatted += `) ${input.substring(3, 6)}`;
                                    if (input.length > 6)
                                      formatted += `-${input.substring(6, 10)}`;
                                    e.target.value = formatted;
                                  }}
                                />
                                {errors[field.name] && (
                                  <p className="error" role="alert">
                                    {errors[field.name].message}
                                  </p>
                                )}
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </>
                  )}

                  {(userType == "consumer" || userType === "agent") && (
                    <>
                      {ConsumerRequestInfoFields.map((field, index) => {
                        return (
                          <div
                            className={`relative mb-6  ${field.width}`}
                            key={index}
                          >
                            <label
                              className="block mb-2 text-xs font-normal tracking-wide text-[#353F4F]"
                              htmlFor={field.label}
                            >
                              {field.label}{" "}
                              <span style={{ color: "red" }}>
                                {field?.inputRules?.required ? "*" : ""}
                              </span>
                            </label>
                            {field.type === "input" ? (
                              <div className="relative">
                                <input
                                  {...register(field.name, field.inputRules)}
                                  type={field.fieldType}
                                  className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                                  placeholder={field.placeholder}
                                  style={{
                                    color: "#232323",
                                    borderRadius: "3px",
                                  }}
                                  aria-invalid={!!errors[field.name]}
                                />
                                {errors[field.name] && (
                                  <p className="error" role="alert">
                                    {errors[field.name].message}
                                  </p>
                                )}
                              </div>
                            ) : field.type === "tel" ? (
                              <div className="relative">
                                <Controller
                                  name={field.name}
                                  control={control}
                                  rules={field.inputRules}
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState: { error },
                                  }) => {
                                    return (
                                      <input
                                        ref={ref}
                                        type={field.fieldType}
                                        className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                 focus-visible:outline-none text-[14px] h-[60px]"
                                        placeholder={field.placeholder}
                                        style={{
                                          color: "#232323",
                                          borderRadius: "3px",
                                        }}
                                        aria-invalid={!!error}
                                        value={value}
                                        onChange={(e) => {
                                          let input = e.target.value.replace(
                                            /\D/g,
                                            ""
                                          ); // Remove all non-digit characters
                                          let formatted = "";

                                          if (input.length > 0)
                                            formatted = `(${input.substring(
                                              0,
                                              3
                                            )}`;
                                          if (input.length > 3)
                                            formatted += `) ${input.substring(
                                              3,
                                              6
                                            )}`;
                                          if (input.length > 6)
                                            formatted += `-${input.substring(
                                              6,
                                              10
                                            )}`;
                                          onChange(formatted);
                                        }}
                                        onBlur={onBlur}
                                      />
                                    );
                                  }}
                                />
                                {errors[field.name] && (
                                  <p className="error mt-1" role="alert">
                                    {errors[field.name].message}
                                  </p>
                                )}
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              )}

              <div
                className="flex items-center justify-between py-5 mb-8"
                style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
              >
                <div className="text-primary-blue text-[18px]">
                  Request Details
                </div>
                <div
                  className="bg-[#F1F6FF] w-6 h-6 rounded-full text-center cursor-pointer"
                  onClick={() => toggleSection("requestDetails")}
                >
                  {sections.requestDetails ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </div>
              </div>
              {sections.requestDetails && (
                <div className="flex flex-wrap gap-4">
                  {(modifiedTheFields || [])?.map((field, index) => {
                    const isAgent = userData?.user_type === "agent";
                    return (
                      <div
                        className={`relative mb-6  ${field.width}`}
                        key={index}
                      >
                        {/* {(field.name === "description" && userData.user_type === 'agent') &&
                      <div className="flex flex-col mb-6 space-y-2">
                        <p className="text-sm text-gray-600">
                          The displayed amount reflects the total payment required.
                        </p>
                        <div className="flex items-center space-x-2">
                          <span className="font-medium">Subtotal</span>
                          <div className=" px-2 py-1 rounded border border-[#D1A639] text-sm">
                            <span className="text-[#D1A639]">$</span>  11.80
                          </div>
                        </div>
                      </div>
                    } */}
                        <label
                          className="block mb-2 text-xs font-normal tracking-wide text-[#353F4F]"
                          htmlFor={field.label}
                        >
                          {field.label}{" "}
                          <span style={{ color: "red" }}>
                            {field?.inputRules?.required ? "*" : ""}
                          </span>
                        </label>
                        {field.type === "input" ? (
                          <div className="relative">
                            <input
                              {...register(field.name, {
                                ...field.inputRules,
                                validate: (value) =>
                                  value >= 0 ||
                                  "Please enter a valid number greater than or equal to 0",
                              })}
                              type={field.fieldType}
                              className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                              placeholder={field.placeholder}
                              style={{
                                color: "#232323",
                                borderRadius: "3px",
                              }}
                              aria-invalid={!!errors[field.name]}
                            />

                            {field.icon && (
                              <img
                                className="absolute top-4 right-4"
                                src={locationIcon}
                                alt="locationIcon"
                              />
                            )}

                            {errors[field.name] && (
                              <p className="error mt-1" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                            {(isAgent || userType === "admin") &&
                              (categoryList || [])
                                ?.filter((ele) => ele?.name)
                                .find(
                                  (e) =>
                                    e?.name?.toLowerCase() ===
                                    category?.toLowerCase()
                                )?.per_cost &&
                              numberOfbeneficiaries && (
                                <div className="flex flex-col mt-8">
                                  <div className="flex flex-col justify-start gap-5">
                                    <p className="text-[20px] font-normal text-[#353F4F]">
                                      The displayed amount reflects the total
                                      payment required.
                                    </p>
                                    <div className="flex items-center gap-5">
                                      <span className="font-medium text-[28px] text-[#353F4F]">
                                        Subtotal
                                      </span>
                                      <div className="p-[10px] flex items-center rounded-[10px] gap-2 border border-[#D1A639] text-sm">
                                        <img src={DollarSymbol} alt="" />
                                        {/* <p className="text-[#353F4F] text-[18px] font-semibold">
                                          {(categoryList || [])
                                            ?.filter((ele) => ele?.name)
                                            .find(
                                              (e) =>
                                                e?.name?.toLowerCase() ===
                                                category?.toLowerCase()
                                            )?.per_cost * numberOfbeneficiaries}
                                        </p> */}
                                        <p className="text-[#353F4F] text-[18px] font-semibold">
                                          {(
                                            ((categoryList || [])
                                              ?.filter((ele) => ele?.name)
                                              .find(
                                                (e) =>
                                                  e?.name?.toLowerCase() ===
                                                  category?.toLowerCase()
                                              )?.per_cost || 0) *
                                            (numberOfbeneficiaries || 0)
                                          ).toFixed(2)}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        ) : field.type === "tel" ? (
                          <div className="relative">
                            <input
                              {...register(field.name, field.inputRules)}
                              type={field.fieldType}
                              className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                      focus-visible:outline-none text-[14px] h-[60px]"
                              placeholder={field.placeholder}
                              style={{
                                color: "#232323",
                                borderRadius: "3px",
                              }}
                              aria-invalid={!!errors[field.name]}
                              onChange={(e) => {
                                const input = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
                                const formatted = input
                                  .replace(
                                    /(\d{3})(\d{3})(\d{4})/,
                                    "($1) $2-$3"
                                  ) // Format with parentheses and dash
                                  .replace(/(\d{3})(\d{3})/, "($1) $2") // Handle intermediate formatting
                                  .substring(0, 14); // Limit to the required format
                                e.target.value = formatted;
                              }}
                            />
                            {errors[field.name] && (
                              <p className="error mt-1" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </div>
                        ) : field.type === "dropdown" ? (
                          <>
                            <Controller
                              name={field.name}
                              control={control}
                              rules={field.inputRules}
                              defaultValue=""
                              render={({ field, fieldState }) => {
                                const options =
                                  field.name === "category"
                                    ? categoryList?.length > 0
                                      ? categoryList?.filter((e) => e.name)
                                      : []
                                    : field.options;

                                return (
                                  <>
                                    <Select
                                      labelId={`${field.name}-label`}
                                      value={field.value}
                                      onChange={field.onChange}
                                      sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          borderWidth: 0,
                                        },
                                      }}
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      className="w-full capitalize border-none px-3 py-[0.32rem] leading-[2.15] input-bg !focus:border-none !focus-visible:border-none focus-visible:outline-none !text-[14px] h-[60px]"
                                      disabled={name} // assuming "name" is a boolean
                                    >
                                      {options.map((option, index) => (
                                        <MenuItem
                                          key={index}
                                          value={option.name
                                            ?.split(" ")
                                            ?.map(
                                              (word) =>
                                                [
                                                  "and",
                                                  "or",
                                                  "but",
                                                  "the",
                                                  "a",
                                                  "an",
                                                  "of",
                                                  "in",
                                                  "on",
                                                  "at",
                                                  "for",
                                                  "to",
                                                  "with",
                                                ].includes(word.toLowerCase())
                                                  ? word // Keep small words as is
                                                  : word
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    word.slice(1) // Capitalize the first letter of significant words
                                            )
                                            .join(" ")}
                                          className="!text-[14px] capitalize"
                                        >
                                          {option.name
                                            ?.split(" ")
                                            ?.map(
                                              (word) =>
                                                [
                                                  "and",
                                                  "or",
                                                  "but",
                                                  "the",
                                                  "a",
                                                  "an",
                                                  "of",
                                                  "in",
                                                  "on",
                                                  "at",
                                                  "for",
                                                  "to",
                                                  "with",
                                                ].includes(word.toLowerCase())
                                                  ? word // Keep small words as is
                                                  : word
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    word.slice(1) // Capitalize the first letter of significant words
                                            )
                                            .join(" ")}
                                          {option.is_premium && (
                                            <span
                                              style={{ color: "#D1A639" }}
                                              className="ml-1"
                                            >
                                              (${option?.per_cost})
                                            </span>
                                          )}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {errors[field.name] && (
                                      <p className="error mt-1" role="alert">
                                        {errors[field.name].message}
                                      </p>
                                    )}
                                  </>
                                );
                              }}
                            />
                          </>
                        ) : field.type === "radio" ? (
                          <div className="">
                            <div className="flex items-center me-4">
                              <Controller
                                name={field.name}
                                control={control}
                                rules={field.inputRules}
                                render={({ field: { value, onChange } }) => {
                                  console.log({ value }, field.name);
                                  return field.options?.map((option, index) => (
                                    <div
                                      value={value}
                                      option={option.value}
                                      className={`flex items-center me-4 input-bg py-3 px-5 rounded-[3px]`}
                                    >
                                      <input
                                        type="radio"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        {...register(
                                          field.name,
                                          field.inputRules
                                        )}
                                        value={option.value}
                                        checked={value === option.value}
                                        onChange={() => onChange(option.value)}
                                      />
                                      <label className="ms-2 text-[12px] text-[#353F4F] dark:text-[#353F4F]">
                                        {option.name}
                                      </label>
                                    </div>
                                  ));
                                }}
                              />
                            </div>
                            {errors[field.name] && (
                              <p className="error mt-1" role="alert">
                                {errors[field.name]?.message}
                              </p>
                            )}
                            {userType === "consumer" &&
                              ((categoryList || [])
                                ?.filter((ele) => ele?.name)
                                .find(
                                  (e) =>
                                    e?.name?.toLowerCase() ===
                                    category?.toLowerCase()
                                )?.per_cost ||
                                money) && (
                                <div className="flex flex-col">
                                  <div className="flex items-center justify-start gap-1">
                                    <span className="font-normal text-[15px] text-[#353F4F]">
                                      Total Payable
                                    </span>
                                    <p className="text-[10px] font-normal text-[#A9A9A9]">
                                      ( The displayed amount reflects the total
                                      payment required. Which becomes payable
                                      after the agent approves your request )
                                    </p>
                                  </div>
                                  <div className="">
                                    <div className="p-[10px] flex items-center gap-2 border-b border-[#2260D9] text-sm">
                                      <img
                                        src={DollarSymbol}
                                        alt=""
                                        width={20}
                                        height={20}
                                      />
                                      <p className="text-[#D1A639] text-[30px] font-semibold">
                                        {(
                                          Number(
                                            categoryList
                                              ?.filter((ele) => ele?.name)
                                              .find(
                                                (e) =>
                                                  e?.name?.toLowerCase() ===
                                                  category?.toLowerCase()
                                              )?.per_cost || money
                                          ) * numberOfbeneficiaries
                                        ).toFixed(2)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        ) : field.type === "textbox" ? (
                          <>
                            <textarea
                              id="message"
                              rows="4"
                              className={`block p-2.5 input-bg  w-full text-sm text-[#353F4F]  
                                                                    focus-visible:outline-none focus-visible:border-none`}
                              style={{ borderRadius: "3px" }}
                              placeholder={field.placeholder}
                              {...register(field.name)}
                            ></textarea>
                            {/* {errors[field.name] && (
                              <p className="error" role="alert">
                                {errors[field.name].message}
                              </p>
                            )} */}
                          </>
                        ) : field.type === "file" ? (
                          <>
                            <div className="flex items-center justify-center w-full">
                              <label
                                htmlFor={field.name}
                                className="flex flex-col items-center justify-center w-full h-64 cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
                                style={{
                                  border: "2px dashed rgba(34, 96, 217, 1)",
                                }}
                              >
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                  <img src={UploadIcon} alt="Upload Image" />
                                  <p className="mt-2 mb-2 text-sm text-light-gray">
                                    <span>Add / Browse</span>
                                  </p>
                                </div>
                                <input
                                  id={field.name}
                                  type="file"
                                  {...register(field.name, field.inputRules)}
                                  // onChange={(e) => {
                                  //     imageField.onChange(e);
                                  //     if (e.target.files && e.target.files[0]) {
                                  //         let types = ['image/png', 'image/jpeg']
                                  //         if (types.includes(e.target.files[0].type)) {
                                  //             setLogoImage({ ...logoImage, [field.name]: URL.createObjectURL(e.target.files[0]) });
                                  //         } else {
                                  //             setError([field.name], 'Invalid file type')
                                  //         }
                                  //     }
                                  // }}
                                  className="hidden"
                                />
                              </label>
                            </div>
                            {errors[field.name] && (
                              <p className="error mt-1" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              )}
              {userType !== "consumer" && (
                <>
                  <div
                    className="flex items-center justify-between py-5 mb-8"
                    style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
                  >
                    <div className="text-primary-blue text-[18px]">
                      Client Secondary Information (Optional)
                    </div>
                    <div
                      className="bg-[#F1F6FF] w-6 h-6 rounded-full text-center cursor-pointer"
                      onClick={() => toggleSection("clientSecondary")}
                    >
                      {sections.clientSecondary ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </div>
                  </div>
                  {sections.clientSecondary && (
                    <div className="flex flex-wrap gap-4">
                      {ClientSecondaryInfoFields.map((field, index) => {
                        return (
                          <div
                            className={`relative mb-6  ${field.width}`}
                            key={index}
                          >
                            <label
                              className="block mb-2 text-xs font-normal tracking-wide text-[#353F4F]"
                              htmlFor={field.label}
                            >
                              {field.label}{" "}
                              <span style={{ color: "red" }}>
                                {field?.inputRules?.required ? "*" : ""}
                              </span>
                            </label>
                            {field.type === "input" ? (
                              <div className="relative">
                                <input
                                  {...register(field.name, field.inputRules)}
                                  type={field.fieldType}
                                  className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                                  placeholder={field.placeholder}
                                  style={{
                                    color: "#232323",
                                    borderRadius: "3px",
                                  }}
                                  aria-invalid={!!errors[field.name]}
                                />
                                {field.icon && (
                                  <img
                                    className="absolute top-4 right-4"
                                    src={locationIcon}
                                    alt="LocationIcon"
                                  />
                                )}

                                {errors[field.name] && (
                                  <p className="error mt-1" role="alert">
                                    {errors[field.name].message}
                                  </p>
                                )}
                              </div>
                            ) : field.type === "tel" ? (
                              <div className="relative">
                                <input
                                  {...register(field.name, field.inputRules)}
                                  type={field.fieldType}
                                  className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                          focus-visible:outline-none text-[14px] h-[60px]"
                                  placeholder={field.placeholder}
                                  style={{
                                    color: "#232323",
                                    borderRadius: "3px",
                                  }}
                                  aria-invalid={!!errors[field.name]}
                                  onChange={(e) => {
                                    let input = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    ); // Remove all non-digit characters
                                    let formatted = "";

                                    if (input.length > 0)
                                      formatted = `(${input.substring(0, 3)}`;
                                    if (input.length > 3)
                                      formatted += `) ${input.substring(3, 6)}`;
                                    if (input.length > 6)
                                      formatted += `-${input.substring(6, 10)}`;
                                    e.target.value = formatted;
                                  }}
                                />
                                {errors[field.name] && (
                                  <p className="error mt-1" role="alert">
                                    {errors[field.name].message}
                                  </p>
                                )}
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
              {userType !== "consumer" && (
                <>
                  <div
                    className="flex items-center justify-between py-5 mb-8"
                    style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
                  >
                    <div className="text-primary-blue text-[18px]">
                      Partner Details
                    </div>
                    <div
                      className="bg-[#F1F6FF] w-6 h-6 rounded-full text-center cursor-pointer"
                      onClick={() => toggleSection("partnerDetails")}
                    >
                      {sections.partnerDetails ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </div>
                  </div>
                  {sections.partnerDetails && (
                    <div className="flex flex-wrap gap-4">
                      {(SeconaryRequestInfoFields || [])?.map(
                        (field, index) => {
                          return (
                            <div
                              className={`relative mb-6  ${field.width}`}
                              key={index}
                            >
                              <label
                                className="block mb-2 text-xs font-normal tracking-wide text-[#353F4F]"
                                htmlFor={field.label}
                              >
                                {field.label}{" "}
                                {/* <span style={{ color: "red" }}>
                                  {field?.inputRules?.required ? "*" : ""}
                                </span> */}
                                <span style={{ color: "red" }}>
                                  {(
                                    watch("partner_request_via") || []
                                  ).includes("call") ||
                                  field?.inputRules?.required
                                    ? "*"
                                    : ""}
                                </span>
                              </label>
                              {field.type === "input" ? (
                                <div className="relative">
                                  <input
                                    {...register(field.name, field.inputRules)}
                                    type={field.fieldType}
                                    className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                                    placeholder={field.placeholder}
                                    style={{
                                      color: "#232323",
                                      borderRadius: "3px",
                                    }}
                                    aria-invalid={!!errors[field.name]}
                                  />
                                  {field.icon && field.icon === "location" && (
                                    <img
                                      className="absolute top-4 right-4"
                                      src={locationIcon}
                                      alt="locationIcon"
                                    />
                                  )}

                                  {errors[field.name] && (
                                    <p className="error mt-1" role="alert">
                                      {errors[field.name].message}
                                    </p>
                                  )}
                                </div>
                              ) : field.type === "tel" ? (
                                <div className="relative">
                                  <input
                                    {...register(field.name, field.inputRules)}
                                    type={field.fieldType}
                                    className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                                    placeholder={field.placeholder}
                                    style={{
                                      color: "#232323",
                                      borderRadius: "3px",
                                    }}
                                    aria-invalid={!!errors[field.name]}
                                    onChange={(e) => {
                                      const input = e.target.value.replace(
                                        /\D/g,
                                        ""
                                      ); // Remove all non-digit characters
                                      const formatted = input
                                        .replace(
                                          /(\d{3})(\d{3})(\d{4})/,
                                          "($1) $2-$3"
                                        ) // Format with parentheses and dash
                                        .replace(/(\d{3})(\d{3})/, "($1) $2") // Handle intermediate formatting
                                        .substring(0, 14); // Limit to the required format
                                      e.target.value = formatted;
                                    }}
                                  />
                                  {errors[field.name] && (
                                    <p className="error mt-1" role="alert">
                                      {errors[field.name].message}
                                    </p>
                                  )}
                                </div>
                              ) : field.type === "dropdown" ? (
                                <>
                                  <Controller
                                    name={field.name}
                                    control={control}
                                    // defaultValue={[]}
                                    // rules={field.inputRules}
                                    rules={{
                                      validate: () =>
                                        selectedRows.length > 0 ||
                                        "This field is required",
                                    }}
                                    render={({ field: controllerField }) => (
                                      <Listbox
                                        value={controllerField.value}
                                        onChange={(value) => {
                                          controllerField.onChange(value);
                                        }}
                                        multiple  
                                      >
                                        <>
                                          <ListboxButton
                                            className="w-full border-none px-3 py-[0.32rem] input-bg flex items-center justify-between h-[60px]"
                                            style={{
                                              color: "#232323",
                                              borderRadius: "3px",
                                            }}
                                          >
                                            <Stack direction="row" spacing={1}>
                                              {selectedRows.map((row) => (
                                                <Chip
                                                  key={row?.id}
                                                  label={row?.full_name}
                                                  onDelete={() =>
                                                    handleDeleteChip(row?.id)
                                                  }
                                                  sx={{
                                                    backgroundColor: "#F0F0F0",
                                                    color: "#2260D9",
                                                    ".MuiChip-deleteIcon": {
                                                      color: "#FF0000",
                                                    },
                                                  }}
                                                />
                                              ))}
                                            </Stack>
                                            <ArrowDropDownIcon />
                                          </ListboxButton>
                                          <ListboxOptions className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            <ListboxOption>
                                              <>
                                                <DataTable
                                                  rows={rows}
                                                  height={"100%"}
                                                  columns={ReferredColumn}
                                                  handleRowClick={
                                                    handleRowClick
                                                  }
                                                  hideCheckbox
                                                  totalRows={
                                                    partnerPagination?.total_count || 0
                                                  }
                                                  pages={pages}
                                                    handlePageChange={(page,e) => {
                                                      handlePageChange(page);
                                                      console.log({e})
                                                    }
                                                  }
                                                />
                                              </>
                                            </ListboxOption>
                                          </ListboxOptions>
                                        </>
                                      </Listbox>
                                    )}
                                  />
                                  {errors[field.name] &&
                                    selectedRows.length === 0 && (
                                      <p className="error mt-1" role="alert">
                                        {errors[field.name].message}
                                      </p>
                                    )}
                                </>
                              ) : field.type === "radio" ? (
                                <>
                                  <div className="flex items-center me-4">
                                    {field.options?.map((option, index) => (
                                      <div
                                        value={getValues[field.name]}
                                        option={option.value}
                                        className={`flex items-center me-4 rounded-[3px] ${
                                          getValues[field.name] === option.value
                                            ? "bg-primary-blue"
                                            : "input-bg"
                                        } py-3 px-5`}
                                        key={index}
                                      >
                                        <input
                                          type="radio"
                                          className="w-4 h-4 text-blue-600 bg-gray-100  border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                          {...register(
                                            field.name,
                                            field.inputRules
                                          )}
                                          value={option.value}
                                          defaultChecked={
                                            option.value === "internal"
                                          }
                                          // checked={getValues[field.name] === option.value}
                                        />
                                        <label className="ms-2 text-[12px] text-gray-900 dark:text-gray-300">
                                          {option.name}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                  {errors[field.name] && (
                                    <p className="error mt-1" role="alert">
                                      {errors[field.name].message}
                                    </p>
                                  )}
                                </>
                              ) : field.type === "checkbox" ? (
                                <>
                                  <div className="flex items-center me-4">
                                    {field.options?.map((option, index) => (
                                      <div
                                        value={getValues[field.name]}
                                        option={option.value}
                                        className={`flex items-center me-4 input-bg rounded-[3px] py-3 px-5`}
                                        key={index}
                                      >
                                        <input
                                          type="checkbox"
                                          className="w-4 h-4 bg-white border-gray-300 text-primary-blue focus:text-primary-blue focus:bg-white focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-white dark:border-gray-600"
                                          {...register(
                                            field.name,
                                            field.inputRules
                                          )}
                                          value={option.value}

                                          // checked={getValues[field.name] === option.value}
                                        />
                                        <label className="ms-2 text-[12px] text-gray-900 dark:text-gray-300">
                                          {option.name}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                  {errors[field.name] && (
                                    <p className="error mt-1" role="alert">
                                      {errors[field.name].message}
                                    </p>
                                  )}
                                  {field.name === "partner_request_via" &&
                                    (checkBoxValue || [])?.includes("call") && (
                                      <button
                                        type="button"
                                        className="mt-8 flex items-center gap-4 bg-[#29B473] text-white px-5 py-2 rounded-[3px]"
                                      >
                                        <img src={CallIcon} alt="" />
                                        Call Center/Contact Center API
                                      </button>
                                    )}
                                </>
                              ) : field.type === "textbox" ? (
                                <>
                                  <textarea
                                    id="message"
                                    rows="4"
                                    className={`block p-2.5 input-bg w-full text-sm text-gray-900  
                                                                    focus-visible:outline-none focus-visible:border-none`}
                                    style={{ borderRadius: "3px" }}
                                    placeholder={field.placeholder}
                                    // {...register(field.name, field.inputRules)}
                                    {...register(field.name, {
                                      validate: (value) => {
                                        const selectedOptions =
                                          watch("partner_request_via") || [];
                                        if (selectedOptions.includes("call")) {
                                          return (
                                            value.trim().length > 0 ||
                                            "This field is required"
                                          );
                                        }
                                        return true; // No validation for other options
                                      },
                                    })}
                                  ></textarea>
                                  {errors[field.name] && (
                                    <p className="error mt-1" role="alert">
                                      {errors[field.name].message}
                                    </p>
                                  )}
                                </>
                              ) : null}
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </>
              )}

              {/* {userData.user_type === 'agent' &&

              <>
                <div
                  className="text-primary-blue text-[18px] py-5 mb-8"
                  style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
                >
                  Partner Details
                </div>
                <div className="flex flex-wrap gap-4">
                  {(partnerDetailsFields || [])?.map((field, index) => {
                    return (
                      <div className={`relative mb-6  ${field.width}`} key={index}>
                        <label
                          className="block mb-2 text-xs font-normal tracking-wide text-gray-700"
                          htmlFor={field.label}
                        >
                          {field.label}{" "}
                          <span style={{ color: "red" }}>
                            {field?.inputRules?.required ? "*" : ""}
                          </span>
                        </label>
                        {field.type === "input" ? (
                          <div className="relative">
                            <input
                              {...register(field.name, field.inputRules)}
                              type={field.fieldType}
                              className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                              placeholder={field.placeholder}
                              style={{
                                color: "#232323",
                                borderRadius: "3px",
                              }}
                              aria-invalid={!!errors[field.name]}
                            />
                            {field.icon && field.icon === "location" && (
                              <img
                                className="absolute top-4 right-4"
                                src={locationIcon}
                                alt="locationIcon"
                              />
                            )}

                            {errors[field.name] && (
                              <p className="error" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </div>
                        ) : field.type === "dropdown" ? (
                          <>
                            <Controller
                              name={field.name}
                              control={control}
                              rules={field.inputRules}
                              render={({ field: controllerField, fieldState }) => (
                                <select
                                  {...controllerField}
                                  className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg 
                 focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]"
                                  placeholder={field.placeholder}
                                  style={{
                                    color: "#232323",
                                    borderRadius: "3px",
                                    borderRight: "16px solid transparent",
                                  }}
                                  disabled={name}
                                >
                                  <option value="">Select</option>
                                  {(field.name === "category"
                                    ? categoryList?.length > 0
                                      ? categoryList
                                      : []
                                    : field.options
                                  )?.map((option, index) => (
                                    <option value={option.value} key={index}>
                                      {option?.name
                                        ?.split(" ")
                                        ?.map((word) =>
                                          [
                                            "and",
                                            "or",
                                            "but",
                                            "the",
                                            "a",
                                            "an",
                                            "of",
                                            "in",
                                            "on",
                                            "at",
                                            "for",
                                            "to",
                                            "with",
                                          ].includes(word.toLowerCase())
                                            ? word // Keep small words as is
                                            : word.charAt(0).toUpperCase() + word.slice(1) // Capitalize the first letter of significant words
                                        )
                                        .join(" ")}
                                    </option>
                                  ))}
                                </select>
                              )}
                            />
                            {errors[field.name] && (
                              <p className="error" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </>
                        ) : field.type === "radio" ? (
                          <>
                            <div className="flex items-center me-4">
                              {field.options?.map((option, index) => (
                                <div
                                  value={getValues[field.name]}
                                  option={option.value}
                                  className={`flex items-center me-4 ${getValues[field.name] === option.value
                                    ? "bg-primary-blue"
                                    : "bg-light-blue"
                                    } py-3 px-5`}
                                  key={index}
                                >
                                  <input
                                    type="radio"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    {...register(field.name, field.inputRules)}
                                    value={option.value}
                                  // checked={getValues[field.name] === option.value}
                                  />
                                  <label className="ms-2 text-[12px] text-gray-900 dark:text-gray-300">
                                    {option.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                            {errors[field.name] && (
                              <p className="error" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </>
                        ) : field.type === "textbox" ? (
                          <>
                            <textarea
                              id="message"
                              rows="4"
                              className={`block p-2.5 input-bg w-full text-sm text-gray-900  
                                                                    focus-visible:outline-none focus-visible:border-none`}
                              style={{ borderRadius: "3px" }}
                              placeholder={field.placeholder}
                              {...register(field.name, field.inputRules)}
                            ></textarea>
                            {errors[field.name] && (
                              <p className="error" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </>
                        ) : field.type === "file" ? (
                          <>
                            <div className="flex items-center justify-center w-full">
                              <label
                                htmlFor={field.name}
                                className="flex flex-col items-center justify-center w-full h-64 cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
                                style={{
                                  border: "2px dashed rgba(34, 96, 217, 1)",
                                }}
                              >
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                  <img src={UploadIcon} alt="Upload Image" />
                                  <p className="mt-2 mb-2 text-sm text-light-gray">
                                    <span>Add / Browse</span>
                                  </p>
                                </div>
                                <input
                                  id={field.name}
                                  type="file"
                                  {...register(field.name, field.inputRules)}
                                  // onChange={(e) => {
                                  //     imageField.onChange(e);
                                  //     if (e.target.files && e.target.files[0]) {
                                  //         let types = ['image/png', 'image/jpeg']
                                  //         if (types.includes(e.target.files[0].type)) {
                                  //             setLogoImage({ ...logoImage, [field.name]: URL.createObjectURL(e.target.files[0]) });
                                  //         } else {
                                  //             setError([field.name], 'Invalid file type')
                                  //         }
                                  //     }
                                  // }}
                                  className="hidden"
                                />
                              </label>
                            </div>
                            {errors[field.name] && (
                              <p className="error" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </>
            } */}

              {/* {userType !== "consumer" && ( */}
              <>
                <div
                  className="flex items-center justify-between py-5 mb-8"
                  style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
                >
                  <div className="text-primary-blue text-[18px]">
                    {userType === "consumer"
                      ? "Further communication availability"
                      : "Client Additional Information"}
                  </div>
                  <div
                    className="bg-[#F1F6FF] w-6 h-6 rounded-full text-center cursor-pointer"
                    onClick={() => toggleSection("additionalInformation")}
                  >
                    {sections.additionalInformation ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </div>
                </div>
                {sections.additionalInformation && (
                  <>
                    <div className="w-full mb-5">
                      <label className="block mb-2 text-xs font-normal tracking-wide text-[#353F4F]">
                        Documents (Optional)
                      </label>
                      <div className="relative flex justify-between items-center w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]">
                        <Stack direction="row" spacing={1}>
                          {imageFiles.map((file, index) => (
                            <Chip
                              key={index}
                              label={file.name}
                              onDelete={() => handleDelete(file)}
                              sx={{
                                backgroundColor: "#F0F0F0",
                                color: "#2260D9",
                                ".MuiChip-deleteIcon": {
                                  color: "#FF0000",
                                },
                              }}
                            />
                          ))}
                        </Stack>
                        <input
                          type="file"
                          accept="image/*"
                          name="image"
                          multiple
                          className={`bg-transparent w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px]`}
                          id="image-upload"
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                            display: "none",
                          }}
                          onChange={handleImageChange}
                        />
                        <button
                          className="bg-[#2260D9] text-white rounded-[2px] p-2"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            document.getElementById("image-upload").click();
                          }}
                        >
                          <AddIcon className="" />
                          Upload Image
                        </button>
                      </div>
                    </div>

                    <div className="flex flex-wrap gap-4">
                      {(ClientAdditionalFields || [])?.map((field, index) => {
                        if (
                          field.name === "comments" &&
                          selectedAvailability !== "opt_out"
                        ) {
                          return null;
                        }
                        return (
                          <div
                            className={`relative mb-6  ${field.width}`}
                            key={index}
                          >
                            <label
                              className="block mb-2 text-xs font-normal tracking-wide text-[#353F4F]"
                              htmlFor={field.label}
                            >
                              {field.label}{" "}
                              <span style={{ color: "red" }}>
                                {field?.inputRules?.required ? "*" : ""}
                              </span>
                            </label>
                            {field.type === "input" ? (
                              <div className="relative">
                                <input
                                  {...register(field.name, field.inputRules)}
                                  type={field.fieldType}
                                  className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                                  placeholder={field.placeholder}
                                  style={{
                                    color: "#232323",
                                    borderRadius: "3px",
                                  }}
                                  aria-invalid={!!errors[field.name]}
                                />
                                {field.icon && field.icon === "location" && (
                                  <img
                                    className="absolute top-4 right-4"
                                    src={locationIcon}
                                    alt="locationIcon"
                                  />
                                )}

                                {errors[field.name] && (
                                  <p className="error mt-1" role="alert">
                                    {errors[field.name].message}
                                  </p>
                                )}
                              </div>
                            ) : field.type === "tel" ? (
                              <div className="relative">
                                <input
                                  {...register(field.name, field.inputRules)}
                                  type={field.fieldType}
                                  className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                                  placeholder={field.placeholder}
                                  style={{
                                    color: "#232323",
                                    borderRadius: "3px",
                                  }}
                                  aria-invalid={!!errors[field.name]}
                                  onChange={(e) => {
                                    const input = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    ); // Remove all non-digit characters
                                    const formatted = input
                                      .replace(
                                        /(\d{3})(\d{3})(\d{4})/,
                                        "($1) $2-$3"
                                      ) // Format with parentheses and dash
                                      .replace(/(\d{3})(\d{3})/, "($1) $2") // Handle intermediate formatting
                                      .substring(0, 14); // Limit to the required format
                                    e.target.value = formatted;
                                  }}
                                />
                                {errors[field.name] && (
                                  <p className="error mt-1" role="alert">
                                    {errors[field.name].message}
                                  </p>
                                )}
                              </div>
                            ) : field.type === "radio" ? (
                              <>
                                <div className="flex items-center me-4">
                                  {field.options?.map((option, index) => (
                                    <div
                                      value={getValues[field.name]}
                                      option={option.value}
                                      className={`flex items-center me-4 ${
                                        getValues[field.name] === option.value
                                          ? "bg-primary-blue"
                                          : "bg-light-blue"
                                      } py-3 px-5`}
                                      key={index}
                                    >
                                      <input
                                        type="radio"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        {...register(
                                          field.name,
                                          field.inputRules
                                        )}
                                        value={option.value}
                                        // checked={getValues[field.name] === option.value}
                                        // checked={getValues[field.name] === option.value || option.value === "opt_out"}
                                        defaultChecked={
                                          option.value === "opt_in"
                                        }
                                      />
                                      <label className="ms-2 text-[12px] text-[#353F4F] dark:text-[#353F4F]">
                                        {option.name}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                                {errors[field.name] && (
                                  <p className="error mt-1" role="alert">
                                    {errors[field.name].message}
                                  </p>
                                )}
                              </>
                            ) : field.type === "textbox" ? (
                              <>
                                <textarea
                                  id="message"
                                  rows="4"
                                  className={`block p-2.5 input-bg w-full text-sm text-[#353F4F]  
                                                                    focus-visible:outline-none focus-visible:border-none`}
                                  style={{ borderRadius: "3px" }}
                                  placeholder={field.placeholder}
                                  {...register(field.name, field.inputRules)}
                                ></textarea>
                                {errors[field.name] && (
                                  <p className="error mt-1" role="alert">
                                    {errors[field.name].message}
                                  </p>
                                )}
                              </>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </>
              {/* )} */}
              <div className="flex justify-center gap-6 my-10 align-middle">
                <Button
                  btnName="Cancel"
                  btnCategory="secondary"
                  btnCls="w-[200px]"
                  onClick={() => {
                    userType === "consumer"
                      ? navigate(`/category/${requestId}?from=dashboard`)
                      : navigate("/");
                  }}
                />
                <Button
                  btnType="submit"
                  id={"program-submit"}
                  btnCls="w-[200px]"
                  btnName={"Submit"}
                  btnCategory="primary"
                />
              </div>
              {/* {userType === "consumer" && (
                <div className="flex justify-center gap-6 my-10 align-middle">
                  <Button
                    btnName="Canc"
                    btnCategory="secondary"
                    btnCls="w-[200px]"
                    onClick={() => navigate(`/category/${userId}?from=dashboard`)}
                  />
                  <Button
                    btnType="submit"
                    id={"program-submit"}
                    btnCls="w-[200px]"
                    btnName={"Submit"}
                    btnCategory="primary"
                  />
                </div>
              )} */}
            </div>
          </form>
        </div>
      </div>
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            "& .MuiPaper-root": {
              boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
              borderRadius: "10px",
            },
          }}
        >
          <div className="flex flex-col px-20 py-12 items-center gap-10">
            <img
              src={BlueTickicon}
              alt="Tick Animation"
              width="61"
              height="80"
            />
            <div className="text-[#2260D9] font-medium text-[34px]">
              Request Submitted
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}
