import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import BreadCrumIcon from "../../assets/breadcrumb-right-icon.svg";
import ProfileIcon from "../../assets/user-profile-icon.svg";
import ShieldIcon from "../../assets/security-shield-icon.svg";
import ProfileEditIcon from "../../assets/profile-edit-icon.svg";
import ProfileImageIcon from "../../assets/profile-image-icon.svg";
import ProfileImagePencilIcon from "../../assets/profile-image-pencil-icon.svg";
import PermissionIcon from "../../assets/permission-icon.svg";
import PermissionCheckIcon from "../../assets/permission-check-icon.svg";
import EyeOpen from "../../assets/eye-open-icon.svg";
import EyeClosed from "../../assets/eye-closed-icon.svg";
import { getUserType } from "../../services/userType";
import { profileDetails } from "../../services/userType";
import { useDispatch, useSelector } from "react-redux";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import EmployeesIcon from "../../assets/employeesicon.svg";
import AllEmployee from "../Partner-Employee/AllEmployee";

export default function Profile() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("profile");
  const [isEditing, setIsEditing] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    getFieldState,
    getValues,
    setError,
    setValue,
  } = useForm();
  const { data, status, userType } = useSelector((state) => state.userInfo);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserType());
  }, [dispatch]);

  const profile = useSelector((state) => state.userTypeData.details);
  // console.log('state',profile)

  const [profileImage, setProfileImage] = useState(ProfileImageIcon);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      readFile(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      readFile(file);
    }
  };

  // Helper to read file as DataURL
  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setProfileImage(reader.result); // Update the profile image
    };
    reader.readAsDataURL(file);
  };

  const toggleEditMode = () => {
    setIsEditing((prev) => !prev);
    if (!isEditing && profile) {
      reset({
        username: profile.username,
        phone_number: profile.phone_number,
        email: profile.email,
        id: profile.id,
        address: profile.address,
        user_bio: profile.user_bio,
      });
    }
  };

  const handleSave = (data) => {
    // console.log("Edited data being saved:", data);
    dispatch(profileDetails({ profileData: data }))
      .then(() => {
        setIsEditing(false);
        dispatch(getUserType());
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  // const toggleEditMode = () => {
  //   setIsEditing(!isEditing);
  //   if (!isEditing) {
  //     reset(profile);
  //   }
  // };

  const inputClassName = isEditing
    ? "w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px] rounded-[3px]"
    : "";

  const onSubmit = (data) => {
    console.log("Change password", data);
  };

  useEffect(() => {
    if (profile && isEditing) {
      reset({
        username: profile.username,
        phone_number: profile.phone_number,
        email: profile.email,
        id: profile.id,
        address: profile.address,
        user_bio: profile.user_bio,
      });
    }
  }, [profile, isEditing, reset]);

  return (
    <div className="px-8 mt-10">
      <div className="mb-6">
        <BreadCumbs
          firstCumbName="Dashboard"
          secoundCumbName="View Profile"
          pathToRedirect=""
        />
      </div>

      <p className="py-8 font-medium font-sans text-lg">Settings</p>

      <div className="border border-[#2260D9] rounded-[3px] mb-10">
        <div className=" grid grid-cols-7">
          <div className="col-span-2 left-side-bar border-r border-[#D9D9D9] pt-5">
            <div
              className={`cursor-pointer ${
                activeTab === "profile" ? "active" : ""
              }`}
              onClick={() => setActiveTab("profile")}
            >
              <div className="flex gap-3 py-4 px-5">
                <img src={ProfileIcon} alt="ProfileIcon" />
                <p>Profile</p>
              </div>
            </div>
            {userType === "partner" && (
              <div
                className={`cursor-pointer ${
                  activeTab === "employees" ? "active" : ""
                }`}
                onClick={() => setActiveTab("employees")}
              >
                <div className="flex gap-3 py-4 px-5">
                  <img src={EmployeesIcon} alt="EmployeesIcon" />
                  <p>Employees</p>
                </div>
              </div>
            )}

            <div
              className={`cursor-pointer ${
                activeTab === "security" ? "active" : ""
              }`}
              onClick={() => setActiveTab("security")}
            >
              <div className="flex gap-3 py-4 px-5">
                <img src={ShieldIcon} alt="ShieldIcon" />
                <p>Security</p>
              </div>
            </div>
            <div
              className={`cursor-pointer ${
                activeTab === "permission" ? "active" : ""
              }`}
              onClick={() => setActiveTab("permission")}
            >
              <div className="flex gap-3 py-4 px-5">
                <img src={PermissionIcon} alt="LockIcon" />
                <p>Permissions</p>
              </div>
            </div>
          </div>
          <div className="col-span-5 px-9 py-9 ">
            {activeTab === "profile" && (
              <>
                <div className="flex justify-between items-start ">
                  <p className="text-[#353F4F] font-medium text-lg">
                    Profile Picture
                  </p>
                  {isEditing ? (
                    userType !== "partner" && (
                      <button
                        onClick={handleSubmit(handleSave)}
                        className="text-[#353F4F] font-medium w-[180px] py-3 border border-[#353F4F] text-sm rounded-[3px] bg-[#2260D9] text-white active:border-none"
                      >
                        Save Changes
                      </button>
                    )
                  ) : (
                    <button
                      className="flex gap-4 items-center bg-[#2260D9] text-white  text-[14px] w-[110px] py-3 justify-center rounded-[3px]"
                      onClick={toggleEditMode}
                    >
                      <img src={ProfileEditIcon} alt="Profile Edit Icon" />
                      Edit
                    </button>
                  )}
                </div>

                {/* <div className="py-4 relative">
                  <img src={ProfileImageIcon} alt="ProfileImageIcon" />
                  <img
                    src={ProfileImagePencilIcon}
                    className="absolute top-[50%] left-2 cursor-pointer"
                    alt="ProfileImagePencilIcon"
                  />
                </div> */}

                <div
                  className="py-4 relative"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={handleDrop}
                >
                  <img
                    src={profileImage}
                    alt="ProfileImageIcon"
                    onClick={() => document.getElementById("fileInput").click()}
                    className="cursor-pointer object-cover"
                  />

                  <img
                    src={ProfileImagePencilIcon}
                    className="absolute top-[50%] left-2 cursor-pointer"
                    alt="ProfileImagePencilIcon"
                    onClick={() => document.getElementById("fileInput").click()}
                  />

                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </div>

                <div className="grid grid-cols-3 text-[13px] py-6 gap-6">
                  <div className="pb-4">
                    <p className="text-[#747474]">Profile Name</p>
                    {isEditing ? (
                      <>
                        <input
                          type="text"
                          {...register("username", {
                            required: "This field is required",
                          })}
                          className={`mt-1.5 ${inputClassName}`}
                          disabled={!isEditing}
                        />
                        {errors.username && (
                          <p className="text-primary-red text-sm mt-1">
                            {errors.username.message}
                          </p>
                        )}
                      </>
                    ) : (
                      <p className="pt-2.5">{profile.username}</p>
                    )}
                  </div>
                  <div className="pb-4">
                    <p className="text-[#747474]">Contact Number</p>
                    {isEditing ? (
                      <>
                        <input
                          type="tel"
                          {...register("phone_number", {
                            required: "This field is required",
                            pattern: {
                              value: /^\(\d{3}\) \d{3}-\d{4}$/, // Match the format (123) 456-7890
                              message:
                                "Phone number must be in the format Ex: (555) 555-1234",
                            },
                          })}
                          className={`mt-1.5 ${inputClassName}`}
                          disabled={!isEditing}
                          placeholder="Ex: (555) 555-1234"
                          onChange={(e) => {
                            let input = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
                            let formatted = "";

                            if (input.length > 0)
                              formatted = `(${input.substring(0, 3)}`;
                            if (input.length > 3)
                              formatted += `) ${input.substring(3, 6)}`;
                            if (input.length > 6)
                              formatted += `-${input.substring(6, 10)}`;
                            e.target.value = formatted;
                          }}
                        />
                        {errors.phone_number && (
                          <p className="text-primary-red text-sm mt-1">
                            {errors.phone_number.message}
                          </p>
                        )}
                      </>
                    ) : (
                      <p className="pt-2.5">{profile.phone_number}</p>
                    )}
                  </div>
                  <div className="pb-4">
                    <p className="text-[#747474]">Mail ID</p>
                    {isEditing ? (
                      <>
                        <input
                          type="email"
                          {...register("email", {
                            required: "This field is required",
                            // pattern: {
                            //   value:
                            //     /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            //   message: "Please enter a valid email address",
                            // },
                          })}
                          className={`mt-1.5 ${inputClassName}`}
                          disabled={!isEditing}
                        />
                        {errors.email && (
                          <p className="text-primary-red text-sm mt-1">
                            {errors.email.message}
                          </p>
                        )}
                      </>
                    ) : (
                      <p className="pt-2.5">{profile.email}</p>
                    )}
                  </div>

                  <div>
                    <p className="text-[#747474] capitalize">
                      {profile.user_type} ID
                    </p>
                    {isEditing ? (
                      <input
                        type="number"
                        {...register("id", {
                          required: "This field is required",
                        })}
                        className={`mt-1.5 ${inputClassName}`}
                        disabled={isEditing}
                      />
                    ) : (
                      <p className="pt-2.5">{profile.id}</p>
                    )}
                  </div>
                  <div className={`${isEditing ? "col-span-2" : ""}`}>
                    <p className="text-[#747474]">Address</p>
                    {isEditing ? (
                      <>
                        <textarea
                          {...register("address", {
                            required: "This field is required",
                          })}
                          className={`mt-1.5 ${inputClassName}`}
                          disabled={!isEditing}
                        />
                        {errors.address && (
                          <p className="text-primary-red text-sm mt-1">
                            {errors.address.message}
                          </p>
                        )}
                      </>
                    ) : (
                      <p className="pt-2.5">{profile.address}</p>
                    )}
                  </div>
                  <div></div>
                </div>
                <div className="text-[13px] pb-4">
                  <p className="text-[#747474]">Professional Bio</p>
                  {isEditing ? (
                    <textarea
                      {...register("user_bio", {
                        required: "This field is required",
                      })}
                      className={`mt-1.5 ${inputClassName}`}
                      disabled={!isEditing}
                    />
                  ) : (
                    <p className="pt-2.5">{profile.user_bio}</p>
                  )}
                </div>
                {userType === "partner" && (
                  <div>
                    {isEditing ? (
                      <div className="w-full flex justify-center gap-4">
                        <button
                          onClick={() => {
                            setIsEditing(false);
                            reset();
                          }}
                          className="border-[#353F4F] border bg-white  text-[#353F4F] font-medium w-[180px] py-3 text-sm rounded-[3px]"
                        >
                          <p>Cancel</p>
                        </button>
                        <button
                          onClick={handleSubmit(handleSave)}
                          className="bg-[#2260D9] text-white font-medium w-[180px] py-3 border text-sm rounded-[3px]"
                        >
                          <p>Save Changes</p>
                        </button>
                      </div>
                    ) : null}
                  </div>
                )}
              </>
            )}

            {userType === "partner" && activeTab === "employees" && (
              <>
                <AllEmployee />
              </>
            )}

            {activeTab === "security" && (
              <>
                <div className="flex justify-between items-center ">
                  <p className="text-[#2260D9] font-medium">Password</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="py-7">
                    <div className="mb-6">
                      <label className="block tracking-wide text-gray-700 text-xs font-normal mb-2">
                        Current Password
                      </label>

                      <div className="relative">
                        <input
                          {...register("current-password", {
                            required: "This field is required",
                          })}
                          type={showCurrentPassword ? "text" : "password"}
                          className="relative w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder={"Current Password"}
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                          }}
                          aria-invalid={!!errors["current-password"]}
                        />
                        <img
                          className="absolute top-[50%] transform -translate-y-[50%] right-3"
                          onClick={() =>
                            setShowCurrentPassword(!showCurrentPassword)
                          }
                          src={showCurrentPassword ? EyeOpen : EyeClosed}
                          alt="EyeCloseIcon"
                        />
                      </div>
                      <div className="flex justify-between text-[#2260D9] font-medium text-xs pt-2">
                        <div>Last Update 21/08/2024</div>
                        <div>Forget Password?</div>
                      </div>
                      {errors["current-password"] && (
                        <p className="error" role="alert">
                          {errors["current-password"].message}
                        </p>
                      )}
                    </div>

                    <div className="relative mb-6">
                      <label className="block tracking-wide text-gray-700 text-xs font-normal mb-2">
                        New Password
                      </label>

                      <div className="relative">
                        <input
                          {...register("new-password", {
                            required: "This field is required",
                          })}
                          type={showNewPassword ? "text" : "password"}
                          className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder={"New Password"}
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                          }}
                          aria-invalid={!!errors["new-password"]}
                        />
                        <img
                          className="absolute top-[50%] transform -translate-y-[50%] right-3"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          src={showNewPassword ? EyeOpen : EyeClosed}
                          alt="EyeCloseIcon"
                        />
                        {errors["new-password"] && (
                          <p className="error" role="alert">
                            {errors["new-password"].message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="relative mb-6">
                      <label className="block tracking-wide text-gray-700 text-xs font-normal mb-2">
                        Confirm Password
                      </label>

                      <div className="relative">
                        <input
                          {...register("confirm-password", {
                            required: "This field is required",
                          })}
                          type={showConfirmPassword ? "text" : "password"}
                          className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder={"Confirm Password"}
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                          }}
                          aria-invalid={!!errors["confirm-password"]}
                        />
                        <img
                          className="absolute top-[50%] transform -translate-y-[50%] right-3"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          src={showConfirmPassword ? EyeOpen : EyeClosed}
                          alt="EyeCloseIcon"
                        />
                        {errors["confirm-password"] && (
                          <p className="error" role="alert">
                            {errors["confirm-password"].message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="relative mb-6 flex justify-center items-stretch gap-6  font-medium text-sm mt-14">
                      <button className="text-[#353F4F] border border-[#353F4F] py-3 w-[150px] rounded-[3px]">
                        Cancel
                      </button>
                      <button className="bg-[#2260D9] text-white w-[150px] rounded-[3px]">
                        Reset Password
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )}

            {activeTab === "permission" && (
              <div>
                <table className="permission-table text-[14px]">
                  <thead>
                    <tr>
                      <th>Name of the Permission</th>
                      <th>Create</th>
                      <th>View</th>
                      <th>Edit</th>
                      <th>Accept</th>
                      <th>Reject</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Request</td>
                      <td>
                        <img
                          src={PermissionCheckIcon}
                          alt="PermissionCheckIcon"
                        />
                      </td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>

                    <tr>
                      <td>Partner</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>

                    <tr>
                      <td>Program</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>

                    <tr>
                      <td>Profile</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>

                    <tr>
                      <td>Password</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
