import { createSlice } from "@reduxjs/toolkit";

import {
  loginSocialMedia,
  facebookLogin,
  optVerified,
  updateInfo,
  updateUserInfo,
  updateUserType,
} from "../../services/user";
import { loginData } from "../../services/user";
import { userApiResponse } from "../../constants/apiResponse";
import { getUserType } from "../../services/userType";

const initialState = {
  userType: "",
  data: {},
  loading: false,
  status: "",
  error: "",
  otp: "",
};

export const userSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginSocialMedia.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(loginSocialMedia.fulfilled, (state, action) => {
        const { userResponse = {}, status } = action.payload;
        return {
          ...state,
          data: {...userResponse, id: userResponse.user_id},
          status: userApiResponse.loggin,
          loading: false,
        };
      })
      .addCase(loginSocialMedia.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });
    builder
      .addCase(optVerified.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(optVerified.fulfilled, (state, action) => {
        const { userResponse = {}, status } = action.payload;
        return {
          ...state,
          data: userResponse,
          status: userApiResponse.loggin,
          loading: false,
        };
      })
      .addCase(optVerified.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

    builder.addCase(updateInfo.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        status: "",
        error: "",
      };
    });
    builder.addCase(updateUserInfo, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });
    builder
      .addCase(updateUserType, (state, action) => {
        return {
          ...state,
          status: "",
          userType: action.payload,
          loading: false,
        };
      })
      .addCase(getUserType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserType.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.userType = action?.payload?.user_type;
      })
      .addCase(getUserType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default userSlice.reducer;
