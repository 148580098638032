export const RequestColumn = [
  {
    field: "full_name",
    headerName: "Request ID",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Program",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "No. of Beneficiaries",
    flex: 1,
    id: 2,
  },
  {
    field: "email",
    headerName: "Via",
    flex: 1,
    id: 1,
  },
  {
    field: "location",
    headerName: "Submitted Date",
    flex: 1,
    id: 2,
  },
  {
    field: "attn_program",
    headerName: "Level",
    flex: 1,
    id: 2,
  },
  {
    field: "last_attend_program",
    headerName: "Description",
    flex: 1,
    id: 1,
  },
  {
    field: "last_attend_program",
    headerName: "Action",
    flex: 1,
    id: 1,
  },
];

export const FrequentRequestColumn = [
  {
    field: "id",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => {
      return (
        <div
          style={{
            color: `${
              {
                "in progress": "#FFCD11",
                accepted: "#278E1D",
                wait: "#1D5BBF",
                rejected: "#DC3545",
                "on hold": "#E26313",
                new: "#2260D9",
                completed: "#278E1D",
                resolved: "#278E1D",
                "in review": "#FF6711",
              }[params?.row?.request_status] || "#000000"
            }`,
          }}
          className="capitalize"
        >
          {params?.row?.request_status}
        </div>
      );
    },
  },
  {
    field: "request_count",
    headerName: "Total Request",
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  // {
  //   field: "time",
  //   headerName: "Last Updated Date & Time",
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  // },
];

// export const CurrentStatusColumn = [
//   {
//     field: "full_name",
//     headerName: "OPT",
//     flex: 1,
//     id: 0,
//   },
//   {
//     field: "id",
//     headerName: "Request ID",
//     flex: 1,
//     id: 1,
//   },
//   {
//     field: "first_name",
//     headerName: "Client Name",
//     flex: 1,
//     id: 2,
//   },
//   {
//     field: "category",
//     headerName: "Program",
//     flex: 1,
//     id: 3,
//   },
//   {
//     field: "contact",
//     headerName: "Contact",
//     flex: 1,
//     id: 4,
//   },
//   {
//     field: "request_via",
//     headerName: "Via",
//     flex: 1,
//     id: 5,
//   },
//   {
//     field: "intakeDate",
//     headerName: "Intake Date",
//     flex: 1,
//     id: 6,
//   },
//   {
//     field: "level",
//     headerName: "Level",
//     flex: 1,
//     id: 7,
//   },
//   {
//     field: "description",
//     headerName: "Description",
//     flex: 1,
//     id: 8,
//   },
//   {
//     field: "submittedDate",
//     headerName: "Submitted Date",
//     flex: 1,
//     id: 9,
//   },
//   {
//     field: "request_status",
//     headerName: "Status",
//     flex: 1,
//     id: 10,
//     renderCell: (params) => {
//       return params.row.request_status || "...";
//     },
//   },
//   {
//     field: "partners",
//     headerName: "Partner",
//     flex: 1,
//     id: 11,
//     renderCell: (params) => {
//       return params.row.partners.length > 0 ? params.row.partners : "...";
//     },
//   },
//   {
//     field: "partner_status",
//     headerName: "Partner Response",
//     flex: 1,
//     id: 12,
//     renderCell: (params) => {
//       return params.row.partner_status === "wait" ? "..." : params.row.partner_status || "...";
//     },
//   },
// ];

export const PartnerColumn = [
  {
    field: "partner_id",
    headerName: "Partner ID",
    flex: 1,
    id: 0,
  },
  {
    field: "full_name",
    headerName: "Organization Name",
    flex: 1,
    id: 1,
  },
  {
    field: "partner_type",
    headerName: "Type",
    flex: 1,
    id: 2,
  },
  {
    field: "category",
    headerName: "Program",
    flex: 1,
    id: 1,
  },
  {
    field: "totle_employees",
    headerName: "Total Employees",
    flex: 1,
    id: 1,
  },
  {
    field: "managed_category",
    headerName: "Managed Program",
    flex: 1,
    id: 2,
  },
];

export const ReferredColumn = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    id: 0,
  },
  {
    field: "partner_id",
    headerName: "Partner ID",
    flex: 1,
    id: 1,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    id: 2,
  },
  {
    field: "gender",
    headerName: "Gender",
    flex: 1,
    id: 1,
  },
  {
    field: "contact",
    headerName: "Contact",
    flex: 1,
    id: 1,
  },
];

export const CategoryColumn = [
  {
    field: "id",
    headerName: "Program ID",
    flex: 1,
    id: 0,
  },
  {
    field: "name",
    headerName: "Program",
    flex: 2,
    id: 1,
    renderCell: (params) => {
      return (
        <div className="capitalize">
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "category_type",
    headerName: "Program Type",
    flex: 1,
    id: 2,
    renderCell: (params) => {
      return (
        <div className="capitalize">
          {params.row.category_type}
        </div>
      );
    },
  },
  {
    field: "partner_count",
    headerName: "Total Partner",
    headerAlign: "center",
    align: "center",
    flex: 1,
    id: 3,
  },
  {
    field: "request_count",
    headerName: "Total Managed Request",
    headerAlign: "center",
    align: "center",
    flex: 1,
    id: 4,
  },
  // {
  //   field: "description",
  //   headerName: "Description",
  //   headerAlign: "center",
  //   flex: 1,
  //   id: 4,
  // },
];

export const AgentColumn = [
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    id: 2,
  },
  {
    field: "phone_number",
    headerName: "Contact",
    flex: 1,
    id: 3,
  },
  {
    field: "managed_partners",
    headerName: "Managed Partners",
    flex: 1,
    id: 4,
  },
  {
    field: "managed_request",
    headerName: "Managed Request",
    flex: 1,
    id: 5,
  },
];

export const TotalRequestColumn = [
  {
    field: "full_name",
    headerName: "OPT",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Request ID",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "Client Name",
    flex: 1,
    id: 2,
  },
  {
    field: "email",
    headerName: "Contact",
    flex: 1,
    id: 1,
  },
  {
    field: "location",
    headerName: "Via",
    flex: 1,
    id: 2,
  },
  {
    field: "intake_date",
    headerName: "Intake Date",
    flex: 1,
    id: 2,
  },
  {
    field: "attn_program",
    headerName: "Level",
    flex: 1,
    id: 2,
  },
  {
    field: "desc",
    headerName: "Description",
    flex: 1,
    id: 1,
  },
  {
    field: "submit",
    headerName: "Submitted Date",
    flex: 1,
    id: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    id: 1,
  },
  {
    field: "partner",
    headerName: "Partner",
    flex: 1,
    id: 1,
  },
  {
    field: "response",
    headerName: "Partner Response",
    flex: 1,
    id: 1,
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    id: 1,
  },
];

export const TotalPartnerColumn = [
  {
    field: "full_name",
    headerName: "Partner ID",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Name",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "Total Employees",
    flex: 1,
    id: 2,
  },
  {
    field: "email",
    headerName: "Managed Program",
    flex: 1,
    id: 1,
  },
  {
    field: "location",
    headerName: "Last Update Date",
    flex: 1,
    id: 2,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    id: 1,
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    id: 2,
  },
];

export const TotalAgentColumn = [
  {
    field: "agent_id",
    headerName: "Agent ID",
    flex: 1,
    id: 0,
  },
  {
    field: "agent_name",
    headerName: " Agent Name",
    flex: 1,
    id: 1,
  },
  {
    field: "total_employees",
    headerName: "Total Employees",
    flex: 1,
    id: 2,
  },
  {
    field: "managed_category",
    headerName: "Managed Program",
    flex: 1,
    id: 1,
  },
  {
    field: "last_update_date",
    headerName: "Last Update Date",
    flex: 1,
    id: 2,
  },
];

export const TotalEmployeesColumn = [
  {
    field: "full_name",
    headerName: "Agent Name",
    flex: 1,
    id: 0,
    renderCell: (params) => {
      return (
        <div className="capitalize">
          {params.row.full_name}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "Contact",
    flex: 1,
    id: 2,
  },
];

export const TotalCategoryColumn = [
  {
    field: "category_id",
    headerName: "Program Id",
    flex: 1,
    id: 0,
  },
  {
    field: "category",
    headerName: "Program",
    flex: 1,
    id: 1,
  },
  {
    field: "category_type",
    headerName: "Program type",
    flex: 1,
    id: 2,
  },
  {
    field: "available_partner",
    headerName: "Available Partner",
    flex: 1,
    id: 2,
  },
  {
    field: "managed_request",
    headerName: "Managed Request",
    flex: 1,
    id: 2,
  },
  {
    field: "last_update_date_time",
    headerName: "Last Update Date & Time",
    flex: 1,
    id: 2,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    id: 2,
  },
  {
    field: "Action",
    headerName: "Action",
    flex: 1,
    id: 2,
  },
];

export const TopAgentColumn = [
  {
    field: "request",
    headerName: "Total Request",
    headerAlign: "center",
    align: "center",
    flex: 1,
    id: 1,
  },
];

export const ExternalCategoryColumn = [
  {
    field: "partnerId",
    headerName: "Partner ID",
    flex: 1,
    id: 1,
  },

  { field: "name", headerName: "Name", flex: 1, id: 5 },
  
];

export const HistoryColumn = [
  {
    field: "ticket_id",
    headerName: "Ticket Id",
    flex: 1,
    id: 1,
  },
  {
    field: "subject_name",
    headerName: "Subject Name",
    flex: 1,
    id: 2,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    id: 3,
  },
  {
    field: "request_date",
    headerName: "Request Date",
    flex: 1,
    id: 4,
  },
  {
    field: "request_to",
    headerName: "Request To",
    flex: 1,
    id: 5,
  },
  {
    field: "file_size",
    headerName: "File Size",
    flex: 1,
    id: 6,
  },
];
