import React, { useEffect, useState } from "react";
import { Modal, Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../assets/close-red-icon.svg";
import { getAllPartnerList } from "../../services/partner";
import OptIcon from "../../assets/opt.svg";

const PartnerListPopup = ({
  open,
  onClose,
  setSelectedPartners,
  selectedPartnerNames,
  setSelectedPartnerNames,
  selectedPartnerIds,
  setSelectedPartnerIds,
}) => {
  const dispatch = useDispatch();
  const partnerInfo = useSelector(
    (state) => state.partnerInfo.partnerData?.data
  );
  // console.log('pInfo',partnerInfo)

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (open) {
      dispatch(
        getAllPartnerList({
          status: null,
          category_id: null,
        })
      );
      setSelectedRows([]);
    }
  }, [open, dispatch]);

  const handleRowClick = (params) => {
    const { id, organization_name } = params.row; 
    const isSelected = selectedRows.some((row) => row.id === id);

    if (isSelected) {
      setSelectedRows((prev) => prev.filter((row) => row.id !== id));
    } else {
      setSelectedRows((prev) => [...prev, params.row]);
    }
  };
  const handleAddClick = () => {
  
    const newIds = selectedRows.map((row) => row.id);
    const newNames = selectedRows.map((row) => row.organization_name);

    setSelectedPartnerIds((prev) => Array.from(new Set([...prev, ...newIds])));
    setSelectedPartnerNames((prev) =>
      Array.from(new Set([...prev, ...newNames]))
    );
    setSelectedPartners([...newNames]); 
    setSelectedRows([]);
    onClose(); 
  };
  
  const handleCancel = () => {
    setSelectedRows([]);
    onClose();
  };
  const columns = [
    {
      field: "partners",
      headerName: "",
      maxWidth: 80,
      renderCell: (params) => {
        const isChecked =
          selectedRows.some((row) => row.id === params.row.id) ||
          selectedPartnerIds.includes(params.row.id);
        return (
          <div className="flex items-center justify-center h-full">
            <input
              className="rounded-sm w-4 h-4 border-[#6F6F6F] text-[#607274] focus:ring-[#C6D8DA]"
              type="checkbox"
              checked={isChecked}
              onChange={() => handleRowClick(params)}
              // onChange={(e) =>
              //   handleCheckboxChange(
              //     // e.target.checked,
              //     // params.row.id,
              //     // params.row.organization_name
              //   )
              // }
            />
          </div>
        );
      },
    },
    {
      field: "organization_name",
      headerName: "Name",
      flex: 1,
    },

    {
      field: "partner_type",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-[5px]">
          <p className="font-sans text-[#18283D] font-semibold">Type</p>
          <img src={OptIcon} alt="opt" />
        </div>
      ),
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
  ];
  const rows = partnerInfo;

  return (
    <Modal
      open={open}
      onClose={handleCancel}
      className="flex items-center justify-center"
    >
      <Box className="bg-white rounded-lg shadow-lg w-[800px] p-4 py-7">
        <div className="flex items-center justify-between border-b border-[#D3D3D3] pb-3 mb-4 px-4">
          <h2 className="text-[#353F4F] text-lg font-semibold">
            Manage Request
          </h2>
          <img
            src={CloseIcon}
            onClick={handleCancel}
            className="cursor-pointer"
          />
        </div>
        <div className="h-[400px] mb-4">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            hideFooter
            onRowClick={handleRowClick}
          />
        </div>
        <div className="flex justify-center space-x-7">
          <Button
            variant="outlined"
            sx={{
              textTransform: "capitalize",
              width: "150px",
              color: "#353F4F",
              border: "1px solid #353F4F",
              borderRadius: "3.9px",
              paddingY: "8px",
              fontWeight: 500,
              fontSize: "16px",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "white",
              width: "150px",
              background: "#2260D9",
              borderRadius: "3.9px",
              textTransform: "capitalize",
              fontWeight: 500,
              fontSize: "16px",
            }}
            onClick={handleAddClick}
          >
            Add
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default PartnerListPopup;
