import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
import LocationIcon from "../../../src/assets/location-icon.svg";
import { Button } from "../../shared/Button/index.jsx";
import { AddNewEmployeeFields } from "../../utills/formFields.js";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import BlueTickicon from "../../assets/images/bluetick-icon.jpg"
import { Dialog } from "@mui/material";
import { useLocation } from "react-router";
import BreadCumbs from "../BreadCumbs/BreadCumbs.jsx";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { createEmployee, getEmployeesDetails, updateEmployee } from "../../services/employees.js";

export default function AddNewEmployee() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const EmpId = searchParams.get("id");
  const [employeeData, setEmployeeData] = useState({});
  const [imageFiles, setImageFiles] = useState([]);
  const { data: userData } = useSelector((state) => state.userInfo);

  const [popupMessage, setPopupMessage] = useState("");
  const [popupIcon, setPopupIcon] = useState("");
  const [open, setOpen] = useState(false);

  const { details } = useSelector((state) => state.employeeInfo);

  useEffect(() => {
    if (details && EmpId) {
      const empData = details?.find((emp) => emp.id === +EmpId);
      if (empData) {
        setEmployeeData(empData);
      }
    }
  }, [details, EmpId]);

  useEffect(() => {
    if (employeeData) {
      reset({
        first_name: employeeData.first_name || "",
        last_name: employeeData.last_name || "",
        email: employeeData.email || "",
        contact_number: employeeData.contact_number || "",
        alternative_number: employeeData.alternative_number || "",
        address: employeeData.address || "",
        city: employeeData.city || "",
        state: employeeData.state || "",
        postal_code: employeeData.postal_code || "",
        country: employeeData.country || "",
      });
    }
  }, [EmpId, employeeData, reset]);

  const onSubmit = async (data) => {
    try {
      const formattedData = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        contact_number: data.contact_number,
        alternative_number: data.alternative_number,
        address: data.address,
        city: data.city,
        state: data.state,
        postal_code: data.postal_code,
        country: data.country,
      };

      let response;
      if (EmpId) {
        const updatedData = Object.keys(formattedData).reduce((acc, key) => {
          if (formattedData[key] !== employeeData[key]) {
            acc[key] = formattedData[key];
          }
          return acc;
        }, {});

        const payload = {
          employee_id: +EmpId,
          ...updatedData,
        };

        response = await dispatch(updateEmployee(payload));
      } else {
        formattedData.partner = userData.id;
        response = await dispatch(createEmployee(formattedData)).then(
          dispatch(getEmployeesDetails(userData.id))
        );
      }

      if (
        response?.status === 201 ||
        response?.status === 200 ||
        response?.payload?.status === "success"
      ) {
        console.log("Employee created successfully", response.data);
        setPopupMessage("Employee Added Successfully");
        setPopupIcon(BlueTickicon);
        // navigate("/profile");
      } else {
        console.error(
          "Error creating employee:",
          response?.data?.message || response?.statusText
        );
      }
      setOpen(true);
      setTimeout(() => {
        navigate("/profile");
        setOpen(false);
      }, 2000);
    } catch (error) {
      console.error("API Error:", error.message);
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const updatedFiles = [...imageFiles, ...files];
    setImageFiles(updatedFiles);
  };
  const handleDelete = (fileToDelete) => {
    setImageFiles(imageFiles.filter((file) => file !== fileToDelete));
  };
  return (
    <div className="px-8 mt-10">
      <BreadCumbs
        firstCumbName="Employees"
        secoundCumbName="Add Employees"
        pathToRedirect="profile"
      />

      <div
        style={{
          boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.05)",
          marginBottom: "20px",
          borderRadius: "5px",
          border: "1px solid rgba(34, 96, 217, 1)",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-8 py-y">
            <div
              className="flex items-center justify-between py-6 title"
              style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
            >
              <div className="flex gap-4">
                <h3 className="text-[20px] text-[#353F4F]">Add Employee</h3>
              </div>
            </div>
          </div>

          <div className="px-8 py-4">
            <div
              className="flex  py-5 mb-8 items-center justify-between"
              style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
            >
              <div className="text-primary-blue text-[18px]">
                Employee Primary Information
              </div>
              <div className="bg-[#F1F6FF] w-6 h-6 rounded-full text-center">
                <ArrowDropDownIcon />
              </div>
            </div>
            <div className="flex flex-wrap gap-4">
              {AddNewEmployeeFields.map((field, index) => {
                return (
                  <div className={`relative mb-6  ${field.width}`} key={index}>
                    <label
                      className="block mb-2 text-xs font-normal tracking-wide text-[#353F4F]"
                      htmlFor={field.label}
                    >
                      {field.label}{" "}
                      <span style={{ color: "red" }}>
                        {field?.inputRules?.required ? "*" : ""}
                      </span>
                    </label>
                    {field.type === "input" ? (
                      <div className="relative">
                        <input
                          {...register(field.name, field.inputRules)}
                          // value={formData?.[field.name] || ""}
                          type={field.fieldType}
                          className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder={field.placeholder}
                          style={{ color: "#232323", borderRadius: "3px" }}
                        />
                        {field.icon && field.icon === "location" && (
                          <img
                            className="absolute top-4 right-4"
                            src={LocationIcon}
                            alt="LocationIcon"
                          />
                        )}

                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}
                          </p>
                        )}
                      </div>
                    ) : field.type === "tel" ? (
                      <div className="relative">
                        <input
                          {...register(field.name, field.inputRules)}
                          // value={formData?.[field.name] || ""}
                          type={field.fieldType}
                          className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder={field.placeholder}
                          style={{ color: "#232323", borderRadius: "3px" }}
                        />
                        {field.icon && field.icon === "location" && (
                          <img
                            className="absolute top-4 right-4"
                            src={LocationIcon}
                            alt="LocationIcon"
                          />
                        )}

                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}
                          </p>
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>

            <div className="w-full">
              <div
                className="flex  py-5 mb-8 items-center justify-between"
                style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
              >
                <div className="text-primary-blue text-[18px]">
                  Additional Informations
                </div>
                <div className="bg-[#F1F6FF] w-6 h-6 rounded-full text-center">
                  <ArrowDropDownIcon />
                </div>
              </div>

              <div className="w-full">
                <label className="block mb-2 text-xs font-normal tracking-wide text-[#353F4F]">
                  Documents (Optional)
                </label>
                <div className="relative flex justify-between items-center w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]">
                  <Stack direction="row" spacing={1}>
                    {imageFiles.map((file, index) => (
                      <Chip
                        key={index}
                        label={file.name}
                        onDelete={() => handleDelete(file)}
                        sx={{
                          backgroundColor: "#F0F0F0",
                          color: "#2260D9",
                          ".MuiChip-deleteIcon": {
                            color: "#FF0000",
                          },
                        }}
                      />
                    ))}
                  </Stack>
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    multiple
                    className={`bg-transparent w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px]`}
                    id="image-upload"
                    style={{
                      color: "#232323",
                      borderRadius: "3px",
                      display: "none",
                    }}
                    onChange={handleImageChange}
                  />
                  <button
                    className="bg-[#2260D9] text-white rounded-[2px] p-2"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      document.getElementById("image-upload").click();
                    }}
                  >
                    <AddIcon className="" />
                    Upload Image
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-center gap-6 my-10 align-middle">
              <Button
                btnName="Cancel"
                btnCategory="secondary"
                btnCls="w-[200px]"
                onClick={() => navigate(-1)}
              />
              <Button
                btnType="submit" // Ensure this is "submit" to trigger form submission
                id="program-submit"
                btnCls="w-[200px]"
                btnName="Submit"
                btnCategory="primary"
              />
            </div>
          </div>
        </form>
      </div>
      {open && (
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                sx={{
                  "& .MuiPaper-root": {
                    boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
                    borderRadius: "10px",
                  },
                }}
              >
                <div className="flex flex-col px-16 py-8 items-center gap-5">
                  <img src={popupIcon} alt="Tick Animation" width="61" height="80" />
                  <div className="text-[#2260D9] font-medium text-base">
                    {popupMessage}
                  </div>
                </div>
              </Dialog>
            )}
    </div>
  );
}
