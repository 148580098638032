import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import DataTable from "../../shared/DataGrid";
import { CategoryTab } from "../../utills/formFields";
import SearchIcon from "../../assets/input-search-icon.svg";
import MoreIcon from "../../assets/more-icon.svg";
import { CategoryColumn } from "../../utills/tableFields";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCategoryList,
  updateCategoryStatus,
} from "../../services/category";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import EyeOpenIcon from "../../assets/eye-open-black.svg";
import EyeClosedIcon from "../../assets/eye-closed-black.svg";
import AddPartnersInCategories from "../Popups/AddPartnersInCategories";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import Crossicon from "../../assets/crossicon.svg";
import Checkimg from "../../assets/checkimg.svg";
import Crossimg from "../../assets/crossimg.svg";
import Checkicon from "../../assets/checkicon.svg";
import Archiveicon from "../../assets/archivedicon.svg";
import { Dialog, DialogContent, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RightIcon from "../../assets/check-icon.svg";
import BlockIcon from "../../assets/block-icon.svg";
import moment from "moment";
import useDebounce from "../../utills/debounce";

export default function AllCategory() {
  const navigate = useNavigate();
  const [actionTab, setActiveTab] = useState("all");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopup, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [activateDialogOpen, setActivateDialogOpen] = useState(false); // New state for Activate dialog
  const [dialogMessage, setDialogMessage] = useState("");
  const [confirmAction, setConfirmAction] = useState({
    action: "",
    id: "",
  });
  const [cancelButtonActive, setCancelButtonActive] = useState(false);
  const [deactivateButtonActive, setDeactivateButtonActive] = useState(false);
  const [activateButtonActive, setActivateButtonActive] = useState(false);
  const [dialogImage, setDialogImage] = useState("");
  const [comment, setComment] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const searchValue = useDebounce(searchQuery, 500);

  const handleAction = (action, id) => {
    setConfirmAction({ action, id });
    if (action === "active") {
      setActivateDialogOpen(true);
    } else if (action === "archive") {
      handleDeleteDialogOpen();
    } else {
      setConfirmDialogOpen(true);
    }
    handleClose();
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false); // Closes the dialog
  };

  const handleConfirmAction = () => {
    // Validate comment for "deactivated" status
    if (confirmAction.action === "inactive" && !comment.trim()) {
      setDialogMessage("Reason is required when status is 'deactivated'.");
      setSuccessDialogOpen(true);
      return;
    }

    let message = "";
    let imageSrc = "";

    switch (confirmAction.action) {
      case "inactive":
        message = `Program successfully deactivated.`;
        imageSrc = Crossicon;
        break;
      case "active":
        message = `Program successfully activated.`;
        imageSrc = Checkicon;
        break;
      case "archive":
        message = `Program successfully archived.`;
        imageSrc = Archiveicon;
        break;
      default:
        break;
    }

    dispatch(
      updateCategoryStatus({
        category_id: confirmAction.id,
        status: confirmAction.action,
      })
    );

    setTimeout(() => {
      dispatch(getAllCategoryList(searchValue));
    }, 50);

    setConfirmAction({ action: "", id: "" });
    setDialogMessage(message);
    setDialogImage(imageSrc);
    setConfirmDialogOpen(false);
    setActivateDialogOpen(false);
    setSuccessDialogOpen(true);
    setDeleteDialogOpen(false);
    setComment("");
    handleClose();

    setTimeout(() => {
      setSuccessDialogOpen(false);
      window.location.reload();
    }, 2000);
  };

  const handleDialogClose = () => setSuccessDialogOpen(false);
  const handleConfirmDialogClose = () => setConfirmDialogOpen(false);
  const handleActivateDialogClose = () => setActivateDialogOpen(false);
  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };
  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEdit = (row) => {
    navigate(`/editcategory/${row.id}`, { state: { rowData: selectedRow } });
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCategoryList(searchValue));
  }, [dispatch,searchValue]);

  const categoryData = useSelector((state) => state.categoryInfo.data.data);

  const CategorysColumn = [
    ...CategoryColumn,
    {
      field: "date",
      headerName: "Last Update Date & Time",
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.date).format("MM/DD/YYYY | h:mm A");
      },
    },

    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const statusStyles = {
          active: {
            textClass: "text-[#29B473]",
            icon: RightIcon,
            label: "Active",
          },
          inactive: {
            textClass: "text-[#D22735]",
            icon: BlockIcon,
            label: "Inactive",
          },
          archive: {
            textClass: "text-[#D22735]",
            icon: BlockIcon,
            label: "Inactive",
          },
          default: {
            textClass: "text-[#D22735]",
            icon: null,
            label: "Unknown",
          },
        };

        const status = params.row.status || "default";
        const { textClass, icon, label } =
          statusStyles[status] || statusStyles.default;

        return (
          <div className={`flex items-center gap-2 ${textClass}`}>
            {icon && <img src={icon} alt={`${status} icon`} />}
            <span className="capitalize">{label}</span>
          </div>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      id: 4,
      renderCell: (params) => {
        const status = params.row.status;
        const handleDeleteDialogClose = () => {
          setDeleteDialogOpen(false);
        };

        return (
          <>
            <Menu as="div" className="inline-block mt-2 text-left">
              <div>
                <MenuButton className="cursor-pointer relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900">
                  <img
                    src={MoreIcon}
                    alt="MoreIcon"
                    onClick={(e) => handleClick(e, params.row)}
                  />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem
                    onClick={() => {
                      navigate(`/externalcategory/${params.id}`);
                    }}
                  >
                    <a
                      href="#"
                      className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                    >
                      View
                    </a>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      // handleEdit(params.row);
                      navigate(`/addnewprogram?id=${params.id}`);
                    }}
                  >
                    <a
                      href="#"
                      className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                    >
                      Edit
                    </a>
                  </MenuItem>
                  {status === "active" ? (
                    <MenuItem
                      onClick={() => handleAction("inactive", params.id)}
                    >
                      <p className=" cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                        Deactivate
                      </p>
                    </MenuItem>
                  ) : (
                    <>
                      <MenuItem
                        onClick={() => handleAction("active", params.id)}
                        // className="!text-[12px] text-green-600 hover:bg-[#D1FAE5] hover:text-green-800"
                      >
                        <p className=" cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                          Activate
                        </p>
                      </MenuItem>
                    </>
                  )}
                  {status === "active" ? (
                    <MenuItem
                      onClick={() => {
                        setOpen(params.id);
                      }}
                    >
                      <button
                        type="submit"
                        className="cursor-pointer block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                      >
                        Add Partners
                      </button>
                    </MenuItem>
                  ) : status === "archive" ? (
                    <MenuItem
                      onClick={() => handleAction("unarchive", params.id)}
                    >
                      <button
                        type="unarchive"
                        className="cursor-pointer block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                      >
                        Unarchive
                      </button>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() => handleAction("archive", params.id)}
                    >
                      <button
                        type="archive"
                        className="cursor-pointer block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                      >
                        Add To Archived
                      </button>
                    </MenuItem>
                  )}
                </div>
              </MenuItems>
            </Menu>
          </>
        );
      },
    },
  ];

  const tabCount = useMemo(() => {
    const obj = {
      all: 0,
      active: 0,
      inactive: 0,
    };
    // obj.all = categoryData?.length;

    obj.active = categoryData?.filter((c) => c?.status === "active")?.length;
    obj.inactive = categoryData?.filter(
      (c) => c?.status === "inactive"
    )?.length;
    obj.all = obj.active + obj.inactive;
    return obj;
  }, [categoryData]);

  const activeTabData = useMemo(() => {
    if (actionTab === "all") {
      if (isOpen) {
        return categoryData?.filter((c) => c?.status === "archive");
      }
      return categoryData?.filter(
        (c) => c?.status === "active" || c?.status === "inactive"
      );
    } else if (actionTab === "active") {
      return categoryData?.filter((c) => c?.status === "active");
    } else if (actionTab === "inactive") {
      if (isOpen) {
        return categoryData?.filter((c) => c?.status === "archive");
      }
      return categoryData?.filter((c) => c?.status === "inactive");
    }

    return categoryData;
  }, [actionTab, categoryData, isOpen]);

  const rows =
    activeTabData && activeTabData.length > 0
      ? activeTabData
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
          .map((item) => ({
            id: item.id,
            categoryId: item.id,
            name: item.name,
            category_type: item.category_type,
            partner_count: item.partner_count,
            request_count: item.request_count,
            description: item.description,
            date: item.updated_at,
            status: item.status,
          }))
      : [];

  return (
    <>
      <div className="px-8 pt-10 bg-[#F9F9F9]">
        <BreadCumbs
          firstCumbName="Program"
          secoundCumbName="Program Status"
          pathToRedirect="addnewprogram"
        />

        <div className="pt-10 pb-7 mt-10 mb-5 custom-shadow rounded-[5px]">
          <div className="font-sans font-medium text-[#18283D] text-[18px] border-b border-[#E4E6EF] mx-3 mb-7 pl-6 pb-4">
            Program
          </div>
          <div className="details-info pl-3 mr-7">
            <div
              className="flex justify-between items-center pl-4"
              style={{ borderBottom: "1px solid #D3D3D3" }}
            >
              <ul className="tab-list">
                {CategoryTab.map((discussion, index) => (
                  <li
                    className={`${
                      actionTab === discussion.key ? "active" : ""
                    } relative`}
                    key={index}
                    onClick={() => setActiveTab(discussion.key)}
                  >
                    <div className="flex justify-center pb-1">
                      <div
                        className={`total-proram-count relative ${
                          actionTab === discussion.key ? "active" : ""
                        }`}
                      >
                        {tabCount[discussion?.key]}
                      </div>
                    </div>
                    <div className="text-[13px]">{`${discussion.name}`}</div>
                    {actionTab === discussion.key && <span></span>}
                  </li>
                ))}
              </ul>
              <div className="flex items-center gap-7 pb-2">
                {actionTab !== "active" && (
                  <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={handleToggle}
                  >
                    <img
                      src={!isOpen ? EyeOpenIcon : EyeClosedIcon}
                      className="text-[#353F4F]"
                    />
                    <p>Archived Programs</p>
                  </div>
                )}

                <div className="relative">
                  <input
                    type="text"
                    id="search-navbar"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="block w-full p-2 text-sm placeholder:text-[#C8C9C9] focus:outline-none rounded-[3px]"
                    placeholder="Search"
                    style={{
                      border: "1px solid rgba(34, 96, 217, 1)",
                      height: "50px",
                      width: "390px",
                    }}
                  />
                  <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
                    <img src={SearchIcon} alt="SearchIcon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-7">
            <DataTable
              rows={rows || []}
              columns={CategorysColumn}
              hideCheckbox
              hideFooter
            />
          </div>
        </div>
        <AddPartnersInCategories
          open={openPopup}
          setOpen={setOpen}
          id={openPopup}
          fromRequest={false}
          setId={setOpen}
        />
      </div>

      <Dialog
        open={successDialogOpen}
        onClose={handleDialogClose} // Close on outside click
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{ width: "450px", height: "180px", borderRadius: "10px" }}
        >
          <img
            src={dialogImage}
            alt="Success"
            style={{ display: "block", margin: "0 auto" }}
          />
          <Typography variant="h6" align="center" style={{ marginTop: "20px" }}>
            {dialogMessage}
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        className="flex items-center justify-center max-w-none rounded-xl"
      >
        <div className="bg-white  shadow-lg w-[600px] sm:max-w-sm md:max-w-md lg:max-w-xl xl:max-w-2xl px-4 sm:px-8 md:px-16 lg:px-20 py-6 sm:py-8 md:py-10 relative">
          <div className="flex items-center justify-center mb-4">
            <img src={Checkimg} alt="Check" className="mx-2" />
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              --
              <ArrowForwardIcon />
              --
            </Typography>
            <img src={Crossicon} alt="Cross" className="mx-2" />
          </div>

          <DialogContent className="mb-4 text-center">
            <Typography className="text-[#353F4F] text-base font-medium">
              Are you sure you want to change the status?
            </Typography>
          </DialogContent>
          <div className="mb-4">
            <label
              htmlFor="Comments"
              className="text-[#353F4F] font-medium flex items-center"
            >
              Comments <span className="ml-1 text-red-600">*</span>
            </label>
            <textarea
              id="Comment"
              name="Comment"
              rows={6}
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-[#D6E5EE] placeholder:text-gray-400 focus:outline-2 focus:outline-indigo-600 sm:text-sm"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter your reason for changing the status"
              required
            />
          </div>

          <div className="flex justify-center gap-4">
            <button
              onClick={handleConfirmDialogClose}
              className="text-[#353F4F] px-6 py-3 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: cancelButtonActive ? "#DC3545" : "white",
                color: cancelButtonActive ? "white" : "#353F4F",
                border: cancelButtonActive ? "none" : "1px solid #18283D80",
              }}
              onMouseDown={() => setCancelButtonActive(true)}
              onMouseUp={() => setCancelButtonActive(false)}
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmAction}
              className="text-[#353F4F] px-6 py-3 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: "#DC3545",
                color: "white",
                border: "none",
              }}
              onMouseDown={() => setDeactivateButtonActive(true)}
              onMouseUp={() => setDeactivateButtonActive(false)}
            >
              Deactivate
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={activateDialogOpen}
        onClose={handleActivateDialogClose}
        className="flex items-center justify-center overflow-hidden max-w-none rounded-xl"
      >
        <div className="bg-white shadow-lg w-[600px] overflow-hidden sm:max-w-sm md:max-w-md lg:max-w-xl xl:max-w-2xl px-4 sm:px-8 md:px-16 lg:px-20 py-6 sm:py-8 md:py-10 relative">
          <div className="flex items-center justify-center mb-4">
            <img src={Crossimg} alt="Cross" className="mx-2 " />

            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              --
              <ArrowForwardIcon />
              --
            </Typography>
            <img src={Checkicon} alt="Check" className="mx-2 " />
          </div>
          <DialogContent className="mb-4 text-center">
            <Typography className="text-[#353F4F] text-base font-medium">
              Are you sure you want to change the status?
            </Typography>
          </DialogContent>
          <div className="flex justify-center">
            <button
              onClick={handleActivateDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: cancelButtonActive ? "#278E1D" : "white",
                color: cancelButtonActive ? "white" : "#353F4F",
                border: cancelButtonActive ? "none" : "1px solid #18283D80",
              }}
              onMouseDown={() => setCancelButtonActive(true)}
              onMouseUp={() => setCancelButtonActive(false)}
            >
              Cancel
            </button>

            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#28A745] hover:text-white hover:border-none"
              style={{
                backgroundColor: "#278E1D",
                color: "white",
                border: "none",
              }}
              onMouseDown={() => setActivateButtonActive(true)}
              onMouseUp={() => setActivateButtonActive(false)}
            >
              Activate
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        className="flex items-center justify-center"
      >
        <div className="relative w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-center mb-4">
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              <img src={Archiveicon} alt="" />
            </Typography>
          </div>

          <DialogContent className="mb-4">
            <Typography className="text-[#353F4F] text-base text-center">
              Are you sure you want to move this Program to Archive?
            </Typography>
          </DialogContent>

          <div className="flex justify-center">
            <button
              onClick={handleDeleteDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#FF6711] hover:text-white"
              style={{
                border: "1px solid #18283D80",
              }}
            >
              No
            </button>
            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#FF6711] hover:text-white"
              style={{
                border: "1px solid #18283D80",
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
