import React from "react";
import Search from "../../assets/search.svg";
import CalenderIcon from "../../assets/calendar-icon.svg";
import DataTable from "../../shared/DataGrid";
import { HistoryColumn } from "../../utills/tableFields";
import GotoIcon from "../../assets/gotoicon.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
export default function TechnicalHistory() {
  const statusOptions = [
    { value: "all", label: "All" },
    { value: "new", label: "New" },
    { value: "pending", label: "Pending" },
    { value: "in-progress", label: "In Progress" },
    { value: "approve", label: "Approve" },
    { value: "cancel", label: "Cancel" },
  ];
  const columns = [
    ...HistoryColumn,
    {
      field: "lastupdate_date",
      headerName: "Last update Date",
      flex: 1,
      id: 7,
    },
    {
      field: "lastupdate_by",
      headerName: "Last update by",
      flex: 1,
      id: 8,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      id: 9,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      id: 10,
    },
  ];

  return (
    <div className="border-[#D9E4F2] border bg-white rounded-[3px]">
      <div className="flex justify-between items-center px-5 py-4 mt-4 border-[#D9E4F2] border-b ">
        <p className="text-[#18283D] text-[22px] font-medium">
          Technical Support History
        </p>
        <div className="flex gap-4">
          <img src={Search} alt="" className="pr-5" />
          <div className="flex border border-[#1D5BBF] rounded-[3px] w-[150px] h-[45px] items-center p-2 ">
            <img src={CalenderIcon} alt="" />
            <select
              style={{
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
              }}
            >
              <option>Month</option>
            </select>
          </div>
          <select className="bg-[#1D5BBF] text-white border border-[#1D5BBF] rounded-[3px] text-sm w-[150px] h-[45px] p-3">
            {statusOptions.map((option, index) => (
              <option
                key={index}
                value={option.value}
                style={{ backgroundColor: "white", color: "#18283D" }}
              >
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="p-7">
        <DataTable rows={[]} columns={columns} hideFooter />
      </div>

      <div className="flex justify-between items-center w-full p-7 bg-[#F9FAFB]">
        <div className="text-sm text-[#1D5BBF] flex items-center gap-2">
          <img src={GotoIcon} alt="" />
          Go To Page 1
        </div>
        <div className="flex items-center gap-5">
          <button
            className="border-[#1D5BBF] border text-[#1D5BBF] w-[250px] h-[55px] rounded-md text-[16px] font-medium active:bg-primary-blue active:text-white"
            onClick={() => console.log("Load More Clicked")}
          >
            Previous Page
          </button>
          <button className="border-[#1D5BBF] border text-[#1D5BBF] w-[250px] h-[55px] rounded-md text-[16px] font-medium active:bg-primary-blue active:text-white">
            Next Page
          </button>
        </div>
        <div className="flex items-center gap-4">
          <div className="text-[#3E3E3E] text-[15px] flex items-center gap-2">
            <p className="border-[#091316] border px-4 py-2 rounded-[3px] text-center">
              1
            </p>
            <p>of 200</p>
          </div>
          <div className="flex items-center gap-4">
            <div className="px-2 py-2 rounded-[3px] text-md bg-[#D9E4F2] text-center">
              <ArrowBackIosIcon />
            </div>
            <div className="px-2 py-2 rounded-[3px] bg-[#D9E4F2] text-center">
              <ArrowForwardIosIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
