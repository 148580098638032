import React from "react";
import MuiModal from "../../../shared/Modal";
import Tooltip from "../../../shared/Tooltip";
import CloseIcon from "../../../assets/close-red-icon.svg";
import EditRequest from "../../../assets/editrequest.svg";
import { useNavigate } from "react-router";

export default function EditRequestModal({ isOpen, onClose, selectedRequestId }) {
    const navigate = useNavigate();

  return (
    <>
      {isOpen && (
        <MuiModal
          modalSize="sm"
          modalOpen={isOpen}
          modalClose={() => onClose(false)}
          noheader
        >
          <div className="flex w-full justify-end mb-4">
            <Tooltip title="Cancel">
              <img
                src={CloseIcon}
                className="cursor-pointer"
                onClick={onClose}
                alt="CloseIcon"
              />
            </Tooltip>
          </div>
          <div className="modal">
            <div className="flex flex-col items-center justify-center gap-10">
              <div className="flex flex-col items-center gap-6">
              <img src={EditRequest} alt="Edit" />
              <h2 className="text-[20px] text-[#353F4F] font-medium text-center">Are you sure you want to edit your submitted request?</h2>
              </div>
              <div className="flex items-center gap-6 mb-8">
              <button className="border border-[#353F4F] w-[170px] h-[50px] bg-white text-[#353F4F] active:bg-primary-blue active:text-white active:border-none rounded-[3px]" onClick={onClose}>No</button>
              <button onClick={() => navigate(`/create-request?id=${selectedRequestId}`)} className="border border-[#353F4F] w-[170px] h-[50px] bg-white text-[#353F4F] active:bg-primary-blue active:text-white active:border-none rounded-[3px]">Yes</button>
              </div>
            </div>
          </div>
        </MuiModal>
      )}
    </>
  );
}
