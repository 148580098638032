import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "./api";

// Create Request
export const createNewRequest = createAsyncThunk(
  "createNewRequest",
  async (requestData, { rejectWithValue }) => {
    try {
      const createRequest = await api.post(`/agent/`, requestData);
      if (createRequest.status === 200 && createRequest.data) {
        return createRequest.data;
      }
      throw new Error("Failed to create Request");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getAllCurrentRequestList = createAsyncThunk(
  "getAllCurrentRequestList",
  async ({page,search}) => {
    const queryParams = new URLSearchParams();
    console.log({ page });
    if (page) queryParams.append("page", page);
    if (search) queryParams.append("search", search);
    const getAllCurrentRequestInfo = await api.get(
      `/agent?${queryParams.toString()}`
    );
    if (
      getAllCurrentRequestInfo.status === 200 &&
      getAllCurrentRequestInfo.data
    ) {
      return getAllCurrentRequestInfo.data;
    }
    return getAllCurrentRequestInfo;
  }
);

export const getAllPartnerRequestList = createAsyncThunk(
  "getAllPartnerRequestList",
  async ({ userId, status = "", page = 0, search }) => {
    const queryParams = new URLSearchParams();
    if (status) queryParams.append("status", status);
    if (page) queryParams.append("page", page);
    if (search) queryParams.append("search", search);

    const getAllPartnerRequestInfo = await api.get(
      `/agent/?partner_id=${userId}&${queryParams.toString()}`
    );
    if (
      getAllPartnerRequestInfo.status === 200 &&
      getAllPartnerRequestInfo.data
    ) {
      return getAllPartnerRequestInfo.data;
    }
    return getAllPartnerRequestInfo;
  }
);

export const getAllCurrentPartnerRequestList = createAsyncThunk(
  "getAllCurrentPartnerRequestList",
  async (userId) => {
    const getAllCurrentPartnerRequestListInfo = await api.get(
      `/agent/partner?user_id=${userId}`
    );
    if (
      getAllCurrentPartnerRequestListInfo.status === 200 &&
      getAllCurrentPartnerRequestListInfo.data
    ) {
      return getAllCurrentPartnerRequestListInfo.data;
    }
    return getAllCurrentPartnerRequestListInfo;
  }
);

export const getPantnerRequestDetail = createAsyncThunk(
  "getPantnerRequestDetail",
  async (userId) => {
    const getPantnerRequestInfo = await api.get(`/agent/?request_id=${userId}`);
    if (getPantnerRequestInfo.status === 200 && getPantnerRequestInfo.data) {
      return getPantnerRequestInfo.data;
    }
    return getPantnerRequestInfo;
  }
);

export const getRequestById = createAsyncThunk(
  "getRequestById",
  async ({ status, id }) => {
    try {
      const response = await api.get(`/?status=${status}&partner_id=${id}`);

      if (response.status === 200 && response.data) {
        return response.data;
      }
      throw new Error("Failed to fetch data");
    } catch (error) {
      throw error.response?.data?.message || error.message;
    }
  }
);

export const cancelRequest = createAsyncThunk(
  "cancelRequest",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.put(`/agent/`, data);
      if (response.status === 200 && response.data.result) {
        return response.data.result;
      }
      return rejectWithValue("Failed to update category");
    } catch (error) {
      console.error("Error updating category:", error);
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const markImportant = createAsyncThunk(
  "markImportant",
  async ({ request_id, priority }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/agent/`, {
        request_id,
        priority,
      });

      if (response.status === 200 && response.data) {
        return response.data;
      }

      throw new Error("Failed to mark as important");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const createNewCase = createAsyncThunk(
  "createNewCase",
  async (caseData, { rejectWithValue }) => {
    try {
      const createCase = await api.post(`/agent/cases/`, caseData);
      if (createCase.status === 200 && createCase.data) {
        return createCase.data;
      }
      throw new Error("Failed to create Request");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getCaseById = createAsyncThunk("getCaseById", async (id) => {
  try {
    const response = await api.get(`/agent/cases/?request_id=${id}`);

    if (response.status === 200 && response.data) {
      return response.data;
    }
    throw new Error("Failed to fetch data");
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
});
