import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { updateStatusByRequestId } from "../../../services/partner";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

export default function UpdateStatusDialog({ open, handleClose, request }) {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSelectStatus = (status) => {
    setSelectedStatus(status);
    setIsConfirmOpen(true);
    handleClose();
  };

  const handleChangeStatus = async () => {
    setLoading(true);
    setIsConfirmOpen(false);

    try {
      const response = await dispatch(
        updateStatusByRequestId({
          request_id: request.id,
          partner_status: selectedStatus,
        })
      ).unwrap();

      if (response.status === "success") {
        navigate(`/request-tracking?id=${request.id}&status=${selectedStatus}`);
        handleClose();
        setSelectedStatus("");
      }
    } catch (error) {
      console.error("Failed to update status:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Update Status Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{ fontSize: "1.5rem" }}
          className="text-primary-blue !my-4 !px-10"
        >
          Update request status
        </DialogTitle>
        <div className="border border-[#D1D1D1] mx-3" />
        <DialogContent className="!p-12">
          <Typography variant="body1" mb={4}>
            Select one to proceed with the request
          </Typography>

          {/* In Progress */}
          <Box
            onClick={() => handleSelectStatus("in progress")}
            sx={{
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "12px",
              marginBottom: "12px",
              cursor: "pointer",
              "&:hover": { borderColor: "blue" },
            }}
          >
            <Typography color="#FBD15B">In Progress</Typography>
            <Typography variant="body2" color="textSecondary">
              Changing to in-progress state will send this request back to ACC
              Corp (Partner) for satisfying the provided requirement.
            </Typography>
          </Box>

          {/* Complete */}
          <Box
            onClick={() => handleSelectStatus("completed")}
            sx={{
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "12px",
              cursor: "pointer",
              "&:hover": { borderColor: "blue" },
            }}
          >
            <Typography color="#25861D">Complete</Typography>
            <Typography variant="body2" color="textSecondary">
              Request will be marked as completed.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>

      {/*Confirmation Dialog */}
      <Dialog
        open={isConfirmOpen}
        aria-labelledby="first-dialog-title"
        aria-describedby="first-dialog-description"
        maxWidth="xl"
        sx={{
          ".MuiDialog-paper": {
            padding: "40px",
          },
        }}
      >
        <DialogTitle
          sx={{ fontSize: "1.5rem" }}
          className="text-primary-blue text-center"
        >
          Confirmation request status
        </DialogTitle>
        <DialogTitle className="!text-[#18283D] !text-[20px] !text-center !font-medium !py-4 !px-4">
          Are you sure you want to mark his request as {selectedStatus}?
        </DialogTitle>
        <DialogContent className="flex justify-center gap-3 !mt-4 !p-4">
          <Button
            onClick={() => {
              setIsConfirmOpen(false);
              setSelectedStatus("");
            }}
            variant="outlined"
            className="!text-[#000] !border  !border-[#1D5BBF] !px-12 w-32"
          >
            Cancel
          </Button>
          <Button
            onClick={handleChangeStatus}
            autoFocus
            className="!text-white !bg-[#2260D9] !px-12 w-32"
          >
            Yes
          </Button>
        </DialogContent>
      </Dialog>

      {/*Loading Dialog */}
      <Dialog
        open={loading}
        aria-labelledby="first-dialog-title"
        aria-describedby="first-dialog-description"
        maxWidth="xl"
        sx={{
          ".MuiDialog-paper": {
            padding: "40px",
          },
        }}
      >
        <DialogContent className="flex justify-center gap-3 !mt-4 !p-4">
          <Typography variant="h6">Updating Information</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
