import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import PaidDoller from "../../assets/paiddollar.svg";
import MoreIcon from "../../assets/show-more.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategoryList } from "../../services/category";

export default function TopCategory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const maxRows = 3;
  const categoryList = useSelector((state) => {
    return state.categoryInfo?.data?.data;
  });

  useEffect(() => {
    dispatch(getAllCategoryList());
  }, [dispatch]);

  return (
    <div
      style={{
        boxShadow: "6px 6px 35px 0px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
      }}
      className="mt-5"
    >
      <div className="flex items-center justify-between title px-7 py-3">
        <div
          className="flex items-center justify-between w-full gap-4 pt-5 pb-5"
          style={{
            borderBottom: "1px solid #2260D9",
          }}
        >
          <h4 className="text-[#353F4F] font-medium text-xl">Top Programs</h4>

          <div
            style={{
              background: "#D2E4FF",
              color: "#2260D9",
              borderRadius: "3px",
            }}
            className="px-3 py-1 cursor-pointer"
            onClick={() => navigate(`/category`)}
          >
            View all
          </div>
        </div>
      </div>
      <div
        className="grid gap-6 px-9 py-7"
        style={{
          gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
        }}
      >
        {categoryList?.slice(0, 25)?.map((freqCat) => {
          return (
            <div className="flex flex-col items-center gap-3">
              <div
                style={{
                  borderRadius: "5px",
                  border: "1px solid #2260D9",
                  padding: "25px 15px",
                  textAlign: "center",
                  width: "100px",
                  height: "100px",
                  position: "relative", // For badge placement
                }}
                className="flex flex-col gap-4 cursor-pointer"
                key={freqCat.id}
                onClick={() => navigate(`/category/${freqCat.id}`)}
              >
                {freqCat.is_premium && (
                  <div className="absolute w-10 top-0 right-0">
                    <img src={PaidDoller} alt="Paid" className="rounded-tr-[5px]" />
                  </div>
                )}
                <img
                  src={`https://crrmsbackend.dataterrain-dev.net${freqCat.image}`}
                  className="h-9"
                  alt="DonationIcon"
                />
              </div>
              <div className="text-center text-[#353F4F] capitalize">
                {freqCat.name ? (freqCat.name) : ""}
              </div>
            </div>
          );
        })}

        <div
          style={{
           
            borderRadius: "5px",
            padding: "0 25px",
            textAlign: "center",
            width: "100px",
            height: "100px",
            background: "#DBE7FF",
            color: "#2260D9",
            border: "1px solid #2260D9",
          }}
          className="flex flex-col justify-center items-center gap-3 cursor-pointer w-full h-full"
          onClick={() => navigate(`/category`)}
        >
        <p className="text-[#2260D9] font-medium">
          Show More
          </p>
          <img src={MoreIcon} alt="more"/>
        </div>
      </div>
    </div>
  );
}
