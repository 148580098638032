import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Close from "../../../assets/close.svg";
import React from "react";

const AcceptRequest = ({ open, handleClose, confirmAccept }) => {
  return (
    <>
      {/* Draft Confirmation Dialog */}
      <Dialog
        open={open}
        aria-labelledby="first-dialog-title"
        aria-describedby="first-dialog-description"
        maxWidth="xl"
      >
        <DialogContent className="!p-2">
          <div className="flex justify-end items-center cursor-pointer" onClick={handleClose}>
            <img src={Close} alt="Gmeet" height={30} width={30} />
          </div>
        </DialogContent>
        <DialogTitle className="!text-[#18283D] !text-[20px] !text-center !font-medium !py-10 !px-4">
          This action is about to Accept the request, would you like to
          continue?
        </DialogTitle>
        <DialogContent className="flex justify-center gap-3 !p-4">
          <Button
            onClick={handleClose}
            variant="outlined"
            className="!text-[#000] !border  !border-[#1D5BBF] !px-12"
          >
            No
          </Button>
          <Button
            onClick={confirmAccept}
            autoFocus
            className="!text-white !bg-[#2260D9] !px-12"
          >
            Yes
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AcceptRequest;
