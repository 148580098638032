import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router";
import DataTable from "../../shared/DataGrid";
import { UnregisteredPartnersTab } from "../../utills/formFields";
import SearchIcon from "../../assets/input-search-icon.svg";
import MoreIcon from "../../assets/more-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  createBgVerificationRequest,
  getAllPartnerList,
  getPartnerList,
  getRegisterStaffCount,
  getRegisterStaffList,
} from "../../services/partner";
import { Menu } from "@headlessui/react";
import { MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import moment from "moment";
import Unregistered from "../../assets/unregistered.svg";
import BlockIcon from "../../assets/block-icon.svg";
import Dialog from "@mui/material/Dialog";
import DeleteIcon from "../../assets/deleteicon.svg";
import Checkicon from "../../assets/checkicon.svg";
import useDebounce from "../../utills/debounce";

export default function UnregisteredStaff() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [actionTab, setActiveTab] = useState("unregistered");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pages, setPage] = useState({ page: 0, pageSize: 10 });
  const [openPopup, setOpenPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const searchValue = useDebounce(searchQuery, 500);

  const partnerInfo = useSelector(
    (state) => state.partnerInfo.registerStaffList
  );
  const totalCount = useSelector(
    (state) => state.partnerInfo.registerStaffCount
  );

  const partnerdetails = useSelector((state) => state.partnerInfo.partnerList);
  useEffect(() => {
    dispatch(getAllPartnerList({}));
  }, []);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(getRegisterStaffList({ status: actionTab, page: pages.page + 1, search: searchValue }));
  }, [actionTab, pages, searchValue]);

  useEffect(() => {
    dispatch(getRegisterStaffCount(searchValue));
  }, [searchValue]);

  useEffect(() => {
    if (deletePopup) {
      const timer = setTimeout(() => {
        setDeletePopup(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [deletePopup]);

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(data);
  };

  const handleDeleteConfirm = () => {
    setDeletePopup(true);
    setOpenPopup(false);
  };

  const handleBgverification = async (data) => {
    console.log(data, "debug data");
    const payload = {
      action: "candidate",
      first_name: data.first_name || "",
      last_name: data.last_name || "",
      email: data.email || "",
      dob: data.dob || "",
      zipcode: data.zipcode || "80909",
      middle_name: data.middle_name || "",
      copy_requested: data.copy_requested || false,
      work_location_country: data.work_location_country || "",
      work_location_state: data.work_location_state || "",
      work_location_city: data.work_location_city || "",
    };

    try {
      const response = await dispatch(
        createBgVerificationRequest({
          formData: payload,
          actiontype: "candidate",
        })
      ).unwrap();
      
      if (response.status === 200 || response.status === "success") {
        const concatenatedResult = response.result.join("");
        const parsedResult = JSON.parse(concatenatedResult);
        if (parsedResult) {
          const invitationPayload = {
            candidate_id: parsedResult.id,
            package: "test_pro_criminal_and_mvr",
            work_locations: [
              {
                country: data.work_location_country,
                state: data.work_location_state,
                city: data.work_location_city,
              },
            ],
          };
          const responseInvitation = await dispatch(
            createBgVerificationRequest({
              formData: invitationPayload,
              actiontype: "invitation",
            })
          ).unwrap();
          const concatenatedResultInvitation =
            responseInvitation.result.join("");
          const parsedResultInvitation = JSON.parse(
            concatenatedResultInvitation
          );
          if (responseInvitation.status === "success") {
            window.open(parsedResultInvitation.invitation_url, "_blank");
          }
        }
      }
    } catch (error) {
      console.error("Error during background verification:", error);
    }
  };

  const getPartnersColumns = () => {
    const allColumns = [
      {
        field: "staff_name",
        headerName: "Agent Name",
        flex: 1,
        renderCell: (params) => params?.row?.full_name,
      },
      {
        field: "contact",
        headerName: "Contact",
        flex: 1,
      },
      {
        field: "bgverfication",
        flex: 1,
        headerName: "BG Verification",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <div className="w-full h-full flex items-center justify-center">
              {params.row.status === "unregistered" ? (
                <div className="w-fit h-7 flex justify-center items-center bg-[#FFEBD0] text-[#CE8946] rounded-[3px] px-[13px] text-center ">
                  Pending
                </div>
              ) : (
                <div className="w-fit h-[30px] flex justify-center items-center bg-[#FFE3E6] text-[#DC3545] rounded-[3px] px-2 py-1 text-center">
                  Fail
                </div>
              )}
            </div>
          );
        },
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "add_date",
        headerName: "Added Date & Time",
        flex: 2,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          return moment(params?.row?.add_date).format("MM/DD/YYYY | h:mm A");
        },
      },
      {
        field: "status",
        headerName: "Status",
        headerAlign: "center",
        align: "center",
        flex: 1,
        renderCell: (params) => {
          const statusStyles = {
            unregistered: {
              textClass: "text-[#2260D9]",
              icon: Unregistered,
              label: "Unregistered",
            },
            declined: {
              textClass: "text-[#D22735]",
              icon: BlockIcon,
              label: "Inactive",
            },
            default: {
              textClass: "text-[#D22735]",
              icon: null,
              label: "Unknown",
            },
          };

          const status = params.row.status || "default";
          const { textClass, icon, label } =
            statusStyles[status] || statusStyles.default;

          return (
            <div className={`flex items-center gap-2 ${textClass}`}>
              {icon && <img src={icon} alt={`${status} icon`} />}
              <span className="capitalize">{label}</span>
            </div>
          );
        },
      },
      {
        field: "action",
        headerName: "Action",
        headerAlign: "center",
        flex: 1,
        renderCell: (params) => {
          const status = params.row.status;
          return (
            <>
              <Menu>
                <MenuButton
                  onClick={(e) => handleClick(e, params.row)}
                  className="cursor-pointer relative inline-flex w-full justify-center items-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900 h-full"
                >
                  <img src={MoreIcon} alt="MoreIcon" />
                </MenuButton>
                <MenuItems
                  transition
                  className="absolute right-20 z-10 w-50 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <MenuItem
                    as="button"
                    onClick={() => handleBgverification(params.row)}
                    className="cursor-pointer block px-4 py-3 text-sm text-gray-700 w-full text-left hover:bg-[#EEF4FF]"
                  >
                    Start Background Check
                  </MenuItem>
                  <MenuItem
                    as="button"
                    onClick={() =>
                      navigate(
                        `/view-agent/id=${params.id}?status=${params.row.status}`
                      )
                    }
                    // onClick={() => navigate(`/partner/${params.id}`)}
                    className="cursor-pointer block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-[#EEF4FF]"
                  >
                    View
                  </MenuItem>
                  <MenuItem
                    as="button"
                    className="cursor-pointer block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-[#EEF4FF]"
                    onClick={() =>
                      navigate(
                        `/addnewagent?id=${params.id}&status=${params.row.status}`
                      )
                    }
                  >
                    Edit
                  </MenuItem>
                  {actionTab === "unregistered" && (
                    <>
                      <MenuItem
                        as="button"
                        className="cursor-pointer block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-[#EEF4FF]"
                        onClick={() => {
                          setOpenPopup(true);
                        }}
                      >
                        Delete
                      </MenuItem>
                      {/* <MenuItem
                        as="button"
                        onClick={() => handleAction("docuSign")}
                        className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-[#EEF4FF]"
                      >
                        DocuSign
                      </MenuItem> */}
                    </>
                  )}
                </MenuItems>
              </Menu>
            </>
          );
        },
      },
    ];

    if (actionTab === "unregistered") {
      return allColumns.filter((col) =>
        [
          "staff_name",
          "contact",
          "bgverfication",
          "email",
          "add_date",
          "status",
          "action",
        ].includes(col.field)
      );
    } else if (actionTab === "declined") {
      return allColumns.filter((col) =>
        [
          "staff_name",
          "contact",
          "bgverfication",
          "email",
          "add_date",
          "status",
          "action",
        ].includes(col.field)
      );
    }

    return allColumns;
  };

  const columns = getPartnersColumns(actionTab);

  const safePartnerInfo = Array.isArray(partnerInfo.data)
    ? partnerInfo.data
    : [];

  const activeTabData = useMemo(() => {
    if (actionTab === "unregistered") {
      return safePartnerInfo?.filter(
        (c) => c?.register_status === "unregistered"
      );
    } else if (actionTab === "declined") {
      return safePartnerInfo?.filter((c) => c?.register_status === "declined");
    }

    return safePartnerInfo;
  }, [actionTab, safePartnerInfo]);

  console.log(activeTabData,"activeTabDataactiveTabData")
  const rows =
    activeTabData?.map((item) => ({
      id: item.id,
      staff_name: item.first_name + " " + item.last_name,
      first_name: item.first_name,
      last_name: item.last_name,
      contact: item.contact_number,
      bgverfication: item.bgverfication,
      email: item.email,
      add_date: item.add_date,
      status: item.register_status,
      dob: item?.dob,
      zipcode: item?.zipcode,
      middle_name: item?.middle_name,
      copy_requested: item?.copy_requested,
      work_location_country: item?.country,
      work_location_state: item?.state,
      work_location_city: item?.city,
    })) || [];

  const searchFilteredRows = rows.filter((row) =>
    row.full_name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <>
      <div className="px-8 mt-10">
        <BreadCumbs
          firstCumbName="Agent"
          secoundCumbName="Agent Status"
          pathToRedirect="addnewpartner"
        />
        <div className="py-7 mb-5 custom-shadow rounded-[5px]">
          <div className="pl-3 details-info mr-7">
            <div
              className="flex items-start justify-between pl-4"
              style={{ borderBottom: "1px solid #D3D3D3" }}
            >
              <ul className="flex tab-list">
                {UnregisteredPartnersTab.map((discussion, index) => (
                  <li
                    className={`${
                      actionTab === discussion.key ? "active" : ""
                    } relative`}
                    key={index}
                    onClick={() => setActiveTab(discussion.key)}
                  >
                    {console.log(
                      discussion,
                      discussion?.key?.length,
                      "discussion"
                    )}
                    <div className="flex justify-center pb-1">
                      <div
                        className={`total-proram-count relative ${
                          actionTab === discussion.key ? "active" : ""
                        }`}
                      >
                        {totalCount[discussion.key] || 0}
                      </div>
                    </div>
                    <div className="text-[13px]">{`${discussion.name}`}</div>
                    {actionTab === discussion.key && <span></span>}
                  </li>
                ))}
              </ul>
              <div className="relative">
                <input
                  type="text"
                  id="search-navbar"
                  className="block w-full p-2 text-sm placeholder:text-[#C8C9C9] focus:outline-none rounded-[3px]"
                  placeholder="Search here"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    border: "1px solid #2260D9",
                    height: "50px",
                    width: "390px",
                  }}
                />
                <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
                  <img src={SearchIcon} alt="SearchIcon" />
                </div>
              </div>
            </div>
          </div>
          <div className="p-7">
            {actionTab === "unregistered" && (
              <div className="text-[#A4A4A4] text-sm pb-3">
                Note : Once background verification is complete, Agent profiles
                will be added to the general list with email notification
              </div>
            )}
            <DataTable
              rows={rows || []}
              columns={getPartnersColumns()}
              hideCheckbox
              height={450}
              pages={pages}
              totalRows={partnerInfo?.total_count || 0}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      {openPopup && (
        <Dialog
          open={setOpenPopup}
          onClose={() => {
            setOpenPopup(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiPaper-root": {
              boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
              borderRadius: "10px",
              paddingY: "40px",
            },
          }}
        >
          <div className="flex flex-col px-16 py-4 items-center gap-6">
            <img src={DeleteIcon} alt="" />

            <p className="text-black font-medium text-lg">
              Are you sure want to delete this request?
            </p>
          </div>
          <div className="flex justify-center items-stretch gap-4 mt-7 font-medium text-sm">
            <button
              onClick={() => setOpenPopup(false)}
              className="text-[#18283D] py-2.5 border border-[#353F4F] rounded-[3px] w-[140px]"
            >
              No
            </button>
            <button
              className="bg-[#DC3545] text-white rounded-[3px] w-[140px]"
              onClick={handleDeleteConfirm}
            >
              Yes
            </button>
          </div>
        </Dialog>
      )}
      {deletePopup && (
        <Dialog
          open={setDeletePopup}
          onClose={() => {
            setDeletePopup(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiPaper-root": {
              boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
              borderRadius: "10px",
              paddingY: "30px",
            },
          }}
        >
          <div className="flex flex-col w-[430px] items-center gap-7">
            <img src={Checkicon} alt="" />

            <p className="text-[#353F4F] font-medium text-lg">
              Request Deleted
            </p>
          </div>
        </Dialog>
      )}
    </>
  );
}
