import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { frequentlySearchLimit } from "../../services/consumerCategory";
import { useNavigate } from "react-router";

export default function PopularSearch() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const requestData = useSelector(
    (state) => state.consumerCategoryInfo.frequentlySearchLimit.data
  );

  useEffect(() => {
    dispatch(frequentlySearchLimit(3));
  }, [dispatch]);

  
  return (
    <div
      style={{
        borderRadius: "10px",
        marginBottom: "40px",
      }}
      className="mt-10 custom-shadow"
    >
      <div className="flex items-center justify-between title px-7 py-3">
        <div
          className="flex items-center justify-between w-full gap-4 pt-5 pb-3"
          style={{ borderBottom: "1px solid #2260D9" }}
        >
          <h4 className="text-[#353F4F] font-medium text-xl">Popular Searches</h4>

          <div
            style={{
              background: "#D2E4FF",
              color: "#2260D9",
              borderRadius: "3px",
            }}
            className="px-3 py-1 cursor-pointer font-medium"
            onClick={() => navigate(`/category`)}
          >
            View all
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 px-11 py-4">
        {requestData?.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                border: "1px solid rgba(34, 96, 217, 1)",
                borderRadius: "5px",
              }}
              className="flex items-center justify-between px-4 py-2 bg-light-blue"
            >
              <div className="flex gap-8 px-3 py-3 w-[80%]">
                <img
                  src={`https://crrmsbackend.dataterrain-dev.net${item.image}`}
                  alt="EventPlanningIcon"
                  // height={126}
                  // width={140}
                  style={{ width: "6%" }}
                />
                <div className="flex flex-col" style={{ width: "90%" }}>
                  <h5 className="text-[#2260D9] text-[16px] font-semibold capitalize">
                    {item.name ? (item.name) : ""}
                  </h5>
                  <p className="text-[#353F4F] text-[12px] pt-3">
                    {item.description}
                  </p>
                </div>
              </div>

              <div className="flex justify-end px-0 w-[15%]">
                <button
                  className="bg-[#2260D9] font-medium text-white text-[14px] cursor-pointer w-[130px] h-[40px]"
                  style={{ borderRadius: "4px" }}
                  onClick={() => navigate(`/category/${item.id}`)}
                >
                  View Details
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
