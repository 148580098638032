import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function DataTable({
  rows,
  columns,
  selectedAllRows = [],
  totalRows,
  pages = {},
  handlePageChange,
  loading,
  hideCheckbox = false,
  hideFooter = false,
  handleSelectedRow = undefined,
  height = 600,
  className = "",
  handleRowClick = undefined,
}) {
  const [selectedIds, setSelectedIds] = useState([]);
  const handleRowSelection = (ids) => {
    const selected = [...rows].filter((row) =>
      ids.includes(row.id || row.categories_id)
    );
    setSelectedIds(ids);
    if (handleSelectedRow) handleSelectedRow(selected);
  };

  useEffect(() => {
    const ids = [];
    selectedAllRows.forEach((row) => ids.push(row.id));
    setSelectedIds(ids);
  }, []);
  return (
    <div
      style={{
        height: height,
        width: "100%",
        position: "relative",
      }}
      className={className}
    >
      <DataGrid
        rowCount={totalRows}
        paginationModel={pages}
        onPaginationModelChange={handlePageChange}
        onRowSelectionModelChange={(itm) => handleRowSelection(itm)}
        rows={rows}
        hideFooter={hideFooter}
        onRowClick={handleRowClick ? handleRowClick : () => {}}
        columns={columns}
        hideFooterPagination={hideFooter}
        getRowId={(row) =>
          row.id || row.first_name || row.categories_id || row?.request_status
        }
        checkboxSelection={!hideCheckbox}
        disableRowSelectionOnClick
        selectionModel={selectedIds}
        paginationMode="server"
        loading={loading}
      />
    </div>
  );
}
