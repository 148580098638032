import React, { useEffect } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { useDispatch, useSelector } from "react-redux";
import { getRequestGraph } from "../../services/requestGraph";
// List of months in correct order
const monthsInOrder = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
function extractData(data) {
  if (!data)
    return {
      new: [],
      pending: [],
      accepted: [],
    };

  // Initialize arrays for New, Accepted, and Pending
  const newArr = [];
  const pendingArr = [];
  const acceptedArr = [];

  // Sort the data object by the correct month order
  monthsInOrder.forEach((month) => {
    if (data[month]) {
      newArr.push(data[month].new);
      pendingArr.push(data[month].pending);
      acceptedArr.push(data[month].completed);
    } else {
      // If the month doesn't exist in the data, push default values
      newArr.push(0);
      pendingArr.push(0);
      acceptedArr.push(0);
    }
  });

  // Return the three arrays
  return { new: newArr, pending: pendingArr, accepted: acceptedArr };
}

const Chart = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRequestGraph());
  }, [dispatch]);

  const graphData = useSelector((state) => state.graphInfo.details);
  console.log("graphData", graphData);

  const xLabels = graphData?.X_labels || [];
  const seriesData = graphData?.series || [];

  const result = extractData(graphData?.data);

  return (
    <div className="col-span-5 custom-shadow p-6 rounded-[5px] bg-[#FFFFFF]">
      <p className="font-medium text-[#353F4F] text-lg  border-[#D3D3D3] pb-6 w-full">
        Request Statics info
      </p>
      <LineChart
        series={[
          { data: result.new, label: "New Request", color: "#2260D9" },
          { data: result.pending, label: "Pending Request", color: "#FF6711" },
          {
            data: result.accepted,
            label: "Accepted Request",
            color: "#2CB420",
          },
        ]}
        xAxis={[{ scaleType: "point", data: xLabels }]}
        margin={{ bottom: 100 }}
        slotProps={{
          legend: {
            position: {
              vertical: "bottom",
              horizontal: "middle",
            },
            itemGap: 20,
          },
        }}
        width={600}
        height={450}
      />
    </div>
  );
};

export default Chart;
