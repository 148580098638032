import { createSlice } from "@reduxjs/toolkit";
import { createNewAgent, getAgentById, getAllAgentList, getRequestCount, getTotalRequestAgentById } from "../../services/agent";

const initialState = {
  allAgentData: [],
  details: {
    data: [],
    status: "idle",
    error: null,
  },
  loading: false,
  status: "",
  error: "",
  agentDetails: [],
  agentTotalRequest: [],
};

export const agentSlice = createSlice({
  name: "agentInfo",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(createNewAgent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
        state.status = "Agent Created Successfully";
      })
      .addCase(createNewAgent.rejected, (state, action) => {
        state.loading = false;
        state.error =
          typeof action.payload === "object"
            ? action.payload
            : { message: action.payload || "Something went wrong!" };
      });
    builder
      .addCase(getAllAgentList.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllAgentList.fulfilled, (state, action) => {
        return {
          ...state,
          details: action.payload,
          loading: false,
        };
      })
      .addCase(getAllAgentList.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });
    builder
      .addCase(getAgentById.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAgentById.fulfilled, (state, action) => {
        return {
          ...state,
          agentDetails: action.payload,
          loading: false,
        };
      })
      .addCase(getAgentById.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });
    builder
      .addCase(getTotalRequestAgentById.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getTotalRequestAgentById.fulfilled, (state, action) => {
        return {
          ...state,
          agentTotalRequest: action.payload,
          loading: false,
        };
      })
      .addCase(getTotalRequestAgentById.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });
    builder
      .addCase(getRequestCount.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getRequestCount.fulfilled, (state, action) => {
        return {
          ...state,
          details: action.payload,
          loading: false,
        };
      })
      .addCase(getRequestCount.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });
  },
});

export default agentSlice.reducer;
