import { createSlice } from "@reduxjs/toolkit";
import { categoryApiResponse } from "../../constants/apiResponse";
import { frequentlySearchCategory, frequentlySearchLimit, relatedCategories } from "../../services/consumerCategory";

const initialState = {
    data: [],
    frequentlySearchCategory: [],
    frequentlySearchLimit: [],
    relatedCategories: [],
    details: {},
    loading: false,
    status: "",
    error: "",
};

export const consumerCategorySlice = createSlice({
    name: "consumerCategoryInfo",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(frequentlySearchCategory.pending, (state) => {
                return {
                    ...state,
                    loading: true,
                };
            })
            .addCase(frequentlySearchCategory.fulfilled, (state, action) => {
                return {
                    ...state,
                    frequentlySearchCategory: action.payload,
                    status: categoryApiResponse.load,
                    loading: false,
                };
            })
            .addCase(frequentlySearchCategory.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.error.message,
                };
            });
        builder.addCase(frequentlySearchLimit.pending, (state) => {
            return {
                ...state,
                loading: true,
            };
        })
            .addCase(frequentlySearchLimit.fulfilled, (state, action) => {
                console.log(action, "action");
                return {
                    ...state,
                    frequentlySearchLimit: action.payload,
                    status: categoryApiResponse.load,
                    loading: false,
                };
            })
            .addCase(frequentlySearchLimit.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.error.message,
                };
            });
        builder.addCase(relatedCategories.pending, (state) => {
            return {
                ...state,
                loading: true,
            };
        })
            .addCase(relatedCategories.fulfilled, (state, action) => {
                console.log(action, "action");
                return {
                    ...state,
                    relatedCategories: action.payload,
                    status: categoryApiResponse.load,
                    loading: false,
                };
            })
            .addCase(relatedCategories.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.error.message,
                };
            });


    },
});

export default consumerCategorySlice.reducer;
