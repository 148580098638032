import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router";

import BreadCrumIcon from "../../assets/breadcrumb-right-icon.svg";
import LocationIcon from "../../assets/location-red-icon.svg";
import CalendarIcon from "../../assets/calendar-1-icon.svg";
import PhoneCircleIcon from "../../assets/phone-circle-icon.svg";
import EmailCircleIcon from "../../assets/email-circle-icon.svg";
import UserCircleIcon from "../../assets/user-circle-icon.svg";
import LinkedInIcon from "../../assets/linkedin-icon.svg";
import TwitterIcon from "../../assets/twitter-icon.svg";
import FacebookIcon from "../../assets/facebook-icon.svg";
import SearchIcon from "../../assets/input-search-icon.svg";
import DataTable from "../../shared/DataGrid";
import { ManageCategoryTab } from "../../utills/formFields";
import { TotalCategoryColumn } from "../../utills/tableFields";
import { TotalEmployeesColumn } from "../../utills/tableFields";
import { useDispatch, useSelector } from "react-redux";
import { getTotalEmployeesColumnList } from "../../services/partner";
import { getParterDetailsbyId } from "../../services/partner";
import { useParams } from "react-router";
import { fetchPartnerCategories } from "../../services/partner";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import { getAllAgentList, updateAgentStatus } from "../../services/agent";
import {
  getAllCategoryList,
  updateCategoryStatus,
} from "../../services/category";
import { CategoryColumn } from "../../utills/tableFields";
import moment from "moment";
import RightIcon from "../../assets/check-icon.svg";
import BlockIcon from "../../assets/block-icon.svg";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import MoreIcon from "../../assets/more-icon.svg";
import Crossicon from "../../assets/crossicon.svg";
import Checkicon from "../../assets/checkicon.svg";
import Archiveicon from "../../assets/archivedicon.svg";
import AddPartnersInCategories from "../Popups/AddPartnersInCategories";
import { Dialog, DialogContent, Typography } from "@mui/material";
import Crossimg from "../../assets/crossimg.svg";
import Checkimg from "../../assets/checkimg.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SilverBadge from "../../assets/silver-badge.svg";
import GoldenBadge from "../../assets/golden-badge.svg";
import BronzeBadge from "../../assets/bronze-badge.svg";
import NoBadge from '../../assets/nobadge.svg'
import { getEmployeesDetails } from "../../services/employees";

export default function ViewPartner() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [searchPartnerQuery, setSearPartnerchQuery] = useState("");
  const [actionTab, setActiveTab] = useState("managecategories");
  const [isOpen, setIsOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState({
    action: "",
    id: "",
  });
  const { userType } = useSelector((state) => state.userInfo);

  const [activateDialogOpen, setActivateDialogOpen] = useState(false); // New state for Activate dialog
  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [comment, setComment] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [dialogImage, setDialogImage] = useState("");
  const [openPopup, setOpen] = useState(false);
  const [cancelButtonActive, setCancelButtonActive] = useState(false);
  const [deactivateButtonActive, setDeactivateButtonActive] = useState(false);
  const [activateButtonActive, setActivateButtonActive] = useState(false);
  const [pages, setPage] = useState({ page: 0, pageSize: 10 });
  const { details: partnerData } = useSelector((state) => state.employeeInfo);
  console.log({partnerData})
  const handlePageChange = (page) => {
    setPage(page);
  };
  const handleAction = (action, id) => {
    setConfirmAction({ action, id });
    if (action === "active") {
      setActivateDialogOpen(true);
    } else if (action === "archive") {
      handleDeleteDialogOpen();
    } else {
      setConfirmDialogOpen(true);
    }
    handleClose();
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false); // Closes the dialog
  };

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleConfirmAction = () => {
    // Validate comment for "deactivated" status
    if (confirmAction.action === "inactive" && !comment.trim()) {
      setDialogMessage("Reason is required when status is 'deactivated'.");
      setSuccessDialogOpen(true);
      return;
    }

    let message = "";
    let imageSrc = "";

    switch (confirmAction.action) {
      case "inactive":
        message = `Program successfully deactivated.`;
        imageSrc = Crossicon;
        break;
      case "active":
        message = `Program successfully activated.`;
        imageSrc = Checkicon;
        break;
      case "archive":
        message = `Program successfully archived.`;
        imageSrc = Archiveicon;
        break;
      default:
        break;
    }

    dispatch(
      updateCategoryStatus({
        category_id: confirmAction.id,
        status: confirmAction.action,
      })
    );

    setTimeout(() => {
      dispatch(getAllCategoryList());
    }, 50);

    setConfirmAction({ action: "", id: "" });
    setDialogMessage(message);
    setDialogImage(imageSrc);
    setConfirmDialogOpen(false);
    setActivateDialogOpen(false);
    setSuccessDialogOpen(true);
    setDeleteDialogOpen(false);
    setComment("");
    handleClose();

    setTimeout(() => {
      setSuccessDialogOpen(false);
      window.location.reload();
    }, 2000);
  };

  const handleDialogClose = () => setSuccessDialogOpen(false);
  const handleConfirmDialogClose = () => setConfirmDialogOpen(false);
  const handleActivateDialogClose = () => setActivateDialogOpen(false);

  const handleEdit = (row) => {
    navigate(`/editcategory/${row.id}`, { state: { rowData: selectedRow } });
  };

  const dispatch = useDispatch();
  const { details = [] } = useSelector((state) => state.partnerInfo || {});
  console.log("Employee Details:", details);

  useEffect(() => {
    dispatch(getTotalEmployeesColumnList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getParterDetailsbyId(id));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(fetchPartnerCategories());
  }, [dispatch]);

  useEffect(() => {
    if(id) dispatch(getEmployeesDetails(id));
  }, [id]);
  const open = Boolean(anchorEl);

  const partnerInfo = useSelector((state) => state.partnerInfo.details);

  useEffect(() => {
    dispatch(getAllCategoryList());
  }, [dispatch]);

  const state = useSelector((state) => state);
  console.log("state", state);

  const agentInfo = useSelector((state) => state.agentInfo.details.data);
  console.log("abd", agentInfo);

  const activeTabData = useMemo(() => {
    if (actionTab === "all") {
      if (isOpen) {
        return agentInfo?.filter((c) => c?.status === "archive");
      }
      return agentInfo?.filter(
        (c) => c?.status === "active" || c?.status === "inactive"
      );
    } else if (actionTab === "active") {
      return agentInfo?.filter((c) => c?.status === "active");
    } else if (actionTab === "inactive") {
      if (isOpen) {
        return agentInfo?.filter((c) => c?.status === "archive");
      }
      return agentInfo?.filter((c) => c?.status === "inactive");
    }

    return agentInfo;
  }, [actionTab, agentInfo, isOpen]);

  const rows =
    activeTabData?.map((agent) => ({
      id: agent.id,
      agent_id: agent.id,
      full_name: `${agent.first_name} ${agent.last_name}`,
      email: agent.email,
      phone_number: agent.contact_number,
    })) || [];

  const searchFilteredRows = rows.filter((row) =>
    row.full_name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const categoryData = useSelector((state) => state.categoryInfo.data.data);
  const total = useSelector((state) => state.categoryInfo?.result?.total_count);
  console.log("total", total);
  const CategorysColumn = [
    ...CategoryColumn,
    {
      field: "date",
      headerName: "Last Update Date & Time",
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.date).format("MM/DD/YYYY | h:mm A");
      },
    },

    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const statusStyles = {
          active: {
            textClass: "text-[#29B473]",
            icon: RightIcon,
          },
          inactive: {
            textClass: "text-[#D22735]",
            icon: BlockIcon,
          },
          default: {
            textClass: "text-gray-500",
            icon: null,
          },
        };
        const status = params.row.status || "default";
        const { textClass, icon } =
          statusStyles[status] || statusStyles.default;
        return (
          <div className={`flex items-center gap-2 ${textClass}`}>
            {icon && <img src={icon} alt={`${status} icon`} />}
            <span className="capitalize">{status}</span>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      id: 4,
      renderCell: (params) => {
        const status = params.row.status;
        const handleDeleteDialogClose = () => {
          setDeleteDialogOpen(false);
        };

        return (
          <>
            <Menu as="div" className="inline-block mt-2 text-left">
              <div>
                <MenuButton className="cursor-pointer relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900">
                  <img
                    src={MoreIcon}
                    alt="MoreIcon"
                    onClick={(e) => handleClick(e, params.row)}
                  />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem
                    onClick={() => {
                      navigate(`/externalcategory/${params.id}`);
                    }}
                  >
                    <a
                      href="#"
                      className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                    >
                      View
                    </a>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleEdit(params.row);
                    }}
                  >
                    <a
                      href="#"
                      className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                    >
                      Edit
                    </a>
                  </MenuItem>
                  {status === "active" ? (
                    <MenuItem
                      onClick={() => handleAction("inactive", params.id)}
                    >
                      <p className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                        Deactivate
                      </p>
                    </MenuItem>
                  ) : (
                    <>
                      <MenuItem
                        onClick={() => handleAction("active", params.id)}
                        // className="!text-[12px] text-green-600 hover:bg-[#D1FAE5] hover:text-green-800"
                      >
                        <p className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                          Activate
                        </p>
                      </MenuItem>
                    </>
                  )}
                  {status === "active" ? (
                    <MenuItem
                      onClick={() => {
                        setIsOpen(params.id);
                      }}
                    >
                      <button
                        type="submit"
                        className="cursor-pointer block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                      >
                        Add Partners
                      </button>
                    </MenuItem>
                  ) : status === "archive" ? (
                    <MenuItem
                      onClick={() => handleAction("unarchive", params.id)}
                    >
                      <button
                        type="unarchive"
                        className="cursor-pointer block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                      >
                        Unarchive
                      </button>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() => handleAction("archive", params.id)}
                    >
                      <button
                        type="archive"
                        className="cursor-pointer block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                      >
                        Add To Archived
                      </button>
                    </MenuItem>
                  )}
                </div>
              </MenuItems>
            </Menu>
          </>
        );
      },
    },
  ];

  const tabCount = useMemo(() => {
    const obj = {
      all: 0,
      active: 0,
      inactive: 0,
    };
    // obj.all = categoryData?.length;

    obj.active = categoryData?.filter((c) => c?.status === "active")?.length;
    obj.inactive = categoryData?.filter(
      (c) => c?.status === "inactive"
    )?.length;
    obj.all = obj.active + obj.inactive;
    return obj;
  }, [categoryData]);

  const activeTabsData = useMemo(() => {
    if (actionTab === "all") {
      if (isOpen) {
        return categoryData?.filter((c) => c?.status === "archive");
      }
      return categoryData?.filter(
        (c) => c?.status === "active" || c?.status === "inactive"
      );
    } else if (actionTab === "active") {
      return categoryData?.filter((c) => c?.status === "active");
    } else if (actionTab === "inactive") {
      if (isOpen) {
        return categoryData?.filter((c) => c?.status === "archive");
      }
      return categoryData?.filter((c) => c?.status === "inactive");
    }

    return categoryData;
  }, [actionTab, categoryData, isOpen]);

  const rowData =
    activeTabsData && activeTabsData.length > 0
      ? activeTabsData.map((item) => ({
          id: item.id,
          categoryId: item.id,
          name: item.name,
          category_type: item.category_type,
          partner_count: item.partner_count,
          request_count: item.request_count,
          description: item.description,
          date: item.updated_at,
          status: item.status,
        }))
      : [];

  const searchCategoryQuery = rowData.filter((row) =>
    row.name?.toLowerCase().includes(searchPartnerQuery.toLowerCase())
  );

  return (
    <div className="px-8 pt-10 bg-[#F9F9F9]">
      <BreadCumbs
        firstCumbName="Partner"
        secoundCumbName="Partner Status"
        pathToRedirect="addnewpartner"
        pathToRedirectSecond="partners"
        thirdCumbName="Partner View"
      />
      <div className="px-6 py-10 mb-5 details-info border border-[#E4EDFF] rounded-[5px] bg-white">
        <div className="flex justify-between">
          <div className="border border-[#2260D9] bg-[#F2F7FF] rounded-[3px] text-[#2260D9] font-semibold text-2xl px-5 mx-5 py-2 w-fit uppercase">
            {partnerInfo.partner_type} partner
          </div>
          {userType !== "agent" && (
            <div>
              <Menu
                as="div"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
              >
                <div>
                  <MenuButton
                    className="cursor-pointer relative inline-flex w-full justify-center py-2 px-4 border border-[#D9D9D9] rounded-md"
                    onClick={(e) => handleMenuClick(e, partnerInfo.id)}
                  >
                    <img src={MoreIcon} alt="MoreIcon" />
                  </MenuButton>

                  <MenuItems
                    transition
                    className="absolute right-10 z-10 px-4 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <div>
                      <MenuItem
                        as="button"
                        className=" cursor-pointer py-2 block !text-[12px] text-[#353F4F] data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                        onClick={() =>
                          navigate(`/addnewpartner?id=${partnerInfo.id}`)
                        }
                      >
                        Edit
                      </MenuItem>
                      {partnerInfo.status === "active" ? (
                        <div>
                          <MenuItem
                            as="button"
                            onClick={() =>
                              handleAction("inactive", partnerInfo.id)
                            }
                            className=" cursor-pointer py-2 block !text-[12px] text-[#353F4F] data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                          >
                            Deactivate
                          </MenuItem>
                        </div>
                      ) : (
                        <div>
                          <MenuItem
                            as="button"
                            onClick={() =>
                              handleAction("active", partnerInfo.id)
                            }
                            // className="!text-[12px] text-green-600 hover:bg-[#D1FAE5] hover:text-green-800"
                          >
                            <p className=" cursor-pointer py-2 block !text-[12px] text-[#353F4F] data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                              Activate
                            </p>
                          </MenuItem>

                          <MenuItem
                            as="button"
                            onClick={() =>
                              handleAction("archive", partnerInfo.id)
                            }
                          >
                            <button
                              type="archive"
                              className=" cursor-pointer py-2 block !text-[12px] text-[#353F4F] data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                            >
                              Add To Archived
                            </button>
                          </MenuItem>
                        </div>
                      )}
                    </div>
                  </MenuItems>
                </div>
              </Menu>
            </div>
          )}
        </div>
        <div className="flex items-center gap-10 px-5 pt-14 pb-6  border-b border-[#E4E6EF]">
          <p
            style={{
              borderRight: "1px solid rgba(34, 96, 217, 1)",
              paddingRight: "20px",
            }}
            className="text-[#1D5BBF]  font-semibold text-3xl uppercase"
          >
            {partnerInfo.organization_name}
          </p>
          <p className="text-[#353F4F] text-xl font-medium border-r-[1px] border-custom-blue pr-5 flex items-center">
            Badge:{" "}
            {partnerInfo.badge === "Silver" && (
              <img src={SilverBadge} alt="Silver Badge" />
            )}
            {partnerInfo.badge === "Gold" && (
              <img src={GoldenBadge} alt="Gold Badge" />
            )}
            {partnerInfo.badge === "Bronze" && (
              <img src={BronzeBadge} alt="Bronze Badge" />
            )}
            {/* {partnerInfo.badge === "No Badge" && (
              <img src={NoBadge} alt="No Badge" />
            )} */}
            {!["Silver", "Gold", "Bronze", "No Badge"].includes(
              partnerInfo.badge
            ) && <span>{partnerInfo.badge}</span>}
          </p>
          <p className="text-[#353F4F] text-xl font-medium">
            ID Number: {partnerInfo.id}
          </p>
          <p
            className={`text-base font-medium px-5 py-1 ml-7 rounded-full capitalize ${
              partnerInfo.status === "inactive"
                ? "bg-[#FFEDED] border border-[#FF4D4F] text-[#FF4D4F]"
                : "bg-[#EDFFF4] border border-[#35A462] text-[#35A462]"
            }`}
          >
            {partnerInfo.status}
          </p>
        </div>
        <div className="px-5">
          <div className="py-8 flex items-center gap-3 ">
            <img src={LocationIcon} alt="LocationIcon" />
            <span className="text-[#353F4F] font-medium text-base">
              {partnerInfo.country}
            </span>
          </div>

          <div className="py-1 text-[#353F4F] font-semibold ">
            <p className="text-base pb-3">Primary Contact:</p>
            <div className="flex gap-3">
              <div className="flex gap-3 items-center">
                <img src={PhoneCircleIcon} alt="PhoneCircleIcon" />
                <span className="underline text-sm">
                  +{partnerInfo.contact_number}
                </span>
              </div>
              <div style={{ border: "1px solid rgba(53, 63, 79, 1)" }}></div>

              <div className="flex gap-3 items-center">
                <img src={EmailCircleIcon} alt="EmailCircleIcon" />
                <span className="text-sm">{partnerInfo.email}</span>
              </div>
              <div style={{ border: "1px solid rgba(53, 63, 79, 1)" }}></div>

              <div className="flex gap-3 items-center">
                <img src={UserCircleIcon} alt="UserCircleIcon" />
                <span className="text-sm">
                  CatrinaFuture@gmail.com /{" "}
                  <span className="underline">
                    +{partnerInfo.alternative_number}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className="pt-10 text-[#353F4F] font-semibold ">
            <p className="text-base pb-3">Secondary Contact:</p>
            <div className="flex gap-3">
              <div className="flex gap-3 items-center">
                <img src={PhoneCircleIcon} alt="PhoneCircleIcon" />
                <span className="underline text-sm">+1 43 456890</span>
              </div>
              <div style={{ border: "1px solid rgba(53, 63, 79, 1)" }}></div>

              <div className="flex gap-3 items-center">
                <img src={EmailCircleIcon} alt="EmailCircleIcon" />
                <span className="text-base">{partnerInfo.email}</span>
              </div>
            </div>
          </div>

          <div className="py-10 text-[#353F4F] text-sm">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop.
          </div>

          <div className="font-medium pb-10">
            <p className="text-[#353F4F] font-medium text-base">
              Website Link:
            </p>
            <p className="underline text-[#1D5BBF] text-sm">
              https://git.drupalcode.org
            </p>
          </div>

          <div className="flex items-center gap-5">
            <p className="text-[#353F4F] font-medium text-base">
              Social Media :{" "}
            </p>
            <div className="flex gap-4 items-center">
              <img src={LinkedInIcon} alt="LinkedInIcon" />
              <img src={TwitterIcon} alt="TwitterIcon" />
              <img src={FacebookIcon} className="h-7" alt="FacebookIcon" />
            </div>
          </div>

          <div className="pt-10 pb-5">
            <p
              style={{ borderBottom: "1px solid rgba(193, 196, 196, 1)" }}
              className="text-primary-blue pb-2 font-semibold"
            >
              Provided Programs
            </p>
            <div className="flex gap-16 py-4 px-4">
              <ul className="text-[14px] leading-10 list-disc">
                <li>Educational Support Aid</li>
                <li>Food Security Aid</li>
                <li>Water and Sanitation Aid</li>
                <li>Disaster Preparedness Aid</li>
              </ul>
              <ul className="text-[14px] leading-10 list-disc">
                <li>Educational Support Aid</li>
                <li>Food Security Aid</li>
                <li>Water and Sanitation Aid</li>
                <li>Disaster Preparedness Aid</li>
              </ul>
              <ul className="text-[14px] leading-10 list-disc">
                <li>Educational Support Aid</li>
                <li>Food Security Aid</li>
                <li>Water and Sanitation Aid</li>
                <li>Disaster Preparedness Aid</li>
              </ul>
            </div>
          </div>

          <div className="details-info mb-8">
            <div>
              <ul className="tab-list border-b border-[#E4E6EF]">
                {ManageCategoryTab.map((discussion, index) => (
                  <li
                    className={`${
                      actionTab === discussion.key ? "active" : ""
                    } relative `}
                    style={{ width: "150px" }}
                    key={index}
                    onClick={() => setActiveTab(discussion.key)}
                  >
                    <div className="text-[13px]"> {`${discussion.name}`}</div>
                    {actionTab === discussion.key && <span></span>}
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex justify-end items-center my-10">
              <div className="flex items-stretch gap-7">
                {actionTab !== "totalemployees" && (
                  <div className="relative">
                    <input
                      type="text"
                      id="search-navbar"
                      value={searchPartnerQuery}
                      onChange={(e) => setSearPartnerchQuery(e.target.value)}
                      className="block w-full p-2 text-sm placeholder:text-[#D6E4FF] text-gray-900 border-none rounded-[3px] focus:outline-none"
                      placeholder="Search"
                      style={{
                        border: "1px solid rgba(34, 96, 217, 1)",
                        height: "50px",
                        width: "300px",
                      }}
                    />
                    <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                      <img src={SearchIcon} alt="SearchIcon" />
                    </div>
                  </div>
                )}
                {actionTab === "totalemployees" && (
                  <div className="relative">
                    <input
                      type="text"
                      id="search-navbar"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="block w-full p-2 text-sm text-gray-900 border-none rounded-[3px] focus:outline-none"
                      placeholder="Search"
                      style={{
                        border: "1px solid rgba(34, 96, 217, 1)",
                        height: "50px",
                        width: "300px",
                      }}
                    />
                    <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                      <img src={SearchIcon} alt="SearchIcon" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {actionTab === "managecategories" && (
              <div>
                <DataTable
                  rows={searchCategoryQuery || []}
                  columns={CategorysColumn}
                  hideCheckbox
                  hideFooter
                  // pages={pages}
                  // totalRows={total}
                  handlePageChange={handlePageChange}
                />
              </div>
            )}
            {actionTab === "totalemployees" && (
              <div>
                <DataTable
                  rows={searchFilteredRows || []}
                  columns={TotalEmployeesColumn}
                  hideCheckbox
                  hideFooter
                />
              </div>
            )}
          </div>
        </div>
        <AddPartnersInCategories
          open={openPopup}
          setOpen={setOpen}
          id={openPopup}
          fromRequest={false}
          setId={setOpen}
        />
      </div>
      <Dialog
        open={successDialogOpen}
        onClose={handleDialogClose} // Close on outside click
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{ width: "450px", height: "180px", borderRadius: "10px" }}
        >
          <img
            src={dialogImage}
            alt="Success"
            style={{ display: "block", margin: "0 auto" }}
          />
          <Typography variant="h6" align="center" style={{ marginTop: "20px" }}>
            {dialogMessage}
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        className="flex items-center justify-center max-w-none rounded-xl"
      >
        <div className="bg-white  shadow-lg w-[600px] sm:max-w-sm md:max-w-md lg:max-w-xl xl:max-w-2xl px-4 sm:px-8 md:px-16 lg:px-20 py-6 sm:py-8 md:py-10 relative">
          <div className="flex items-center justify-center mb-4">
            <img src={Checkimg} alt="Check" className="mx-2" />
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              --
              <ArrowForwardIcon />
              --
            </Typography>
            <img src={Crossicon} alt="Cross" className="mx-2" />
          </div>

          <DialogContent className="mb-4 text-center">
            <Typography className="text-[#353F4F] text-base font-medium">
              Are you sure you want to change the status?
            </Typography>
          </DialogContent>
          <div className="mb-4">
            <label
              htmlFor="Comments"
              className="text-[#353F4F] font-medium flex items-center"
            >
              Comments <span className="ml-1 text-red-600">*</span>
            </label>
            <textarea
              id="Comment"
              name="Comment"
              rows={6}
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-[#D6E5EE] placeholder:text-gray-400 focus:outline-2 focus:outline-indigo-600 sm:text-sm"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter your reason for changing the status"
              required
            />
          </div>

          <div className="flex justify-center gap-4">
            <button
              onClick={handleConfirmDialogClose}
              className="text-[#353F4F] px-6 py-3 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: cancelButtonActive ? "#DC3545" : "white",
                color: cancelButtonActive ? "white" : "#353F4F",
                border: cancelButtonActive ? "none" : "1px solid #18283D80",
              }}
              onMouseDown={() => setCancelButtonActive(true)}
              onMouseUp={() => setCancelButtonActive(false)}
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmAction}
              className="text-[#353F4F] px-6 py-3 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: "#DC3545",
                color: "white",
                border: "none",
              }}
              onMouseDown={() => setDeactivateButtonActive(true)}
              onMouseUp={() => setDeactivateButtonActive(false)}
            >
              Deactivate
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={activateDialogOpen}
        onClose={handleActivateDialogClose}
        className="flex items-center justify-center overflow-hidden max-w-none rounded-xl"
      >
        <div className="bg-white shadow-lg w-[600px] overflow-hidden sm:max-w-sm md:max-w-md lg:max-w-xl xl:max-w-2xl px-4 sm:px-8 md:px-16 lg:px-20 py-6 sm:py-8 md:py-10 relative">
          <div className="flex items-center justify-center mb-4">
            <img src={Crossimg} alt="Cross" className="mx-2 " />

            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              --
              <ArrowForwardIcon />
              --
            </Typography>
            <img src={Checkicon} alt="Check" className="mx-2 " />
          </div>
          <DialogContent className="mb-4 text-center">
            <Typography className="text-[#353F4F] text-base font-medium">
              Are you sure you want to change the status?
            </Typography>
          </DialogContent>
          <div className="flex justify-center">
            <button
              onClick={handleActivateDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: cancelButtonActive ? "#278E1D" : "white",
                color: cancelButtonActive ? "white" : "#353F4F",
                border: cancelButtonActive ? "none" : "1px solid #18283D80",
              }}
              onMouseDown={() => setCancelButtonActive(true)}
              onMouseUp={() => setCancelButtonActive(false)}
            >
              Cancel
            </button>

            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#28A745] hover:text-white hover:border-none"
              style={{
                backgroundColor: "#278E1D",
                color: "white",
                border: "none",
              }}
              onMouseDown={() => setActivateButtonActive(true)}
              onMouseUp={() => setActivateButtonActive(false)}
            >
              Activate
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        className="flex items-center justify-center"
      >
        <div className="relative w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-center mb-4">
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              <img src={Archiveicon} alt="" />
            </Typography>
          </div>

          <DialogContent className="mb-4">
            <Typography className="text-[#353F4F] text-base text-center">
              Are you sure you want to move this Program to Archive?
            </Typography>
          </DialogContent>

          <div className="flex justify-center">
            <button
              onClick={handleDeleteDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#FF6711] hover:text-white"
              style={{
                border: "1px solid #18283D80",
              }}
            >
              No
            </button>
            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#FF6711] hover:text-white"
              style={{
                border: "1px solid #18283D80",
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
