import React, { useState } from "react";
import { useNavigate } from "react-router";
import AddIcon from "@mui/icons-material/Add";
const MeetingList = ({ selectedMeeting, setSelectedMeeting }) => {
  const navigate = useNavigate();

  const meetings = [
    { id: 1, name: "All Meetings", color: "bg-[#00AEBD]", value: "" },
    {
      id: 2,
      name: "Upcoming Meetings",
      color: "bg-[#2260D9]",
      value: "upcoming",
    },
    {
      id: 3,
      name: "Internal Meetings",
      color: "bg-[#FEC125]",
      value: "internal",
    },
    {
      id: 4,
      name: "External Meetings",
      color: "bg-[#506992]",
      value: "external",
    },
    {
      id: 5,
      name: "Rescheduled Meetings",
      color: "bg-[#FF8A00]",
      value: "reschedule",
    },
    {
      id: 6,
      name: "Completed Meetings",
      color: "bg-[#16B681]",
      value: "completed",
    },
    {
      id: 7,
      name: "Cancelled Meetings",
      color: "bg-[#E0382D]",
      value: "cancelled",
    },
    { id: 8, name: "Draft Meetings", color: "bg-[#B7B7B7]", value: "draft" },
  ];

  const handleSelection = (id) => {
    setSelectedMeeting(id);
  };

  return (
    <div className="w-80 bg-white shadow-xl border-[#DBE0E5] border rounded-lg p-6">
      <h2 className="text-lg font-bold mb-4 text-[#353F4F]">Meeting List</h2>
      <button
        className="w-full bg-[#2260D9] text-white py-2 mb-4 text-[18px] font-semibold"
        onClick={() => navigate("/create-meeting")}
      >
        <span className="text-[20px] mr-2 font-bold">
          <AddIcon />
        </span>
        Add New Meeting
      </button>
      {meetings.map((meeting) => (
        <div
          key={meeting.id}
          className={`flex items-center p-2 h-[50px] mb-2  rounded text-[#353F4F] text-[16px] font-semibold cursor-pointer ${
            selectedMeeting === meeting.value
              ? "bg-[#EEF5FF]"
              : "border-[#a3c3f5] border"
          }`}
          onClick={() => handleSelection(meeting.value)}
        >
          <span className={`w-4 h-4 ${meeting.color} mr-4`}></span>
          <span className="text-gray-700">{meeting.name}</span>
        </div>
      ))}
    </div>
  );
};

export default MeetingList;
