import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

import BreadCrumIcon from "../../assets/breadcrumb-right-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import ConsumerView from "./ConsumerView";
import AgentView from "./AgentView";
import { getCategoryById } from "../../services/category";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export default function ViewCategory() {
  const navigate = useNavigate();
  const params = useParams();
  const { userType } = useSelector((state) => state.userInfo);
  const [searchParams] = useSearchParams();

  const from = searchParams.get("from");

  const { details, loading } = useSelector((state) => state.categoryInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    const categoryId = params.id;
    if (categoryId !== "") {
      dispatch(getCategoryById(params.id));
    }
  }, [params.id]);

  return (
    <div className="px-8 mt-10">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {Object.keys(details)?.length > 0 && (
        <>
          <div className="">
            <nav class="flex" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                <li class="inline-flex items-center">
                  <span
                    class="inline-flex items-center text-sm text-gray-700 hover:text-blue-600 dark:text-gray-400 cursor-pointer"
                    onClick={() =>
                      navigate(from === "dashboard" ? "/" : "/category")
                    }
                  >
                    {from === "dashboard" ? "Dashboard" : "Program"}
                  </span>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <img src={BreadCrumIcon} alt="BreadCrumIcon" />
                    <span class="ms-1 text-sm  md:ms-2 text-primary-blue capitalize">
                      View {details.name} detail page
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
          </div>
          {userType === "consumer" && <ConsumerView />}
          {userType === "other" && <AgentView />}
        </>
      )}
    </div>
  );
}
