import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import FrequentCategory from "../FrequentCategory";
import TopCategory from "../TopCategory";
import PopularSearch from "../PopularSearch";

import PlusIcon from "../../assets/plus-white-circle.svg";
import SearchIcon from "../../assets/search-icon.svg";
import LocationIcon from "../../assets/dashboard-location.svg";
import { useDispatch, useSelector } from "react-redux";
import MuiModal from "../../shared/Modal";
import { useForm } from "react-hook-form";
import AddOtpLogin from "../../components/Popups/AddOtpLogin";
import { LoginSocialGoogle } from "reactjs-social-login";
import { getUserType } from "../../services/userType";
import {
  loginSocialMedia,
  loginWithPhoneNumber,
  updateUserInfo,
} from "../../services/user";
import { facebookLogin } from "../../services/user";
import { userApiResponse } from "../../constants/apiResponse";
import GoogleIcon from "../../assets/google-icon.svg";
import FacebookIcon from "../../assets/facebook-icon.svg";
import InstagramIcon from "../../assets/instagram-icon.svg";
import { frequentlySearchCategory } from "../../services/consumerCategory";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
export default function Consumer() {
  const navigate = useNavigate();
  const [showListbox, setShowListbox] = useState(false);
  // login
  const { data, status, userType } = useSelector((state) => state.userInfo);
  const [loginModal, setLoginModal] = useState();
  const [openOtp, setOpenOtp] = useState(false);
  const [number, setNumber] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const handleCloseLoginModal = () => {
    reset();
    setLoginModal(false);
  };

  const requestData = useSelector(
    (state) => state.consumerCategoryInfo.frequentlySearchCategory.data
  );

  const categoryList = useSelector((state) => {
    return state.categoryInfo?.data?.data;
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onLogoutFailure = useCallback(() => {
    alert("logout fail");
  }, []);
  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);
  const onLogoutSuccess = useCallback(() => {
    alert("logout success");
  }, []);
  const onLoginSuccess = (data) => {
    console.log("Google login", data);
    if (
      data &&
      Object.keys(data).length &&
      data.hasOwnProperty("name") &&
      data.hasOwnProperty("email")
    ) {
      let l = { email: data.email };
      console.log("Login", l);
      dispatch(loginSocialMedia(l));
    }
  };
  const handleFacebookLogin = () => {
    window.FB.login(
      (response) => {
        if (response.status === "connected") {
          console.log("Logged in:", response);
          const accessToken = response.authResponse.accessToken;
          dispatch(facebookLogin(accessToken)).then(() => {
            setLoginModal(false);
            dispatch(getUserType());
            navigate("/");
          });
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "public_profile,email" }
    );
  };
  const handleOtpClick = () => {
    setOpenOtp(true);
  };
  const onSubmit = (data) => {
    dispatch(
      loginWithPhoneNumber({ phone_number: `+91${data.phone_number}` })
    ).then(() => {
      handleOtpClick();
      setNumber(data.phone_number);
    });
  };
  useEffect(() => {
    if (status === userApiResponse.loggin) {
      handleCloseLoginModal();
      dispatch(updateUserInfo({ status: "" }));
    }
  }, [status]);

  useEffect(() => {
    dispatch(getUserType());
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    console.log({ newValue });
    // Find the category corresponding to the selected name
    const selectedCategory = [
      ...(requestData || []),
      ...(categoryList || []),
    ].find((option) => option.name === newValue);

    if (selectedCategory) {
      // Navigate to the category page
      navigate(`/category/${selectedCategory.id}`);
    }
  };
  return (
    <div className="px-4 lg:px-10 py-4">
      <div className="flex gap-7 my-6">
        <div className="relative ">
          <div className="absolute left-2 top-3 ">
            <img src={LocationIcon} className="h-5" alt="SearchIcon" />
          </div>
          <input
            type="text"
            className="block w-[99px] p-2 text-[16px] text-[#353F4F] bg-[#F0F0F0] text-sm outline-none"
            placeholder="California"
            style={{
              border: "1px solid #B6B6B6",
              borderRadius: "3px",
              height: "45px",
              minWidth: "290px",
              paddingLeft: "35px",
            }}
          />
        </div>
        <div className="relative ">
          <Stack spacing={2} sx={{ width: 300, position: "relative" }}>
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              options={[...(categoryList || [])].map((option) => option.name)}
              onChange={handleChange}
              renderInput={(params) => (
                <div
                  {...params.InputProps}
                  style={{ position: "relative", width: "100%" }}
                >
                  <TextField
                    {...params}
                    dis
                    placeholder="Search here"
                    sx={{
                      width: "100%",
                      height: "45px",
                      borderRadius: "3px",
                      border: "1px solid rgba(29, 91, 191, 1)",
                      "& .MuiInputBase-root": {
                        padding: "0px",
                      },
                      "& input": {
                        padding: "12px 14px",
                        fontSize: "16px",
                        color: "#353F4F",
                      },
                      "& .MuiInputLabel-root": {
                        fontSize: "16px",
                        color: "#C8C9C9",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },

                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <div className="absolute right-2 top-2 cursor-pointer">
                          <img src={SearchIcon} alt="SearchIcon" />
                        </div>
                      ),
                    }}
                  />
                </div>
              )}
            />
          </Stack>
        </div>
        <button
          className="bg-primary-orange text-white px-12 py-2 text-[14px] rounded items-center flex gap-4 cursor-pointer"
          onClick={() =>
            // navigate("/create-request")
            {
              Object.keys(data).length > 0
                ? navigate("/create-request")
                : setLoginModal(true);
            }
          }
          style={{
            height: "45px",

            minWidth: "150px",
          }}
        >
          <img src={PlusIcon} alt="PlusIcon" />
          <span>Create Request</span>
        </button>
      </div>

      <div className="text-primary-blue py-3 text-[28px] font-bold">
        {/* Welcome to Community Request and Referral Management System ! */}
        Hearing Aid of America, Washington Chapter
      </div>
      <div className="text-[16px] text-dark-gray">
      Dedicated to supporting individuals with hearing loss by providing resources, education, and advocacy. It aims to enhance the quality of life through access to advanced hearing solutions and community support.
      {/* Brings up the core services to displayed here. You can customize the program line up based on your needs. Core services are dealt internally and other services can be dealt externally. */}
      </div>

      <FrequentCategory />
      <TopCategory />
      <PopularSearch />
      <MuiModal
        modalSize="md"
        modalOpen={loginModal}
        modalClose={handleCloseLoginModal}
        noheader
      >
        {openOtp ? (
          <AddOtpLogin
            number={number}
            setNumber={setNumber}
            setOpenOtp={setOpenOtp}
            handleCloseLoginModal={handleCloseLoginModal}
            urlPath={`/create-request`}
          />
        ) : (
          <>
            <div className="flex flex-col items-center py-7">
              <h5 className="text-primary-blue text-[26px] text-center font-medium">
                CRRMS
              </h5>
              <p className="text-primary-black text-[18px] py-4">
                Welcome to the Request Management System
              </p>
              <p className="text-primary-black text-[16px] pb-6">
                {/* Login for a seamless experience */}
              </p>
              <div className="flex gap-4">
                <LoginSocialGoogle
                  client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  onLogoutFailure={onLogoutFailure}
                  onLoginStart={onLoginStart}
                  onLogoutSuccess={onLogoutSuccess}
                  scope="openid profile email"
                  onResolve={(e) => {
                    onLoginSuccess(e.data);
                  }}
                  onReject={(err) => {
                    console.log("hbhbdhd", err);
                  }}
                >
                  <div
                    className="px-6 py-3 cursor-pointer"
                    style={{
                      border: "0.5px solid rgba(34, 96, 217, 0.5)",
                      borderRadius: "3px",
                    }}
                  >
                    <img src={GoogleIcon} alt="GoogleIcon" />
                  </div>
                </LoginSocialGoogle>
                <div
                  className="px-6 py-3 cursor-pointer"
                  style={{
                    border: "0.5px solid rgba(34, 96, 217, 0.5)",
                    borderRadius: "3px",
                  }}
                >
                  <img src={InstagramIcon} alt="InstagramIcon" />
                </div>
                <div
                  onClick={handleFacebookLogin}
                  className="px-6 py-3 cursor-pointer"
                  style={{
                    border: "0.5px solid rgba(34, 96, 217, 0.5)",
                    borderRadius: "3px",
                  }}
                >
                  <img src={FacebookIcon} alt="FacebookIcon" />
                </div>
                {/* <div
              className="px-6 py-3 cursor-pointer"
              style={{
                border: "0.5px solid rgba(34, 96, 217, 0.5)",
                borderRadius: "3px",
              }}
            >
              <img src={EmailIcon} alt="EmailIcon" />
            </div> */}
              </div>
              <div
                className="mb-8 mt-8 flex items-center before:mt-0.5 before:flex-1 before:border-t
                     before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t
                      after:border-neutral-300 dark:before:border-neutral-500
                       dark:after:border-neutral-500"
                style={{ color: "rgba(183, 185, 189, 1)", width: "50%" }}
              >
                <p
                  className="mx-4 mb-0 font-semibold text-center dark:text-neutral-200"
                  style={{
                    color: "rgba(183, 185, 189, 1)",
                    border: "1px solid rgba(183, 185, 189, 1)",
                    padding: "2px 20px",
                    borderRadius: "20px",
                    fontSize: "14px",
                  }}
                >
                  OR
                </p>
              </div>

              <form onSubmit={handleSubmit(onSubmit)} className="w-[50%]">
                <div className="flex flex-wrap gap-2">
                  <div className="relative w-full mb-6">
                    <input
                      type="tel"
                      className={`w-full rounded px-3 py-[0.32rem] text-[14px] leading-[2.15] focus:outline-none h-[60px] ${
                        errors["phone_number"]
                          ? "focus:border-teal focus:outline-none focus:ring-0"
                          : ""
                      }`}
                      placeholder="Ex: (555) 555-1234"
                      style={{
                        color: "#232323",
                        border: `1px solid ${
                          errors["phone_number"]
                            ? "rgb(239 68 68)"
                            : "rgba(34, 96, 217, 1)"
                        }`,
                      }}
                      {...register("phone_number", {
                        required: "This field is required",
                        pattern: {
                          value: /^\(\d{3}\) \d{3}-\d{4}$/,
                          message:
                            "Please Enter Valid Mobile Number in (415) 555-1234 format",
                        },
                      })}
                      aria-invalid={errors["phone_number"] ? "true" : "false"}
                      onChange={(e) => {
                        let input = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
                        let formatted = "";

                        if (input.length > 0)
                          formatted = `(${input.substring(0, 3)}`;
                        if (input.length > 3)
                          formatted += `) ${input.substring(3, 6)}`;
                        if (input.length > 6)
                          formatted += `-${input.substring(6, 10)}`;
                        e.target.value = formatted;
                      }}
                    />
                    {errors["phone_number"] && (
                      <p className="error" role="alert">
                        {errors["phone_number"].message}
                      </p>
                    )}
                  </div>

                  <div className="relative w-full">
                    <button
                      type="submit"
                      className="inline-block w-full py-4 text-sm font-medium text-white rounded px-7 bg-primary-blue "
                      data-twe-ripple-init
                      data-twe-ripple-color="light"
                    >
                      Login with OTP
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </MuiModal>
    </div>
  );
}
