import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { frequentlySearchCategory } from "../../services/consumerCategory";
import { useDispatch, useSelector } from "react-redux";
import PaidDoller from "../../assets/paiddollar.svg";

export default function FrequentCategory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const requestData = useSelector(
    (state) => state.consumerCategoryInfo.frequentlySearchCategory.data
  );

  useEffect(() => {
    dispatch(frequentlySearchCategory(7));
  }, [dispatch]);

  return (
    <div
      style={{
        boxShadow: "6px 6px 35px 0px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
      }}
      className="mt-5"
    >
      <div className="flex items-center justify-between px-7 py-3 title">
        <div
          className="w-full pt-5 pb-5"
          style={{
            borderBottom: "1px solid #2260D9",
          }}
        >
          <h4 className="text-[#353F4F] font-medium text-xl">Frequently Requested Programs</h4>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-16 px-7 pt-7 pb-10 md:gap-10 mb-10">
        {requestData?.map((freqCat) => (
          <div
            style={{
              boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
              borderRadius: "5px",
              padding: "30px 42px 20px",
              textAlign: "center",
              position: "relative", // For badge placement
            }}
            className="flex flex-col justify-center items-center gap-4 cursor-pointer"
            key={freqCat.key}
            onClick={() => navigate(`/category/${freqCat.id}?from=dashboard`)}
          >
            {freqCat.is_premium && (
              <div className="absolute h-[70px] w-[70px] top-0 right-0">
                <img src={PaidDoller} alt="Paid" className="rounded-tr-[5px]"/>
              </div>
            )}

            <img
              src={`https://crrmsbackend.dataterrain-dev.net${freqCat.image}`}
              className="h-9"
              alt="DonationIcon"
            />
            <p className="text-[#252A3A] font-medium capitalize">{freqCat.name ? (freqCat.name) : ""}</p>
          </div>
        ))}
        {/* Optional "Show more" button */}
        {/* <div
    style={{
      boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "5px",
      padding: "20px 42px 30px",
      textAlign: "center",
      background: "rgba(219, 231, 255, 1)",
      color: "rgba(34, 96, 217, 1)",
      border: "1px solid rgba(34, 96, 217, 1)",
    }}
    className="cursor-pointer"
  >
    Show more...
  </div> */}
      </div>
    </div>
  );
}
