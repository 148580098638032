import React from "react";
import {
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import moment from "moment";
import CloseIcon from "../../assets/close.svg";
import DeleteIcon from "../../assets/delete.svg";

const MeetingsPopup = ({ open, onClose, meetings, setActiveEvent, setIsModalOpen }) => {
  const formatMeetingTime = (start, end) => {
    const startTime = moment(start).format("h:mm A");
    const endTime = moment(end).format("h:mm A"); // Example: 1.5 hours duration
    return `${startTime} - ${endTime}`;
  };
  return (
    <Dialog open={open} onClose={onClose}>
      {/* Header */}
      <div className="flex justify-between  items-center border-b text-[#18283D]">
        <div className="p-3 text-[20px] font-semibold">Meetings</div>
        <IconButton onClick={onClose}>
          <img src={CloseIcon} alt="CloseIcon" />
        </IconButton>
      </div>

      {/* Content */}
      <List className="!p-0 overflow-y-auto max-h-[500px] text-[#18283D]">
        {meetings.map((meeting, index) => (
          <div key={index} className="border-b border-[#D6D6D6] px-4 py-2">
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <div className="capitalize text-[16px]">{meeting.title}</div>
                }
                secondary={
                  <div className="text-[14px]">
                    <div>Admin: {meeting.admin}</div>
                    <div className="flex gap-4">
                      <div>
                        Date: {moment(meeting.start).format("Do MMM YYYY")}
                      </div>
                      <div className="text-[#D6D6D6]">|</div>
                      <div>
                        Time: {formatMeetingTime(meeting.start, meeting.end)}
                      </div>
                    </div>
                  </div>
                }
              />
              <IconButton
                edge="end"
                sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
                onClick={() => {
                    setActiveEvent(meeting)
                    setIsModalOpen(true)
                    onClose()
                }}
              >
                <img src={DeleteIcon} alt="CloseIcon" height={30} width={30} />
              </IconButton>
            </ListItem>
          </div>
        ))}
      </List>
    </Dialog>
  );
};

export default MeetingsPopup;
