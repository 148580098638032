import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  getCaseById,
  getPantnerRequestDetail,
} from "../../../services/request";
import BreadCumbs from "../../BreadCumbs/BreadCumbs";
import Circle from "../../../assets/history-right-icon.svg";
import CrossCircle from "../../../assets/crosscircle.svg";
import Export from "../../../assets/export.svg";
import moment from "moment";
import { usePDF } from "react-to-pdf";
import { Button } from "@headlessui/react";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Tooltip } from "@mui/material";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { YearCalendar } from "@mui/x-date-pickers/YearCalendar";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import CloseIcon from "@mui/icons-material/Close";
import FilterIcon from "../../../assets/filter-icon.svg";
import ClearIcon from "../../../assets/clear-filter-icon.svg";
import ArrowDownIcon from "../../../assets/arrow-down-icon.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Satisfied from "../../../assets/satisfied.svg";
import Notsatisfied from "../../../assets/not-satisfied.svg";
import Tick from "../../../assets/true.svg";
import End from "../../../assets/end.svg";
import ExportFile from "../../../assets/exportfile.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

const currentYear = dayjs();

const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const RequestHistory = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [view, setView] = useState("year");
  const [isToggled, setIsToggled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleYearChange = (year) => {
    setSelectedYear(year.$y);
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleInputClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
    setView("year");
  };

  const handleClickAway = () => {
    setIsCalendarOpen(false);
  };

  const handleCloseClick = () => {
    setIsCalendarOpen(false);
  };

  const toggleButton = () => {
    setIsToggled(!isToggled);
  };

  // const handleMonthNavigation = (direction) => {
  //   const newMonth = selectedMonth + direction;
  //   if (newMonth >= 0 && newMonth < 12) {
  //     setSelectedMonth(newMonth);
  //   }
  // };

  const handleMonthNavigation = (direction) => {
    let newMonth = selectedMonth + direction;

    if (newMonth < 0) {
      newMonth = 11;
      setSelectedYear(selectedYear - 1);
    } else if (newMonth > 11) {
      newMonth = 0;
      setSelectedYear(selectedYear + 1);
    }

    setSelectedMonth(newMonth);

    setSelectedDate(dayjs().year(selectedYear).month(newMonth).date(1));
  };

  const handleDone = () => {
    if (view === "year") {
      setView("month"); // Show month selection after selecting a year
    } else if (view === "month") {
      setView("date"); // Show date selection after selecting a month
    } else if (view === "date") {
      setIsCalendarOpen(false); // Close calendar after selecting a date
    }
  };

  const handleCancel = () => {
    setIsCalendarOpen(false);
  };

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentRequestData = useSelector(
    (state) => state.requestInfo?.requestDetail?.result?.data?.[0]
  );
  const currentCaseData = useSelector(
    (state) => state.requestInfo?.caseDetails.result
  );
  const { data, userType } = useSelector((state) => state.userInfo);
  const { toPDF, targetRef } = usePDF({
    filename: `${currentRequestData?.category_name}.pdf`,
  });
  useEffect(() => {
    if (id) {
      dispatch(getPantnerRequestDetail(id));
      dispatch(getCaseById(id));
    }
  }, [dispatch, id]);

  // Function to group data and create updates object for memo fields
  const groupData = (data) => {
    const result = [];
    let group = null;

    (Array.isArray(data) ? data : [])?.forEach((item) => {
      if (
        item.memo ||
        item.consumer_memo ||
        item.agent_memo ||
        item.partner_memo ||
        item.admin_memo
      ) {
        if (item.memo) {
          // If group exists and has memo, add the current item to this group
          if (group) {
            result.push(group);
            group = { updates: [] };
          }
          group = {
            ...item,
            updates: [
              { value: item.consumer_memo, name: "Client" },
              { value: item.agent_memo, name: "Agent" },
              { value: item.partner_memo, name: "Partner" },
              { value: item.admin_memo, name: "Partner" },
            ],
          };
        } else {
          // If no group exists, create a new group with the current item
          group = {
            ...group,
            updates: [
              ...group.updates,
              { value: item.consumer_memo, name: "Client" },
              { value: item.agent_memo, name: "Agent" },
              { value: item.partner_memo, name: "Partner" },
              { value: item.admin_memo, name: "Admin" },
            ],
          };
        }
      }
    });

    if (group) {
      result.push(group);
    }

    if (result.length > 0) {
      result.push({
        case_id: "",
        created_at: "",
        updated_at: "",
        is_deleted: false,
        location: "",
        memo: "",
        consumer_memo: null,
        agent_memo: null,
        partner_memo: null,
        admin_memo: null,
        super_admin_memo: null,
        request_status: "",
        user_role: "",
        user_name: "",
        updates: [],
      });
    }

    return result;
  };

  // Get the grouped data
  const groupedData = groupData(currentCaseData || []);

  const formattedData = useMemo(
    () =>
      groupedData.map((ele) => ({
        CaseID: ele.case_id,
        CreatedAt: ele.created_at,
        UpdatedAt: ele.updated_at,
        IsDeleted: ele.is_deleted,
        Location: ele.location,
        Memo: ele.memo,
        RequestStatus: ele.request_status,
        UserRole: ele.user_role,
        UserName: ele.user_name,
        CreatedBy: ele.created_by,
        UpdatedBy: ele.updated_by,
        Request: ele.request,
        Updates: ele.updates
          .map((update) => `${update.name}: ${update.value ?? "N/A"}`)
          .join("\n"),
      })),
    [groupedData]
  );

  const headers = [
    { label: "Case ID", key: "CaseID" },
    { label: "Created At", key: "CreatedAt" },
    { label: "Updated At", key: "UpdatedAt" },
    { label: "Is Deleted", key: "IsDeleted" },
    { label: "Location", key: "Location" },
    { label: "Memo", key: "Memo" },
    { label: "Request Status", key: "RequestStatus" },
    { label: "User Role", key: "UserRole" },
    { label: "User Name", key: "UserName" },
    { label: "Created By", key: "CreatedBy" },
    { label: "Updated By", key: "UpdatedBy" },
    { label: "Request", key: "Request" },
    { label: "Updates", key: "Updates" },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownload = () => {
    toPDF();
  };

  const requestStatusColors = {
    "in progress": "#FBD15B",
    accepted: "#278E1D",
    wait: "#1D5BBF",
    rejected: "#DC3545",
    "on hold": "#E26313",
    new: "#1D5BBF",
    completed: "#278E1D",
    resolved: "#278E1D",
  };

  const renderMemo = (label, value, color) => (
    <div className="flex items-center text-[#353F4F] gap-2">
      {label}
      {value && (
        <div
          style={{ color: requestStatusColors[value] || color || "#353F4F" }}
          className="capitalize rounded-full"
        >
          : {value}
        </div>
      )}
    </div>
  );

  return (
    <div className="px-8 mt-5">
      {userType !== "consumer" && (
        <BreadCumbs
          firstCumbName="Request"
          secoundCumbName="Request Status"
          thirdCumbName="Track Request"
          pathToRedirect=""
          pathToRedirectSecond="all-request"
        />
      )}
      {userType === "consumer" && (
        <>
          {" "}
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 mb-5 md:space-x-2 rtl:space-x-reverse">
              <li class="inline-flex items-center">
                <span class="inline-flex items-center text-sm text-[#353F4F] hover:text-blue-600 dark:text-gray-400 cursor-pointer">
                  My Request
                </span>
              </li>
            </ol>
          </nav>
        </>
      )}
      <div className="flex justify-end w-full my-6">
        <Menu as="div" className="inline-block mt-2 text-left">
          <div>
            <MenuButton
              onClick={handleClick}
              className="cursor-pointer flex items-center gap-2 focus:outline-none"
            >
              <img src={ExportFile} alt="" />
              <p className="text-[#353F4F] text-[16px] font-semibold">Export</p>
              <ExpandMoreIcon className="text-[#A8A8A8]" />
            </MenuButton>
          </div>

          <MenuItems
            onClick={() => {
              handleClose();
            }}
            transition
            className="absolute right-10 z-10 mt-2 w-40 origin-top-right bg-white shadow-lg  ring-1  ring-black transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <div className="py-1 w-full">
              <MenuItem
                as="button"
                className="block px-4 py-2 w-full text-start text-sm text-[#353F4F] cursor-pointer hover:bg-[#EEF4FF] focus:bg-gray-100 focus:text-gray-900"
              >
                <CSVLink
                  data={formattedData}
                  headers={headers}
                  filename={`${currentRequestData?.category_name}.csv`}
                >
                  <p>Excel</p>
                </CSVLink>
              </MenuItem>
              <MenuItem onClick={handleDownload}>
                <p className=" cursor-pointer block px-4 py-2 text-sm text-[#353F4F] data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                  PDF
                </p>
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>
      </div>
      {/* <div className="flex justify-end items-center">
        <CSVLink
          data={formattedData}
          headers={headers}
          filename={`${currentRequestData?.category_name}.csv`}
        >
          <Tooltip title="Download CSV" arrow>
            <img src={Export} alt="export" height={28} width={28} />
          </Tooltip>
        </CSVLink>
        <Button onClick={handleDownload} className="mb-1">
          <Tooltip title="Download PDF" arrow>
            <DownloadForOfflineIcon color="primary" />
          </Tooltip>
        </Button>
      </div> */}

      <div className="w-full" ref={targetRef}>
        <div className="border border-[#E4EDFF] rounded-[3px]">
          <div className="pb-3 border-b border-[#D3D3D3]">
            {currentRequestData ? (
              <div>
                <div className="flex justify-between px-5 pt-5">
                  <div className="flex flex-col">
                    <div className="flex items-center justify-center gap-10">
                      <div
                        className="px-4 py-2 bg-white"
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.15)",
                          borderRadius: "5px",
                          boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
                        }}
                      >
                        <img
                          src={`https://crrmsbackend.dataterrain-dev.net/media/${currentRequestData?.category_image}`}
                          alt="CallIcon"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center gap-5">
                          <h3 className="text-primary-black text-[40px] font-semibold capitalize">
                            {userType === "partner"
                              ? currentRequestData?.category_name
                              : currentRequestData?.category_name}
                          </h3>
                          <div
                            style={{
                              border: `1px solid ${
                                {
                                  "in progress": "#FFCD11",
                                  accepted: "#278E1D",
                                  wait: "#1D5BBF",
                                  rejected: "#DC3545",
                                  "on hold": "#E26313",
                                  new: "#1D5BBF",
                                  completed: "#278E1D",
                                  resolved: "#278E1D",
                                }[currentRequestData.request_status] ||
                                "#CCCCCC"
                              }`,

                              backgroundColor: `${
                                {
                                  "in progress": "#FFF4D4",
                                  accepted: "#EAF9EA",
                                  wait: "#DCE8FF",
                                  rejected: "#FDE7E9",
                                  "on hold": "#FDEEE5",
                                  new: "#DCE8FF",
                                  completed: "#EAF9EA",
                                  resolved: "#EAF9EA",
                                }[currentRequestData.request_status] ||
                                "#FFFFFF"
                              }`,
                              color: `${
                                {
                                  "in progress": "#FFCD11",
                                  accepted: "#278E1D",
                                  wait: "#1D5BBF",
                                  rejected: "#DC3545",
                                  "on hold": "#E26313",
                                  new: "#1D5BBF",
                                  completed: "#278E1D",
                                  resolved: "#278E1D",
                                }[currentRequestData?.request_status] ||
                                "#000000"
                              }`,
                            }}
                            className="px-8 py-2 capitalize rounded-full"
                          >
                            {currentRequestData?.request_status}
                          </div>
                        </div>
                        {currentRequestData?.category_is_premium === "True" &&
                        userType === "consumer" ? (
                          <h4 className="text-[#D1A639] text-[12px] font-medium">
                            ${currentRequestData.category_per_cost}
                          </h4>
                        ) : null}
                        <div className="flex mt-3 text-base font-medium">
                          <div className="pr-5 border-r border-black">
                            Request ID :{" "}
                            <span className="text-[#2260D9] underline underline-offset-2">
                              #{currentRequestData?.id}
                            </span>
                          </div>
                          <div className="pl-5">
                            Request date & Time :{" "}
                            <span className="text-[#2260D9] underline underline-offset-2">
                              {currentRequestData?.created_at
                                ? moment(currentRequestData?.created_at).format(
                                    "MM/DD/YYYY | h:mm A"
                                  )
                                : null}
                            </span>
                          </div>
                          {currentRequestData.request_status !== "new" && (
                            <div className="pl-5">
                              Last Updated date & Time :{" "}
                              <span className="text-[#2260D9] underline underline-offset-2">
                                {currentRequestData?.updated_at
                                  ? moment(
                                      currentRequestData?.updated_at
                                    ).format("MM/DD/YYYY | h:mm A")
                                  : null}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <button
                      className="bg-[#2260D9] rounded-[3px] border border-[#2260D9] text-white px-5 text-[14px] py-3 w-[200px]"
                      onClick={() =>
                        navigate(`/view-request/${currentRequestData?.id}`)
                      }
                    >
                      View Request Detail
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="p-4 bg-white rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <div className="mb-4 text-lg font-bold">Updates</div>
            <div className="flex items-end gap-6 py-4">
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="relative w-64">
                    <label
                      htmlFor="date-input"
                      className="block mb-0.5 text-[#353F4F] text-xs"
                    >
                      Data Filter
                    </label>

                    <input
                      id="date-input"
                      type="text"
                      value={
                        selectedDate ? selectedDate.format("DD/MM/YYYY") : ""
                      }
                      placeholder="Select"
                      readOnly
                      onClick={handleInputClick}
                      className="bg-[#F5FAFF] p-1.5 border border-[#9EBCE3] rounded-[3px] outline-none w-full placeholder:text-[#C0C0C0] placeholder:text-xs cursor-pointer"
                    />

                    {isCalendarOpen && (
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <div className="absolute w-[320px] z-10 bg-[#FAFAFA] border border-[#0000001A] rounded-[3px] shadow-lg mt-2 left-0">
                          {/* Header */}
                          <div className="flex justify-between items-center px-4 py-3">
                            <span className="text-[#757575] text-sm ">
                              {view === "year"
                                ? "Select Year:"
                                : view === "month"
                                ? "Select Month:"
                                : "Select To date"}
                            </span>
                            <CloseIcon
                              onClick={handleCloseClick}
                              sx={{
                                height: "16px",
                                width: "16px",
                                color: "#424242",
                              }}
                              className="cursor-pointer"
                            />
                          </div>
                          <div className="bg-white h-[290px] mx-2 rounded-[3px]">
                            {view === "year" && (
                              <YearCalendar
                                maxDate={currentYear}
                                yearsOrder="desc"
                                onChange={(year) => {
                                  handleYearChange(year);
                                  setView("month");
                                }}
                                sx={{
                                  "& .css-innj4t-MuiPickersYear-yearButton": {
                                    fontSize: "12px",
                                    color: "#353F4F",
                                    "&:hover": {
                                      backgroundColor: "#2260D9",
                                      color: "white",
                                      borderRadius: "3px",
                                    },
                                  },
                                }}
                              />
                            )}

                            {view === "month" && (
                              <div className="pt-2">
                                <div className="bg-[#0000001A] flex items-center gap-2 w-fit p-2 ml-2 rounded-[3px]">
                                  <p className="text-[#353F4F] text-xs">
                                    {selectedYear}
                                  </p>
                                  <div className="w-2 h-2 flex justify-center items-center">
                                    <img
                                      src={ArrowDownIcon}
                                      alt=""
                                      className="cursor-pointer"
                                      // onClick={() => setView("year")}
                                    />
                                  </div>
                                </div>
                                <div className="grid grid-cols-3 gap-2 gap-y-5 p-4">
                                  {[...Array(12)].map((_, index) => {
                                    const monthName = dayjs()
                                      .month(index)
                                      .format("MMMM");
                                    return (
                                      <button
                                        key={index}
                                        onClick={() => {
                                          handleMonthChange(index);
                                          setView("date");
                                        }}
                                        className="p-2 text-center text-xs text-[#353F4F] hover:bg-[#2260D9] hover:text-white rounded-[3px]"
                                      >
                                        {monthName}
                                      </button>
                                    );
                                  })}
                                </div>
                              </div>
                            )}

                            {view === "date" && (
                              <>
                                <div className="flex items-center justify-between gap-2  p-2 rounded-[3px]">
                                  <div className="flex items-center gap-2">
                                    <p className="text-[#353F4F] text-xs">
                                      {selectedYear}
                                    </p>
                                    <div className="w-2 h-2 flex items-center">
                                      <img
                                        src={ArrowDownIcon}
                                        alt="Arrow Down"
                                        // onClick={() => setView("year")}
                                      />
                                    </div>
                                  </div>

                                  <div className="flex items-center">
                                    <button
                                      onClick={() => handleMonthNavigation(-1)}
                                      className="p-1 flex justify-center items-center cursor-pointer"
                                    >
                                      <ChevronLeftIcon
                                        sx={{ fontSize: 16, color: "#424242" }}
                                      />
                                    </button>
                                    <p className="text-[#353F4F] text-xs">
                                      {dayjs()
                                        .month(selectedMonth)
                                        .format("MMMM")}
                                    </p>
                                    <button
                                      onClick={() => handleMonthNavigation(1)}
                                      className="p-1 flex justify-center items-center cursor-pointer"
                                    >
                                      <ChevronRightIcon
                                        sx={{ fontSize: 16, color: "#424242" }}
                                      />
                                    </button>
                                  </div>
                                </div>
                                <div className="grid grid-cols-7 text-center text-xs text-[#353F4F] bg-[#0000000D] py-1">
                                  {weekdays.map((day, index) => (
                                    <span key={index}>{day}</span>
                                  ))}
                                </div>
                                <DateCalendar
                                  value={selectedDate}
                                  // onChange={handleDateChange}
                                  // minDate={dayjs(
                                  //   `${selectedYear}-${selectedMonth + 1}-01`
                                  // )}
                                  // maxDate={dayjs(
                                  //   `${selectedYear}-${selectedMonth + 1}-31`
                                  // )}
                                  // minDate={dayjs(`${selectedYear}-${selectedMonth + 1}-01`)}
                                  // maxDate={dayjs(`${selectedYear}-${selectedMonth + 1}-01`).endOf('month')}
                                  onChange={(newDate) =>
                                    setSelectedDate(newDate)
                                  }
                                  minDate={dayjs()
                                    .year(selectedYear)
                                    .month(selectedMonth)
                                    .date(1)}
                                  maxDate={dayjs()
                                    .year(selectedYear)
                                    .month(selectedMonth)
                                    .endOf("month")}
                                  sx={{
                                    height: "auto",
                                    minHeight: "195px",
                                    "& .MuiPickersCalendarHeader-root": {
                                      display: "none",
                                    },
                                    "& .MuiPickersDay-root": {
                                      fontSize: "12px",
                                    },
                                    "& .MuiPickersDay-root.Mui-selected": {
                                      backgroundColor: "#2260D9",
                                    },
                                    "& .MuiTypography-root.MuiDayCalendar-weekDayLabel":
                                      {
                                        display: "none",
                                      },
                                  }}
                                />
                              </>
                            )}
                          </div>

                          <div className="flex w-full items-streach space-x-2 p-2">
                            <button
                              onClick={handleCancel}
                              className="py-2 px-3 text-xs text-[#353F4F] border border-[#0000001A] rounded-[3px]"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleCancel}
                              className="w-full text-xs text-white bg-[#2260D9] rounded-[3px]"
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </ClickAwayListener>
                    )}
                  </div>
                </LocalizationProvider>
              </div>
              <button
                className="bg-[#2260D9] flex items-center justify-center gap-2 py-1.5 rounded-[3px] w-[100px]"
                onClick={toggleButton}
              >
                <img
                  src={isToggled ? ClearIcon : FilterIcon}
                  alt={isToggled ? "Clear Icon" : "Filter Icon"}
                />
                <p className="text-white text-base">
                  {isToggled ? "Clear" : "Filter"}{" "}
                </p>
              </button>
            </div>
          </div>
          <div className="w-full bg-[#EEF4FF] py-3 px-4">
            <div className="grid items-center grid-cols-8 text-sm">
              <div className="text-left col-span-2">Date & Time</div>
              {userType === "consumer" ? (
                <div className="text-start col-span-5">History</div>
              ) : (
                <div className="text-start col-span-5">Updates</div>
              )}

              <div className=" col-span-1">Updated by</div>
            </div>
          </div>
          {console.log({ currentCaseData, currentRequestData })}
          {(currentRequestData?.request_status === "completed"
            ? currentCaseData
            : [
                ...(currentCaseData || []),
                {
                  case_id: "",
                  created_at: "",
                  updated_at: "",
                  is_deleted: false,
                  location: "",
                  memo: "",
                  consumer_memo: null,
                  agent_memo: null,
                  partner_memo: null,
                  admin_memo: null,
                  super_admin_memo: null,
                  request_status: "",
                  user_role: "",
                  user_name: "",
                  updates: [],
                },
              ]
          )?.map((item, index) => (
            <div
              key={index}
              className="grid items-start grid-cols-8 px-4 text-sm text-gray-700"
            >
              <div className="col-span-2 flex flex-col items-start justify-start h-full py-4 text-left w-fit text-[12px]">
                <div>
                  Date:{" "}
                  {item?.created_at
                    ? moment(item?.created_at).format("MM/DD/YYYY")
                    : "---"}
                </div>
                <div>
                  Time:{" "}
                  {item?.created_at
                    ? moment(item?.created_at).format("h:mm A")
                    : "---"}
                </div>
              </div>

              <div
                className={`text-center relative col-span-5 flex h-full w-full`}
              >
                <div
                  key={index}
                  className={`flex flex-col items-center h-full  ${
                    index === 0 && "pt-4"
                  }`}
                >
                  {item.memo || index === (currentCaseData || []).length ? (
                    <img
                      src={
                        index === (currentCaseData || []).length
                          ? End
                          : item?.request_status === "rejected"
                          ? CrossCircle
                          : Circle
                      }
                      height={22}
                      width={22}
                      alt={
                        item?.request_status === "rejected"
                          ? "Cross Circle"
                          : "Circle"
                      }
                    />
                  ) : (
                    <div className="min-w-[21.06px] max-w-[21.06px]" />
                  )}

                  {(currentRequestData?.request_status === "completed"
                    ? index !== currentCaseData?.length - 1
                    : index !== currentCaseData?.length) && (
                    <div
                      className={`w-0.5 h-full ${
                        true ? "dashed-borderd border-[#8a8888]" : "bg-gray"
                      }`}
                    ></div>
                  )}
                </div>

                <div className="flex flex-col items-start  w-full px-4">
                  <div
                    className={`relative z-10 inline-block  bg-white ${
                      index === 0 && "pt-4"
                    }`}
                  >
                    <div className="flex text-[16px]">
                      {item?.memo ||
                      item?.consumer_memo ||
                      item?.agent_memo ||
                      item?.partner_memo ||
                      item?.admin_memo ? (
                        <div className="flex flex-col gap-2 text-[#353F4F]">
                          {item?.memo &&
                            renderMemo(
                              item.memo,
                              item.request_status,
                              "#2260D9"
                            )}
                          {item?.consumer_memo &&
                            renderMemo(item.consumer_memo, null)}
                          {item?.agent_memo &&
                            renderMemo(item.agent_memo, null)}
                          {item?.partner_memo &&
                            renderMemo(item.partner_memo, null)}
                          {item?.admin_memo &&
                            renderMemo(item.admin_memo, null)}
                        </div>
                      ) : (
                        <div className="text-[#C0C0C0]">
                          (Update yet to be posted, check back later)
                        </div>
                      )}
                    </div>
                  </div>
                  {(item?.memo === "Satisfied" ||
                    item?.memo === "Not Satisfied") && (
                    <div className="flex items-center gap-2 mt-2 bg-[#FAFAFA] p-2 px-4 rounded-lg border border-[#2260D9] mb-4">
                      <img
                        src={
                          item?.memo === "Satisfied" ? Satisfied : Notsatisfied
                        }
                        alt="Satisfied"
                        className="w-6 h-6"
                      />
                      <span className="text-[#353F4F] text-[16px] flex gap-2">
                        {item?.memo} <img src={Tick} alt="Tick" />
                      </span>
                    </div>
                  )}
                  {/* Comments (if any) */}
                  {/* {item?.updates?.filter((ele) => ele.value).length > 0 && (
                    <div className="w-full mt-4">
                      <div className="w-full bg-[#EEF4FF] text-[#2260D9] text-start pl-2 text-[14px] py-1">
                        <div className="font-semibold ">Updates</div>
                      </div>
                      <table className="w-full text-sm text-left">
                        <tbody>
                          {item?.updates
                            ?.filter((ele) => ele.value)
                            ?.map((comment, commentIndex) => (
                              <tr
                                key={commentIndex}
                                className="border border-[#E4EDFF]"
                              >
                                <td className="p-2 border border-[#E4EDFF]">
                                  {comment.name}
                                </td>
                                <td className="p-2">{comment.value}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )} */}
                </div>
              </div>

              {/* Updated By */}
              <div className="col-span-1 flex items-center justify-start h-full text-left capitalize text-[#2260D9] text-[16px]">
                {item?.user_name || "---"}
                {item?.user_name
                  ? item?.user_role === "consumer"
                    ? `${userType === "consumer" ? "(You)" : "(Client)"}`
                    : item?.user_role === "agent"
                    ? `${userType === "agent" ? "(You)" : "(Agent)"}`
                    : item?.user_role === "partner"
                    ? `${userType === "partner" ? "(You)" : "(Partner)"}`
                    : `(${item?.user_role})${
                        userType === item?.user_role ? "You" : ""
                      }`
                  : ""}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RequestHistory;
