import React, { useEffect, useState } from "react";
import DataTable from "../../shared/DataGrid";
import SearchIcon from "../../assets/input-search-icon.svg";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getTotalRequestAgentById } from "../../services/agent";
import ViaIcon from "../../assets/via.svg";
import { useNavigate } from "react-router";
import moment from "moment";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import MoreIcon from "../../assets/more-icon.svg";
import {
  createCurrentRequest,
  getCurrentRequest,
} from "../../services/currentRequest";
const ManageRequest = () => {
  const { id } = useParams();
  const [actionTab, setActiveTab] = useState("all");
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    dispatch(getTotalRequestAgentById(id));
  }, [id]);
  const [pages, setPage] = useState({ page: 0, pageSize: 10 });
  const agentInfo = useSelector((state) => state.agentInfo.agentTotalRequest);

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  console.log({ agentInfo }, "Staff 18");

  const actionTabFilter = [
    {
      key: "all",
      name: "all",
    },
    {
      key: "new",
      name: "new",
    },
    {
      key: "in-progress",
      name: "in progress",
    },
    {
      key: "completed",
      name: "completed",
    },
    {
      key: "rejected",
      name: "rejected",
    },
  ];

  const requestColumns = [
    {
      field: "id",
      headerName: "Request ID",
      align: "center",
    },
    {
      field: "name",
      headerName: "Client Name",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Program",
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
    },
    {
      field: "via",
      headerName: "Via",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-3">
          <p>Via</p>
          <img src={ViaIcon} alt="via" />
        </div>
      ),
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "intakeDate",
      headerName: "Intake Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.intakeDate).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "submmitedDate",
      headerName: "Submitted Date & Time",
      flex: 2,
      renderCell: (params) => {
        console.log("params?.row", params?.row);
        return moment(params?.row?.submittedDate).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "level",
      headerName: "Level",
      flex: 1,
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        let color = "black";
        switch (params.value) {
          case "in progress":
            color = "#FFCD11";
            break;
          case "new":
            color = "#1D5BBF";
            break;
          case "on hold":
            color = "#E26313";
            break;
          case "rejected":
            color = "#DC3545";
            break;
          case "completed":
            color = "#278E1D";
            break;
          default:
            color = "black";
        }
        const capitalizeWords = (str) =>
          str
            .split(" ")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ");
        return <span style={{ color }}>{capitalizeWords(params.value)}</span>;
      },
    },
    {
      field: "response",
      headerName: "Partner Response",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        let color = "black";
        switch (params.value) {
          case "wait":
            color = "#1D5BBF";
            break;
          case "on-hold":
            color = "#E26313";
            break;
          case "rejected":
            color = "#DC3545";
            break;
          case "accepted":
            color = "#278E1D";
            break;
          default:
            color = "black";
        }
        const capitalizeWords = (str) =>
          str
            .split(" ")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ");
        return <span style={{ color }}>{capitalizeWords(params.value)}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,

      renderCell: (params) => {
        return (
          <Menu as="div" className="inline-block mt-2 text-left">
            <div>
              <MenuButton className="cursor-pointer relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900">
                <img
                  src={MoreIcon}
                  alt="MoreIcon"
                  onClick={(e) => handleClick(e, params.row)}
                />
              </MenuButton>
            </div>

            <MenuItems
              transition
              className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                <MenuItem
                  onClick={() => {
                    navigate(`/request-tracking`);
                  }}
                >
                  <a
                    href="#"
                    className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                  >
                    View
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      getCurrentRequest({
        request_via: "",
        status: "",
      })
    );
  }, [dispatch]);

  const currentData = useSelector((state) => state.currentRequest.currentData);

  const total = useSelector((state) => state.currentRequest.total_pages);

  const rows =
    currentData && currentData.length > 0
      ? currentData.map((item) => ({
          id: item.id,
          requestId: item.id,
          name: item.first_name,
          category: item.id,
          contact: item.contact,
          via: item.request_via,
          intakeDate: item.created_at,
          level: item.urgency_level,
          description: item.description,
          submittedDate: item.updated_at,
          status: item.request_status,
          response: item.partner_status,
        }))
      : [];

  const filteredRows =
    actionTab === "all"
      ? rows
      : rows.filter((data) => data?.status === actionTab.toLowerCase());
  const searchFilteredRows = filteredRows.filter((row) =>
    row.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div className="w-full h-full">
        <div className="flex items-center w-full">
          <h2 className="text-2xl font-medium text-[#353F4F]">
            Total Managed Request
          </h2>
        </div>
        <div className="py-2 px-4 border border-[#D9D9D9] mt-5 rounded-md">
          <div className="mt-5 details-info">
            <div
              className="flex items-center justify-between px-5 mb-8"
              style={{ borderBottom: "1px solid rgba(211, 211, 211, 1)" }}
            >
              <ul className="tab-list">
                {actionTabFilter?.map((discussion, index) => (
                  <li
                    className={`${
                      actionTab === discussion.name ? "active" : ""
                    } relative`}
                    key={index}
                    onClick={() => setActiveTab(discussion.name)}
                  >
                    <div className="flex justify-center pb-1">
                      <div
                        className={`total-proram-count relative ${
                          actionTab === discussion.key ? "active" : ""
                        }`}
                      >
                        {rows.filter((data) =>
                          discussion.name !== "all"
                            ? data?.status === discussion.name
                            : true
                        )?.length || 0}
                      </div>
                    </div>
                    <div className="text-[13px] capitalize">
                      {" "}
                      {`${discussion.name}`}
                    </div>
                    {actionTab === discussion.key && <span></span>}
                  </li>
                ))}
              </ul>
              <div className="relative">
                <input
                  type="text"
                  id="search-navbar"
                  className="block w-full p-2 text-sm text-gray-900 border-none focus:outline-none"
                  placeholder="Search here..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    border: "1px solid rgba(34, 96, 217, 1)",
                    height: "50px",
                    width: "200px",
                  }}
                />
                <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
                  <img src={SearchIcon} alt="SearchIcon" />
                </div>
              </div>
            </div>
          </div>
          <DataTable
            rows={searchFilteredRows || []}
            columns={requestColumns}
            hideCheckbox
            pages={pages}
            totalRows={total}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default ManageRequest;
