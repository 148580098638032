import React from "react";
import UserImage from "../../assets/images/user.png";
import PermissionIcon from "../../assets/permission-icon.svg";
import PermissionCheckIcon from "../../assets/permission-check-icon.svg";
import { useParams } from "react-router";

const Permission = ({ agentInfo }) => {
  const { id } = useParams;

  return (
    <div className="mb-20">
      <h2 className="text-[22px] font-medium text-[#353F4F]">Agent Profile</h2>

      <div className="grid grid-cols-12 items-center py-5 px-6 mt-6 border border-[#D9D9D9] rounded-md">
        <div className="flex items-center w-full col-span-2">
          <img
            className="rounded-full h-36 w-36 p-2 border border-[#D9D9D9]"
            src={UserImage}
            alt="UserImage"
          />
        </div>
        <div className="col-span-7 pl-8">
          <p className="text-[34px] font-medium text-[#353F4F]">
            {" "}
            {`${agentInfo?.first_name} ${agentInfo?.last_name}`}
          </p>
          <p className="text-base font-medium text-[#353F4F]">Agent</p>
        </div>
        <div className="flex items-center justify-end w-full h-full col-span-3 pr-3 font-medium text-base">
          {agentInfo?.status === "active" ? (
            <button
              className={`h-10 rounded-[30px] py-2 px-8 border border-[#35A462] text-[#35A462] bg-[#EDFFF4]`}
            >
              Active
            </button>
          ) : (
            <button
              className={`h-10 rounded-[30px] py-2 px-8 border border-[#DC3545] text-[#DC3545] bg-[#FFF3F4]`}
            >
              Inctive
            </button>
          )}
        </div>
      </div>
      <div className="text-[#A9A9A9] text-sm mt-12">
        Note: Permissions shown here are populated based on roles{" "}
      </div>
      <div className="mt-3">
        <table className="permission-table text-sm">
          <thead>
            <tr>
              <th>Name of the Permission</th>
              <th>Create</th>
              <th>View</th>
              <th>Edit</th>
              <th>Accept</th>
              <th>Reject</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Request</td>
              <td>
                <img src={PermissionCheckIcon} alt="PermissionCheckIcon" />
              </td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
            </tr>

            <tr>
              <td>Partner</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
            </tr>

            <tr>
              <td>Program</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
            </tr>

            <tr>
              <td>Profile</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
            </tr>

            <tr>
              <td>Password</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Permission;
