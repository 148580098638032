import React from "react";

const CustomStepper = ({ filteredSteps, activeStep }) => {
    return (
      <div className="relative">
        <div className="flex justify-between items-start">
          {filteredSteps.map((step, index) => {
            const isCurrentStep = index === Math.floor(activeStep);
            const nextFraction = isCurrentStep ? activeStep % 1 : 0;
  
            const stepSize = isCurrentStep
              ? 40 + nextFraction * 8 // Dynamically increase size
              : 40;
  
            const isLastStep = index === filteredSteps.length - 1;
  
            return (
              <div
                key={step.label}
                className="flex flex-col items-center relative flex-1"
              >
                {/* Connector Line */}
                {!isLastStep && (
                <div className="absolute top-4 left-1/2 w-full h-[6px] -translate-y-1/2 flex z-[1px]">
                  {/* Progressed part */}
                  <div
                    className="h-full bg-[#2260D9] rounded-full" // Blue line for completed section
                    style={{
                      width: `${Math.min(
                        Math.max(0, activeStep - index),
                        1
                      ) * 100}%`,
                      transition: "width 0.3s ease",
                    }}
                  />
                  {/* Remaining part */}
                  <div
                    className="h-full bg-[#C8DBFF]" // Light blue or gray line for remaining section
                    style={{
                      flexGrow: 1,
                    }}
                  />
                </div>
              )}
  
                {/* Step Circle and Label Container */}
                <div className="flex flex-col items-center relative">
                  {/* Step Circle */}
                  {index < activeStep || isCurrentStep ? (
                    <img
                      src={step.icon}
                      alt="completed"
                      className="h-8 w-8 z-20 relative"
                      style={{
                        width: stepSize,
                        height: stepSize,
                      }}
                    />
                  ) : (
                    <div
                      className={`flex items-center justify-center rounded-full bg-white border-[6px] ${
                        index < activeStep
                          ? "border-[#6888c4]"
                          : "border-[#C8DBFF]"
                      }`}
                      style={{
                        width: stepSize,
                        height: stepSize,
                      }}
                    ></div>
                  )}
  
                  {/* Step Label */}
                  <div className="mt-3 text-center">
                    <div className="text-sm font-medium text-slate-700">
                      {step.label}
                    </div>
                    <div className="mt-1 text-xs text-[#848484]">
                      {step.description}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  

export default CustomStepper;
