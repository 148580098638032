import { Box, Modal } from "@mui/material";
import DeleteIcon from "../../assets/delete.svg";

export default function ConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
  title,
}) {
    
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="meeting-modal-title"
      aria-describedby="meeting-modal-description"
    >
      <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-full max-w-[400px] border border-[#DC3545] p-3">
        <div className="flex flex-col items-center gap-4">
          <div className="h-10 w-10 rounded-full bg-red-50 flex items-center justify-center">
          <img src={DeleteIcon} alt="CloseIcon" height={40} width={40}/>
          </div>
          <p className="text-center text-[18px]">{title}</p>
        </div>

        <div className="flex gap-4 mt-5">
          <button
            onClick={onClose}
            className="flex-1 px-4 py-2 border border-gray-300 text-[16px] "
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="flex-1 px-4 py-2 bg-[#DC3545] text-white text-[16px]"
          >
            Delete
          </button>
        </div>
      </Box>
    </Modal>
  );
}
