import Cards from "./Cards";
import Grid from "./Grid";

function PartnerDashboard() {
  return (
    <main className="px-10 py-14 bg-[#F9F9F9]">
      <Cards />
      <Grid />
    </main>
  );
}

export default PartnerDashboard;
