import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useForm, Controller } from "react-hook-form";
import {
  AddNewCategoryFields,
  CategoryCheckbox,
  CategoryDescription,
  CategoryManagerDetailField,
  CategoryOrganizationField,
  categoryStatus,
  PartnerDescription,
  ProgramDescription,
} from "../../utills/formFields";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import {
  getAddNewCategory,
  getAllCategoryList,
  getCategoryById,
  updateCategory,
} from "../../services/category";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react";
import { getAllAgentList } from "../../services/agent";
import { useDispatch, useSelector } from "react-redux";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import SuccessDialog from "../SuccessDialog";
import BlueTickicon from "../../assets/images/bluetick-icon.jpg";
import GreenTickicon from "../../assets/images/greentick-icon.png";
import Dialog from "@mui/material/Dialog";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Autocomplete, TextField, Chip } from "@mui/material";
import AgentListPopup from "../Admin-DashBoard/AgentListPopup";
import PartnerListPopup from "../Admin-DashBoard/PartnerListPopup";

export default function AddNewCategory() {
  const [selectedType, setSelectedType] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [showCategoryDiv, setShowCategoryDiv] = useState(false);
  const [selectedUrgency, setSelectedUrgency] = useState("");
  const [showPriceInput, setShowPriceInput] = useState(false);
  const [programType, setProgramType] = useState("");
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [openAgentList, setOpenAgentList] = useState(false);
  const [openPartnerList, setOpenPartnerList] = useState(false);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [selectedAgentNames, setSelectedAgentNames] = useState([]);
  const [selectedPartnerNames, setSelectedPartnerNames] = useState([]);
  const [selectedPartnerIds, setSelectedPartnerIds] = useState([]);
  const [selectedAgentIds, setSelectedAgentIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isPremium, setIsPremium] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupIcon, setPopupIcon] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const categoryDetails = useSelector((state) => state.categoryInfo.details);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (id) {
      dispatch(getCategoryById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getAddNewCategory());
    dispatch(getAllAgentList());
    dispatch(getAllCategoryList());
  }, [dispatch]);

  useEffect(() => {
    if (id && categoryDetails) {
      reset({
        id: categoryDetails.id,
        program_title: categoryDetails.name,
        maximum_number_of_beneficiaries: categoryDetails.beneficiaries,
        program_type: categoryDetails.category_type,
        urgency_level: categoryDetails.urgency_level,
        category_manager: categoryDetails.category_manager,
        agent_name: categoryDetails.agent_name,
        agent_contact_details: categoryDetails.agent_contact_details,
        organization_name: categoryDetails.organization_name,
        organization_contact_details:
          categoryDetails.organization_contact_details,
        partner_ids: categoryDetails.partner_ids,
        description: categoryDetails.description,
        status: categoryDetails.status,
        is_premium: categoryDetails.is_premium,
        status: categoryDetails.status,
        reason:
          categoryDetails.status === "inactive" ? categoryDetails.reason : "",
      });
    }
  }, [categoryDetails, id, reset]);

  // const handleImageChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   const updatedFiles = [...imageFiles, ...files];
  //   setImageFiles(updatedFiles);
  // };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Only get the first file
    if (file) {
      setImageFiles([file]); // Set the state with just the selected file
    }
  };

  const handleDelete = (fileToDelete) => {
    setImageFiles(imageFiles.filter((file) => file !== fileToDelete));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    if (name === "program_type") {
      setSelectedType(value);
      if (value === "internal") {
        setShowCategoryDiv(true);
      } else {
        setShowCategoryDiv(false);
      }
    }
    if (name === "urgency_level") {
      setSelectedUrgency(value);
    }
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (value === "paid" && checked) {
      setIsPremium(checked);
      setSelectedOption("paid");
    } else if (value === "free" && checked) {
      setSelectedOption("free");
    }
    if (!checked) {
      setSelectedOption("");
    }
    if (value === "paid") {
      setShowPriceInput(checked);
    } else if (value === "free") {
      setShowPriceInput(false);
    }
  };

  const onSubmit = async (data) => {
    if (data.status === "inactive" && !data.reason) {
      console.error("Reason is required when deactivating the agent.");
      return;
    }
    console.log(data, "data");
    const editData = {
      id: data.id,
      name: data.program_title,
      beneficiaries: data.maximum_number_of_beneficiaries,
      category_type: data.program_type,
      urgency_level: data.urgency_level,
      category_manager: data.category_manager,
      agent_id: agentInfo?.find(
        (agent) => `${agent.first_name} ${agent.last_name}` === data?.agent_name
      )?.id,
      per_cost: data.price,
      program_description: data.program_description,
      agent_name: data.agent_name,
      agent_contact_details: data.agent_contact_details,
      organization_name: data.organization_name,
      organization_contact_details: data.organization_contact_details,
      partner_ids: selectedPartnerIds,
      description: data.description,
      status: data.status,
      is_premium: isPremium,
      partner_description: data.partner_description,
      status: data.status,
      // images: imageFiles,a
    };

    console.log("editdata", editData);
    try {
      if (id) {
        await dispatch(
          updateCategory({ id: id, updatedData: editData })
        ).unwrap();
        console.log("Category updated successfully.");
        setPopupMessage("Program updated successfully");
        setPopupIcon(GreenTickicon);
      } else {
        await dispatch(getAddNewCategory(editData)).unwrap();
        console.log("Category added successfully.");
        setPopupMessage("Program Created Successfully");
        setPopupIcon(BlueTickicon);
      }
      setOpen(true);
      setTimeout(() => {
        navigate("/allcategory");
        setOpen(false);
      }, 2000);
    } catch (error) {
      console.error("Error updating/adding category:", error);
    }
  };

  useEffect(() => {
    console.log(selectedAgentNames, "selectedAgentNames+++++");
    if (selectedAgentNames?.length > 0) {
      setValue("attendees", setSelectedPartnerIds);
      setValue("attendees", selectedAgentNames);
    }
  }, [setSelectedPartnerIds, selectedAgentNames]);

  useEffect(() => {
    setValue("assignPartner", selectedPartnerIds);
    setValue("assignPartner", selectedPartnerNames);
  }, [selectedPartnerIds, selectedPartnerNames]);

  console.log(selectedAgentNames);

  const categoryInfo = useSelector((state) => state.categoryInfo.data.data);
  // console.log("categoryinfo", categoryInfo);

  const agentInfo = useSelector((state) => state.agentInfo.details.data);
  // console.log("abd", agentInfo);

  const inputRules = {
    programStaff: {
      required: true,
    },
  };

  return (
    <>
      {/* <SuccessDialog open={open} onClose={() => setOpen(false)} /> */}
      <div className="px-8 pt-10 bg-[#F9F9F9]">
        <BreadCumbs
          firstCumbName="Dashboard"
          secoundCumbName="Program"
          pathToRedirect="allcategory"
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white custom-shadow  rounded-[5px] pt-8 my-10 px-3">
            {id && (
              <div className="flex flex-row w-full items-start gap-10  pl-8 justify-start ">
                <div className="flex  mb-4">
                  {categoryStatus.map((field, index) => {
                    return (
                      <div className="flex flex-col" key={index}>
                        <label
                          className="block tracking-wide text-xs font-normal"
                          htmlFor={field.label}
                        >
                          {field.label}{" "}
                          <span style={{ color: "red" }}>
                            {field?.inputRules?.required ? "*" : ""}
                          </span>
                        </label>
                        <div className="py-2 rounded-[5px] w-60">
                          {field.type === "dropdown" ? (
                            <>
                              <Controller
                                name={field.name}
                                control={control}
                                defaultValue={field.options[0]?.value || ""}
                                rules={{
                                  validate: (value) =>
                                    value === "inactive" && !getValues("reason")
                                      ? "Reason is required when status is 'inactive'."
                                      : true,
                                }}
                                render={({ field: controllerField }) => (
                                  <Listbox
                                    value={controllerField.value}
                                    onChange={(value) =>
                                      controllerField.onChange(value)
                                    }
                                  >
                                    <div className="relative">
                                      <ListboxButton
                                        className={`w-full flex justify-between items-center px-3 
                          focus-visible:outline-none font-semibold text-[14px] h-[60px] text-left border border-[#D9D9D9] bg-white rounded-[5px] ${
                            controllerField.value === "active"
                              ? "text-[#25861D]"
                              : controllerField.value === "inactive"
                              ? "text-[#D22735]"
                              : "text-gray-900"
                          }`}
                                      >
                                        <span className="flex items-center">
                                          {controllerField.value && (
                                            <span
                                              className={`w-3 h-3 rounded-full mr-2 ${
                                                controllerField.value ===
                                                "active"
                                                  ? "bg-[#25861D]"
                                                  : controllerField.value ===
                                                    "inactive"
                                                  ? "bg-[#D22735]"
                                                  : "bg-gray-400"
                                              }`}
                                            />
                                          )}
                                          {controllerField.value
                                            ? field.options.find(
                                                (option) =>
                                                  option.value ===
                                                  controllerField.value
                                              )?.name
                                            : "Select"}
                                        </span>
                                        <KeyboardArrowDownIcon
                                          sx={{ color: "#353F4F" }}
                                        />
                                      </ListboxButton>
                                      <ListboxOptions className="w-60 absolute z-10 mt-1 font-semibold bg-white rounded-md border border-[#D9D9D9] max-h-60 overflow-auto focus:outline-none">
                                        {field.options
                                          .filter(
                                            (option) =>
                                              option.value !==
                                              controllerField.value
                                          )
                                          .map((option, idx) => (
                                            <ListboxOption
                                              key={idx}
                                              value={option.value}
                                              className={({ active }) =>
                                                `cursor-pointer relative py-4 px-4 flex items-center ${
                                                  active
                                                    ? "bg-blue-600"
                                                    : option.value === "active"
                                                    ? "text-[#25861D]"
                                                    : option.value ===
                                                      "inactive"
                                                    ? "text-[#D22735]"
                                                    : "text-gray-900"
                                                }`
                                              }
                                            >
                                              <span
                                                className={`w-3 h-3 rounded-full mr-2 ${
                                                  option.value === "active"
                                                    ? "bg-[#25861D]"
                                                    : option.value ===
                                                      "inactive"
                                                    ? "bg-[#D22735]"
                                                    : "bg-gray-400"
                                                }`}
                                              />
                                              {option.name}
                                            </ListboxOption>
                                          ))}
                                      </ListboxOptions>
                                    </div>
                                  </Listbox>
                                )}
                              />
                              {errors[field.name] && (
                                <p className="error" role="alert">
                                  {errors[field.name].message}
                                </p>
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-col w-full md:w-1/2 gap-1.5">
                  {watch("status") === "inactive" && (
                    <>
                      <label
                        htmlFor="Comment"
                        className="text-[#353F4F] text-[12px] flex items-center"
                      >
                        Reason{" "}
                        <span className=" ml-1" style={{ color: "red" }}>
                          *
                        </span>
                      </label>
                      <textarea
                        id="reason"
                        name="reason"
                        rows={6}
                        {...register("reason", {
                          required:
                            "Reason is required when deactivating the agent.",
                        })}
                        className="block w-full rounded-md h-[60px] bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-[#D6E5EE] placeholder:text-gray-400 focus:outline-2 focus:outline-indigo-600 sm:text-sm"
                        placeholder="Enter your reason for changing the status"
                      />
                      {errors.reason && (
                        <p className="error" role="alert">
                          {errors.reason.message}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}

            <div>
              {!id && (
                <div className="border-b border-[#E4E6EF] font-medium text-[#2260D9] text-base pl-6 pb-3">
                  Add New Program
                </div>
              )}
              <div className="flex flex-wrap gap-4 px-8 pt-10 ">
                {AddNewCategoryFields.map((field, index) => {
                  return (
                    <div className={`relative mb-6 ${field.width}`} key={index}>
                      <label
                        className="block tracking-wide text-[#353F4F] text-xs font-normal mb-2"
                        htmlFor={field.label}
                      >
                        {field.label}{" "}
                        <span style={{ color: "red" }}>
                          {field?.inputRules?.required ? "*" : ""}
                        </span>
                      </label>
                      {field.type === "input" ? (
                        <div className="relative">
                          <input
                            {...register(field.name, field.inputRules)}
                            type={field.fieldType}
                            className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]"
                            placeholder={field.placeholder}
                            style={{
                              color: "#232323",
                              borderRadius: "3px",
                            }}
                            aria-invalid={!!errors[field.name]}
                          />
                          {errors[field.name] && (
                            <p className="error" role="alert">
                              {errors[field.name].message}
                            </p>
                          )}
                        </div>
                      ) : field.type === "dropdown" ? (
                        <div>
                          <Controller
                            name={field.name}
                            control={control}
                            rules={field.inputRules}
                            render={({ field: { value, onChange } }) => (
                              <Listbox
                                value={value}
                                onChange={(selectedValue) => {
                                  onChange(selectedValue);
                                  if (field.name === "program_type") {
                                    setProgramType(selectedValue);
                                  }
                                }}
                              >
                                {({ open }) => (
                                  <div className="relative">
                                    <ListboxButton
                                      className={`w-full flex justify-between items-center px-3 py-[0.32rem] leading-[2.15] input-bg text-sm h-[60px] rounded-[3px] ${
                                        value
                                          ? "text-[#232323]"
                                          : "text-[#9ca3af]"
                                      }`}
                                    >
                                      {value
                                        ? field.options.find(
                                            (option) => option.value === value
                                          )?.name
                                        : field.placeholder}
                                      {open ? (
                                        <KeyboardArrowUpIcon
                                          style={{ color: "black" }}
                                        />
                                      ) : (
                                        <KeyboardArrowDownIcon
                                          style={{ color: "black" }}
                                        />
                                      )}
                                    </ListboxButton>
                                    {open && (
                                      <ListboxOptions className="absolute z-10 mt-1 w-full bg-white border border-[#BCBCBC] rounded-[3px] divide-y divide-[#D3D3D3]">
                                        {field.options.map((option, index) => (
                                          <ListboxOption
                                            key={index}
                                            value={option.value}
                                            className={({ active }) =>
                                              `cursor-pointer px-3 py-5 text-black text-sm ${
                                                active ? "bg-[#bfdbfe]" : ""
                                              }`
                                            }
                                          >
                                            {option.name}
                                          </ListboxOption>
                                        ))}
                                      </ListboxOptions>
                                    )}
                                  </div>
                                )}
                              </Listbox>
                            )}
                          />
                          {errors[field.name] && (
                            <p
                              className="error mt-1"
                              role="alert"
                            >
                              {errors[field.name].message}
                            </p>
                          )}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
              {/* {programType === "internal" && ( */}
              <div className="px-8 mt-4 flex flex-col gap-6">
                <div className="flex flex-wrap gap-4 justify-between w-full">
                  <div>
                    {CategoryCheckbox.map((field, index) => (
                      <div key={index}>
                        <label className="block text-[#353F4F] text-xs font-normal mb-2">
                          {field.label}{" "}
                          {/* <span style={{ color: "#FF0000" }}>
                            {field?.inputRules?.required ? "*" : ""}
                          </span> */}
                        </label>
                        {/* Render Checkboxes */}
                        {field.type === "checkbox" && (
                          <div className="flex flex-wrap gap-4">
                            {field.options.map((option) => (
                              <div
                                key={option.value}
                                className="flex items-center"
                              >
                                <input
                                  type="checkbox"
                                  id={option.value}
                                  name={field.name}
                                  value={option.value}
                                  checked={selectedOption.includes(
                                    option.value
                                  )}
                                  onChange={handleCheckboxChange}
                                  className="mr-2"
                                />
                                <label
                                  htmlFor={option.value}
                                  className="text-sm text-[#353F4F]"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  {showPriceInput && (
                    <div className="w-[50%]">
                      <label
                        htmlFor="price"
                        className="block text-[#353F4F] text-xs font-normal mb-2"
                      >
                        Price <span style={{ color: "#FF0000" }}>*</span>
                      </label>

                      <div className="relative">
                        <input
                          {...register("price", {
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]+(\.[0-9]{1,2})?$/,
                              message: "Please enter a valid price",
                            },
                          })}
                          type="text"
                          id="price"
                          name="price"
                          className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder="$"
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                          }}
                          aria-invalid={!!errors.price}
                        />

                        {errors.price && (
                          <p className="error" role="alert">
                            {errors.price.message}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  {ProgramDescription.map((field) => {
                    return (
                      <div key={field.name} className="pt-4">
                        {" "}
                        {/* Add a key for each mapped element and margin-bottom for spacing */}
                        <label
                          htmlFor={field.name}
                          className="block text-xs  text-[#353F4F] mb-2"
                        >
                          {field.label}{" "}
                          <span style={{ color: "#FF0000" }}>
                            {field?.inputRules?.required ? "*" : ""}
                          </span>
                        </label>
                        <textarea
                          id={field.name}
                          rows="4"
                          className={`block p-2.5 input-bg w-full text-sm text-gray-900 
                        focus-visible:outline-none focus-visible:border-none`}
                          style={{ borderRadius: "3px" }}
                          placeholder={field.placeholder}
                          {...register(field.name, field.inputRules)} // assuming `register` is passed from `useForm`
                        ></textarea>
                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}{" "}
                            {/* Display validation errors */}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="w-full mt-4">
                  <label className="block mb-2 text-xs font-normal tracking-wide text-[#353F4F]">
                    Program Logo (Optional)
                  </label>
                  <div className="relative flex justify-between items-center w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]">
                    <Stack direction="row" spacing={1}>
                      {imageFiles.map((file, index) => (
                        <Chip
                          key={index}
                          label={file.name}
                          onDelete={() => handleDelete(file)}
                          sx={{
                            backgroundColor: "#D3E2FF",
                            color: "#3E3E3E",
                            borderRadius: "11px",
                            ".MuiChip-deleteIcon": {
                              color: "#E34D30",
                            },
                          }}
                        />
                      ))}
                    </Stack>
                    <input
                      type="file"
                      accept="image/*"
                      name="image"
                      className={`bg-transparent w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px]`}
                      id="image-upload"
                      style={{
                        color: "#232323",
                        borderRadius: "3px",
                        display: "none",
                      }}
                      onChange={handleImageChange}
                    />
                    <button
                      className="bg-[#2260D9] text-white rounded-[2px] p-2"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        document.getElementById("image-upload").click();
                      }}
                    >
                      <AddIcon className="" />
                      Upload Image
                    </button>
                  </div>
                </div>
              </div>
              {/* )} */}
            </div>
            <div className="pt-14">
              <>
                {/* {programType === "internal" && ( */}
                <div className="pl-6 pb-3 font-medium text-[#2260D9] text-base border-b border-[#E4E6EF]">
                  Program Agent Details
                </div>
                {/* )} */}
              </>

              {/* {programType === "internal" && ( */}
              <>
                <div className="px-8 pt-10">
                  <label className="block text-[#353F4F] text-[14px] mb-1.5">
                    Program Agent <span className="text-[#FF0000]">*</span>
                  </label>
                  <div className="relative ">
                    <Controller
                      name="attendees"
                      control={control}
                      defaultValue={[]}
                      rules={{
                        required: "Attendees are required",
                        validate: (value) =>
                          Array.isArray(value) &&
                          value.every((id) => typeof id === "string"),
                      }}
                      render={({ field }) => (
                        <div
                          onClick={() => setOpenAgentList(true)}
                          style={{
                            height: "60px",
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            padding: "8px",
                            borderRadius: "4px",
                            backgroundColor: "#F1F6FF",
                          }}
                        >
                          {field.value.map((chip, index) => (
                            <Chip
                              key={index}
                              variant="outlined"
                              label={chip}
                              // onDelete={() => {
                              //   const updatedValue = field.value.filter(
                              //     (_, i) => i !== index
                              //   );
                              //   field.onChange(updatedValue);
                              // }}
                              onDelete={() => {
                                  const deletedId = selectedAgentIds[index];
                                  const updatedValue = field.value.filter(
                                    (_, i) => i !== index
                                  );
                                  field.onChange(updatedValue);

                                  setSelectedAgentIds((prev) =>
                                    prev.filter((id) => id !== deletedId)
                                  );
                                  setSelectedAgentNames((prev) =>
                                    prev.filter((name) => name !== chip)
                                  );
                                 console.log('val',updatedValue)
                                }}
                              sx={{
                                margin: "4px",
                                background: "#D3E2FF",
                                color: "#3E3E3E",
                                border: "none",
                                borderRadius: "11px",
                                "& .MuiChip-deleteIcon": {
                                  color: "#E34D30",
                                },
                              }}
                            />
                          ))}
                        </div>
                      )}
                    />

                    <span
                      className="h-6 w-6 text-[20px] text-[#2260D9] border border-dashed border-[#2260D9] rounded-sm absolute flex items-center justify-center 
               right-2 top-1/2 transform -translate-y-1/2  cursor-pointer"
                      onClick={() => setOpenAgentList(true)}
                      style={{
                        zIndex: 2, // Ensures the "+" icon is above everything else
                        backgroundColor: "#FFFFFF", // Adds a background to prevent interaction issues
                      }}
                    >
                      +
                    </span>
                  </div>
                </div>

                <div>
                  {CategoryDescription.map((field) => {
                    return (
                      <div key={field.name} className="mb-6 px-8 pt-4 mt-6">
                        {" "}
                        {/* Add a key for each mapped element and margin-bottom for spacing */}
                        <label
                          htmlFor={field.name}
                          className="block text-xs  text-[#353F4F] mb-2"
                        >
                          {field.label}{" "}
                          <span style={{ color: "#FF0000" }}>
                            {field?.inputRules?.required ? "*" : ""}
                          </span>
                        </label>
                        <textarea
                          id={field.name}
                          rows="4"
                          className={`block p-2.5 input-bg w-full text-sm text-gray-900 
                        focus-visible:outline-none focus-visible:border-none`}
                          style={{ borderRadius: "3px" }}
                          placeholder={field.placeholder}
                          {...register(field.name, field.inputRules)} // assuming `register` is passed from `useForm`
                        ></textarea>
                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}{" "}
                            {/* Display validation errors */}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="pl-6 pb-3  pt-14 font-medium text-[#2260D9] text-base border-b border-[#E4E6EF]">
                  Program Partner Details
                </div>

                <div className="px-8 pt-10">
                  <label className="block text-[#353F4F] text-[14px] mb-1">
                    Assign Partners <span className="text-[#FF0000]">*</span>
                  </label>
                  <div className="relative">
                    <Controller
                      name="assignPartner"
                      control={control}
                      defaultValue={[]}
                      rules={{
                        required: "Assign Request are required",
                        validate: (value) => {
                          console.log({ value });
                          return value
                            .map((id) => id?.id?.trim() || id?.trim())
                            .every((id) => typeof id === "string");
                        },
                      }}
                      render={({ field }) => {
                        console.log({ field });
                        return (
                          <div
                            onClick={() => setOpenPartnerList(true)}
                            style={{
                              height: "60px",
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              padding: "8px",
                              borderRadius: "4px",
                              backgroundColor: "#F1F6FF",
                            }}
                          >
                            {field.value.map((chip, index) => (
                              <Chip
                                key={index}
                                variant="outlined"
                                label={chip}
                                onDelete={() => {
                                  const deletedId = selectedPartnerIds[index];
                                  const updatedValue = field.value.filter(
                                    (_, i) => i !== index
                                  );
                                  field.onChange(updatedValue);

                                  setSelectedPartnerIds((prev) =>
                                    prev.filter((id) => id !== deletedId)
                                  );
                                  setSelectedPartnerNames((prev) =>
                                    prev.filter((name) => name !== chip)
                                  );

                                }}
                                sx={{
                                  margin: "4px",
                                  background: "#D3E2FF",
                                  color: "#3E3E3E",
                                  border: "none",
                                  borderRadius: "11px",
                                  "& .MuiChip-deleteIcon": {
                                    color: "#E34D30",
                                  },
                                }}
                              />
                            ))}
                          </div>
                        );
                      }}
                    />

                    <span
                      className="h-6 w-6 text-[20px] text-[#2260D9] border border-dashed border-[#2260D9] rounded-sm absolute flex items-center justify-center 
               right-2 top-1/2 transform -translate-y-1/2  cursor-pointer"
                      onClick={() => setOpenPartnerList(true)}
                      style={{
                        zIndex: 2, // Ensures the "+" icon is above everything else
                        backgroundColor: "#FFFFFF", // Adds a background to prevent interaction issues
                      }}
                    >
                      +
                    </span>
                  </div>
                </div>

                <div className="mt-6">
                  {PartnerDescription.map((field) => {
                    return (
                      <div key={field.name} className="mb-6 px-8 pt-4">
                        {" "}
                        <label
                          htmlFor={field.name}
                          className="block text-xs  text-[#353F4F] mb-2"
                        >
                          {field.label}{" "}
                          <span style={{ color: "#FF0000" }}>
                            {field?.inputRules?.required ? "*" : ""}
                          </span>
                        </label>
                        <textarea
                          id={field.name}
                          rows="4"
                          className={`block p-2.5 input-bg w-full text-sm text-gray-900 
                        focus-visible:outline-none focus-visible:border-none`}
                          style={{ borderRadius: "3px" }}
                          placeholder={field.placeholder}
                          {...register(field.name, field.inputRules)} // assuming `register` is passed from `useForm`
                        ></textarea>
                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}{" "}
                            {/* Display validation errors */}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </>
              {/* )} */}

              <div className="flex w-full justify-center gap-10 mt-16 pb-20">
                <button className="border-[#353F4F] border-[1.3px] text-[#353F4F] rounded-[3.9px] w-[195px] h-[45px]">
                  Cancel
                </button>
                <button
                  type="submit"
                  className="rounded-[3.9px] w-[195px] h-[45px] bg-[#2260D9] text-white"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            "& .MuiPaper-root": {
              boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
              borderRadius: "10px",
            },
          }}
        >
          <div className="flex flex-col px-16 py-8 items-center gap-5">
            <img src={popupIcon} alt="Tick Animation" width="61" height="80" />
            <div className="text-[#2260D9] font-medium text-base">
              {popupMessage}
            </div>
          </div>
        </Dialog>
      )}

      <AgentListPopup
        open={openAgentList}
        onClose={() => setOpenAgentList(false)}
        setSelectedAgents={setSelectedAgents}
        setSelectedAgentNames={setSelectedAgentNames}
        selectedAgentNames={selectedAgentNames}
        selectedAgentIds={selectedAgentIds}
        setSelectedAgentIds={setSelectedAgentIds}
        // setSelectedRows={setSelectedRows}
        // selectedRows={selectedRows}
      />
      <PartnerListPopup
        open={openPartnerList}
        onClose={() => setOpenPartnerList(false)}
        setSelectedPartners={setSelectedPartners}
        setSelectedPartnerNames={setSelectedPartnerNames}
        selectedPartnerNames={selectedPartnerNames}
        selectedPartnerIds={selectedPartnerIds}
        setSelectedPartnerIds={setSelectedPartnerIds}
      />
    </>
  );
}
