import React, { useCallback, useEffect, useState } from "react";
import FileIcon from "../../assets/file-white-icon.svg";
import PhoneIcon from "../../assets/phone-green.icon.svg";
import DoubleArrowDownIcon from "../../assets/double-down-arrow-blue.svg";
import AvatarGirlIcon from "../../assets/avatar-lady-icon.svg";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { relatedCategories } from "../../services/consumerCategory";
import GoogleIcon from "../../assets/google-icon.svg";
import FacebookIcon from "../../assets/facebook-icon.svg";
import InstagramIcon from "../../assets/instagram-icon.svg";
import EmployeeEdit from "../../assets/edit.svg";
import EmailIcon from "../../assets/email-icon.svg";
import { LoginSocialGoogle } from "reactjs-social-login";
import MuiModal from "../../shared/Modal";
import { useForm } from "react-hook-form";
import {
  facebookLogin,
  loginSocialMedia,
  loginWithPhoneNumber,
  updateUserInfo,
} from "../../services/user";
import AddOtpLogin from "../../components/Popups/AddOtpLogin";
import { getUserType } from "../../services/userType";
import { userApiResponse } from "../../constants/apiResponse";

export default function ConsumerView() {
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useNavigate();
  const { details } = useSelector((state) => state.categoryInfo);
  const { data, status, userType } = useSelector((state) => state.userInfo);
  const [loginModal, setLoginModal] = useState();
  const [openOtp, setOpenOtp] = useState(false);
  const [number, setNumber] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(relatedCategories({ id: details.id, limit: 5 }));
  }, [dispatch]);

  console.log({ userType });
  const handleCloseLoginModal = () => {
    reset();
    setLoginModal(false);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const relatedCategory = useSelector(
    (state) => state.consumerCategoryInfo.relatedCategories.data
  );
  const onLogoutFailure = useCallback(() => {
    alert("logout fail");
  }, []);
  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);
  const onLogoutSuccess = useCallback(() => {
    alert("logout success");
  }, []);
  const onLoginSuccess = (data) => {
    console.log("Google login", data);
    if (
      data &&
      Object.keys(data).length &&
      data.hasOwnProperty("name") &&
      data.hasOwnProperty("email")
    ) {
      let l = { email: data.email };
      console.log("Login", l);
      dispatch(loginSocialMedia(l)).then(() => {
        navigate(
          `/create-request?name=${details.name}&money=${details?.per_cost}&id=${details.id}`
        );
      });
    }
  };
  const handleFacebookLogin = (open) => {
    window.FB.login(
      (response) => {
        if (response.status === "connected") {
          console.log("Logged in:", response);
          const accessToken = response.authResponse.accessToken;
          dispatch(facebookLogin(accessToken)).then(() => {
            setLoginModal(false);
            dispatch(getUserType());
            console.log({  open });
            if (userType === 'consumer' && open === "myRequest") {
              navigate(
                `/create-request?name=${details.name}&money=${details?.per_cost}&id=${details.id}`
              );
            } else {
              navigate("/");
            }
          });
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "public_profile,email" }
    );
  };
  const handleOtpClick = () => {
    setOpenOtp(true);
  };
  const onSubmit = (data) => {
    dispatch(
      loginWithPhoneNumber({ phone_number: `+91${data.phone_number}` })
    ).then(() => {
      handleOtpClick();
      setNumber(data.phone_number);
    });
  };
  useEffect(() => {
    if (status === userApiResponse.loggin) {
      handleCloseLoginModal();
      dispatch(updateUserInfo({ status: "" }));
    }
  }, [status]);

  useEffect(() => {
    dispatch(getUserType());
  }, [dispatch]);

  return (
    <>
      {showSuccess && (
        <MuiModal
          modalSize="sm"
          modalOpen={showSuccess}
          modalClose={() => setShowSuccess(false)}
          noheader
        >
          <div className="flex items-center justify-center p-4">
            <p className="text-primary-green font-medium text-[18px]">
              Call Center/ Contact Center API
            </p>
          </div>
        </MuiModal>
      )}
      <div className="grid grid-cols-6 gap-5 py-5">
        <div className="col-span-4">
          <div
            className="px-10 py-10 mb-5"
            style={{
              // boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
              border: "1px solid #E4EDFF",
              borderRadius: "5px",
            }}
          >
            <div className="flex items-start justify-between gap-4">
              <img
                src={`https://crrmsbackend.dataterrain-dev.net${details.image}`}
                alt="FileIcon"
              />
              <div className="flex flex-col w-full">
                <div className="flex justify-between w-full items-center">
                  <div>
                    <div className="flex">
                      <p className="text-[40px] font-bold capitalize">
                        {details.name}
                      </p>
                      {/* {details.is_premium === true ? (
                        <div
                          className="inline-flex items-center px-4 py-2 rounded-lg h-8 ml-6"
                          style={{ borderColor: "#D1A639", borderWidth: "1px" }}
                        >
                          <span className="text-sm font-medium">
                            {details.per_cost}
                            <span className="ml-1" style={{ color: "#D1A639" }}>
                              $
                            </span>
                          </span>
                        </div>
                      ) : null} */}
                    </div>

                    {details.is_premium === true ? (
                      <span
                        className="text-[12px]"
                        style={{ color: "#D1A639" }}
                      >
                        $ {details.per_cost}
                      </span>
                    ) : null}
                  </div>

                  <div className="flex gap-3">
                    <button
                      className="bg-primary-green font-semibold text-white text-[16px] flex gap-3 justify-center items-center py-2 h-[55px] px-5 w-[175px]"
                      style={{ borderRadius: "3px" }}
                      onClick={() => {
                        setShowSuccess(true);
                      }}
                    >
                      <img src={PhoneIcon} alt="PhoneIcon" />
                      <span>Call</span>
                    </button>
                    <button
                      className="bg-primary-blue text-white font-semibold flex gap-3 text-[16px] justify-center items-center py-2 h-[55px] px-5 w-[175px]"
                      style={{ borderRadius: "3px" }}
                      onClick={() => {
                        console.log(data, 434343);
                        Object.keys(data).length > 0
                          ? details?.per_cost
                            ? navigate(
                                `/create-request?name=${details.name}&money=${details?.per_cost}`
                              )
                            : navigate(`/create-request?name=${details.name}`)
                          : setLoginModal(true);
                      }}
                    >
                      <img src={FileIcon} alt="FileIcon" />
                      <span> Request</span>
                    </button>
                  </div>
                </div>
                <div className="pt-7">{details.description}</div>
              </div>
            </div>

            <div
              style={{ border: "1px solid rgba(228, 237, 255, 1)" }}
              className="px-6 mt-10 py-7 post-comments"
            >
              <h3 className="text-[18px]">Comments(02)</h3>

              <div className="relative add-comments">
                <img src={AvatarGirlIcon} alt="UserIcon" />
                <input
                  className="comment-input"
                  type="text"
                  value={""}
                  placeholder="Add Comment..."
                  onChange={undefined}
                />
              </div>

              <div class="flex space-x-4 justify-end">
                <button class="px-4 py-2 text-gray-800 border rounded text-[14px] font-medium">
                  Cancel
                </button>
                <button class="px-4 py-2 bg-primary-blue text-white rounded text-[14px] font-medium">
                  Comment
                </button>
              </div>

              <div>
                <div className="post-list-comments">
                  <img
                    className="user-img"
                    src={AvatarGirlIcon}
                    alt="Userimage"
                  />
                  <div
                    style={{ width: "calc(100% - 50px)", paddingLeft: "12px" }}
                  >
                    <div className="flex items-center justify-start gap-3">
                      <p className="text-[14px] capitalize">
                        <span>Johnson</span>
                      </p>
                      <p className="text-[10px]">2months ago</p>
                    </div>
                    <div className="py-2 my-2 text-[13px]">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </div>
                  </div>
                </div>

                <div className="post-list-comments">
                  <img
                    className="user-img"
                    src={AvatarGirlIcon}
                    alt="Userimage"
                  />
                  <div
                    style={{ width: "calc(100% - 50px)", paddingLeft: "12px" }}
                  >
                    <div className="flex items-center justify-start gap-3">
                      <p className="text-[14px] capitalize">
                        <span>Johnson</span>
                      </p>
                      <p className="text-[10px]">2months ago</p>
                      <img src={EmployeeEdit} alt="EmployeeEdit" />
                    </div>
                    <div className="py-2 my-2 text-[13px]">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2">
          <div
            className="mb-5"
            style={{
              borderRadius: "5px",
              border: "1px solid #E4EDFF",
            }}
          >
            <div
              style={{ borderBottom: "1px solid rgba(228, 237, 255, 1)" }}
              className="flex items-center justify-between py-5 px-7"
            >
              <p className="text-[20px]">Related Programs</p>
              <button
                className="px-3 py-2 bg-secondary-blue text-primary-blue"
                style={{ borderRadius: "3px" }}
                onClick={() => navigate("/category")}
              >
                View More
              </button>
            </div>
            <div
              className="grid gap-2 h-full overflow-hidden"
              style={{
                display: "grid",
                gridTemplateRows: "repeat(5, auto)", // 5 rows of automatic height
                gridTemplateColumns: "1fr", // 1 column
              }}
            >
              {relatedCategory &&
                (relatedCategory || [])?.map((i) => {
                  return (
                    <div
                      className="flex px-6 py-4 gap-9"
                      key={i}
                      style={{
                        borderBottom: "1px solid rgba(228, 237, 255, 1)",
                      }}
                    >
                      <div className="bg-secondary-blue flex justify-center items-center min-w-[150px]">
                        <img
                          src={`https://crrmsbackend.dataterrain-dev.net${i.image}`}
                          alt="icon"
                          width={60}
                          height={40}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <p className="text-[20px] font-semibold capitalize">
                          {i.name}
                        </p>
                        <p className="text-[14px] font-medium line-clamp-1">
                          {i.description}
                        </p>
                        <button
                          className="bg-primary-blue text-white text-[14px] font-medium py-2 px-2 max-w-[120px] cursor-pointer"
                          style={{ borderRadius: "3px" }}
                          onClick={() => navigate(`/category/${i.id}`)}
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <MuiModal
          modalSize="md"
          modalOpen={loginModal}
          modalClose={handleCloseLoginModal}
          noheader
        >
          {openOtp ? (
            <AddOtpLogin
              number={number}
              setNumber={setNumber}
              setOpenOtp={setOpenOtp}
              handleCloseLoginModal={handleCloseLoginModal}
              urlPath={`/create-request?name=${details.name}&money=${details?.per_cost}`}
            />
          ) : (
            <>
              <div className="flex flex-col items-center py-7">
                <h5 className="text-primary-blue text-[26px] text-center font-medium">
                  CRRMS
                </h5>
                <p className="text-primary-black text-[18px] py-4">
                  Welcome to the Request Management System
                </p>
                <p className="text-primary-black text-[16px] pb-6">
                  {/* Login for a seamless experience */}
                </p>
                <div className="flex gap-4">
                  <LoginSocialGoogle
                    client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    onLogoutFailure={onLogoutFailure}
                    onLoginStart={onLoginStart}
                    onLogoutSuccess={onLogoutSuccess}
                    scope="openid profile email"
                    onResolve={(e) => {
                      onLoginSuccess(e.data);
                    }}
                    onReject={(err) => {
                      console.log("hbhbdhd", err);
                    }}
                  >
                    <div
                      className="px-6 py-3 cursor-pointer"
                      style={{
                        border: "0.5px solid rgba(34, 96, 217, 0.5)",
                        borderRadius: "3px",
                      }}
                    >
                      <img src={GoogleIcon} alt="GoogleIcon" />
                    </div>
                  </LoginSocialGoogle>
                  <div
                    className="px-6 py-3 cursor-pointer"
                    style={{
                      border: "0.5px solid rgba(34, 96, 217, 0.5)",
                      borderRadius: "3px",
                    }}
                  >
                    <img src={InstagramIcon} alt="InstagramIcon" />
                  </div>
                  <div
                    onClick={() => handleFacebookLogin("myRequest")}
                    className="px-6 py-3 cursor-pointer"
                    style={{
                      border: "0.5px solid rgba(34, 96, 217, 0.5)",
                      borderRadius: "3px",
                    }}
                  >
                    <img src={FacebookIcon} alt="FacebookIcon" />
                  </div>
                  {/* <div
              className="px-6 py-3 cursor-pointer"
              style={{
                border: "0.5px solid rgba(34, 96, 217, 0.5)",
                borderRadius: "3px",
              }}
            >
              <img src={EmailIcon} alt="EmailIcon" />
            </div> */}
                </div>
                <div
                  className="mb-8 mt-8 flex items-center before:mt-0.5 before:flex-1 before:border-t
                     before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t
                      after:border-neutral-300 dark:before:border-neutral-500
                       dark:after:border-neutral-500"
                  style={{ color: "rgba(183, 185, 189, 1)", width: "50%" }}
                >
                  <p
                    className="mx-4 mb-0 font-semibold text-center dark:text-neutral-200"
                    style={{
                      color: "rgba(183, 185, 189, 1)",
                      border: "1px solid rgba(183, 185, 189, 1)",
                      padding: "2px 20px",
                      borderRadius: "20px",
                      fontSize: "14px",
                    }}
                  >
                    OR
                  </p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="w-[50%]">
                  <div className="flex flex-wrap gap-2">
                    <div className="relative w-full mb-6">
                      <input
                        type="tel"
                        className={`w-full rounded px-3 py-[0.32rem] text-[14px] leading-[2.15] focus:outline-none h-[60px] ${
                          errors["phone_number"]
                            ? "focus:border-teal focus:outline-none focus:ring-0"
                            : ""
                        }`}
                        placeholder="Ex: (555) 555-1234"
                        style={{
                          color: "#232323",
                          border: `1px solid ${
                            errors["phone_number"]
                              ? "rgb(239 68 68)"
                              : "rgba(34, 96, 217, 1)"
                          }`,
                        }}
                        {...register("phone_number", {
                          required: "This field is required",
                          pattern: {
                            value: /^\(\d{3}\) \d{3}-\d{4}$/,
                            message:
                              "Please Enter Valid Mobile Number in (415) 555-1234 format",
                          },
                        })}
                        aria-invalid={errors["phone_number"] ? "true" : "false"}
                        onChange={(e) => {
                          let input = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
                          let formatted = "";

                          if (input.length > 0)
                            formatted = `(${input.substring(0, 3)}`;
                          if (input.length > 3)
                            formatted += `) ${input.substring(3, 6)}`;
                          if (input.length > 6)
                            formatted += `-${input.substring(6, 10)}`;
                          e.target.value = formatted;
                        }}
                      />
                      {errors["phone_number"] && (
                        <p className="error" role="alert">
                          {errors["phone_number"].message}
                        </p>
                      )}
                    </div>

                    <div className="relative w-full">
                      <button
                        type="submit"
                        className="inline-block w-full py-4 text-sm font-medium text-white rounded px-7 bg-primary-blue "
                        data-twe-ripple-init
                        data-twe-ripple-color="light"
                      >
                        Login with OTP
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </MuiModal>
      </div>
    </>
  );
}
