import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequestAdmin } from "../../services/adminRequest";
import TopAgentList from "./TopAgentList";
import CurrentRequest from "./CurrentRequest";
import TopPartnerList from "./TopPartenerList";
import Chart from "./LineChart";
import { useNavigate } from "react-router";
import { getUserType } from "../../services/userType";
import moment from "moment";

const AdminDashBoard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const requestData = useSelector((state) => state.admin.details);
  const userData = useSelector((state) => state.userTypeData.details);

  const [time, setTime] = useState(moment().format("hh:mm A"));
  const [date, setDate] = useState(moment().format("MM-DD-YYYY"));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().format("hh:mm A"));
      setDate(moment().format("MM-DD-YYYY"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(getRequestAdmin());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserType());
  }, [dispatch]);

  return (
    <div className="bg-[#F9F9F9] p-10 flex flex-col gap-6">
      <div className="grid grid-cols-11 gap-6">
        <div className="col-span-3 custom-shadow rounded-[5px]">
          <div className="flex flex-col justify-center h-full gap-2.5 px-8">
            <p className="font-semibold text-[#1D5BBF] text-[33px] capitalize">
              Welcome, {userData.username}
            </p>
            <div className="flex text-[#353F4F] font-medium text-xl py-2">
              <p className="border-r border-[#353F4F] pr-4 capitalize">
                {time}
              </p>
              <p className="pl-4">{date}</p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-4 col-span-8 gap-6">
          <div
            className={`flex flex-col px-4 border cursor-pointer rounded ${"border-[#1C65F3]"} ${"bg-[#ECF2FF]"}`}
          >
            <div className="flex flex-col rounded-[5px] gap-3">
              <div
                className="flex items-center justify-between gap-[13px] border-b border-[#D9D9D9] font-medium pt-8 pb-4"
                onClick={() => navigate(`/agentrequest?status=${""}`)}
              >
                <p className="text-[#353F4F] text-lg capitalize">
                  Total Request
                </p>
                <div className="w-[6rem]">
                  <p className="bg-[#596A82] text-white text-[36px] rounded text-center w-full py-1">
                    {requestData?.requests?.reduce(
                      (acc, curr) => curr?.request_count + acc,
                      0
                    )}
                  </p>
                </div>
              </div>
              <div className="text-xs pb-[7px]">
                <p>
                  Metrics: <span className="text-[#2260D9]">Current Month</span>
                </p>
              </div>
            </div>
          </div>
          {requestData?.requests && requestData?.requests.length > 0 ? (
            requestData?.requests.map((item, index) => (
              <div
                key={index}
                className={`flex flex-col px-4 border cursor-pointer rounded ${
                  index === 0
                    ? "border-[#17900B]"
                    : index === 1
                    ? "border-[#FFD500]"
                    : index === 2
                    ? "border-[#FF7B00]"
                    : "border-[#FF7B00]"
                } ${
                  index === 0
                    ? "bg-[#F1FFEF]"
                    : index === 1
                    ? "bg-[#FEFFD9]"
                    : index === 2
                    ? "bg-[#FFF5E7]"
                    : "bg-[#FFF5E7]"
                }`}
                onClick={() =>
                  navigate(`/agentrequest?status=${item.request_via}`)
                }
              >
                <div className="flex flex-col rounded-[5px] gap-3">
                  <div className="flex items-center justify-between gap-[13px] border-b border-[#D9D9D9] font-medium pt-8 pb-4 ">
                    <p className="text-[#353F4F] text-lg capitalize">
                      {item.request_via} Request
                    </p>
                    <div className="w-[6rem]">
                      <p className="bg-[#596A82] text-white text-[36px] rounded text-center w-full py-1">
                        {item.request_count}
                      </p>
                    </div>
                  </div>
                  <div className="text-xs pb-1.5">
                    <p>
                      Metrics:{" "}
                      <span className="text-[#2260D9]">Current Month</span>
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No request data available.</p>
          )}
        </div>
      </div>

      <div className="grid grid-cols-11 gap-6">
        <TopAgentList />
        <TopPartnerList />
        <Chart />
      </div>
      <CurrentRequest />
    </div>
  );
};

export default AdminDashBoard;
