import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoutes = (props) => {
  const { Component } = props;
  const navigate = useNavigate();
  const allowedUrls = useRef(['https://crrms1.dataterrain-dev.net/','https://crrms1.dataterrain-test.net/','https://crrms1.dataterrain-demo.net/', 'http://localhost:3000/']);

  useEffect(() => {
    const login = localStorage.getItem('access_token');
    const currentUrl = window.location.href;

    if (!allowedUrls.current.includes(currentUrl)) {
      if (!login) {
        navigate('/login');
      }
    }
  }, [navigate]);

  return (
    <div>
      <Component />
    </div>
  );
};

export default ProtectedRoutes;
