import React, { useState, useEffect } from "react";
import { TextField, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { loginWithPhoneNumber, optVerified } from "../../services/user";

const ConfirmOtp = ({
  number,
  handleCloseLoginModal,
  setNumber,
  setOpenOtp,
  urlPath,
}) => {
  const { email } = useParams();
  const [otp, setOtp] = React.useState(Array(6).fill(""));
  const [isOtpComplete, setIsOtpComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const otpError = useSelector((state) => state.loginInfo.error);
  console.log("error", otpError);

  useEffect(() => {
    const isComplete = otp.every((value) => value.length === 1);
    setIsOtpComplete(isComplete);
  }, [otp]);

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, otp.length);
    const newOtp = pastedData
      .split("")
      .concat(Array(otp.length).fill(""))
      .slice(0, otp.length);
    setOtp(newOtp);

    const nextEmptyIndex = newOtp.findIndex((value) => value === "");
    if (nextEmptyIndex !== -1) {
      document.getElementById(`otp-input-${nextEmptyIndex}`).focus();
    }
  };

  const handleChange = (e, index) => {
    const newOtp = otp.slice();
    newOtp[index] = e.target.value.slice(0, 1);
    setOtp(newOtp);

    if (e.target.value.length === 1 && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  const handleConfirmOtp = async () => {
    if (isOtpComplete) {
      const reset_code = otp.join("");

      try {
        dispatch(
          optVerified({ phone_number: `+91${number}`, otp: reset_code })
        ).then(() => {
          setNumber("");
          setOpenOtp(false);
          handleCloseLoginModal();
          if (urlPath) {
            navigate(urlPath);
          }
        });
      } catch (error) {
        setErrorMessage(otpError);
      }
    }
  };

  const [timeLeft, setTimeLeft] = useState(60); // Set initial countdown to 60 seconds
  const [resendEnabled, setResendEnabled] = useState(false);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer); // Cleanup timer
    } else {
      setResendEnabled(true); // Enable resend after countdown
    }
  }, [timeLeft]);

  const handleResend = () => {
    if (resendEnabled) {
      setTimeLeft(60); // Reset countdown
      setResendEnabled(false); // Disable resend during countdown
      // Trigger resend OTP logic here
      dispatch(loginWithPhoneNumber({ phone_number: `+91${number}` })).then(
        () => {
          //   handleOtpClick();
        }
      );
    }
  };

  return (
    <div className="flex justify-center items-center py-8 w-full">
      <div className="flex flex-col px-2 gap-4 rounded-[5px] w-fit">
        <div className="flex flex-col items-center w-full gap-5 py-2">
          <p className="font-bold text-[38px] text-primary-blue">CRRMS</p>
          <p className="font-medium text-[#353F4F] text-[24px] text-center">
            Community Request and Referrals Management System
          </p>
        </div>

        <div className="flex flex-col gap-5">
          <div className="flex justify-between">
            <p className="text-base">
              Enter the Code Sent To
              <span className="font-semibold pl-2">{number}</span>
            </p>
          </div>

          <div className="flex justify-between w-full h-full">
            {otp.map((value, index) => (
              <Grid item key={index}>
                <TextField
                  id={`otp-input-${index}`}
                  value={value}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={index === 0 ? handlePaste : null}
                  inputProps={{
                    maxLength: 1,
                    style: {
                      textAlign: "center",
                      width: "40px",
                      fontSize: "20px",
                      padding: "10px",
                      margin: "0 5px",
                    },
                  }}
                  variant="outlined"
                  type="text"
                  impouMode="numeric"
                  sx={{
                    width: "65px",
                    "& .MuiOutlinedInput-root": {
                      height: "65px",
                    },
                  }}
                />
              </Grid>
            ))}
          </div>
          <div className="flex justify-between items-center relative">
            <p className="text-xs relative flex justify-between items-center w-full">
              Didn’t Receive Any OTP? Retry In{" "}
              {timeLeft > 0
                ? `00:${timeLeft.toString().padStart(2, "0")}`
                : "00:00"}
              <span
                className={`text-[#2260D9] font-semibold decoration-1 underline-offset-2 pl-1.5 ${
                  resendEnabled
                    ? "cursor-pointer"
                    : "opacity-50 pointer-events-none"
                }`}
                onClick={handleResend}
              >
                Resend OTP
              </span>
              {otpError && (
                <div className="absolute right-0 text-[#DC3545] text-sm mt-1">
                  {otpError}
                </div>
              )}
            </p>
          </div>
        </div>
        <div className="flex flex-col">
          <button
            className={`py-3 rounded-[3px] ${
              isOtpComplete
                ? "bg-[#2260D9] text-white"
                : "bg-[#E4E4E4] text-[#BBBBBB]"
            }`}
            disabled={!isOtpComplete}
            onClick={handleConfirmOtp}
          >
            Confirm OTP
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOtp;
