import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Box, Tab, Tabs } from "@mui/material";
import AgentProfile from "./AgentProfile";
import ManagedRequest from "./ManageRequest";
import ManagePartner from "./ManagePartner";
import { useDispatch, useSelector } from "react-redux";
import { getAgentById } from "../../services/agent";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import Permission from "./Permission";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ViewAgent() {
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAgentById(id));
  }, [id]);

  const agentInfo = useSelector((state) => state.agentInfo.agentDetails);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="w-full h-full px-8 mt-10 ">
      <div className="mb-6">
        <BreadCumbs
          firstCumbName="Dashboard"
          secoundCumbName="View Profile"
          pathToRedirect=""
        />
      </div>
      <div
        className="min-h-[75vh]  mb-5"
        style={{
          boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
          borderRadius: "5px",
        }}
      >
        <div className="">
          <div className="grid grid-cols-10">
            <div className="col-span-2 pl-8 pr-5 pt-6 border-r border-[#D9D9D9]">
              <Tabs
                value={value}
                onChange={handleChange}
                orientation="vertical"
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTab-root": {
                    alignItems: "flex-start",
                    gap:"10px",
                    paddingX: "20px",
                    paddingY: "15px",
                    marginTop:"12px",
                    color: "#353F4F",
                    fontWeight: 500,
                    fontSize: "16px",
                    fontFamily: "DM Sans",
                    textTransform: "capitalize",
                    borderRadius: "3px",
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#2260D9",
                    width: 4,
                    borderRadius: "0 3px 3px 0",
                  },
                  "& .MuiTab-root.Mui-selected": {
                    backgroundColor: "#EEF4FF",
                    color: "#2260D9",
                  },
                }}
              >
                <Tab label="Profile" className="pt-2" {...a11yProps(0)} />
                <Tab label="Managed Request" {...a11yProps(1)} />
                <Tab label="Managed Partners" {...a11yProps(2)} />
                <Tab label="Permission" {...a11yProps(3)}/>
              </Tabs>
            </div>

            <div className="col-span-8 pl-8 pr-14 pt-12">
              <CustomTabPanel value={value} index={0}>
                <AgentProfile agentInfo={agentInfo} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <ManagedRequest agentInfo={agentInfo}/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <ManagePartner agentInfo={agentInfo}/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <Permission agentInfo={agentInfo}/>
              </CustomTabPanel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
