export const PrimaryInfoFields = [
  {
    type: "input",
    fieldType: "text",
    name: "first_name",
    label: "First Name",
    placeholder: "Enter your first name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "last_name",
    label: "Last Name",
    placeholder: "Enter your last name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "primary_contact_number",
    label: "Primary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {
      required: "This field is required",
      pattern: {
        value: /^\(\d{3}\) \d{3}-\d{4}$/,
        message: "Please Enter Valid Mobile Number in (415) 555-1234 format",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "secondary_contact_number",
    label: "Secondary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {},
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "address",
    label: "Address",
    placeholder: "Enter your Address",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-full",
  },
  {
    type: "dropdown",
    fieldType: "select",
    name: "agent_status",
    label: "Agent Status",
    placeholder: "Select request Program",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "active",
        value: "Active",
      },
      {
        name: "inactive",
        value: "InActive",
      },
    ],
    width: "w-[49%]",
  },
];

export const editstatus = [
  {
    type: "dropdown",
    fieldType: "select",
    name: "status",
    label: "Agent Status",
    placeholder: "Select request Program",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Active",
        value: "active",
      },
      {
        name: "Inactive",
        value: "inactive",
      },
    ],
    width: "w-[35%]",
  },
];

export const partnerstatus = [
  {
    type: "dropdown",
    fieldType: "select",
    name: "status",
    label: "Partner Status",
    placeholder: "Select request Program",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Active",
        value: "active",
      },
      {
        name: "Inactive",
        value: "inactive",
      },
    ],
    width: "w-[35%]",
  },
];

export const RequestDetailsFields = [
  {
    type: "dropdown",
    fieldType: "select",
    name: "category",
    label: "Request Program",
    placeholder: "Select request Program",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Program1",
        value: "category1",
      },
      {
        name: "Program2",
        value: "category2",
      },
      {
        name: "Program3",
        value: "category3",
      },
    ],
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "beneficiary",
    label: "Number of Beneficiaries",
    placeholder: "Number of Beneficiaries",
    width: "w-[49%]",
    inputRules: {
      required: "This field is required",
    },
  },
  {
    type: "radio",
    fieldType: "radio",
    name: "urgency_level",
    label: "Urgency Level",
    placeholder: "Enter Urgency Level",
    options: [
      {
        name: "High",
        value: "high",
      },
      {
        name: "Medium",
        value: "medium",
      },
      {
        name: "Low",
        value: "low",
      },
    ],
    width: "w-full",
  },
  {
    type: "textbox",
    fieldType: "text",
    name: "desc",
    label: "Description",
    placeholder: "Description",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
  {
    type: "file",
    fieldType: "file",
    name: "attachment",
    label: "Attachments (optional)",
    placeholder: "Upload attachment",
    width: "w-full",
  },
];

export const PrimaryRequestInfoFields = [
  {
    type: "input",
    fieldType: "text",
    name: "first_name",
    label: "First Name",
    placeholder: "Enter your first name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "last_name",
    label: "Last Name",
    placeholder: "Last Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "contact",
    label: "Primary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {
      required: "This field is required",
      pattern: {
        value: /^\(\d{3}\) \d{3}-\d{4}$/,
        message: "Please Enter Valid Mobile Number in (415) 555-1234 format",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "alternative_number",
    label: "Secondary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {},
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "address",
    label: "Address Line 1",
    placeholder: "Current Address",
    // inputRules: {
    //   required: "This field is required   ",
    // },
    width: "w-full",
    icon: "location-red-icon.svg",
  },
  {
    type: "input",
    fieldType: "text",
    name: "address2",
    label: "Address Line 2",
    placeholder: "Current Address",
    // inputRules: {
    //   required: "This field is required   ",
    // },
    width: "w-[49%]",
    icon: "location-red-icon.svg",
  },
];

export const ConsumerRequestInfoFields = [
  {
    type: "input",
    fieldType: "text",
    name: "first_name",
    label: "First Name",
    placeholder: "Enter your first name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "last_name",
    label: "Last Name",
    placeholder: "Last Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "contact",
    label: "Primary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {
      required: "This field is required",
      pattern: {
        value: /^\(\d{3}\) \d{3}-\d{4}$/,
        message: "Please Enter Valid Mobile Number in (415) 555-1234 format",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "alternative_number",
    label: "Secondary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {},
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "address1",
    label: "Address Line 1",
    placeholder: "Current Address",
    // inputRules: {
    //   required: "This field is required   ",
    // },
    width: "w-full",
  },

  {
    type: "input",
    fieldType: "text",
    name: "address_line",
    label: "Address Line 2",
    placeholder: "Current Address",
    // inputRules: {
    //   required: "This field is required   ",
    // },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "city",
    label: "City",
    placeholder: "City",
    width: "w-[49%]",
  },

  {
    type: "input",
    fieldType: "text",
    name: "state",
    label: "State",
    placeholder: "State",
    width: "w-[49%]",
  },

  {
    type: "input",
    fieldType: "number",
    name: "zip_code",
    label: "Zip Code",
    placeholder: "Zip Code",
    width: "w-[49%]",
  },
];

export const ClientSecondaryInfoFields = [
  {
    type: "input",
    fieldType: "text",
    name: "client_first_name",
    label: "First Name",
    placeholder: "Enter your first name",
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "client_last_name",
    label: "Last Name",
    placeholder: "Last Name",
    width: "w-[49%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "client_contact",
    label: "Primary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    width: "w-[49%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "client_alternative_number",
    label: "Secondary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "client_address",
    label: "Address",
    placeholder: "Current Address",
    width: "w-full",
    icon: "location-red-icon.svg",
  },
];

export const NewRequestDetailsFields = [
  {
    type: "radio",
    fieldType: "radio",
    name: "request_via",
    label: "Request via",
    placeholder: "Enter Urgency Level",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Call",
        value: "call",
      },
      {
        name: "Walk-in",
        value: "walk_in",
      },
    ],
    width: "w-[49%]",
  },
  {
    type: "dropdown",
    fieldType: "select",
    name: "category",
    label: "Request Program",
    placeholder: "Select request Program",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Program1",
        value: "category1",
      },
      {
        name: "Program2",
        value: "category2",
      },
      {
        name: "Program3",
        value: "category3",
      },
    ],
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "beneficiaries",
    label: "Number of Beneficiaries",
    placeholder: "Number of Beneficiaries",
    width: "w-[49%]",
    inputRules: {
      required: "This field is required",
    },
  },
  {
    type: "radio",
    fieldType: "radio",
    name: "urgency_level",
    label: "Urgency Level",
    placeholder: "Enter Urgency Level",
    options: [
      {
        name: "High",
        value: "high",
      },
      {
        name: "Medium",
        value: "medium",
      },
      {
        name: "Low",
        value: "low",
      },
    ],
    width: "w-[49%]",
    inputRules: {
      required: "This field is required",
    },
  },
  {
    type: "textbox",
    fieldType: "text",
    name: "description",
    label: "Description",
    placeholder: "Enter Description",
    width: "w-full",
  },
];

export const consumerDetailsFields = [
  {
    type: "dropdown",
    fieldType: "select",
    name: "category",
    label: "Request Program",
    placeholder: "Select request Program",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Program1",
        value: "category1",
      },
      {
        name: "Program 2",
        value: "category2",
      },
      {
        name: "Program3",
        value: "category3",
      },
    ],
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "beneficiaries",
    label: "Number of Beneficiaries",
    placeholder: "Number of Beneficiaries",
    width: "w-[49%]",
    inputRules: {
      required: "This field is required",
    },
  },
  {
    type: "radio",
    fieldType: "radio",
    name: "urgency_level",
    label: "Urgency Level",
    placeholder: "Enter Urgency Level",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "High",
        value: "high",
      },
      {
        name: "Medium",
        value: "medium",
      },
      {
        name: "Low",
        value: "low",
      },
    ],
    width: "w-full",
  },
  {
    type: "textbox",
    fieldType: "text",
    name: "description",
    label: "Description",
    placeholder: "Enter Description",
    // inputRules: {
    //   required: "This field is required",
    // },
    width: "w-full",
  },
  {
    type: "file",
    fieldType: "file",
    name: "id_proof",
    label: "Documents (optional)",
    placeholder: "Upload attachment",
    width: "w-full",
  },
  // {
  //   type: "radio",
  //   fieldType: "radio",
  //   name: "availability",
  //   label: "Availability",
  //   placeholder: "Select Availability",
  //   inputRules: {
  //     required: "This field is required",
  //   },
  //   options: [
  //     {
  //       name: "Opt-In",
  //       value: "opt_in",
  //     },
  //     {
  //       name: "Opt-Out",
  //       value: "opt_out",
  //     },
  //   ],
  //   width: "w-[49%]",
  // },
];

export const ClientAdditionalFields = [
  {
    type: "radio",
    fieldType: "radio",
    name: "availability",
    label: "Availability",
    placeholder: "Select Availability",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Opt-In",
        value: "opt_in",
      },
      {
        name: "Opt-Out",
        value: "opt_out",
      },
    ],
    width: "w-[49%]",
  },
  {
    type: "textbox",
    fieldType: "text",
    name: "comments",
    label: "Comments",
    placeholder: "",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
];

export const SeconaryRequestInfoFields = [
  {
    type: "radio",
    fieldType: "radio",
    name: "referral_type",
    label: "Referral Type",
    placeholder: "Enter Referral Type",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Internal",
        value: "internal",
      },
      {
        name: "External",
        value: "external",
      },
      {
        name: "Internal and External",
        value: "internalandexternal",
      },
    ],
    width: "w-full",
  },
  {
    type: "dropdown",
    fieldType: "select",
    name: "referred",
    label: "Referred",
    placeholder: "Select Referred",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
  {
    type: "checkbox",
    fieldType: "checkbox",
    name: "partner_request_via",
    label: "Request Via",
    placeholder: "Enter Request Via*",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Call",
        value: "call",
      },
      {
        name: "E-mail",
        value: "email",
      },
      {
        name: "Message",
        value: "message",
      },
    ],
    width: "w-full",
  },
  {
    type: "textbox",
    fieldType: "text",
    name: "description",
    label: "Description",
    placeholder: "Description",
    // inputRules: {
    //   required: "This field is required",
    // },
    width: "w-full",
  },
];

export const ProfileFields = [
  {
    type: "input",
    fieldType: "text",
    name: "username",
    label: "Name",
    placeholder: "Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
  {
    type: "tel",
    fieldType: "number",
    name: "phone_number",
    label: "Phone Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
  {
    type: "input",
    fieldType: "email",
    name: "email",
    label: "Email Id",
    placeholder: "email@gmail.com",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
  {
    type: "input",
    fieldType: "text",
    name: "address",
    label: "Address",
    placeholder: "Address",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
];

export const AllRequestTab = [
  {
    name: "All",
    key: "all",
  },
  {
    name: "Completed",
    key: "completed",
  },
  {
    name: "On Hold",
    key: "on-hold",
  },
  {
    name: "Rejected",
    key: "rejected",
  },
];

export const PartnersTab = [
  {
    name: "All",
    key: "all",
  },
  {
    name: "Active",
    key: "active",
  },
  {
    name: "Inactive",
    key: "inactive",
  },
];

export const UnregisteredPartnersTab = [
  {
    name: "Unregistered",
    key: "unregistered",
  },
  {
    name: "Declined",
    key: "declined",
  },
];

export const CategoryTab = [
  {
    name: "All",
    key: "all",
  },
  {
    name: "Active",
    key: "active",
  },
  {
    name: "Inactive",
    key: "inactive",
  },
];

export const ExternalCategoryTab = [
  {
    name: "Total Request",
    key: "totalrequest",
  },
  {
    name: "Total Partners",
    key: "totalpartners",
  },
];

export const ManageCategoryTab = [
  {
    name: "Manage Programs",
    key: "managecategories",
  },
  {
    name: "Total Employees",
    key: "totalemployees",
  },
];

export const AgentTab = [
  {
    name: "All",
    key: "all",
  },
  {
    name: "Active",
    key: "active",
  },
  {
    name: "Inactive",
    key: "inactive",
  },
];

export const RequestViaTab = [
  {
    name: "Total Request",
    key: "",
  },
  {
    name: "Call",
    key: "call",
  },

  {
    name: "Online",
    key: "online",
  },
  {
    name: "Walk-In",
    key: "walk_in",
  },
];

export const AddNewCategoryFields = [
  {
    type: "input",
    fieldType: "text",
    name: "program_title",
    label: "Program Title",
    placeholder: "Program Title",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "maximum_number_of_beneficiaries",
    label: "Maximum Number of Beneficiaries ",
    placeholder: "Number of Beneficiaries",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "dropdown",
    fieldType: "select",
    name: "program_type",
    label: "Program Type",
    placeholder: "Internal / External ",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Internal",
        value: "internal",
      },
      {
        name: "External",
        value: "external",
      },
      {
        name: "Internal and External",
        value: "both",
      },
    ],
    width: "w-full",
  },
  // {
  //   type: "dropdown",
  //   fieldType: "select",
  //   name: "urgency_level",
  //   label: "Urgency Level",
  //   placeholder: "Urgency Level",
  //   inputRules: {
  //     required: "This field is required",
  //   },
  //   options: [
  //     {
  //       name: "High",
  //       value: "High",
  //     },
  //     {
  //       name: "Medium",
  //       value: "Medium",
  //     },
  //     {
  //       name: "Low",
  //       value: "Low",
  //     },
  //   ],
  //   width: "w-[49%]",
  // },
];

export const CategoryCheckbox = [
  {
    type: "checkbox",
    name: "program_options",
    label: "Program Service Options",
    // inputRules: {
    //   required: "At least one option must be selected",
    // },
    options: [
      {
        label: "Paid",
        value: "paid",
      },
      // {
      //   label: "Free",
      //   value: "free",
      // },
    ],
  },
];

export const ProgramDescription = [
  {
    type: "textbox",
    fieldType: "text",
    name: "program_description",
    label: "Program Description",
    placeholder: "Description",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
];

export const CategoryManagerDetailField = [
  {
    type: "dropdown",
    fieldType: "select",
    name: "agent_name",
    label: "Program Manager",
    placeholder: "Select Program Manager",
    inputRules: {
      required: "This field is required",
    },
    // options: [
    //   {
    //     name: "Program1",
    //     value: "category1",
    //   },
    //   {
    //     name: "Program2",
    //     value: "category2",
    //   },
    //   {
    //     name: "Program3",
    //     value: "category3",
    //   },
    // ],
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "agent_contact_details",
    label: "Manager Contact Details ",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  // {
  //   type: "input",
  //   fieldType: "textarea",
  //   name: "Program_description",
  //   label: "Program Description",
  //   placeholder: "Program Description",
  //   inputRules: {
  //     required: "This field is required",
  //   },
  //   width: "w-full",
  //   height: "h-[200px]",
  // },
];

export const CategoryDescription = [
  {
    type: "textbox",
    fieldType: "text",
    name: "description",
    label: "Description",
    placeholder: "Description",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
];

export const PartnerDescription = [
  {
    type: "textbox",
    fieldType: "text",
    name: "partner_description",
    label: "Description",
    placeholder: "Description",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
];

export const CategoryOrganizationField = [
  {
    type: "dropdown",
    fieldType: "select",
    name: "organization_name",
    label: "Organization Name",
    placeholder: "Select Program Manager",
    inputRules: {
      required: "This field is required",
    },
    // options: [
    //   {
    //     name: "Program1",
    //     value: "category1",
    //   },
    //   {
    //     name: "Program2",
    //     value: "category2",
    //   },
    //   {
    //     name: "Program3",
    //     value: "category3",
    //   },
    // ],
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "organization_contact_details",
    label: "Organization Contact Details ",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  // {
  //   type: "input",
  //   fieldType: "textarea",
  //   name: "Program_description",
  //   label: "Program Description",
  //   placeholder: "Program Description",
  //   inputRules: {
  //     required: "This field is required",
  //   },
  //   width: "w-full",
  //   height: "h-[200px]",
  // },
];
export const AddNewPartnerFields = [
  {
    type: "input",
    fieldType: "text",
    name: "organization_name",
    label: "Organization Name",
    placeholder: "Organization Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
  {
    type: "input",
    fieldType: "text",
    name: "email1",
    label: "E-Mail",
    placeholder: "E-mail",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "contact_number",
    label: "Primary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {
      required: "This field is required",
      pattern: {
        value: /^\(\d{3}\) \d{3}-\d{4}$/,
        message: "Please Enter Valid Mobile Number in (415) 555-1234 format",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "alternative_number",
    label: "Secondary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {
      pattern: {
        value: /^\(\d{3}\) \d{3}-\d{4}$/,
        message: "Please Enter Valid Mobile Number in (415) 555-1234 format",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "total_org_employee",
    label: "Total Employees",
    placeholder: "Total Employees",
    inputRules: {},
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "org_address",
    label: "Address",
    placeholder: "Address",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-full",
  },
  {
    type: "input",
    fieldType: "text",
    name: "city",
    label: "City",
    placeholder: "City",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "state",
    label: "State",
    placeholder: "State",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "postal_code",
    label: "Postal Code",
    placeholder: "Postal Code",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "country",
    label: "Country",
    placeholder: "Country",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
];
export const OrganizationContactPersonFields = [
  {
    type: "input",
    fieldType: "text",
    name: "first_name",
    label: "First Name",
    placeholder: "First Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "last_name",
    label: "Last Name",
    placeholder: "Last Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "email",
    label: "E-Mail",
    placeholder: "E-mail",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "org_phone_number",
    label: "Primary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {
      required: "This field is required",
      pattern: {
        value: /^\(\d{3}\) \d{3}-\d{4}$/,
        message: "Please Enter Valid Mobile Number in (415) 555-1234 format",
      },
    },
    width: "w-[49%]",
  },
];

export const partnerDetailsFields = [
  {
    type: "radio",
    fieldType: "radio",
    name: "referral_type",
    label: "Referral Type",
    placeholder: "Enter Urgency Level",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Internal",
        value: "call",
      },
      {
        name: "External",
        value: "walk_in",
      },
      {
        name: "Internal and External",
        value: "walk_in",
      },
    ],
    width: "w-full",
  },
  {
    type: "dropdown",
    fieldType: "select",
    name: "referred",
    label: "Referred",
    placeholder: "Select Referred",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Program 1",
        value: "category1",
      },
      {
        name: "Program 2",
        value: "category2",
      },
      {
        name: "Program 3",
        value: "category3",
      },
    ],
    width: "w-[49%]",
  },
  {
    type: "radio",
    fieldType: "radio",
    name: "request_via",
    label: "Request via",
    placeholder: "Enter Urgency Level",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Call",
        value: "call",
      },
      {
        name: "E-mail",
        value: "email",
      },
      {
        name: "Message",
        value: "online",
      },
    ],
    width: "w-full",
  },
  {
    type: "textbox",
    fieldType: "text",
    name: "description",
    label: "Description",
    placeholder: "Enter Description",
    width: "w-full",
  },
];

export const AddNewAgentFields = [
  {
    type: "input",
    fieldType: "text",
    name: "first_name",
    label: "First Name",
    placeholder: "First Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[32%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "last_name",
    label: "Last Name",
    placeholder: "Last Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[32%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "email",
    label: "E-Mail",
    placeholder: "E-mail",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[33%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "contact_number",
    label: "Primary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {
      required: "This field is required",
      pattern: {
        value: /^\(\d{3}\) \d{3}-\d{4}$/,
        message: "Please Enter Valid Mobile Number in (415) 555-1234 format",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "alternative_number",
    label: "Secondary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {
      pattern: {
        value: /^\(\d{3}\) \d{3}-\d{4}$/,
        message: "Please Enter Valid Mobile Number in (415) 555-1234 format",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "address",
    label: "Address",
    placeholder: "Address",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-full",
  },
  {
    type: "input",
    fieldType: "text",
    name: "city",
    label: "City",
    placeholder: "City",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },

  {
    type: "input",
    fieldType: "text",
    name: "state",
    label: "State",
    placeholder: "State",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "postal_code",
    label: "Postal Code",
    placeholder: "Postal Code",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "country",
    label: "Country",
    placeholder: "Country",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
];

export const AddNewEmployeeFields = [
  {
    type: "input",
    fieldType: "text",
    name: "first_name",
    label: "First Name",
    placeholder: "First Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[32%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "last_name",
    label: "Last Name",
    placeholder: "Last Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[32%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "email",
    label: "E-Mail",
    placeholder: "E-mail",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[33%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "contact_number",
    label: "Primary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {
      required: "This field is required",
      pattern: {
        value: /^\(\d{3}\) \d{3}-\d{4}$/,
        message: "Please Enter Valid Mobile Number in (555) 555-1234 format",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "alternative_number",
    label: "Secondary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {
      pattern: {
        value: /^\(\d{3}\) \d{3}-\d{4}$/,
        message: "Please Enter Valid Mobile Number in (555) 555-1234 format",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "address",
    label: "Address",
    placeholder: "Address",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-full",
  },
  {
    type: "input",
    fieldType: "text",
    name: "city",
    label: "City",
    placeholder: "City",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },

  {
    type: "input",
    fieldType: "text",
    name: "state",
    label: "State",
    placeholder: "State",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "postal_code",
    label: "Postal Code",
    placeholder: "Postal Code",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "country",
    label: "Country",
    placeholder: "Country",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
];

export const OrgannizationAgentFields = [
  {
    type: "input",
    fieldType: "text",
    name: "organization.first_name",
    label: "First Name",
    placeholder: "First Name",
    inputRules: {
      // required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "organization.last_name",
    label: "Last Name",
    placeholder: "Last Name",
    inputRules: {
      // required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "organization.email",
    label: "E-Mail",
    placeholder: "E-mail",
    inputRules: {
      // required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "tel",
    fieldType: "tel",
    name: "org_phone_number",
    label: "Primary Contact Number",
    placeholder: "Ex: (555) 555-1234",
    inputRules: {
      // required: "This field is required",
      pattern: {
        value: /^\(\d{3}\) \d{3}-\d{4}$/,
        message: "Please Enter Valid Mobile Number in (415) 555-1234 format",
      },
    },
    width: "w-[49%]",
  },
];

export const categoryStatus = [
  {
    type: "dropdown",
    fieldType: "select",
    name: "status",
    label: "Program Status",
    placeholder: "Select request Program",

    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Active",
        value: "active",
      },
      {
        name: "Inactive",
        value: "inactive",
      },
    ],
    width: "w-[35%]",
  },
];
