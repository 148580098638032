import { Routes, Route } from "react-router-dom";
import "./App.css";
import Layout from "./Layout";
import Dashboard from "./components/Dashboard";
import CreateRequest from "./components/Request/CreateRequest";
import Category from "./components/Category";
import AllRequest from "./components/Request/AllRequest";
import Notification from "./components/Notification";
import Help from "./components/Help";
import ViewRequest from "./components/Request/View";
import ViewCategory from "./components/Category/ViewCategory";
import Partner from "./components/Partner";
import ViewPartner from "./components/Partner/ViewPartner";
import Profile from "./components/Profile";
import PartnerDashboard from "./components/PartnerDashboard";
import PartnerRequestsView from "./components/PartnerRequestsView";
import PartnerRequestDetailView from "./components/PartnerRequestDetailView";
import PartnerTrackingView from "./components/PartnerTrackingView";
import Logout from "./components/Logout";
import AllCategory from "./components/Category/AllCategory";
import AddNewCategory from "./components/Category/AddNewCategory";
import CategoryVisibility from "./components/Category/CategoryVisibility";
import LogIn from "./components/Login/Login";
import ForgotPassword from "./components/Login/ForgotPassword";
import ConfirmOtp from "./components/Login/ConfirmOtp";
import ChangePassword from "./components/Login/ChangePassword";
import AddNewPartner from "./components/Partner/AddNewPartner";

import AddNewAgent from "./components/Agent/AddNewAgent";
import AllAgent from "./components/Agent/AllAgent";
import ProtectedRoutes from "./components/ProtectedRoutes";
import AgentView from "./components/Category/AgentView";
import ViewAgent from "./components/Category/ViewAgent";
import ExternalCategory from "./components/Category/ExternalCategory";
import EditCategory from "./components/Category/EditCategory";
import AgentProfile from "./components/Category/AgentProfile";
import RequestDetails from "./components/Request/AllRequest/RequestDetails";
import AgentRequestVia from "./components/Admin-DashBoard/AgentRequestVia";
import AddNewEmployee from "./components/Partner-Employee/AddNewEmployee";
import BulkUpload from "./components/Profile/BulkUpload";
import Success from "./components/Payment/Success";
import Failed from "./components/Payment/Failed";
import RequestHistory from "./components/Request/AllRequest/RequestHistory";
// import AddCategoryPartners from "./components/Category/AddCategoryPartners";
import MoreReport from "./components/Admin-DashBoard/MoreReport";
import MoreScheduler from "./components/Admin-DashBoard/MoreScheduler";
import CreateMeeting from "./components/Admin-DashBoard/CreateMeeting";
import UnregisteredPartner from "./components/Partener-Unregistered/UnregisteredPartner";
import UnregisteredStaff from "./components/Agent/UnregisteredStaff";
import NotFoundPage from "./NotFoundPage";

const allowedUrls = [
  "https://crrms1.dataterrain-dev.net/",
  "https://crrms1.dataterrain-test.net/",
  "https://crrms1.dataterrain-demo.net/",
  "http://localhost:3000/",
];

function App() {
  const currentUrl = window.location.href;

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<ProtectedRoutes Component={Dashboard} />} />
        {/* <Route path="/" element={<Dashboard/>} /> */}
        <Route path="/success" element={<Success />} />
        <Route path="/failed" element={<Failed />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/help" element={<Help />} />
        <Route path="/category" element={<Category />} />
        <Route path="/allcategory" element={<AllCategory />} />
        <Route path="/addnewprogram" element={<AddNewCategory />} />
        <Route path="/categoryvisibility" element={<CategoryVisibility />} />
        <Route path="/externalcategory/:id" element={<ExternalCategory />} />
        <Route path="/editcategory/:id" element={<EditCategory />} />
        {/* <Route path="/addcategorypartner/:id" element= {<AddCategoryPartners/>} /> */}
        <Route path="/addnewagent" element={<AddNewAgent />} />
        <Route path="/all-staff" element={<AllAgent />} />
        <Route path="/addnewpartner" element={<AddNewPartner />} />
        <Route path="/agentview" element={<AgentView />} />
        <Route path="/view-agent/:id" element={<ViewAgent />} />
        <Route path="/agentprofile/:id" element={<AgentProfile />} />
        <Route path="/agentrequest" element={<AgentRequestVia />} />
        <Route path="/addnewemployee" element={<AddNewEmployee />} />
        <Route path="/report" element={<MoreReport />} />
        <Route path="/scheduler" element={<MoreScheduler />} />
        <Route path="/create-meeting" element={<CreateMeeting />} />

        <Route path="/category/:id" element={<ViewCategory />} />
        <Route path="/create-request" element={<CreateRequest />} />
        <Route path="/all-request" element={<AllRequest />} />
        <Route path="/view-request/:id" element={<ViewRequest />} />
        <Route path="/request-tracking" element={<RequestDetails />} />
        <Route path="/request-history/:id" element={<RequestHistory />} />

        <Route path="/profile" element={<Profile />} />
        <Route path="/bulk-upload" element={<BulkUpload />} />
        <Route path="/Partners" element={<Partner />} />
        <Route
          path="/unregistered-partners"
          element={<UnregisteredPartner />}
        />
        <Route path="/unregistered-staff" element={<UnregisteredStaff />} />

        <Route path="/partner/:id" element={<ViewPartner />} />

        <Route path="/requests" element={<PartnerRequestsView />} />
        <Route
          path="/requests/:requestId/tracking"
          element={<PartnerTrackingView />}
        />
        <Route path="/requests/:id" element={<PartnerRequestDetailView />} />
        <Route path="/" element={<PartnerDashboard />} />
        <Route path="/partner"></Route>
      </Route>
      <Route path="/logout" element={<Logout />} />
      {!allowedUrls.includes(currentUrl) && (
        <Route path="/login" element={<LogIn />} />
      )}
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/confirmotp/:email" element={<ConfirmOtp />} />
      <Route path="/changepassword" element={<ChangePassword />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
