import React, { useState, useEffect } from "react";
import { Backdrop } from "@mui/material";
import { Button } from "../../shared/Button";
import SearchIcon from "../../assets/input-search-icon.svg";
import CalenderIcon from "../../assets/calendar-icon.svg";
import housingIcon from "../../assets/housing-repair-icon.svg";
import childcareIcon from "../../assets/childcare-icon.svg";
import counselingIcon from "../../assets/counseling-icon.svg";
import CloseRedBgIcon from "../../assets/close-red-icon.svg";
import { getUserType } from "../../services/userType";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotification,
  ignoreNotification,
  deleteNotification,
  viewNotification,
} from "../../services/notification";
import moment from "moment";
import { useNavigate } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Notification() {
  const [notificationAction, setNotificationIcon] = useState({
    modal: false,
    selectedItem: {},
  });

  const [notifications, setNotifications] = useState({});
  const [notificationData, setNotificationData] = useState([]);
  const [todayNotifications, setTodayNotifications] = useState([]);
  const [yesterdayNotifications, setYesterdayNotifications] = useState([]);
  const [earlierNotifications, setEarlierNotifications] = useState([]);
  const [clearedIds, setClearedIds] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, status, userType } = useSelector((state) => state.userInfo);

  const notification = useSelector((state) => state.notificationInfo.data);
  console.log("notif", notification);
  const formatTime = (time) => {
    const duration = moment.duration(moment().diff(moment(time)));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes());

    if (hours > 0) {
      return `${hours} hr${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} min${minutes > 1 ? "s" : ""} ago`;
    }
    return "Just now";
  };

  useEffect(() => {
    dispatch(deleteNotification());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getNotification({ page: 1 }))
      .then((response) => {
        if (response.payload && response.payload.data) {
          console.log({ response });
          setNotifications(response.payload);
          setNotificationData(response.payload.data);
          categorizeNotifications(response.payload.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  }, [dispatch]);

  const handleClearBtn = (item) => {
    setNotificationIcon({ modal: true, selectedItem: item });
  };

  const handleClosePopup = () => {
    setNotificationIcon({ modal: false, selectedItem: {} });
  };

  useEffect(() => {
    dispatch(getUserType());
  }, [dispatch]);

  const getIcon = (status) => {
    switch (status) {
      case "in progress":
        return housingIcon;
      case "completed":
        return childcareIcon;
      case "pending":
        return counselingIcon;
      default:
        return childcareIcon;
    }
  };

  const categorizeNotifications = (notifications) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const yesterday = new Date(today - 24 * 60 * 60 * 1000);

    const isSameDay = (date1, date2) =>
      new Date(date1).setHours(0, 0, 0, 0) === date2;

    const todayData = notifications.filter((notification) =>
      isSameDay(notification.created_at || notification.updated_at, today)
    );

    const yesterdayData = notifications.filter((notification) =>
      isSameDay(notification.created_at || notification.updated_at, yesterday)
    );

    const earlierNotifications = notifications.filter((notification) => {
      const notificationDate = new Date(
        notification.created_at || notification.updated_at
      ).setHours(0, 0, 0, 0);
      return notificationDate < yesterday;
    });

    setTodayNotifications(todayData);
    setYesterdayNotifications(yesterdayData);
    setEarlierNotifications(earlierNotifications);
  };

  const handleIgnoreNotification = (notification) => {
    const { request: request_id, request_status_id: request_status_id } =
      notification;

    if (!request_id || !request_status_id) {
      console.error("Both 'request_id' and 'request_status_id' are required.");
      return;
    }

    dispatch(ignoreNotification({ request_id, request_status_id }))
      .unwrap()
      .then((response) => {
        console.log("Notification ignored successfully:", response);

        dispatch(getNotification({ page: 1 }))
          .then((response) => {
            if (response.payload && response.payload.data) {
              setNotifications(response.payload);
              setNotificationData(response.payload.data);
              categorizeNotifications(response.payload.data);
            }
          })
          .catch((error) => {
            console.error("Error refetching notifications:", error);
          });
      })
      .catch((error) => {
        console.error("Failed to ignore notification:", error);
      });
  };

  const handleViewNotification = (notification) => {
    const { request: request_id, request_status_id: request_status_id } =
      notification;

    if (!request_id || !request_status_id) {
      console.error("Both 'request_id' and 'request_status_id' are required.");
      return;
    }

    dispatch(viewNotification({ request_id, request_status_id }))
      .unwrap()
      .then((response) => {
        console.log("Notification ignored successfully:", response);

        dispatch(getNotification({ page: 1 }))
          .then((response) => {
            if (response.payload && response.payload.data) {
              setNotifications(response.payload);
              setNotificationData(response.payload.data);
              categorizeNotifications(response.payload.data);
            }
          })
          .catch((error) => {
            console.error("Error refetching notifications:", error);
          });
      })
      .catch((error) => {
        console.error("Failed to ignore notification:", error);
      });
  };

  const fetchMore = () => {
    console.log("called++++");
    dispatch(
      getNotification({
        page: notifications?.page + 1,
      })
    )
      .unwrap()
      .then((response) => {
        setNotifications(response);
        setNotificationData([...notificationData, ...response.data]);
        console.log({ response });
      })
      .catch((error) => {
        console.error("Error fetching more notifications:", error);
      });
  };

  const handleConfirmPopup = () => {
    dispatch(deleteNotification({ userType }))
      .unwrap()
      .then((response) => {})
      .catch((error) => {
        console.error("Error clearing notifications:", error);
      });
    setClearedIds([]);
    setTodayNotifications([]);
    setYesterdayNotifications([]);
    setNotifications({});
    setNotificationData([]);
    categorizeNotifications({});
    handleClosePopup();
  };

  const NotificationSection = ({
    title,
    notifications,
    handleIgnoreNotification,
    handleViewNotification,
    navigate,
    data,
  }) => {
    if (!notifications.length) return null;
    console.log({ data, notifications });
    return (
      <>
        <div className="flex gap-6 items-center">
          <p>{title}</p>
          <p className="bg-secondary-blue text-primary-blue py-1 px-1">
            {notifications.length}
          </p>
        </div>

        <div className="flex flex-col gap-10 py-7">
          <InfiniteScroll
            dataLength={notifications.length}
            next={fetchMore}
            hasMore={data?.total_count > notifications.length}
            loader={<h4>Loading...</h4>}
          >
            {notifications.map((item, index) => (
              <div className="flex gap-10 w-full self-stretch" key={index}>
                <div
                  style={{
                    boxShadow: "4px 4px 15px 0px rgba(0, 0, 0, 0.1)",
                    borderRadius: "3px",
                  }}
                  className="flex py-7 w-full px-8 items-center"
                >
                  <div className="flex gap-4 w-[75%]">
                    <img src={getIcon(item.status)} alt="Notification Icon" />
                    {/* <img src={`https://crrmsbackend.dataterrain-dev.net/media/${item.icon}`} alt="Notification Icon" /> */}
                    <div className="flex flex-col">
                      <div className="flex items-center gap-2">
                        <h5 className="text-[16px] capitalize">
                          {`Notification #${item.id} `}
                        </h5>
                        <p
                          className="text-[16px] capitalize"
                          style={{
                            color:
                              {
                                "in progress": "#FFCD11",
                                accepted: "#278E1D",
                                wait: "#1D5BBF",
                                rejected: "#DC3545",
                                "in review": "#E26313",
                                "on hold": "#E26313",
                                new: "#2260D9",
                                completed: "#278E1D",
                                resolved: "#278E1D",
                              }[item.status] || "#353F4F",
                          }}
                        >
                          {item.status}
                        </p>
                        {!item.read && (
                          <div className="w-1.5 h-1.5 bg-primary-blue rounded-full"></div>
                        )}
                        <p className="text-[#3E3E3E] text-[14px]">
                          {formatTime(item.updated_at)}
                        </p>
                      </div>
                      <p className="text-primary-black text-[14px] pt-3">
                        {item.description}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-end w-[25%] gap-4">
                    <button
                      className="text-primary-blue py-2 cursor-pointer w-[30%]"
                      style={{ borderRadius: "3px" }}
                      onClick={() => handleIgnoreNotification(item)}
                    >
                      Ignore
                    </button>
                    <button
                      className="bg-primary-blue text-white py-2 cursor-pointer w-[25%]"
                      style={{ borderRadius: "3px" }}
                      onClick={() => {
                        handleViewNotification(item);
                        navigate(
                          `/request-tracking?id=${
                            item.request || item.id
                          }&status=${item.status}`
                        );
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
          <div></div>
        </div>
      </>
    );
  };

  console.log({ notificationData, notifications });

  const groupNotificationsByDate = (notificationData) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const categorizedNotifications = {
      today: [],
      yesterday: [],
      earlier: [],
    };

    if (Array.isArray(notificationData)) {
      notificationData.forEach((notification) => {
        const notificationDate = new Date(notification.updated_at);
        const notificationDateString = notificationDate.toDateString();
        const todayDateString = today.toDateString();
        const yesterdayDateString = yesterday.toDateString();

        if (notificationDateString === todayDateString) {
          categorizedNotifications.today.push(notification);
        } else if (notificationDateString === yesterdayDateString) {
          categorizedNotifications.yesterday.push(notification);
        } else {
          categorizedNotifications.earlier.push(notification);
        }
      });
    }

    return categorizedNotifications;
  };

  const categorizedNotifications = groupNotificationsByDate(notificationData);

  return (
    <div className=" px-8 mt-10">
      <div
        className="px-3 py-5 mb-5"
        style={{
          boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
          borderRadius: "5px",
        }}
      >
        <div className="flex justify-between px-5 pb-4 mb-8 items-center">
          <div className="flex gap-5 items-center">
            <p className="text-[24px]">All Notification</p>
          </div>
          <div className="flex gap-8 items-center">
            <div className="relative">
              <input
                type="text"
                id="search-navbar"
                className="block w-full p-2 text-base text-gray-900 border-none focus:outline-none rounded"
                placeholder="Search"
                style={{
                  border: "1px solid rgba(34, 96, 217, 1)",
                  height: "60px",
                  width: "300px",
                }}
              />
              <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
            </div>
            {(userType === "consumer" || userType === "partner") && (
              <div
                className="relative flex gap-2 px-8 items-center"
                style={{
                  border: "1px solid rgba(34, 96, 217, 0.25)",
                  borderRadius: "3px",
                  height: "50px",
                }}
              >
                <img
                  src={CalenderIcon}
                  alt="CalenderIcon"
                  style={{ width: "20px" }}
                />
                <select
                  className="focus:outline-none"
                  style={{
                    background: "transparent",
                    border: "none",
                    boxShadow: "none",
                    padding: "0",
                    fontSize: "14px",
                  }}
                  onChange={() => undefined}
                >
                  <option value={"month"}>Month</option>
                  <option value={"year"}>Year</option>
                </select>
              </div>
            )}
          </div>
        </div>

        <div className="notification-list pr-5 pl-16">
          <div className="flex w-full justify-between items-center">
            <div className="flex gap-6 items-center"></div>
            <div onClick={() => handleClearBtn()} className="cursor-pointer">
              <p className="underline text-primary-blue underline-offset-1 link-underline-primary-blue">
                Clear All
              </p>
            </div>
          </div>

          {/* <NotificationSection
            title="Today"
            notifications={notificationData}
            data={notifications}
            handleIgnoreNotification={handleIgnoreNotification}
            handleViewNotification={handleViewNotification}
            navigate={navigate}
          /> */}

          {/* Yesterday Notifications */}
          {/* <NotificationSection
            title="Yesterday"
            notifications={yesterdayNotifications}
            handleIgnoreNotification={handleIgnoreNotification}
            handleViewNotification={handleViewNotification}
            navigate={navigate}
          />

          {/* Earlier Notifications */}
          {/* <NotificationSection
            title="Earlier Notifications"
            notifications={earlierNotifications}
            handleIgnoreNotification={handleIgnoreNotification}
            handleViewNotification={handleViewNotification}
            navigate={navigate}
          />  */}

          {categorizedNotifications.today.length > 0 && (
            <NotificationSection
              title="Today"
              notifications={categorizedNotifications.today}
              data={notifications}
              handleIgnoreNotification={handleIgnoreNotification}
              handleViewNotification={handleViewNotification}
              navigate={navigate}
            />
          )}

          {categorizedNotifications.yesterday.length > 0 && (
            <NotificationSection
              title="Yesterday"
              notifications={categorizedNotifications.yesterday}
              data={notifications}
              handleIgnoreNotification={handleIgnoreNotification}
              handleViewNotification={handleViewNotification}
              navigate={navigate}
            />
          )}

          {categorizedNotifications.earlier.length > 0 && (
            <NotificationSection
              title="Earlier"
              notifications={categorizedNotifications.earlier}
              data={notifications}
              handleIgnoreNotification={handleIgnoreNotification}
              handleViewNotification={handleViewNotification}
              navigate={navigate}
            />
          )}

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => 1 }}
            open={notificationAction.modal}
          >
            <div
              className="popup-content relative border border-primary-red bg-white flex flex-col gap-2 justify-center items-center"
              style={{
                borderRadius: "10px",
                paddingTop: "30px",
                width: "406px",
                height: "193px",
              }}
            >
              <img
                src={CloseRedBgIcon}
                className="absolute top-3 w-5 h-5 right-3"
                alt="TickColorIcon"
                onClick={handleClosePopup}
              />

              {/* Close Icon Centered Above the Content */}
              <div
                className="flex justify-center items-center pt"
                style={{
                  background: "rgba(224, 56, 45, 0.2)",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  marginTop: "-30px",
                  marginBottom: "0px",
                }}
              >
                <span
                  style={{
                    color: "#E0382D",
                    fontSize: "50px",
                    fontWeight: 200,
                  }}
                >
                  ×
                </span>
              </div>

              <div className="py-5 mt-0">
                <p
                  style={{
                    color: "rgba(24, 40, 61, 1)",
                    fontWeight: 600,
                    fontSize: "17px",
                  }}
                >
                  Are you sure want to clear the notification?
                </p>
              </div>
              <div className="flex justify-center">
                <div className="flex gap-6 justify-center align-middle">
                  <Button
                    btnCls="w-[110px] h-[40px] text-primary-black"
                    btnName={"No"}
                    btnCategory="secondary"
                    onClick={handleClosePopup}
                  />
                  <Button
                    btnType="button"
                    btnCls="w-[110px] h-[40px]"
                    btnName={"Yes"}
                    style={{ background: "#E0382D" }}
                    btnCategory="primary"
                    onClick={handleConfirmPopup}
                  />
                </div>
              </div>
            </div>
          </Backdrop>
        </div>
      </div>
    </div>
  );
}
