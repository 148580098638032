import React, { useState } from "react";
import RequestDetails from "./RequestDetails";
import { useSelector } from "react-redux";
import AdminRequest from "./AdminRequest";

export default function AllRequest() {
  const [viewMode, setViewMode] = useState("detail-view");
  const { userType } = useSelector((state) => state.userInfo);

  return (
    <div className="bg-[#FCFCFC]">
      {userType === "consumer" && (
        <>
          {viewMode === "detail-view" && (
            <div className=" request-details">
              <RequestDetails handleView={() => setViewMode("list-view")} />
            </div>
          )}
        </>
      )}
      {(userType === "admin" || userType === 'agent' ||  viewMode === "list-view") && <AdminRequest setViewMode={setViewMode} />}
    </div>
  );
}
