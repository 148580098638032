import React, { useEffect } from "react";
import SearchIcon from "../../assets/search-icon.svg";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import {
  getAllPartnerRequestList,
  markImportant,
} from "../../services/request";
import { useDispatch, useSelector } from "react-redux";
import OptIcon from "../../assets/opt.svg";
import ViaIcon from "../../assets/via.svg";
import moment from "moment";
import trackIcon from "../../assets/trackIcon.svg";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import MoreIcon from "../../assets/more-icon.svg";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import {
  getPartnerRequestById,
  getPartnerRequestByStatus,
} from "../../services/partnerRequest";
import { useSearchParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react";
import { updateStatusByRequestId } from "../../services/partner";
import FilledStar from "../../assets/filledstar.svg";
import UnFilledStar from "../../assets/unfilledstar.svg";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import DataTable from "../../shared/DataGrid";
import { titleCase } from "../../utills/castle";
import CancelRequest from "../Request/AllRequest/CancelRequest";
import useDebounce from "../../utills/debounce";

export const ResponseCell = ({ value, onChange, serviceOption }) => {
  const [selectedStatus, setSelectedStatus] = useState(value);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { data, userType } = useSelector((state) => state.userInfo);

  const colors = {
    "in progress": "text-[#FFCD11]",
    accepted: "text-[#278E1D]",
    wait: "text-[#1D5BBF]",
    "on hold": "text-[#E26313]",
    rejected: "text-[#DC3545]",
    completed: "text-[#278E1D]",
    resolved: "text-[#278E1D]",
  };

  const statusOptions = [
    "accepted",
    "on hold",
    "rejected",
    "completed",
    "resolved",
  ];

  useEffect(() => {
    if (selectedStatus === "accepted" && serviceOption === "Paid") {
      setSelectedStatus("completed");
      onChange("completed");
    }
  }, [selectedStatus, serviceOption, onChange]);

  const filteredStatusOptions =
  userType === "partner"
    ? statusOptions.filter((status) => status !== "completed")
    : statusOptions;

  const filteredOptions = filteredStatusOptions.filter((option) => {
    if (selectedStatus === "accepted") {
      return option !== "accepted";
    } else if (selectedStatus === "wait") {
      return option !== "completed";
    } else return option !== selectedStatus;
  });
  const handleChange = (newStatus) => {
    // setSelectedStatus(newStatus);
    onChange(newStatus);
  };

  return (
    <div className="">
      <Listbox
        value={selectedStatus}
        onChange={handleChange}
        disabled={["completed", "rejected", "resolved"].includes(
          selectedStatus
        )}
        className="flex flex-col absolute"
      >
        {({ open }) => (
          <>
            <ListboxButton
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className={`capitalize ${
                colors[selectedStatus] || ""
              }  border-none rounded   w-full cursor-pointer flex items-center justify-between`}
            >
              {open ? (
                selectedStatus === "resolved" ? (
                  <p>{selectedStatus}</p>
                ) : (
                  selectedStatus !== "rejected" && (
                    <p className="text-[#848484]">Select Status</p>
                  )
                )
              ) : selectedStatus === "wait" ? (
                "Waiting"
              ) : (
                selectedStatus
              )}

              {selectedStatus !== "rejected" &&
                selectedStatus !== "completed" &&
                selectedStatus !== "resolved" && (
                  <>
                    {open ? (
                      <KeyboardArrowUpIcon className="ml-2 text-[#353F4F]" />
                    ) : (
                      <KeyboardArrowDownIcon className="ml-2 text-[#353F4F]" />
                    )}
                  </>
                )}
            </ListboxButton>
            {open && (
              <ListboxOptions className="absolute w-40  z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-b-md bg-white border border-[#D9D9D9] shadow-lg  transition focus:outline-none ">
                {filteredOptions.map((option) => (
                  <ListboxOption
                    key={option}
                    value={option}
                    className={`block px-4 py-2 text-sm capitalize ${
                      colors[option] || ""
                    } border-[#D9D9D9]`}
                  >
                    {option}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            )}
          </>
        )}
      </Listbox>
    </div>
  );
};

function PartnerRequestsView() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionTab, setActiveTab] = useState("all");
  const [pages, setPage] = useState({ page: 0, pageSize: 10 });

  const userData = useSelector((state) => state.userInfo.data);
  const { data, userType } = useSelector((state) => state.userInfo);
  const userId = userData.id;
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const id = searchParams.get("id");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const searchValue = useDebounce(searchQuery, 500);

  // const id = userData.id;
  const [detailAction, setDetailAction] = useState({
    cancelModal: false,
    id: "",
  });
  const navigate = useNavigate();
  const handlePageChange = (page) => {
    setPage(page);
  };
  const options = [
    { value: "", label: "All" },
    { value: "new", label: "New" },
    { value: "in progress", label: "In Progress" },
    { value: "resolved", label: "Resolved" },
    { value: "completed", label: "Completed" },
    { value: "on hold", label: "On Hold" },
    { value: "rejected", label: "Rejected" },
    { value: "important", label: "Important" },
  ];

  useEffect(() => {
    dispatch(markImportant());
  }, [dispatch]);

  const columns = [
    {
      field: "opt",
      headerName: "OPT",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-[5px]">
          <p className="font-sans text-[#18283D] font-semibold"></p>
          {/* <img src={OptIcon} alt="opt" /> */}
        </div>
      ),
      renderCell: (params) => {
        const { otp, id } = params.row;

        const handleStarClick = () => {
          dispatch(markImportant({ request_id: id, priority: !otp })).then(
            dispatch(getAllPartnerRequestList(userData.id))
          );
        };

        return (
          <>
            <img
              src={otp ? FilledStar : UnFilledStar}
              alt={otp ? "Filled Star" : "Unfilled Star"}
              className="w-5 h-5 mt-4 cursor-pointer"
              onClick={handleStarClick}
            />
          </>
        );
      },
    },
    // {
    //   field: "requestId",
    //   headerName: "Request ID",
    //   flex: 1,
    // },
    {
      field: "name",
      headerName: "Client Name",
      flex: 1,
      renderCell: (params) => {
        return <div className="capitalize">{params.row.name}</div>;
      },
    },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
    },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   flex: 1,
    // },
    {
      field: "category",
      headerName: "Program",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>{params.row.category ? titleCase(params.row.category) : ""}</div>
        );
      },
    },
    {
      field: "service_option",
      headerName: "Service Option",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const isPaid = params.row.category_is_premium === "True";
        return <div className="capitalize">{isPaid ? "Paid" : "⋯"}</div>;
      },
    },
    {
      field: "via",
      headerName: "Via",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-3">
          <p className="font-sans text-[#18283D] font-semibold">Via</p>
          <img src={ViaIcon} alt="via" />
        </div>
      ),
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "level",
      headerName: "Level",
      flex: 1,
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "intakeDate",
      headerName: "Intake Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.intakeDate).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "created_by",
      headerName: "Requested By",
      flex: 1,
      rendercell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "receivedDate",
      headerName: "Received Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.receivedDate).format("MM/DD/YYYY | h:mm A");
      },
    },

    {
      field: "response",
      headerName: "Your Response",
      flex: 2,
      renderCell: (params) => (
        <ResponseCell
          value={params.value}
          serviceOption={params.row.service_option}
          onChange={(newResponse) =>
            handleResponseChange(params.id, newResponse)
          }
        />
      ),
    },
    {
      field: "requestStatus",
      headerName: "Request Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          completed: "text-[#278E1D]",
          resolved: "text-[#278E1D]",
          accepted: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF]",
          "in review": "text-[#1D5BBF]",
        };
        return (
          <div className={`capitalize ${colors[params.value] || ""}`}>
            {params.value === "in review" ? "new" : params?.value}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,

      renderCell: (params) => {
        return (
          <Menu as="div" className="inline-block text-left mt-2">
            <div>
              <MenuButton className="cursor-pointer relative inline-flex w-full justify-center gap-x-1.5 py-1 text-sm font-semibold text-gray-900">
                <img
                  src={MoreIcon}
                  alt="MoreIcon"
                  onClick={(e) => handleClick(e, params.row)}
                />
              </MenuButton>
            </div>

            <MenuItems
              transition
              className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                <MenuItem
                  onClick={() => {
                    navigate(`/view-request/${params?.row?.id}`);
                  }}
                >
                  <a
                    href="#"
                    className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                  >
                    View Details
                  </a>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                       
                    navigate(
                      `/request-tracking?id=${params?.row?.id}&status=${params?.row?.requestStatus === 'in progress' && userType === 'partner' ? 'accepted' : params?.row?.requestStatus}`
                    );
                  }}
                >
                  <a className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                    Track Request
                  </a>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    navigate(`/request-history/${params?.row?.id}`)
                  }
                >
                  <a className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                    History
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="#"
                    className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                  >
                    share
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        );
      },
    },
  ];

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };
  const currentRequestData = useSelector(
    (state) => state.requestInfo?.data?.result?.data
  );
  const total = useSelector(
    (state) => state.requestInfo?.data?.result?.total_pages
  );
  const totalCount = useSelector(
    (state) => state.requestInfo?.data?.result?.total_count
  );

  useEffect(() => {
    if (userId) {
      dispatch(
        getAllPartnerRequestList({
          userId,
          status: selectedValue,
          page: pages.page + 1,
          search: searchValue
        })
      );
    }
  }, [dispatch, userId, selectedValue, pages.page, searchValue]);

  useEffect(() => {
    if (status) {
      setActiveTab(status);
    }
  }, [status]);

  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (Array.isArray(currentRequestData) && currentRequestData.length > 0) {
      const updatedRows = currentRequestData.map((item) => ({
        id: item.id,
        requestId: item.id,
        name: item.first_name,
        category: item.category_name,
        contact: item.contact,
        service_option: item.service,
        category_is_premium: item.category_is_premium,
        via: item.request_via,
        intakeDate: item.created_at,
        created_by: item.created_by,
        level: item.urgency_level,
        description: item.description,
        receivedDate: item.updated_at,
        requestStatus: item.request_status,
        response: item.partner_status,
        otp: item.priority,
      }));
      setRows(updatedRows);
    } else {
      setRows([]);
    }
  }, [currentRequestData, selectedValue]);

  const determineRequestStatus = (response) => {
    switch (response) {
      case "accepted":
        return userType === "partner" ? "accepted" : "in progress";
      case "completed":
        return "completed";
      case "resolved":
        return "resolved";
      case "wait":
        return "new";
      case "on hold":
        return "on hold";
      case "rejected":
        return "rejected";
    }
  };

  const handleResponseChange = (id, newResponse) => {
    if (newResponse === "rejected") {
      setDetailAction({
        cancelModal: true,
        id: id,
        reject: true,
      });
      return;
    }
    dispatch(
      updateStatusByRequestId({
        request_id: +id,
        partner_status: determineRequestStatus(newResponse),
      })
    ).then(() => {
      if (userData?.id) {
        dispatch(getAllPartnerRequestList(userData?.id));
      }
    });
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id
          ? {
              ...row,
              response: newResponse,
              requestStatus: determineRequestStatus(newResponse),
            }
          : row
      )
    );
  };

  

  const closeModal = () => {
    setDetailAction({ cancelModal: false, id: "" });
    // setShowSuccess({ model: true, status: "rejected" });
    if (id) dispatch(getAllPartnerRequestList({ userId: id }));
  };

  return (
    <main className="p-10">
      <BreadCumbs
        firstCumbName="Request"
        secoundCumbName="Request Status"
        pathToRedirect=""
        pathToRedirectSecond="all-request"
      />
      {userType === "partner" && (
        <div className="w-full flex justify-end mt-5">
          <div
            className="flex cursor-pointer"
            onClick={() => navigate(`/request-tracking`)}
          >
            <div className="flex items-center pointer-events-none end-0 pe-2">
              <img src={trackIcon} alt="track Icon" />
            </div>
            <div className="text-[#2260D9] text-[18px] font-normal">
              Track View
            </div>
          </div>
        </div>
      )}
      <div className="mt-7 p-2  rounded details-info custom-shadow">
        <div className="p-4 flex items-center justify-between pt-6 pb-10">
          <h2 className="text-[#353F4F] font-medium text-3xl capitalize">
            {selectedValue} Request ({totalCount})
          </h2>
          <div className="flex gap-6">
            <div>
              <Listbox value={selectedValue} onChange={setSelectedValue}>
                {({ open }) => (
                  <div className="relative">
                    <Listbox.Button
                      className={` w-[250px] h-[50px] flex justify-between items-center pl-6 pr-3 py-2 text-base ${
                        open ? "text-[#A9A9A9]" : "text-[#353F4F]"
                      } border border-[#353F4F] rounded-[3px]`}
                    >
                      {!open
                        ? options.find(
                            (option) => option.value === selectedValue
                          )?.label
                        : "Select"}

                      {open ? (
                        <KeyboardArrowUpIcon style={{ color: "black" }} />
                      ) : (
                        <KeyboardArrowDownIcon style={{ color: "black" }} />
                      )}
                    </Listbox.Button>
                    {open && (
                      <Listbox.Options className="absolute z-10 w-full mt-1 bg-white border border-[#9EBCE3] rounded-[3px] ">
                        {options.map((option) => (
                          <Listbox.Option
                            key={option.value}
                            value={option.value}
                            className={({ active, selected }) =>
                              `cursor-pointer px-4 py-2 mb-1 text-[#353F4F] text-sm ${
                                active ? "bg-[#EEF4FF]" : ""
                              } ${selected ? "bg-[#EEF4FF]" : ""}`
                            }
                          >
                            {option.label}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    )}
                  </div>
                )}
              </Listbox>
            </div>
            <div className="flex gap-8">
              <div className="relative">
                <input
                  type="text"
                  className="block w-full p-2 text-sm text-gray-900 border-none focus:outline-none rounded-[3px] h-[50px] placeholder:text-[#D6E4FF]"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    border: "1px solid rgba(29, 91, 191, 1)",
                    width: "300px",
                  }}
                />
                <div className="absolute right-4 top-3">
                  <img src={SearchIcon} alt="SearchIcon" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-5">
          <DataTable
            columns={columns}
            rows={rows}
            pages={pages}
            totalRows={totalCount}
            handlePageChange={handlePageChange}
            loading={false}
            hideCheckbox
          />
        </div>
      </div>
      <CancelRequest
        open={detailAction.cancelModal}
        id={detailAction.id}
        closeModal={closeModal}
        actionTab={""}
        isRejectPopup={true}
      />
    </main>
  );
}

export default PartnerRequestsView;
