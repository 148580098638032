import React, { useState } from "react";
import FailedImage from "../../assets/failed.gif";
import CloseRedIcon from "../../assets/close-red-icon.svg";
import { useLocation, useNavigate } from "react-router";

const Failed = () => {
  const [isVisible, setIsVisible] = useState(true);

  const navigate = useNavigate();
   const location = useLocation();

  const handleClose = () => {
    setIsVisible(false);
  };

  const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");
    const id = queryParams.get("id");

  if (!isVisible) return null;
  // const handleDownloadInvoice = async () => {
  //   if (onDownloadInvoice) {
  //     onDownloadInvoice();
  //   } else {
  //     try {
  //       const response = await fetch('/api/download-invoice');
  //       const blob = await response.blob();
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = 'invoice.pdf';
  //       document.body.appendChild(a);
  //       a.click();
  //       a.remove();
  //     } catch (error) {
  //       console.error('Error downloading invoice:', error);
  //     }
  //   }
  // };

  return (
    <div className=" bg-white flex items-center justify-center p-4 border border-gray-200 rounded-lg border-[#2260D9] mx-8 my-12 relative">
      {/* Close Icon */}
      <img
        src={CloseRedIcon}
        // onClick={() => navigate(`/request-tracking?id=${id}&status=${status}`)}
          onClick={()=>navigate("request-tracking")}
        // onClick={handleClose}
        alt="Close"
        className="absolute top-4 right-4 w-6 h-6 cursor-pointer"
      />
      {/* Container with border */}

      <div className="px-8 py-16 w-full flex flex-col items-center">
        {/* Success GIF */}
        <div className="mb-8">
          <img src={FailedImage} alt="Payment Success" className="w-56 h-48" />
        </div>

        {/* Success Content */}
        <div className="text-center">
          {/* Success Message */}
          <h1 className="text-[#DC3545] text-4xl font-medium mb-6">
            Payment Failed
          </h1>

          {/* Thank you Message */}
          <p className="text-gray-600 text-lg mb-12">
            Oops! An error occurred. Please try again.
          </p>

          {/* Download Button */}
          <button
            // onClick={handleDownloadInvoice}
            className="bg-[#2260D9] text-white px-8 py-3 rounded hover:bg-blue-700 transition-colors text-lg"
          >
            Download Invoice
          </button>
        </div>
      </div>
    </div>
  );
};

export default Failed;
