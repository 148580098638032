import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Autocomplete,
  TextField,
  Chip,
} from "@mui/material";
import Draft from "../../assets/draft.svg";
import Right from "../../assets/right.svg";
import SelectMemberPopup from "./AgentPopup";
import Calender from "../../assets/calendar-icon.svg";
import Time from "../../assets/time.svg";
import {
  createMeeting,
  editMeeting,
  getCurrentMeeting,
} from "../../services/createMeeting";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";

const CreateMeeting = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [openAgent, setOpenAgent] = useState(false);
  const attendees = watch("attendees") || [];
  const meeting_type = watch("meeting_type");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allValues = watch();
  const [searchParams] = useSearchParams();
  const { loading, details } = useSelector((state) => state.meetingInfo);

  const id = searchParams.get("id");

  const onSubmit = (data) => {
    const payload = {
      ...data,
      attendees,
      meeting_type,
      notification_type: "minutes",
      notification_time: "15",
      date_category: "do_not_repeat",
    };
    if (id) {
      const {
        notification_time,
        notification_type,
        description,
        meeting_type,
        start,
        end,
        ...rest
      } = payload;

      // Adjust the `start` and `end` if their length is 8
      const modifiedStart = start?.length === 8 ? start.slice(0, -3) : start;
      const modifiedEnd = end?.length === 8 ? end.slice(0, -3) : end;

      console.log({modifiedStart, modifiedEnd})

      dispatch(
        editMeeting({
          id,
          data: { ...rest, start: modifiedStart, end: modifiedEnd },
        })
      );
    } else {
      const modifiedPayload = {
        ...payload,
        start:
          payload.start?.length === 8
            ? payload.start.slice(0, -3)
            : payload.start,
        end: payload.end?.length === 8 ? payload.end.slice(0, -3) : payload.end,
      };

      dispatch(createMeeting(modifiedPayload));
    }

    navigate("/scheduler");
  };

  useEffect(() => {
    if (selected) {
      setValue("attendees", selected);
    }
  }, [selected]);

  useEffect(() => {
    if (id) {
      dispatch(getCurrentMeeting(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && Object.keys(details).length > 0) {
      reset({
        attendees: details.data.attendees?.map((ele) => ele?.email),
        description: details.data.description,
        end_date: details.data.end_date,
        end: details.data.end,
        meeting_type: details.data.meeting_type,
        start: details.data.start,
        start_date: details.data.start_date,
        title: details.data.title,
      });
    } else if (!id) {
      reset({
        attendees: [],
        description: "",
        end_date: "",
        end: "",
        meeting_type: "",
        start: "",
        start_date: "",
        title: "",
      });
    }
  }, [id, details, reset]);

  const handleDraftClick = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmDraft = async () => {
    const payload = {
      ...allValues,
      status: "draft",
      attendees,
      meeting_type,
      notification_type: "minutes",
      notification_time: "15",
      date_category: "do_not_repeat",
    };
    if (id) {
      const {
        notification_time,
        notification_type,
        description,
        meeting_type,
        start,
        end,
        ...rest
      } = payload;

      // Adjust the `start` and `end` if their length is 8
      const modifiedStart = start?.length === 8 ? start.slice(0, -3) : start;
      const modifiedEnd = end?.length === 8 ? end.slice(0, -3) : end;

      console.log({modifiedStart, modifiedEnd})

      dispatch(
        editMeeting({
          id,
          data: { ...rest, start: modifiedStart, end: modifiedEnd },
        })
      );
    } else {
      const modifiedPayload = {
        ...payload,
        start:
          payload.start?.length === 8
            ? payload.start.slice(0, -3)
            : payload.start,
        end: payload.end?.length === 8 ? payload.end.slice(0, -3) : payload.end,
      };

      dispatch(createMeeting(modifiedPayload));
    }

    setOpen(false);
    navigate("/scheduler");

  };
  const handleCancel = () => {
    navigate("/scheduler");
  };

  return (
    <div className="px-8 mt-10">
      <BreadCumbs
        firstCumbName="Calendar"
        secoundCumbName="Create New Meeting"
        pathToRedirect="scheduler"
      />
      <div className="p-6 mt-4 bg-white rounded shadow-md text-[14px]">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          {/* Title */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-[#353F4F] text-[14px] mb-1">
                Title <span className="text-[red]">*</span>
              </label>
              <input
                type="text"
                {...register("title", { required: "Title is required" })}
                className="w-full bg-[#F1F6FF] px-4 py-2 focus:outline-none focus:border-blue-500"
                placeholder="Enter title"
              />
              {errors.title && (
                <p className="text-[#E0382D] text-[12px]">
                  {errors.title.message}
                </p>
              )}
            </div>

            {/* Meeting Type */}
            <div>
              <label className="block  mb-1">Select Meeting Type</label>
              <div className="flex space-x-4 mt-2">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    value="internal"
                    {...register("meeting_type")}
                    className="hidden peer"
                  />
                  <span className="w-5 h-5 border-2 border-[#BEBCBC] flex items-center justify-center peer-checked:border-transparent">
                    {meeting_type === "internal" && (
                      <img src={Right} alt="right" />
                    )}
                  </span>
                  <span>Internal</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    value="external"
                    {...register("meeting_type")}
                    className="hidden peer1"
                  />
                  <span className="w-5 h-5 border-2 border-[#BEBCBC] flex items-center justify-center peer1-checked:border-transparent">
                    {meeting_type === "external" && (
                      <img src={Right} alt="right" />
                    )}
                  </span>
                  <span>External</span>
                </label>
              </div>
            </div>
          </div>

          {/* Start and End Date */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-[#353F4F] text-[14px] mb-1">
                Start Date <span className="text-[red]">*</span>
              </label>
              <div className="relative w-full bg-[#F1F6FF] px-4 py-4 focus-within:outline-none focus-within:border-blue-500">
                <Controller
                  name="start_date"
                  control={control}
                  rules={{ required: "Start date is required" }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      selected={field.value}
                      onChange={(date) => {
                        const formattedTime = date
                          ? moment(date).format("YYYY-MM-DD")
                          : null; // Format to "HH:mm"

                        console.log(formattedTime, "formattedTime");
                        field.onChange(formattedTime);
                      }}
                      dateFormat="YYYY-MM-DD"
                      placeholderText="Select start date"
                      className="w-[650px] bg-[#F1F6FF] focus:outline-none"
                    />
                  )}
                />
                <img
                  src={Calender}
                  alt="Calendar Icon"
                  className="absolute top-1/2 right-3 transform -translate-y-1/2"
                />
              </div>
              {errors.start_date && (
                <p className="text-[#E0382D] text-[12px]">
                  {errors.start_date.message}
                </p>
              )}
            </div>
            <div>
              <label className="block text-[#353F4F] text-[14px] mb-1">
                End Date <span className="text-[red]">*</span>
              </label>
              {/* <div className="w-full bg-[#F1F6FF] px-4 py-2 focus:outline-none  focus:border-blue-500">
              <input
                type="date"
                {...register("end_date", { required: "End date is required" })}
                
              />
              <img src={Calender} alt="" />
              </div>
              {errors.end_date && (
                <p className="text-[#E0382D] text-[12px]">
                  {errors.end_date.message}
                </p>
              )} */}
              <div className="relative w-full bg-[#F1F6FF] px-4 py-4 focus-within:outline-none focus-within:border-blue-500">
                <Controller
                  name="end_date"
                  control={control}
                  rules={{ required: "End date is required" }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      selected={field.value}
                      onChange={(date) => {
                        const formattedTime = date
                          ? moment(date).format("YYYY-MM-DD")
                          : null; // Format to "HH:mm"
                        field.onChange(formattedTime);
                      }}
                      dateFormat="YYYY-MM-DD"
                      placeholderText="End start date"
                      className="w-[650px] bg-[#F1F6FF] focus:outline-none"
                    />
                  )}
                />
                <img
                  src={Calender}
                  alt="Calendar Icon"
                  className="absolute top-1/2 right-3 transform -translate-y-1/2"
                />
              </div>
              {errors.end_date && (
                <p className="text-[#E0382D] text-[12px]">
                  {errors.end_date.message}
                </p>
              )}
            </div>
          </div>
          {/* Start and End Time */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-[#353F4F] text-[14px] mb-1">
                Start Time <span className="text-[red]">*</span>
              </label>
              {/* <div className="w-full bg-[#F1F6FF] px-4 py-2 focus:outline-none  focus:border-blue-500">
                <input
                  type="time"
                  {...register("start", {
                    required: "Start time is required",
                  })}
                />
                <img src={Time} alt="" />
              </div> */}
              <div className="relative w-full bg-[#F1F6FF] px-4 py-4 focus-within:outline-none focus-within:border-blue-500">
                <Controller
                  name="start"
                  control={control}
                  rules={{ required: "Start Time is required" }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      selected={
                        field.value
                          ? moment(field.value, "HH:mm").toDate()
                          : null
                      }
                      onChange={(date) => {
                        const formattedTime = date
                          ? moment(date).format("HH:mm")
                          : null; // Format to "HH:mm"
                        console.log(formattedTime);
                        field.onChange(formattedTime);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      placeholderText="Select start time"
                      className="w-[650px] bg-[#F1F6FF] focus:outline-none "
                    />
                  )}
                />

                <img
                  src={Time}
                  alt="Time Icon"
                  className="absolute top-1/2 right-3 transform -translate-y-1/2"
                />
              </div>
              {errors.start && (
                <p className="text-[#E0382D] text-[12px]">
                  {errors.start.message}
                </p>
              )}
            </div>
            <div>
              <label className="block text-[#353F4F] text-[14px] mb-1">
                End Time <span className="text-[red]">*</span>
              </label>
              {/* <div className="w-full bg-[#F1F6FF] px-4 py-2 focus:outline-none  focus:border-blue-500">
                <input
                  type="time"
                  {...register("end", { required: "End time is required" })}
                />
                <img src={Time} alt="" />
              </div> */}
              <div className="relative w-full bg-[#F1F6FF] px-4 py-4 focus-within:outline-none focus-within:border-blue-500">
                <Controller
                  name="end"
                  control={control}
                  rules={{ required: "End Time is required" }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      selected={
                        field.value
                          ? moment(field.value, "HH:mm").toDate()
                          : null
                      }
                      onChange={(date) => {
                        const formattedTime = date
                          ? moment(date).format("HH:mm")
                          : null; // Format to "HH:mm"
                        field.onChange(formattedTime);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      placeholderText="Select end time"
                      className="w-[650px] bg-[#F1F6FF] focus:outline-none "
                    />
                  )}
                />
                <img
                  src={Time}
                  alt="Time Icon"
                  className="absolute top-1/2 right-3 transform -translate-y-1/2"
                />
              </div>
              {errors.end && (
                <p className="text-[#E0382D] text-[12px]">
                  {errors.end.message}
                </p>
              )}
            </div>
          </div>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-[#353F4F] text-[14px] mb-1">
                Notification Type
              </label>
              <input
                {...register("notification_type", {
                  required: "Notification Type time is required",
                })}
                value={"minutes"}
                disabled
                className="w-full bg-[#F1F6FF] px-4 py-2 focus:ring-blue-500 focus:border-blue-500"
              />
              {errors.notification_type && (
                <p className="text-[#E0382D] text-[12px]">
                  {errors.notification_type.message}
                </p>
              )}
            </div>
            <div>
              <label className="block text-[#353F4F] text-[14px] mb-1">
                Notification Time (minutes)
              </label>
              <select
                value="15"
                {...register("notification_time", {
                  required: "Notification Time is required",
                })}
                disabled
                className="w-full bg-[#F1F6FF] px-4 h-[41px] focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
              </select>
              {errors.notification_time && (
                <p className="text-[#E0382D] text-[12px]">
                  {errors.notification_time.message}
                </p>
              )}
            </div>
          </div>

          <div>
            <label className="block text-[#353F4F] text-[14px] mb-1">
              Date Category
            </label>
            <select
              {...register("date_category", {
                required: "Date Category is required",
              })}
              className="w-full h-[41px]  bg-[#F1F6FF] px-4 py-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Select Date Category</option>
              <option value="do_not_repeat">Do Not Repeat</option>
              <option value="daily">Daily</option>
              <option value="every_week">Every Week</option>
              <option value="weekly">Weekly</option>
              <option value="custom">Custom</option>
            </select>
            {errors.date_category && (
              <p className="text-[#E0382D] text-[12px]">
                {errors.date_category.message}
              </p>
            )}
          </div> */}

          {/* Member Selection */}
          <div>
            <label className="block text-[#353F4F] text-[14px] mb-1">
              Select Member or Email ID <span className="text-[red]">*</span>
            </label>
            <div className="relative ">
              <Controller
                name="attendees"
                control={control}
                defaultValue={[]}
                rules={{
                  required: "Attendees are required",
                  validate: (value) => {
                    return value
                      .map((email) => email?.email?.trim() || email?.trim()) // Remove any extra spaces
                      .every((email) =>
                        /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/.test(email)
                      );
                  },
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    freeSolo
                    options={[]}
                    onChange={(event, newValue) => field.onChange(newValue)}
                    value={field.value}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.email || option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Write and press enter to add"
                        error={!!errors.attendees}
                        helperText={
                          errors.attendees && errors.attendees.message
                        }
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            height: 41,
                          },
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <span
                                className="h-6 w-6 text-[20px] text-[#2260D9] border border-dashed border-[#2260D9] flex items-center justify-center 
                                ml-2 cursor-pointer"
                                onClick={() =>
                                  meeting_type === "internal" &&
                                  setOpenAgent(true)
                                }
                              >
                                +
                              </span>
                            </>
                          ),
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "4px",
                            height: 45,
                            border: "none",
                            backgroundColor: "#F1F6FF",
                          },
                          "& .MuiInputBase-root": {
                            padding: "0 10px",
                          },
                          "& .MuiFormHelperText-root": {
                            marginTop: "4px",
                          },
                        }}
                      />
                    )}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                        height: 41,
                        border: "none",
                        backgroundColor: "#F1F6FF",
                      },
                      "& .MuiInputBase-root": {
                        padding: "0 10px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderWidth: 0,
                      },
                    }}
                  />
                )}
              />
            </div>
          </div>

          {/* Description */}
          <div>
            <label className="block text-[#353F4F] text-[14px] mb-1">
              Description
            </label>
            <textarea
              {...register("description")}
              className="w-full bg-[#F1F6FF] px-4 py-2 focus:outline-none focus:border-blue-500"
              placeholder="Enter description"
            ></textarea>
          </div>

          {/* Buttons */}
          <div className="flex justify-center space-x-4 mt-4">
            <button  onClick={handleCancel} type="button" className="px-8 py-2 border border-[#353F4F]">
              Cancel
            </button>
            <button
              onClick={handleDraftClick}
              type="button"
              className="px-8 py-2 bg-[#D9E4F2] text-[#1D5BBF] "
            >
              Draft
            </button>
            <button type="submit" className="px-8 py-2 bg-[#2260D9] text-white">
              Create
            </button>
          </div>
        </form>
        {/* Draft Confirmation Dialog */}
        <Dialog open={open} onClose={handleClose} className="!p-6">
          <DialogContent className="!p-2">
            <div className="flex justify-center items-center">
              <img src={Draft} alt="Draft Icon" className="w-12 h-12 mt-6" />
            </div>
          </DialogContent>
          <DialogTitle className="!text-[#18283D] !text-[20px] !text-center !font-medium !p-0 !pt-2 !px-4">
            Are you sure move to draft?
          </DialogTitle>
          <DialogContent className="flex justify-center !p-4">
            <Button
              onClick={handleClose}
              className="!text-[#000] border !border-[#1D5BBF] !px-12"
            >
              No
            </Button>
            <Button
              onClick={confirmDraft}
              autoFocus
              className="!text-white !bg-[#2260D9] !px-12"
            >
              Yes
            </Button>
          </DialogContent>
        </Dialog>
        {openAgent && meeting_type === "internal" && (
          <SelectMemberPopup
            setSelected={setSelected}
            selected={selected}
            openAgent={openAgent}
            setOpenAgent={setOpenAgent}
          />
        )}
      </div>
    </div>
  );
};

export default CreateMeeting;
