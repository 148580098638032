import React from "react";
import MuiModal from "../../../shared/Modal";
import Tooltip from "../../../shared/Tooltip";

import CloseIcon from "../../../assets/close-red-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { cancelRequest } from "../../../services/request";
import { getCurrentRequest } from "../../../services/consumerCategory";
import { useNavigate } from "react-router";
import { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default function ChangeInReview({
  open,
  closeModal,
  id,
  actionTab,
  details,
  refetch,
}) {
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);
  const { data, userType } = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState(false);
  console.log("det", details);
  // const handleClick = () => {
  //     const payload = {
  //         request_id: id,
  //         is_deleted: true
  //     }
  //     dispatch(cancelRequest(payload))
  //     if (actionTab) {
  //         dispatch(getCurrentRequest({ status: actionTab, ...(userType === "consumer" ? { user_id: data.id } : {}) }));
  //     } else {
  //         navigate(-1)
  //     }
  //     closeModal()
  // }

  const handleClick = () => {
    if (!comment.trim()) {
      setCommentError(true);
      return;
    }
    const payload = {
      request_id: id,
      partner_status: "in review",
      reason: comment,
    };
    dispatch(cancelRequest(payload)).then(() => {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false); // Hide the success message
        refetch();
        setComment("");
      }, 2000);
    });

    closeModal();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    if (e.target.value.trim()) {
      setCommentError(false);
    }
  };

  return (
    <>
      {showSuccess && (
        <MuiModal
          modalSize="sm"
          modalOpen={showSuccess}
          modalClose={() => setShowSuccess(false)}
          noheader
        >
          <div className="flex items-center justify-center p-4">
            <p className=" font-medium text-[18px]">
              Changed Status Sucessfully
            </p>
          </div>
        </MuiModal>
      )}

      <MuiModal
        modalSize="md"
        modalOpen={open}
        modalClose={closeModal}
        noheader
      >
        <div>
          <div
            className="px-2 py-3 title"
            style={{ borderBottom: "1px solid rgba(214, 214, 214, 1)" }}
          >
            <div className="flex items-center justify-between">
              <p className=" font-medium text-[18px]">
                Are you sure want to change the status?
              </p>
              <Tooltip title="Cancel">
                <img
                  src={CloseIcon}
                  className="cursor-pointer"
                  onClick={closeModal}
                  alt="CloseIcon"
                />
              </Tooltip>
            </div>
          </div>
          <div className="w-full mt-4 rounded-lg bg-[#F1F6FF] p-4 ">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="relative">
                  {/* <img src={"asfa"} alt="new image" height={40} width={50} /> */}
                  <div
                    className="flex items-center justify-center bg-white custom-shadow w-[70px] h-[70px] p-2"
                    style={{
                      borderRadius: "5px",
                    }}
                  >
                    <img
                      src={`https://crrmsbackend.dataterrain-dev.net/media/${details?.category_image}`}
                      alt="Request Icon"
                      style={{
                        width: "45px",
                        height: "45px",
                        objectFit: "contain", // Ensures the image maintains aspect ratio
                      }}
                    />
                  </div>
                  {/* <ScrollText className="h-5 w-5 text-blue-500" /> */}
                </div>
                <div className="flex flex-col">
                  <h3 className="font-medium text-[24px] text-gray-900 capitalize">
                    {details?.category_name} Request
                  </h3>
                  <p className="text-[16px] text-gray-500">
                    Request date & Time:{" "}
                    <Link href="#" className="text-[#2260D9] underline">
                      {details?.intakeDate || details?.created_at
                        ? moment(
                            details.intakeDate || details?.created_at
                          ).format("MM/DD/YYYY | h:mm A")
                        : null}
                    </Link>
                  </p>
                </div>
              </div>
              <div
                onClick={() => navigate(`/view-request/${details?.id}`)}
                className=" text-[20px] text-[#2260D9] cursor-pointer"
              >
                View
              </div>
            </div>
          </div>
          <div className="p-6 pb-0 flex gap-2 items-center">
            <p className="text-gray-600 text-[16px]">
              This action is about to change the request status from:
            </p>
            <div className="flex items-center justify-center">
              <span className="px-8 py-1 rounded-full bg-[#F4F8FF] text-[16px] text-[#2260D9] border border-[#2260D9]">
                New
              </span>
              <span className="text-gray-400">- - → - -</span>
              <span className="px-4 py-1 rounded-full bg-[#FFF8F4] text-[16px] text-[#FF6711] border border-[#FF6711]">
                In Review
              </span>
            </div>
          </div>
          <div className="flex gap-5 px-5 py-6 ">
            <div className="flex flex-col gap-3 w-[40%]">
              <h3>Reason</h3>
              <div className="relative flex flex-col gap-4">
                <div className="flex items-center w-full gap-6">
                  <input
                    type="checkbox"
                    className="h-[30px] w-[30px]"
                    style={{ border: "1px solid rgba(210, 226, 255, 1)" }}
                  />
                  <p className="text-[16px]">Client Verification</p>
                </div>
                <div className="flex items-center w-full gap-6">
                  <input
                    type="checkbox"
                    className="h-[30px] w-[30px]"
                    style={{ border: "1px solid rgba(210, 226, 255, 1)" }}
                  />
                  <p className="text-[16px]">Program Requirement Va...</p>
                </div>
                <div className="flex items-center w-full gap-6">
                  <input
                    type="checkbox"
                    className="h-[30px] w-[30px]"
                    style={{ border: "1px solid rgba(210, 226, 255, 1)" }}
                  />
                  <p className="text-[16px]">Lorem Ipsum</p>
                </div>
                <div className="flex items-center w-full gap-6">
                  <input
                    type="checkbox"
                    className="h-[30px] w-[30px]"
                    style={{ border: "1px solid rgba(210, 226, 255, 1)" }}
                  />
                  <p className="text-[16px]">Lorem Ipsum</p>
                </div>
                <div className="flex items-center w-full gap-6">
                  <input
                    type="checkbox"
                    className="h-[30px] w-[30px]"
                    style={{ border: "1px solid rgba(210, 226, 255, 1)" }}
                  />
                  <p className="text-[16px]">Lorem Ipsum</p>
                </div>
              </div>
            </div>
            <div className={`w-full flex flex-col gap-3`}>
              <h3>
                Comments
                <span className="text-primary-red">*</span>{" "}
                <span>
                  {commentError && (
                    <p className="text-sm text-primary-red">
                      {`Comment is mandatory.`}
                    </p>
                  )}
                </span>
              </h3>

              <textarea
                className={`w-full h-full px-2 py-3 focus:outline-none`}
                style={{ border: "1px solid rgba(214, 229, 238, 1)" }}
                placeholder={"Enter your valid reason for Cancelling Request"}
                value={comment}
                onChange={handleCommentChange}
              ></textarea>
            </div>
          </div>

          <div className="flex items-center justify-center gap-6 my-6 text-[16px]">
            <button
              style={{
                borderRadius: "3px",
              }}
              className="w-[150px] py-3 border"
              onClick={closeModal}
            >
              {"Close"}
            </button>
            <button
              className="bg-[#2260D9] text-white w-[150px] py-3"
              style={{ borderRadius: "3px" }}
              onClick={handleClick}
            >
              {"Change Status"}
            </button>
          </div>
        </div>
      </MuiModal>
    </>
  );
}
