import React, { useEffect, useRef, useState, useMemo } from "react";
import SearchIcon from "../../../assets/input-search-icon.svg";
import MoreIcon from "../../../assets/more-icon.svg";
import DataTable from "../../../shared/DataGrid";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentRequest } from "../../../services/currentRequest";
import { useNavigate } from "react-router";
import AddPartnersInCategories from "../../Popups/AddPartnersInCategories";
import ViaIcon from "../../../assets/via.svg";
import OptIcon from "../../../assets/opt.svg";
import moment from "moment";
import { getRequestCount } from "../../../services/agent";
import GreenOpt from "../../../assets/green-opt.svg";
import RedOpt from "../../../assets/red-opt.svg";
import MoreHorizIcon from "../../../assets/moreHorizIcon.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Popper,
  MenuList,
  MenuItem,
  Paper,
  IconButton,
  Popover,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react";
import CancelRequest from "./CancelRequest";
import { useSearchParams } from "react-router-dom";
import BreadCumbs from "../../BreadCumbs/BreadCumbs";
import { titleCase } from "../../../utills/castle";
import FileIcon from "../../../assets/file-icon.svg";
import EditRequestModal from "./EditRequestModal";
import useDebounce from "../../../utills/debounce";

export default function AdminRequest({ setViewMode }) {
  const [actionTab, setActiveTab] = useState("");
  const [pages, setPage] = useState({ page: 0, pageSize: 10 });
  const { userType, data } = useSelector((state) => state.userInfo);
  const [categoryId, setCategoryId] = useState("");
  const [searchParams] = useSearchParams();
  const [isEditRequestOpen, setIsEditRequestOpen] = useState(false);
  const [selectedEditRequestId, setSelectedEditRequestId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterOption, setFilterOption] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const status = searchParams.get("status");
  const searchValue = useDebounce(searchQuery, 500);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [detailAction, setDetailAction] = useState({
    cancelModal: false,
    id: null,
    reject: false,
  });

  useEffect(() => {
    if (status) {
      setSelectedValue(status);
    }
  }, [status]);

  const closeModal = () => {
    setDetailAction({ cancelModal: false, id: null, reject: false });
  };
  const { currentData, total, loading } = useSelector(
    (state) => state.currentRequest
  );

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleFilterChange = (option) => {
    setFilterOption((prevOption) => (prevOption === option ? null : option));
  };

  const options = [
    { value: "", label: "All" },
    { value: "new", label: "New" },
    { value: "in review", label: "In Review" },
    { value: "in progress", label: "In Progress" },
    { value: "resolved", label: "Resolved" },
    { value: "completed", label: "Completed" },
    { value: "on hold", label: "On Hold" },
    { value: "rejected", label: "Rejected" },
  ];

  const Column = () => {
    const [anchorEl, setAnchorEl] = React.useState(null); // Declare state outside renderCell
    const popoverRef = useRef(null); // To reference the Popper/Popover
    const buttonRef = useRef(null); // To reference the button that opens the popover
    const [activeRowId, setActiveRowId] = React.useState(null); // Tracks the active row for the popover

    // Handles popover toggle
    console.log(anchorEl, activeRowId, "hello");
    const handleClick = (event, rowId) => {
      if (activeRowId === rowId) {
        // Close if the same row is clicked
        setAnchorEl(null);
        setActiveRowId(null);
      } else {
        // Open for a new row
        setAnchorEl(event.currentTarget);
        setActiveRowId(rowId);
      }
    };

    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (
          popoverRef.current &&
          !popoverRef.current.contains(event.target) &&
          anchorEl &&
          !anchorEl.contains(event.target)
        ) {
          setAnchorEl(null);
          setActiveRowId(null);
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [anchorEl]);
    const open = Boolean(anchorEl);

    return [
      ...(userType === "consumer"
        ? [
            {
              field: "category_name",
              headerName: "Program",
              flex: 1,
              renderCell: (params) => (
                <div className="capitalize">{params.value}</div>
              ),
            },
            {
              field: "beneficiaries",
              headerName: "No. of Beneficiaries",
              align: "center",
              flex: 1,
            },
            {
              field: "service_option",
              headerName: "Service Option",
              flex: 1,
              renderCell: (params) => {
                const isPaid = params.row.cost === 0;
                return (
                  <div className="flex justify-center items-center w-full h-full">
                    {isPaid ?<img src={MoreHorizIcon} alt="More" /> : "paid" }
                  </div>
                );
              },
            },
            {
              field: "isPaid",
              headerName: "Payment Status",
              flex: 1,
              renderCell: (params) => {
                const isPaid = params.row.category_is_premium === "True";
  
                return (
                  <div className="capitalize">
                    {isPaid ? (
                      params.row.is_paid ? (
                        <div className="text-[#278E1D]">Paid</div>
                      ) : (
                        <div className="text-[#FFCD11]">Pending</div>
                      )
                    ) : (
                      "..."
                    )}
                  </div>
                );
              },
            },
            {
              field: "request_via",
              headerName: "Via",
              flex: 1,
              renderHeader: () => (
                <div className="flex items-center gap-3">
                  <p className="font-sans text-[#18283D] font-semibold">Via</p>
                  <img src={ViaIcon} alt="via" />
                </div>
              ),
              renderCell: (params) => (
                <div className="capitalize">{params.value}</div>
              ),
            },
            {
              field: "submittedDate",
              headerName: "Submitted Date & Time",
              flex: 1,
              renderCell: (params) => {
                return moment(params?.row?.submittedDate).format(
                  "MM/DD/YYYY | h:mm A"
                );
              },
            },
            {
              field: "level",
              headerName: "Level",
              flex: 1,
              renderCell: (params) => (
                <div className="capitalize">{params.value}</div>
              ),
            },
            {
              field: "description",
              headerName: "Description",
              flex: 1,
            },
            {
              field: "request_status",
              headerName: "Status",
              flex: 1,
              renderCell: (params) => {
                console.log(params, "params");
                const colors = {
                  "in progress": "text-[#FFCD11]",
                  "in review": "text-[#E26313]",
                  completed: "text-[#278E1D]",
                  resolved: "text-[#278E1D]",
                  wait: "text-[#1D5BBF]",
                  "on hold": "text-[#E26313]",
                  rejected: "text-[#DC3545]",
                  new: "text-[#1D5BBF]",
                };
                return (
                  <div className={`capitalize ${colors[params.value] || ""}`}>
                    {params.value === "wait" ? "waiting" : params?.value}
                  </div>
                );
              },
            },
          ]
        : [
            {
              field: "opt",
              headerName: "OPT",
              flex: 1,
              headerAlign: "center",
              renderHeader: () => (
                <div
                  onClick={handleHeaderClick}
                  className="flex items-center gap-[5px]"
                >
                  <p className="font-sans text-[#18283D] font-semibold">OPT</p>
                  <img src={OptIcon} alt="opt" />
                </div>
              ),
              renderCell: (params) => {
                const via = params.row?.communication_preference?.toLowerCase();
                const optImage = via === "opt_in" ? GreenOpt : RedOpt;
                return (
                  <div className="flex items-center justify-center h-full">
                    <img src={optImage} alt="star icon" className="w-4 h-4" />
                  </div>
                );
              },
            },
            {
              field: "id",
              headerName: "Request ID",
              flex: 1,
            },
            {
              field: "first_name",
              headerName: "Client Name",
              flex: 1,
              renderCell: (params) => {
                return (
                  <div className="capitalize">{params.row.first_name}</div>
                );
              },
            },
            {
              field: "category_name",
              headerName: "Program",
              flex: 1,
              renderCell: (params) => {
                return (
                  <div>
                    {params.row.category_name
                      ? titleCase(params.row.category_name)
                      : ""}
                  </div>
                );
              },
            },
            {
              field: "service_option",
              headerName: "Service Option",
              flex: 1,
              renderCell: (params) => {
                const isPaid = params.row.cost === 0;
                return (
                  <div className="flex justify-center items-center w-full h-full">
                    {isPaid ? <img src={MoreHorizIcon} alt="More" /> : "Paid"}
                  </div>
                );
              },
            },

            {
              field: "isPaid",
              headerName: "Payment Status",
              flex: 1,
              renderCell: (params) => {
                const isPaid = params.row.category_is_premium === "True";
  
                return (
                  <div className="capitalize">
                    {isPaid ? (
                      params.row.is_paid ? (
                        <div className="text-[#278E1D]">Paid</div>
                      ) : (
                        <div className="text-[#FFCD11]">Pending</div>
                      )
                    ) : (
                      "..."
                    )}
                  </div>
                );
              },
            },
            {
              field: "intakeDate",
              headerName: "Intake Date & Time ",
              flex: 1,
              renderCell: (params) => {
                return moment(params?.row?.intakeDate).format(
                  "MM/DD/YYYY | h:mm A"
                );
              },
            },
            {
              field: "request_via",
              headerName: "Via",
              flex: 1,
              renderHeader: () => (
                <div className="flex items-center gap-3">
                  <p className="font-sans text-[#18283D] font-semibold">Via</p>
                  <img src={ViaIcon} alt="via" />
                </div>
              ),
              renderCell: (params) => (
                <div className="capitalize">{params.value}</div>
              ),
            },

            {
              field: "submittedDate",
              headerName: "Submitted Date & Time",
              flex: 1,
              renderCell: (params) => {
                return moment(params?.row?.submittedDate).format(
                  "MM/DD/YYYY | h:mm A"
                );
              },
            },
            {
              field: "level",
              headerName: "Level",
              flex: 1,
              renderCell: (params) => (
                <div className="capitalize">{params.value}</div>
              ),
            },
            {
              field: "request_status",
              headerName: "Status",
              flex: 1,
              renderCell: (params) => {
                console.log(params, "params");
                const colors = {
                  "in progress": "text-[#FFCD11]",
                  completed: "text-[#278E1D]",
                  "in review": "text-[#E26313]",
                  resolved: "text-[#278E1D]",
                  wait: "text-[#1D5BBF]",
                  "on hold": "text-[#E26313]",
                  rejected: "text-[#DC3545]",
                  new: "text-[#1D5BBF]",
                };
                return (
                  <div className={`capitalize ${colors[params.value] || ""}`}>
                    {params.value === "wait" ? "waiting" : params?.value}
                  </div>
                );
              },
            },
            {
              field: "partners",
              headerName: "Partner",
              flex: 1,
              renderCell: (params) => {
                return (
                  <div className="capitalize flex justify-center items-center w-full h-full">
                    {params.row.partners?.length > 0 ? (
                      params.row.partners_names
                    ) : (
                      <img src={MoreHorizIcon} alt="More" className="w-6 h-6" />
                    )}
                  </div>
                );
              },
            },
            {
              field: "response",
              headerName: "Partner Response",
              headerAlign: "center",
              align: "center",
              flex: 1,
              renderCell: (params) => {
                const colors = {
                  "in progress": "text-[#FFCD11]",
                  accepted: "text-[#278E1D]",
                  completed: "text-[#278E1D]",
                  resolved: "text-[#278E1D]",
                  "in review": "text-[#E26313]",
                  wait: "text-[#1D5BBF]",
                  "on hold": "text-[#E26313]",
                  rejected: "text-[#DC3545]",
                  new: "text-[#1D5BBF],",
                };

                const isPartnerNull =
                  !params.row.partners || params.row.partners.length === 0;
                return (
                  <div
                    className={`capitalize flex justify-center items-center w-full h-full ${
                      colors[params.row.partner_status] || ""
                    }`}
                  >
                    {isPartnerNull ? (
                      <img src={MoreHorizIcon} alt="More" className="w-6 h-6" />
                    ) : params.row.response === "wait" ? (
                      "waiting"
                    ) : (
                      params?.row?.response
                    )}
                  </div>
                );
              },
            },
          ]),

      // ...(userType === "agent"
      //   ? [
      //       {
      //         field: "description",
      //         headerName: "Description",
      //         flex: 1,
      //       },
      //     ]
      //   : []),

      {
        field: "action",
        headerName: "Action",
        flex: 1,
        renderCell: (params) => {
          return (
            <>
              <IconButton
                onClick={(e) => handleClick(e, params.row.id)}
                ref={buttonRef}
                aria-controls={open ? "action-menu" : undefined}
                aria-haspopup="true"
              >
                <MoreVertIcon />
              </IconButton>

              <Popper
                open={open && activeRowId === params.row.id}
                ref={popoverRef}
                anchorEl={anchorEl}
                placement="bottom"
                disablePortal
                className="menu-before"
              >
                <Paper className="absolute right-5 z-50 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5">
                  <MenuList>
                    {params.row.request_status === "new" &&
                      userType === "consumer" && (
                        <MenuItem
                          onClick={() => {
                            setIsEditRequestOpen(params.id);
                            setSelectedEditRequestId(params.id);
                            setAnchorEl(null);
                          }}
                        >
                          Edit Request
                        </MenuItem>
                      )}

                    <MenuItem
                      onClick={() => {
                        navigate(`/view-request/${params.row.id}`);
                        setAnchorEl(null);
                      }}
                    >
                      View Details
                    </MenuItem>

                    {params.row.partner_status === "rejected" &&
                      userType !== "consumer" && (
                        <MenuItem
                          onClick={() => {
                            setOpen(params.id);
                            setId(params.id);
                            setAnchorEl(null);
                          }}
                        >
                          Reassign
                        </MenuItem>
                      )}

                    {params.row.partners_names.length === 0 &&
                      params.row.request_status !== "rejected" &&
                      userType !== "consumer" && (
                        <MenuItem
                          onClick={() => {
                            setOpen(params.id);
                            setId(params.id);
                            setCategoryId(params.row.categoryId);
                            setAnchorEl(null);
                          }}
                        >
                          Assign
                        </MenuItem>
                      )}

                    {params.row.partner_status !== "completed" && (
                      <MenuItem
                        onClick={() => {
                          navigate(
                            `/request-tracking?id=${params?.row?.id}&status=${params?.row?.request_status}`
                          );

                          setAnchorEl(null);
                        }}
                      >
                        Track Request
                      </MenuItem>
                    )}

                    {params.row.partner_status === null &&
                      params.row.request_status !== "rejected" &&
                      userType !== "consumer" && (
                        <MenuItem
                          onClick={() => {
                            setDetailAction({
                              cancelModal: true,
                              id: params.row.id,
                              reject: true,
                            });
                            setAnchorEl(null);
                          }}
                        >
                          Reject Request
                        </MenuItem>
                      )}

                    {params.row.partner_status === "completed" && (
                      <MenuItem
                        onClick={() => {
                          navigate(`/create-request?id=${params?.row?.id}`);
                          setAnchorEl(null);
                        }}
                      >
                        Request Again
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        navigate(`/request-history/${params?.row?.id}`);
                        setAnchorEl(null);
                      }}
                    >
                      History
                    </MenuItem>
                  </MenuList>
                </Paper>
              </Popper>
            </>
          );
        },
      },
    ];
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getCurrentRequest({
        page: pages.page + 1,
        // limit,
        status: selectedValue,
        ...(userType === "consumer" ? { user_id: data.id } : {}),
        search: searchValue
      })
    );
  }, [dispatch, selectedValue, pages, searchValue]);

  useEffect(() => {
    dispatch(getRequestCount());
  }, [selectedValue]);

  const rows =
    currentData && currentData.length > 0
      ? currentData.map((item) => ({
          ...item,
          id: item.id,
          requestId: item.id,
          name: item.first_name,
          category_name: item.category_name,
          categoryId: item.category,
          contact: item.contact,
          via: item.request_via,
          intakeDate: item.created_at,
          level: item.urgency_level,
          description: item.description,
          submittedDate: item.updated_at,
          status: item.request_status,
          partners: item.partners_names,
          response: item.partner_status,
          beneficiaries: item.beneficiaries,
          communication_preference: item.communication_preference,
        }))
      : [];

  const handleOptionChange = (value) => {
    setSelectedValue(value); // Update selected value
    setPage({ ...pages, page: 0 }); // Reset to the first page
  };

   const searchFilteredRows = useMemo(() => {
      if (!filterOption) {
        return rows;
      }
    
      let modifiedRows = rows;
    
      if (filterOption) {
        modifiedRows = modifiedRows.filter(
          (row) => row.communication_preference === filterOption
        );
      }
    
      return modifiedRows;
    }, [rows  , filterOption]);
    
  
    const handleHeaderClick = (event) => {
      setAnchorEl1(event.currentTarget);
    };
  
    const closePopover = () => {
      setAnchorEl1(null);
    };

  return (
    <>
      <div className="px-8 pt-10 bg-[#F9F9F9]">
        <BreadCumbs firstCumbName="Request" secoundCumbName="Request Status" />

        {userType === "consumer" && (
          <div className="flex justify-end gap-2">
            <img src={FileIcon} alt="FileIcon" />
            <p
              className="cursor-pointer text-primary-blue"
              onClick={() => {
                if (setViewMode) {
                  setViewMode("detail-view");
                }
              }}
            >
              Detail View
            </p>
          </div>
        )}
        <div className="px-3 py-5 mb-5 mt-5 custom-shadow rounded-[5px]">
          <div className="flex flex-col items-center justify-between py-4 mb-8 ">
            <div
              className="flex justify-between w-full px-4 py-6 mb-8"
              style={{ borderBottom: "1px solid rgba(211, 211, 211, 1)" }}
            >
              <h2 className="flex items-center gap-2.5 text-[#353F4F] font-medium text-3xl capitalize">
                {selectedValue} {selectedValue == "" ? "All " : ""}Request{" "}
                <span
                  style={{
                    color: "#1D4ED8", // Blue text
                    backgroundColor: "#E0F2FE", // Sky-blue background
                    padding: "4px 8px", // Padding
                    borderRadius: "4px", // Rounded corners
                  }}
                  className="text-base mr-4"
                >
                  {total || 0}
                </span>
              </h2>
              <div className=" flex relative gap-6 pb-1">
                <div>
                  <Listbox
                    value={selectedValue}
                    // onChange={setSelectedValue}
                    onChange={handleOptionChange}
                  >
                    {({ open }) => (
                      <div className="relative">
                        <ListboxButton
                          className={` w-[250px] h-[50px] flex justify-between items-center pl-6 pr-3 py-2 text-base ${
                            open ? "text-[#A9A9A9]" : "text-[#353F4F]"
                          } border border-[#353F4F] rounded-[3px]`}
                        >
                          {!open
                            ? options.find(
                                (option) => option.value === selectedValue
                              )?.label
                            : "Select"}

                          {open ? (
                            <KeyboardArrowUpIcon style={{ color: "black" }} />
                          ) : (
                            <KeyboardArrowDownIcon style={{ color: "black" }} />
                          )}
                        </ListboxButton>
                        {open && (
                          <ListboxOptions className="absolute z-10 w-full mt-1 bg-white border border-[#9EBCE3] rounded-[3px] ">
                            {options.map((option) => (
                              <ListboxOption
                                key={option.value}
                                value={option.value}
                                className={({ active, selected }) =>
                                  `cursor-pointer px-4 py-2 mb-1 text-[#353F4F] text-sm ${
                                    active ? "bg-[#EEF4FF]" : ""
                                  } ${selected ? "bg-[#EEF4FF]" : ""}`
                                }
                              >
                                {option.label}
                              </ListboxOption>
                            ))}
                          </ListboxOptions>
                        )}
                      </div>
                    )}
                  </Listbox>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    id="search-navbar"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="block w-full p-2 text-sm text-gray-900 focus:outline-none rounded-[3px] h-[50px] placeholder:text-[#D6E4FF]"
                    placeholder="Search"
                    style={{
                      border: "1px solid  rgba(34, 96, 217, 1)",
                      width: "300px",
                    }}
                  />
                  <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
                    <img src={SearchIcon} alt="SearchIcon" />
                  </div>
                </div>
                {/* <div className="relative">
                <input
                  type="text"
                  id="search-navbar"
                  className="block w-full p-2 text-sm text-gray-900 focus:outline-none rounded-[3px] h-[50px] placeholder:text-[#D6E4FF]"
                  placeholder="Search"
                  style={{
                    border: "1px solid  rgba(34, 96, 217, 1)",
                    width: "300px",
                  }}
                />
                <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
                  <img src={SearchIcon} alt="SearchIcon" />
                </div>
              </div>
            <div className=" flex relative gap-6 pb-1">
              <div>
                <Listbox value={selectedValue} onChange={setSelectedValue}>
                  {({ open }) => (
                    <div className="relative">
                      <ListboxButton
                        className={` w-[250px] h-[50px] flex justify-between items-center pl-6 pr-3 py-2 text-base ${
                          open ? "text-[#A9A9A9]" : "text-[#353F4F]"
                        } border border-[#353F4F] rounded-[3px]`}
                      >
                        {!open
                          ? options.find(
                              (option) => option.value === selectedValue
                            )?.label
                          : "Select"}

                        {open ? (
                          <KeyboardArrowUpIcon style={{ color: "black" }} />
                        ) : (
                          <KeyboardArrowDownIcon style={{ color: "black" }} />
                        )}
                      </ListboxButton>
                      {open && (
                        <ListboxOptions className="absolute z-10 w-full mt-1 bg-white border border-[#9EBCE3] rounded-[3px] ">
                          {options.map((option) => (
                            <ListboxOption
                              key={option.value}
                              value={option.value}
                              className={({ active, selected }) =>
                                `cursor-pointer px-4 py-2 mb-1 text-[#353F4F] text-sm ${
                                  active ? "bg-[#EEF4FF]" : ""
                                } ${selected ? "bg-[#EEF4FF]" : ""}`
                              }
                            >
                              {option.label}
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      )}
                    </div>
                  )}
                </Listbox>
              </div>

              <div className="relative">
                <input
                  type="text"
                  id="search-navbar"
                  className="block w-full p-2 text-sm text-gray-900 focus:outline-none rounded-[3px] h-[50px] placeholder:text-[#D6E4FF]"
                  placeholder="Search"
                  style={{
                    border: "1px solid  rgba(34, 96, 217, 1)",
                    width: "300px",
                  }}
                />
                <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
                  <img src={SearchIcon} alt="SearchIcon" />
                </div>
              </div>
            </div> */}
              </div>
            </div>
            <div className="px-3 w-full">
              <DataTable
                rows={searchFilteredRows || []}
                columns={Column()}
                hideCheckbox
                pages={pages}
                totalRows={total || 0}
                handlePageChange={handlePageChange}
                loading={loading}
              />

              <Popover
                open={Boolean(anchorEl1)}
                anchorEl={anchorEl1}
                onClose={closePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div className=" z-10  w-36 bg-white border border-[#D9D9D9] rounded-[3px]">
                  <ul className="p-3 space-y-3">
                    <li className="flex items-center">
                      <input
                        type="checkbox"
                        id="opt-in"
                        name="opt-in"
                        className="mr-2"
                        // onChange={handleOptionChange}
                        checked={filterOption === "opt_in"}
                        onClick={() => handleFilterChange("opt_in")}
                      />
                      <label htmlFor="opt-in" className="text-sm text-black">
                        Opt In
                      </label>
                    </li>
                    <li className="flex items-center">
                      <input
                        type="checkbox"
                        id="opt-out"
                        name="opt-out"
                        className="mr-2"
                        // onChange={handleOptionChange}
                        checked={filterOption === "opt_out"}
                        onClick={() => handleFilterChange("opt_out")}
                      />
                      <label htmlFor="opt-out" className="text-sm text-black">
                        Opt Out
                      </label>
                    </li>
                  </ul>
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <EditRequestModal
        isOpen={isEditRequestOpen}
        onClose={() => setIsEditRequestOpen(false)}
        selectedRequestId={selectedEditRequestId}
      />
      <AddPartnersInCategories
        open={open}
        setOpen={setOpen}
        fromRequest={userType === "agent" || userType === "admin"}
        id={id}
        setId={setId}
        categoryId={categoryId}
        getRequest={() =>
          dispatch(
            getCurrentRequest({
              page: pages.page + 1,
              status: actionTab,
              search: searchValue
            })
          )
        }
      />
      <CancelRequest
        open={detailAction.cancelModal}
        id={detailAction.id}
        closeModal={closeModal}
        actionTab="rejected"
        // isRejectPopup={detailAction.reject}
      />
    </>
  );
}
