import React from "react";
import { Modal, Box } from "@mui/material";
import { CloseIcon, FileIcon, UsersIcon } from "./Icons";
import moment from "moment";
import Edit from "../../assets/edit.svg";
import { useNavigate } from "react-router";

export default function MeetingModal({
  isOpen,
  onClose,
  activeEvent,
  setIsModalOpen,
  setGmeet
}) {
  const navigate = useNavigate()
  const formatMeetingDateTime = (dateTime, end) => {
    const date = moment(dateTime).format("dddd, MMMM D YYYY");
    const startTime = moment(dateTime).format("h:mm");
    const endTime = moment(end).format("h:mmA");
    return `${date} - ${startTime} - ${endTime}`;
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="meeting-modal-title"
      aria-describedby="meeting-modal-description"
    >
      <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-full max-w-[600px] outline-none">
        {/* Header */}
        <div className="bg-[#D9E4F2] p-6 rounded-t-lg flex items-start justify-between">
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <h2
                id="meeting-modal-title"
                className="text-xl flex items-center gap-2 capitalize"
              >
                {activeEvent?.title}
                <button className="hover:bg-gray-100 p-1 rounded" onClick={() => navigate(`/create-meeting?id=${activeEvent?.id}`)}>
                  <img src={Edit} alt="Edit"/>
                </button>
              </h2>
            </div>
          </div>
          <button
            onClick={onClose}
            className="hover:bg-gray-100 p-2 rounded-full"
          >
            <CloseIcon className="h-4 w-4" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6 space-y-6">
          <div>
            <div className="flex gap-2 items-center">
              <div className="h-4 w-4 rounded bg-[#2260D9]" />
              <h2 className="text-xl font-medium capitalize ">{activeEvent?.title}</h2>
            </div>
            <p className="text-[#B5B4B4] pl-6 text-[16px]">
              {formatMeetingDateTime(activeEvent?.start, activeEvent?.end)}
            </p>
          </div>

          <div className="flex capitalize items-center gap-2 text-[#18283D] text-[16px]">
            <FileIcon className="h-5 w-5" />
            <span>{activeEvent?.meeting_type} Meeting</span>
          </div>

          <div className="space-y-3 text-[16px]">
            <div className="flex items-center gap-2 text-[#18283D]">
              <UsersIcon className="h-5 w-5" />
              <span>{activeEvent?.attendees?.length} Members</span>
            </div>
            {/* Member items */}
            <div className="flex flex-wrap gap-3 pl-4">
              {activeEvent?.attendees?.map((name, index) => (
                <div key={index} className="flex gap-2 items-center">
                  <div className="rounded-full flex items-center justify-center text-[14px]">
                    {name.first_name
                      ?.split(" ")
                      .map((n) => n[0])
                      .join("")}
                  </div>
                  <span className="text-[14px] font-medium">
                    {name?.first_name} {name?.last_name}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className="space-y-2 text-[16px] text-[#18283D] pl-6">
            <h3 className="font-medium">Description:</h3>
            <p className="text-gray-600">
              Lorem ipsum dolor sit amet, consectetur adipiscing......
            </p>
          </div>

          {/* Footer Buttons */}
          <div className="flex gap-3 justify-center text-[14px]">
            <button
              onClick={onClose}
              className="px-8 py-2 border border-gray-300 rounded"
            >
              Cancel
            </button>
            <button
              className="px-8 py-2 text-[#FD003A] border border-[#FFC7C7] rounded"
              onClick={() => {
                setIsModalOpen(true);
                onClose();
              }}
            >
              Delete
            </button>
            <button className="px-8 py-2 bg-[#2260D9] text-white rounded " onClick={() => setGmeet(true)}>
              Join Meeting
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
