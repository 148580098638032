import React, { useCallback, useEffect, useState } from "react";
import { LoginSocialGoogle } from "reactjs-social-login";
import { useForm } from "react-hook-form";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Backdrop, Badge } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import MuiModal from "./shared/Modal";
import MyProfile from "./components/MyProfile";
import {
  loginSocialMedia,
  loginWithPhoneNumber,
  updateUserInfo,
} from "./services/user";
import { userApiResponse } from "./constants/apiResponse";
import { Button } from "./shared/Button";
import GoogleIcon from "./assets/google-icon.svg";
import FacebookIcon from "./assets/facebook-icon.svg";
import InstagramIcon from "./assets/instagram-icon.svg";
import PlusIcon from "./assets/plus-white-circle.svg";
import NotificationIcon from "./assets/notification-bell-icon.svg";
import UserImage from "./assets/images/user.png";
import HelpIcon from "./assets/help-icon.svg";
import ProfileIcon from "./assets/profile-icon.svg";
import LogoutIcon from "./assets/logout-icon.svg";
import LogoutColorIcon from "./assets/logout-popup-icon.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SettingIcon from "./assets/settingsicon.svg";
import { getUserType } from "./services/userType";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import BulkUpload from "./assets/bulkupload.svg";
import ReportAnalytics from "./assets/reportanalytics.svg";
import { facebookLogin } from "./services/user";
import Help from "./assets/help.svg";
import AddOtpLogin from "./components/Popups/AddOtpLogin";
import ConfirmOtp from "./components/Login/ConfirmOtp";
import { getNotification } from "./services/notification";
export default function Navbar() {
  const navigate = useNavigate();
  const [number, setNumber] = useState("");
  const { data, status, userType } = useSelector((state) => state.userInfo);
  const userInfo = useSelector((state) => state.userInfo);


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  // const isUserAdmin = userGetIsUserAdmin();

  const [loginModal, setLoginModal] = useState({ model: false, open: "" });
  const [isLogout, setIsLogout] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userAction, setUserAction] = useState({ profileModal: false });
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(null);
  const [agentMenu, setAgentMenu] = useState(null);
  const [partnerMenu, setPartnerMenu] = useState(null);
  const [categoryMenu, setCategoryMenu] = useState(null);
  const [requestMenu, setRequestMenu] = useState(null);
  const [activeItem, setActiveItem] = useState(false);
  const [moreMenu, setMoreMenu] = useState(null);
  const location = useLocation();
  const { id } = useParams();

  const notificationData = useSelector((state) => state.notificationInfo.data);

  const [resourcesMenu, setResourcesMenu] = useState(null); // Resources menu anchor
  const [submenuAnchor, setSubmenuAnchor] = useState(null); // Submenu anchor for right-side menu
  const [hoveredItem, setHoveredItem] = useState(null); // To track hovered submenu (Program, Staff, Partner)

  // Handle opening and closing of Resources menu
  const handleResourcesMenu = (event) => {
    setResourcesMenu(event.currentTarget);
  };
  const handleCloseResourcesMenu = () => {
    setResourcesMenu(null);
    setSubmenuAnchor(null);
    setHoveredItem(null);
  };

  // Handle submenu hover
  const handleSubmenuOpen = (event, item) => {
    setSubmenuAnchor(event.currentTarget);
    setHoveredItem(item);
  };
  const handleSubmenuClose = () => {
    setSubmenuAnchor(null);
    setHoveredItem(null);
  };

  // useEffect(() => {
  //   window.fbAsyncInit = function () {
  //     window.FB.init({
  //       appId: '8660876960677017',
  //       cookie: true,
  //       xfbml: true,
  //       version: 'v13.0',
  //     });

  
  //   };

  //   if (!window.FB) {
  //     const script = document.createElement('script');
  //     script.src = 'https://connect.facebook.net/en_US/sdk.js';
  //     script.async = true;
  //     script.defer = true;
  //     document.body.appendChild(script);
  //   }
  // }, []);

  const [resourceMenu, setResourceMenu] = useState(null);

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_ID,
        cookie: true,
        xfbml: true,
        version: "v13.0",
      });

    };

    if (!window.FB) {
      const script = document.createElement("script");
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }, []);

  // Handle Facebook login
  // const handleFacebookLogin = () => {
  //   window.FB.login(
  //     (response) => {
  //       if (response.status === 'connected') {
  //         console.log('Logged in:', response);

  //         const accessToken = response.authResponse.accessToken; // Extract the access token

  //         // Send the token to the backend
  //         fetch('https://crrmsbackend.dataterrain-dev.net/api/user/facebook-login/', {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify({ access_token: accessToken }), // Send the access token
  //         })
  //           .then((res) => res.json())
  //           .then((data) => {
  //             if (data.success) {
  //               console.log('User logged in successfully on backend:', data);
  //             } else {
  //               console.error('Error logging in on backend:', data.error);
  //             }
  //           })
  //           .catch((err) => console.error('Error sending token to backend:', err));

  //       } else {
  //         console.log('User cancelled login or did not fully authorize.');
  //       }
  //     },
  //     { scope: 'public_profile,email' }
  //   );
  // };
  const handleFacebookLogin = (open) => {
    window.FB.login(
      (response) => {
        if (response.status === "connected") {
          console.log("Logged in:", response);
          const accessToken = response.authResponse.accessToken;
          dispatch(facebookLogin(accessToken)).then(() => {
            setLoginModal({ model: false, open: "" });
            dispatch(getUserType())
              .unwrap()
              .then((userType) => {
                if (userType.user_type === "consumer" && open === "myRequest") {
                  navigate("/create-request");
                } else {
                  navigate("/");
                }
              });
          });
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "public_profile,email" }
    );
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const requestStatus = queryParams.get("status");

    if (
      ["/", "/profile", "/bulk-upload", "/help", "/notification"].includes(
        location.pathname
      )
    ) {
      setActiveItem("dashboard");
    } else if (
      (location.pathname === "/agentrequest" && requestStatus) ||
      location.pathname === "/create-request" ||
      location.pathname === `/request-tracking` ||
      (location.pathname === "/agentrequest" && !requestStatus)
    ) {
      setActiveItem("requests");
    } else if (location.pathname === `/view-agent/${id}`) {
      setActiveItem("agent");
    } else if (location.pathname === `/partner/${id}`) {
      setActiveItem("partner");
    } else if (location.pathname === "/scheduler") {
      setActiveItem("scheduler");
    }
  }, [location]);

  const handleCloseLoginModal = () => {
    reset();
    setLoginModal({ model: false, open: "" });
  };
  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);
  const onLoginSuccess = (data) => {
    console.log("Google login", data);
    if (
      data &&
      Object.keys(data).length &&
      data.hasOwnProperty("name") &&
      data.hasOwnProperty("email")
    ) {
      let l = { email: data.email };
      console.log("Login", l);
      dispatch(loginSocialMedia(l));
    }
  };
  const onLogoutFailure = useCallback(() => {
    alert("logout fail");
  }, []);
  const onLogoutSuccess = useCallback(() => {
    alert("logout success");
  }, []);
  const handleCloseUserProfile = () => {
    setUserAction({ ...userAction, profileModal: false });
  };

  const handleAgentMenu = (event) => {
    setAgentMenu(agentMenu ? null : event.currentTarget);
  };

  const handleCloseAgentMenu = () => {
    setAgentMenu(null);
  };

  const handleMoreMenu = (event) => {
    setMoreMenu(moreMenu ? null : event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setMoreMenu(null);
  };

  const handlePartnerMenu = (event) => {
    setPartnerMenu(partnerMenu ? null : event.currentTarget);
  };

  const handleClosePartnerMenu = () => {
    setPartnerMenu(null);
  };

  const handleClickMenu = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const handleRequestMenu = (event) => {
    setRequestMenu(requestMenu ? null : event.currentTarget);
  };

  const handleSchedulerMenu = (event) => {
    setRequestMenu(requestMenu ? null : event.currentTarget);
  };

  const handleCloseRequestMenu = () => {
    setRequestMenu(null);
  };

  const handleCategoryMenu = (event) => {
    setCategoryMenu(categoryMenu ? null : event.currentTarget);
  };

  const handleCloseCategoryMenu = () => {
    setCategoryMenu(null);
  };

  const handleCloseMenu = () => {
    setMenuOpen(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userType");
    dispatch({ type: "logout" });
    navigate("/");
    setIsLogout(false);
    window.location.reload();
  };
  const [openOtp, setOpenOtp] = useState(false);

  const handleOtpClick = () => {
    setOpenOtp(true);
  };

  const onSubmit = (data) => {
    dispatch(
      loginWithPhoneNumber({ phone_number: `+91${data.phone_number}` })
    ).then(() => {
      handleOtpClick();
      setNumber(data.phone_number);
    });
  };
  useEffect(() => {
    if (status === userApiResponse.loggin) {
      handleCloseLoginModal();
      dispatch(updateUserInfo({ status: "" }));
    }
  }, [status]);

  useEffect(() => {
    dispatch(getUserType());
    dispatch(getNotification());
  }, [dispatch]);

  return (
    <>
      <header
        className="py-3 px-4 bg-white"
        style={{ borderBottom: "1px solid #353F4F" }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLogout}
        >
          <div className="popup-content w-2/6 bg-white flex flex-col gap-2 h-[330px] justify-center items-center">
            <img src={LogoutColorIcon} alt="LogoutColorIcon" />
            <span
              style={{ color: "#232323", fontWeight: 600, fontSize: "24px" }}
            >
              Log out
            </span>

            <div className="py-5">
              <p
                style={{
                  color: "rgba(24, 40, 61, 1)",
                  fontWeight: 600,
                  fontSize: "18px",
                }}
              >
                Are you sure you want to log out ?
              </p>
            </div>
            <div className="flex justify-center">
              <div className="flex justify-center gap-6 align-middle">
                <Button
                  btnName="Cancel"
                  btnStyle={{ color: "#000" }}
                  btnCategory="secondary"
                  onClick={() => setIsLogout(false)}
                />
                <Button
                  btnType="button"
                  btnCls="w-[110px]"
                  btnName={"Logout"}
                  btnCategory="primary"
                  onClick={handleLogout}
                />
              </div>
            </div>
          </div>
        </Backdrop>

        <nav className="px-4 py-3 border-gray-200 lg:px-6 dark:bg-gray-800">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex items-center gap-20">
              <span
                className="self-center font-bold whitespace-nowrap text-[38px] cursor-pointer text-primary-blue  leading-[49.48px] font-dm-sans"
                onClick={() => {
                  setActiveItem("dashboard");
                  navigate("/");
                }}
              >
                CRRMS
              </span>

              <div
                className="items-center justify-between w-full lg:flex md-flex lg:w-auto lg:order-1"
                id="mobile-menu-2"
              >
                <ul className="flex items-center mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0 primary-black">
                  {userType === "consumer" && (
                    <>
                      <li>
                        <span
                          className={`block py-2 pr-4 ml-3 primary-black font-normal cursor-pointer text-[#353F4F] ${
                            location.pathname === "/" ? "!text-[#2260D9]" : ""
                          }`}
                          onClick={() => navigate("/")}
                        >
                          Home
                        </span>
                      </li>
                      <li className="flex items-center gap-1">
                        <span
                          className={`block py-2  pl-3 primary-black font-normal cursor-pointer text-[#353F4F] ${
                            location.pathname === "/category"
                              ? "!text-[#2260D9]"
                              : ""
                          }`}
                          onClick={() => navigate("/category")}
                        >
                          Program
                        </span>
                      </li>
                      <li className="flex items-center gap-1">
                        <span
                          className={`block py-2  pl-3 primary-black font-normal cursor-pointer text-[#353F4F] ${
                            location.pathname === "/all-request"
                              ? "!text-[#2260D9]"
                              : ""
                          }`}
                          onClick={() => {
                            Object.keys(data).length > 0
                              ? navigate("/all-request")
                              : setLoginModal({
                                  model: true,
                                  open: "",
                                });
                          }}
                        >
                          My Requests
                        </span>
                      </li>
                    </>
                  )}
                  {userType === "partner" && (
                    <>
                      <li>
                        <span
                          className={`block py-2 pr-4 pl-3 primary-black font-normal cursor-pointer text-[#353F4F] ${
                            location.pathname === "/" ? "text-[#2260D9]" : ""
                          }`}
                          onClick={() => navigate("/")}
                        >
                          Home
                        </span>
                      </li>
                      <li
                        className="flex items-center"
                        onClick={handleRequestMenu}
                      >
                        <span
                          className="block py-2 pl-3 font-normal cursor-pointer text-[#353F4F]"
                          onClick={() => navigate("/requests")}
                        >
                          Requests
                        </span>
                      </li>
                      <li
                        className="flex items-center"
                        onClick={handleSchedulerMenu}
                      >
                        <span
                          className="block py-2 pl-3 font-normal cursor-pointer text-[#353F4F]"
                          onClick={() => {
                            navigate("/scheduler");
                          }}
                        >
                          Scheduler
                        </span>
                      </li>
                    </>
                  )}
                  {(userType === "other" || userType === "agent") && (
                    <>
                      <li>
                        <span
                          className={`block py-2  ml-3 primary-black font-normal cursor-pointer text-[#353F4F] text-[17px] relative ${
                            activeItem === "dashboard" ? "font-semibold" : ""
                          }`}
                          onClick={() => {
                            setActiveItem("dashboard");
                            navigate("/");
                          }}
                        >
                          Dashboard
                          {activeItem === "dashboard" && (
                            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                          )}
                        </span>
                      </li>
                      {userType !== "agent" && (
                        <li className="flex items-center gap-1">
                          <div
                            onClick={handleAgentMenu}
                            className="flex items-center"
                          >
                            <span
                              className="block py-2  pl-3 primary-black font-normal cursor-pointer text-[#353F4F]"
                              onClick={() =>
                                userType === "agent" && navigate("/all-staff")
                              }
                            >
                              Agent
                            </span>
                            <span>
                              <KeyboardArrowDownIcon className="text-[#353F4F] cursor-pointer" />
                            </span>
                          </div>
                          <Menu
                            id="basic-menu"
                            anchorEl={agentMenu}
                            open={agentMenu}
                            onClose={() => setAgentMenu(null)}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            className="text-[#353F4F]"
                          >
                            <MenuItem
                              onClick={(event) => {
                                event.preventDefault();
                                handleCloseAgentMenu();
                                navigate("/addnewagent");
                              }}
                            >
                              Add New Agent
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleCloseAgentMenu();
                                navigate("/all-staff");
                              }}
                            >
                              All Agent
                            </MenuItem>
                          </Menu>
                        </li>
                      )}
                      <li className="flex items-center gap-1">
                        <span
                          className={`block py-2 ml-3 primary-black font-normal cursor-pointer text-[#353F4F] text-[17px] relative ${
                            activeItem === "partner" ? "font-semibold" : ""
                          }`}
                          onClick={() => {
                            if (userType === "agent") {
                              navigate("/partners");
                              setActiveItem("partner");
                            }
                          }}
                        >
                          Partner
                          {activeItem === "partner" && (
                            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                          )}
                        </span>
                        {userType !== "agent" && (
                          <>
                            {" "}
                            <span onClick={handlePartnerMenu}>
                              <KeyboardArrowDownIcon className="text-[#353F4F] cursor-pointer" />
                            </span>
                            <Menu
                              id="basic-menu"
                              anchorEl={partnerMenu}
                              open={partnerMenu}
                              onClose={() => setPartnerMenu(null)}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              className="text-[#353F4F]"
                            >
                              <MenuItem
                                onClick={() => {
                                  handleClosePartnerMenu();
                                  navigate("/addnewpartner");
                                }}
                              >
                                Add New Partner
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleClosePartnerMenu();
                                  navigate("/partners");
                                }}
                              >
                                All Partner
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </li>
                      <li className="flex items-center gap-1">
                        <span
                          className={`block py-2 ml-3 primary-black font-normal cursor-pointer text-[#353F4F] text-[17px] relative ${
                            activeItem === "categories" ? "font-semibold" : ""
                          }`}
                          onClick={() => {
                            if (userType === "agent") {
                              navigate("/category");
                              setActiveItem("categories");
                            }
                          }}
                        >
                          Programs
                          {activeItem === "categories" && (
                            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                          )}
                        </span>
                        {userType !== "agent" && (
                          <>
                            <span onClick={handleClickMenu}>
                              <KeyboardArrowDownIcon className="text-[#353F4F] cursor-pointer" />
                            </span>
                            <Menu
                              id="basic-menu"
                              anchorEl={menuOpen}
                              open={menuOpen}
                              onClose={() => setMenuOpen(null)}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              className="text-[#353F4F]"
                            >
                              <MenuItem
                                onClick={() => {
                                  handleCloseMenu();
                                  navigate("/addnewprogram");
                                }}
                              >
                                Add Program
                              </MenuItem>

                              <MenuItem
                                onClick={() => {
                                  handleCloseMenu();
                                  navigate("/categoryvisibility");
                                }}
                              >
                                Program Visibility
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleCloseMenu();
                                  navigate("/allcategory");
                                }}
                              >
                                All Program
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </li>
                      <li
                        className="flex items-center gap-1"
                        onClick={handleRequestMenu}
                      >
                        <span
                          className={`block py-2 ml-3 primary-black font-normal cursor-pointer text-[#353F4F] text-[17px] relative ${
                            activeItem === "requests" ? "font-semibold" : ""
                          }`}
                        >
                          Requests
                          {activeItem === "requests" && (
                            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                          )}
                        </span>
                        <span>
                          {requestMenu ? (
                            <KeyboardArrowUpIcon className="text-[#353F4F] cursor-pointer" />
                          ) : (
                            <KeyboardArrowDownIcon className="text-[#353F4F] cursor-pointer" />
                          )}
                        </span>
                        <Menu
                          id="basic-menu"
                          anchorEl={requestMenu}
                          open={Boolean(requestMenu)}
                          onClose={() => setRequestMenu(null)}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              boxShadow: "none",
                              border: "1px solid #3b82f77f",
                            },
                          }}
                          className="text-[#353F4F]"
                        >
                          <MenuItem
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleCloseRequestMenu();
                              setActiveItem("requests");
                              navigate("/create-request");
                            }}
                          >
                            Create Request
                          </MenuItem>
                          <MenuItem
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleCloseRequestMenu();
                              setActiveItem("requests");
                              navigate("/all-request");
                            }}
                          >
                            View All request
                          </MenuItem>
                          {/* <MenuItem
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleCloseRequestMenu();
                              setActiveItem("requests");
                              navigate("/request-tracking");
                            }}
                          >
                            Track Request
                          </MenuItem> */}
                        </Menu>
                      </li>
                      <li
                        className="flex items-center"
                        // onClick={handleSchedulerMenu}
                      >
                        <span
                          className={`block py-2 ml-3 primary-black font-normal cursor-pointer text-[#353F4F] text-[17px] relative ${
                            activeItem === "scheduler" ? "font-semibold" : ""
                          }`}
                          onClick={() => {
                            navigate("/scheduler");
                          }}
                        >
                          Scheduler
                          {activeItem === "scheduler" && (
                            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                          )}
                        </span>
                      </li>
                    </>
                  )}
                  {userType === "admin" && (
                    <>
                      <li>
                        <span
                          className={`block py-2  ml-3 primary-black font-normal cursor-pointer text-[#353F4F] text-[17px] relative ${
                            activeItem === "dashboard" ? "font-semibold" : ""
                          }`}
                          onClick={() => {
                            setActiveItem("dashboard");

                            navigate("/");
                          }}
                        >
                          Dashboard
                          {activeItem === "dashboard" && (
                            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                          )}
                        </span>
                      </li>

                      <li
                        className="flex items-center gap-1"
                        onClick={handleRequestMenu}
                      >
                        <span
                          className={`block py-2 ml-3 primary-black font-normal cursor-pointer text-[#353F4F] text-[17px] relative ${
                            activeItem === "requests" ? "font-semibold" : ""
                          }`}
                        >
                          Requests
                          {activeItem === "requests" && (
                            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                          )}
                        </span>
                        <span>
                          {requestMenu ? (
                            <KeyboardArrowUpIcon className="text-[#353F4F] cursor-pointer" />
                          ) : (
                            <KeyboardArrowDownIcon className="text-[#353F4F] cursor-pointer" />
                          )}
                        </span>
                        <Menu
                          id="basic-menu"
                          anchorEl={requestMenu}
                          open={Boolean(requestMenu)}
                          onClose={handleCloseRequestMenu}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              boxShadow: "none",
                              border: "1px solid #3b82f77f",
                            },
                          }}
                          className="text-[#353F4F]"
                        >
                          <MenuItem
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleCloseRequestMenu();
                              setActiveItem("requests");
                              navigate("/create-request");
                            }}
                          >
                            New Request
                          </MenuItem>
                          <MenuItem
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleCloseRequestMenu();
                              setActiveItem("requests");
                              navigate("/all-request");
                            }}
                          >
                            All request
                          </MenuItem>
                          {/* <MenuItem
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleCloseRequestMenu();
                              setActiveItem("requests");
                              navigate("/request-tracking");
                            }}
                          >
                            Request Tracking
                          </MenuItem> */}
                        </Menu>
                      </li>

                      <li className="flex items-center gap-1">
                        {/* Resources Text */}
                        <div
                          className="flex items-center gap-1"
                          onClick={handleResourcesMenu}
                        >
                          <span
                            className={`block py-2 ml-3 primary-black font-normal cursor-default text-[#353F4F] text-[17px] relative ${
                              activeItem === "resources" ? "font-semibold" : ""
                            }`}
                          >
                            Resources
                            {activeItem === "resources" && (
                              <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                            )}
                          </span>
                          {/* Arrow Icon (Dropdown Trigger) */}
                          <span className="cursor-pointer">
                            {resourcesMenu ? (
                              <KeyboardArrowUpIcon className="text-[#353F4F]" />
                            ) : (
                              <KeyboardArrowDownIcon className="text-[#353F4F]" />
                            )}
                          </span>
                        </div>
                        {/* Resources Main Menu */}
                        <Menu
                          id="resources-menu"
                          anchorEl={resourcesMenu}
                          open={Boolean(resourcesMenu)}
                          onClose={handleCloseResourcesMenu}
                          MenuListProps={{
                            "aria-labelledby": "resources-button",
                            onMouseLeave: handleCloseResourcesMenu,
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              boxShadow: "none",
                              border: "1px solid #3b82f77f",
                            },
                          }}
                          className="text-[#353F4F]"
                        >
                          {/* Program Menu */}
                          <MenuItem
                            onMouseEnter={(event) =>
                              handleSubmenuOpen(event, "Program")
                            }
                            onMouseLeave={handleSubmenuClose}
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            Program
                            <ChevronRightIcon />
                            {hoveredItem === "Program" && (
                              <Menu
                                anchorEl={submenuAnchor}
                                open={hoveredItem === "Program"}
                                onClose={handleSubmenuClose}
                                MenuListProps={{
                                  "aria-labelledby": "program-menu",
                                }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleCloseResourcesMenu();
                                    setActiveItem("resources");
                                    navigate("/addnewprogram");
                                  }}
                                >
                                  Add Program
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleCloseResourcesMenu();
                                    setActiveItem("resources");
                                    navigate("/categoryvisibility");
                                  }}
                                >
                                  Program Visibility
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleCloseResourcesMenu();
                                    setActiveItem("resources");
                                    navigate("/allcategory");
                                  }}
                                >
                                  All Program
                                </MenuItem>
                              </Menu>
                            )}
                          </MenuItem>

                          {/* Staff Menu */}
                          <MenuItem
                            onMouseEnter={(event) =>
                              handleSubmenuOpen(event, "Staff")
                            }
                            onMouseLeave={handleSubmenuClose}
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            Agent
                            <ChevronRightIcon />
                            {hoveredItem === "Staff" && (
                              <Menu
                                anchorEl={submenuAnchor}
                                open={hoveredItem === "Staff"}
                                onClose={handleSubmenuClose}
                                MenuListProps={{
                                  "aria-labelledby": "staff-menu",
                                }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleCloseResourcesMenu();
                                    setActiveItem("resources");
                                    navigate("/addnewagent");
                                  }}
                                >
                                  Add Agent
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleCloseResourcesMenu();
                                    setActiveItem("resources");
                                    navigate("/all-staff");
                                  }}
                                >
                                  All Agent
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleCloseResourcesMenu();
                                    setActiveItem("resources");
                                    navigate("/unregistered-staff");
                                  }}
                                >
                                  Unregistered Agent
                                </MenuItem>
                              </Menu>
                            )}
                          </MenuItem>

                          {/* Partner Menu */}
                          <MenuItem
                            onMouseEnter={(event) =>
                              handleSubmenuOpen(event, "Partner")
                            }
                            onMouseLeave={handleSubmenuClose}
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            Partner
                            <ChevronRightIcon />
                            {hoveredItem === "Partner" && (
                              <Menu
                                anchorEl={submenuAnchor}
                                open={hoveredItem === "Partner"}
                                onClose={handleSubmenuClose}
                                MenuListProps={{
                                  "aria-labelledby": "partner-menu",
                                }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleCloseResourcesMenu();
                                    setActiveItem("resources");
                                    navigate("/addnewpartner");
                                  }}
                                >
                                  Add Partner
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleCloseResourcesMenu();
                                    setActiveItem("resources");
                                    navigate("/partners");
                                  }}
                                >
                                  All Partner
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleCloseResourcesMenu();
                                    setActiveItem("resources");
                                    navigate("/unregistered-partners");
                                  }}
                                >
                                  Unregistered Partners
                                </MenuItem>
                              </Menu>
                            )}
                          </MenuItem>
                        </Menu>
                      </li>

                      <li>
                        <span
                          className={`block py-2 ml-3 primary-black font-normal cursor-pointer text-[#353F4F] text-[17px] relative ${
                            activeItem === "report" ? "font-semibold" : ""
                          }`}
                          onClick={() => {
                            setActiveItem("report");
                            window.open("https://app.powerbi.com/reportEmbed?reportId=c138c5f9-3def-4a5b-b8cb-3e48e5d9f0d3&autoAuth=true&ctid=4673b081-e64e-481e-82c9-1eecd44c322a","__blank");
                          }}
                        >
                          Reports
                          {activeItem === "report" && (
                            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                          )}
                        </span>
                      </li>

                      <li>
                        <span
                          className={`block py-2 ml-3 primary-black font-normal cursor-pointer text-[#353F4F] text-[17px] relative ${
                            activeItem === "scheduler" ? "font-semibold" : ""
                          }`}
                          onClick={() => {
                            setActiveItem("scheduler");
                            navigate("/scheduler");
                          }}
                        >
                          Scheduler
                          {activeItem === "scheduler" && (
                            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                          )}
                        </span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            {Object.keys(data).length === 0 && (
              <div className="flex items-center lg:order-2">
                <span
                  className="px-16 py-3 font-normal text-white rounded cursor-pointer bg-primary-blue"
                  onClick={() => setLoginModal({ model: true, open: "login" })}
                >
                  Login/Signup
                </span>
              </div>
            )}

            {Object.keys(data).length > 0 && (
              <div className="flex items-center gap-10 ">
                {userType !== "partner" &&
                  userType !== "consumer" &&
                  userType !== "admin" && (
                    <div className="border-r border-[#D9D9D9] pr-10 py-1">
                      <button
                        className="flex gap-3 py-3 text-white rounded cursor-pointer bg-primary-orange px-7 "
                        onClick={() => navigate("/create-request")}
                      >
                        <img src={PlusIcon} alt="PlusIcon" />
                        <span> Create Request</span>
                      </button>
                    </div>
                  )}

                <Badge
                  badgeContent={notificationData?.total_count}
                  sx={{
                    "& .MuiBadge-badge": {
                      top: "-5px",
                      right: "-5px",
                      background: "#ff0000c9",
                      color: "#fff",
                      fontSize: "11px",
                      padding: "4px",
                      minWidth: "auto",
                      height: "auto",
                      borderRadius: "50%",
                    },
                  }}
                >
                  <img
                    src={NotificationIcon}
                    alt="NotificationIcon"
                    onClick={() => navigate("/notification")}
                    className="cursor-pointer w-[24px]"
                  />
                </Badge>
                <img
                  className="object-cover w-8 h-8 cursor-pointer rounded-3xl"
                  src={UserImage}
                  alt="User Icon"
                  onClick={handleClick}
                />

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 0px 0px rgba(0,0,0,0))",
                      border: "1px solid #D9D9D9",
                      mt: 1.5,

                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                        borderTop: "1px solid #D9D9D9",
                        borderLeft: "1px solid #D9D9D9",
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  // sx={{
                  //   "& .MuiPaper-root": {
                  //     boxShadow: "none",
                  //     border: "1px solid #D9D9D9",
                  //     marginTop: "15px",
                  //   },
                  // }}
                >
                  {userType !== "partner" && userType !== "consumer" && (
                    <MenuItem
                      sx={{
                        backgroundColor: "white",
                        fontSize: "14px",
                        "&:hover": {
                          backgroundColor: "#EEF4FF",
                        },
                      }}
                      onClick={() => {
                        handleClose();
                        if (userType === "consumer") {
                          setUserAction({ ...userAction, profileModal: true });
                        } else {
                          navigate("/profile");
                        }
                      }}
                    >
                      <img
                        src={SettingIcon}
                        alt="SettingIcon"
                        className="pr-3 w-[30px]"
                      />
                      Setting
                    </MenuItem>
                  )}
                  {userType === "admin" && (
                    <MenuItem
                      sx={{
                        backgroundColor: "white",
                        fontSize: "14px",
                        "&:hover": {
                          backgroundColor: "#EEF4FF",
                        },
                      }}
                      onClick={() => {
                        handleClose();
                        navigate("/bulk-upload");
                      }}
                    >
                      <img
                        src={BulkUpload}
                        alt="ProfileIcon"
                        className="pr-3 w-[30px]"
                      />
                      Import / bulk Upload
                    </MenuItem>
                  )}

                  {(userType === "partner" || userType === "consumer") && (
                    <MenuItem
                      sx={{
                        backgroundColor: "white",
                        fontSize: "14px",
                        "&:hover": {
                          backgroundColor: "#EEF4FF",
                        },
                      }}
                      onClick={() => {
                        handleClose();
                        if (userType === "consumer") {
                          setUserAction({
                            ...userAction,
                            profileModal: true,
                          });
                        } else {
                          navigate("/profile");
                        }
                      }}
                    >
                      <img
                        src={ProfileIcon}
                        alt="ProfileIcon"
                        className="pr-3 w-[30px]"
                      />
                      {" Profile"}
                    </MenuItem>
                  )}

                  <MenuItem
                    sx={{
                      backgroundColor: "white",
                      fontSize: "14px",
                      "&:hover": {
                        backgroundColor: "#EEF4FF",
                      },
                    }}
                    onClick={() => {
                      handleClose();
                      navigate("/help");
                    }}
                  >
                    {(userType === "partner" || userType === "admin") && (
                      <img
                        src={HelpIcon}
                        alt="HelpIcon"
                        className="pr-3 w-[30px]"
                      />
                    )}
                    {(userType === "consumer" || userType === "agent") && (
                      <img src={Help} alt="Help" className="pr-3 w-[30px]" />
                    )}
                    Help
                  </MenuItem>

                  {/* 
                  {userType === "admin" && (
                    <MenuItem
                      sx={{
                        backgroundColor: "white",
                        fontSize: "14px",
                        "&:hover": {
                          backgroundColor: "#EEF4FF",
                        },
                      }}
                      // onClick={() => {
                      //   handleClose();
                      //   navigate("/bulk-upload");
                      // }}
                    >
                      <img
                        src={ReportAnalytics}
                        alt="ProfileIcon"
                        className="pr-3 w-[30px]"
                      />
                      Report and Analytics
                    </MenuItem>
                  )} */}

                  <MenuItem
                    sx={{
                      backgroundColor: "white",
                      fontSize: "14px",
                      "&:hover": {
                        backgroundColor: "#EEF4FF",
                      },
                    }}
                    onClick={() => {
                      handleClose();
                      setIsLogout(true);
                    }}
                  >
                    <img
                      src={LogoutIcon}
                      alt="LogoutIcon"
                      className="pr-3 w-[30px]"
                    />
                    Log out
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </nav>
      </header>

      <MuiModal
        modalSize="md"
        modalOpen={loginModal.model}
        modalClose={handleCloseLoginModal}
        noheader
      >
        {openOtp ? (
          <AddOtpLogin
            number={number}
            setNumber={setNumber}
            setOpenOtp={setOpenOtp}
            handleCloseLoginModal={handleCloseLoginModal}
          />
        ) : (
          <>
            <div className="flex flex-col items-center py-7">
              <h5 className="text-primary-blue text-[26px] text-center font-medium">
                CRRMS
              </h5>
              <p className="text-primary-black text-[18px] py-4">
                Welcome to the Request Management System
              </p>
              <p className="text-primary-black text-[16px] pb-6">
                {/* Login for a seamless experience */}
              </p>
              <div className="flex gap-4">
                <LoginSocialGoogle
                  client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  onLogoutFailure={onLogoutFailure}
                  onLoginStart={onLoginStart}
                  onLogoutSuccess={onLogoutSuccess}
                  scope="openid profile email"
                  onResolve={(e) => {
                    onLoginSuccess(e.data);
                  }}
                  onReject={(err) => {
                    console.log("hbhbdhd", err);
                  }}
                >
                  <div
                    className="px-6 py-3 cursor-pointer"
                    style={{
                      border: "0.5px solid rgba(34, 96, 217, 0.5)",
                      borderRadius: "3px",
                    }}
                  >
                    <img src={GoogleIcon} alt="GoogleIcon" />
                  </div>
                </LoginSocialGoogle>
                <div
                  className="px-6 py-3 cursor-pointer"
                  style={{
                    border: "0.5px solid rgba(34, 96, 217, 0.5)",
                    borderRadius: "3px",
                  }}
                >
                  <img src={InstagramIcon} alt="InstagramIcon" />
                </div>
                <div
                  onClick={() => handleFacebookLogin(loginModal.open)}
                  className="px-6 py-3 cursor-pointer"
                  style={{
                    border: "0.5px solid rgba(34, 96, 217, 0.5)",
                    borderRadius: "3px",
                  }}
                >
                  <img src={FacebookIcon} alt="FacebookIcon" />
                </div>
                {/* <div
              className="px-6 py-3 cursor-pointer"
              style={{
                border: "0.5px solid rgba(34, 96, 217, 0.5)",
                borderRadius: "3px",
              }}
            >
              <img src={EmailIcon} alt="EmailIcon" />
            </div> */}
              </div>
              <div
                className="mb-8 mt-8 flex items-center before:mt-0.5 before:flex-1 before:border-t
                     before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t
                      after:border-neutral-300 dark:before:border-neutral-500
                       dark:after:border-neutral-500"
                style={{ color: "rgba(183, 185, 189, 1)", width: "50%" }}
              >
                <p
                  className="mx-4 mb-0 font-semibold text-center dark:text-neutral-200"
                  style={{
                    color: "rgba(183, 185, 189, 1)",
                    border: "1px solid rgba(183, 185, 189, 1)",
                    padding: "2px 20px",
                    borderRadius: "20px",
                    fontSize: "14px",
                  }}
                >
                  OR
                </p>
              </div>

              <form onSubmit={handleSubmit(onSubmit)} className="w-[50%]">
                <div className="flex flex-wrap gap-2">
                  <div className="relative w-full mb-6">
                    <input
                      type="tel"
                      className={`w-full rounded px-3 py-[0.32rem] text-[14px] leading-[2.15] focus:outline-none h-[60px] ${
                        errors["phone_number"]
                          ? "focus:border-teal focus:outline-none focus:ring-0"
                          : ""
                      }`}
                      placeholder="Ex: (555) 555-1234"
                      style={{
                        color: "#232323",
                        border: `1px solid ${
                          errors["phone_number"]
                            ? "rgb(239 68 68)"
                            : "rgba(34, 96, 217, 1)"
                        }`,
                      }}
                      {...register("phone_number", {
                        required: "This field is required",
                        pattern: {
                          value: /^\(\d{3}\) \d{3}-\d{4}$/,
                          message:
                            "Please Enter Valid Mobile Number in (415) 555-1234 format",
                        },
                      })}
                      aria-invalid={errors["phone_number"] ? "true" : "false"}
                      onChange={(e) => {
                        let input = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
                        let formatted = "";

                        if (input.length > 0)
                          formatted = `(${input.substring(0, 3)}`;
                        if (input.length > 3)
                          formatted += `) ${input.substring(3, 6)}`;
                        if (input.length > 6)
                          formatted += `-${input.substring(6, 10)}`;
                        e.target.value = formatted;
                      }}
                    />

                    {errors["phone_number"] && (
                      <p className="error" role="alert">
                        {errors["phone_number"].message}
                      </p>
                    )}
                  </div>

                  <div className="relative w-full">
                    <button
                      type="submit"
                      className="inline-block w-full py-4 text-sm font-medium text-white rounded px-7 bg-primary-blue "
                      data-twe-ripple-init
                      data-twe-ripple-color="light"
                    >
                      Login with OTP
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </MuiModal>

      <MyProfile
        open={userAction.profileModal}
        closeModal={handleCloseUserProfile}
        data={data}
      />
    </>
  );
}
