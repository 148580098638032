import React, { useEffect } from 'react';

const FacebookLoginButton = () => {
  useEffect(() => {
    // Initialize the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_ID, // Replace with your Facebook App ID
        cookie: true,
        xfbml: true,
        version: 'v13.0', // Use the latest valid version
      });

    };

    // Load the Facebook SDK script dynamically
    if (!window.FB) {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
}, []);

// Handle Facebook login
const handleFacebookLogin = () => {
  window.FB.login(
    (response) => {
      if (response.status === 'connected') {
        console.log('Logged in:', response);

        const accessToken = response.authResponse.accessToken; // Extract the access token

        // Send the token to the backend
        fetch('http://127.0.0.1:8000/api/user/facebook-login/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ access_token: accessToken }), // Send the access token
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              console.log('User logged in successfully on backend:', data);
            } else {
              console.error('Error logging in on backend:', data.error);
            }
          })
          .catch((err) => console.error('Error sending token to backend:', err));

      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    },
    { scope: 'public_profile,email' } // Specify the permissions
  );
};

return (
  <div>
    <button onClick={handleFacebookLogin}>Login with Facebook</button>
  </div>
);
};

export default FacebookLoginButton;
