import { Modal, Box, IconButton } from "@mui/material";
import Gmeet from "../../assets/gmeet.svg";
import Close from "../../assets/close.svg";
export function GMeet({ handleClose, open, activeEvent }) {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="google-meet-modal"
        aria-describedby="google-meet-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: 600,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 16,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "text.secondary",
            }}
          >
            <img src={Close} alt="Gmeet" height={30} width={30}/>
            
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <div className="text-[24px]">
              Google Meet
            </div>
            <img src={Gmeet} alt="Gmeet" height={150} width={150}/>
            <button
              className="bg-[#2260D9] text-white text-[16px] px-5 py-2 mt-5"
              onClick={() => window.open(activeEvent?.meet, "_blank")}
            >
              Go to G-Meet
            </button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
