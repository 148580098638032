import { createSlice } from "@reduxjs/toolkit";
import { getEmployeesDetails, createEmployee} from "../../services/employees";

const initialState = {
  details: [],
  loading: false,
  status: "",
  error: "",
};

export const employeeSlice = createSlice({
  name: "employeeInfo",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(createEmployee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
        state.status = "Employee Created Successfully";
      })
      .addCase(createEmployee.rejected, (state, action) => {
        state.loading = false;
        state.error =
          typeof action.payload === "object"
            ? action.payload
            : { message: action.payload || "Something went wrong!" };
      });
    builder
      .addCase(getEmployeesDetails.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getEmployeesDetails.fulfilled, (state, action) => {
        return {
          ...state,
          details: action.payload,
          loading: false,
        };
      })
      .addCase(getEmployeesDetails.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });
  },
});

export default employeeSlice.reducer;
