import React from 'react'

export default function MoreReport () {
    return (
        <div className="w-full h-screen flex flex-col items-center justify-center gap-4 bg-gray-100 p-2 mt-2">
          {/* Header */}
          <h1 className="text-4xl font-bold mb-4">Reports</h1>
      
          {/* Image Row */}
          <div className="flex items-center justify-center gap-4">
            <img
              src="../../chart.png"
              alt="Chart"
              className="flex-1 h-[500px] object-cover rounded-lg shadow-lg hover:scale-105 transition-transform duration-300"
            />
            <img
              src="../../map.png"
              alt="Map"
              className="flex-1 h-[500px] object-cover rounded-lg shadow-lg hover:scale-105 transition-transform duration-300"
            />
            <img
              src="../../graph.png"
              alt="Graph"
              className="flex-1 h-[500px] object-cover rounded-lg shadow-lg hover:scale-105 transition-transform duration-300"
            />
          </div>
        </div>
      )
      
}