import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreIcon from "../../../assets/more-icon.svg";

const ThreeDotMenu = ({ menuOptions }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="flex justify-center items-center h-auto">
      <IconButton
        aria-label="more"
        aria-controls={open ? "menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          borderRadius: "3px",
          minHeight: "47px",
          minWidth: "47px",
          border: "1px solid #C0C0C0",
        }}
      >
        <img src={MoreIcon} alt="more" />
        {/* <MoreVertIcon className="text-blue-500" /> */}
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "none",
            border: "1px solid #3b82f77f",
          },
        }}
        className="menu"
        style={{top:"10px" }}
      >
        {menuOptions.map((option) => (
          <MenuItem
            sx={{
              backgroundColor: "white",
              fontSize: "14px",
              color: "#353F4F",
              "&:hover": {
                backgroundColor: "#EEF4FF",
              },
            }}
            key={option.key}
            onClick={() => {
              handleClose();
              option.onClick();
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ThreeDotMenu;
