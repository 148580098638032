import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import MeetingList from "./MeetingList";
import { deleteMeeting, getMeetings } from "../../services/createMeeting";
import { useDispatch, useSelector } from "react-redux";
import MeetingsPopup from "./MeetingListPopup";
import EventDetailModal from "./EventDetailsModal";
import ConfirmationModal from "./DeletePopup";
import { GMeet } from "./Gmeet";
import { useNavigate } from "react-router";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// Localizer for the calendar
const localizer = momentLocalizer(moment);

// Dummy event data
const events = [
  {
    title: "Internal Meeting",
    start: new Date(2024, 2, 6, 10, 0),
    end: new Date(2024, 2, 6, 11, 0),
    type: "internal",
  },
  {
    title: "External Meeting",
    start: new Date(2024, 2, 7, 14, 0),
    end: new Date(2024, 2, 7, 15, 0),
    type: "external",
  },
  {
    title: "Upcoming Meeting",
    start: new Date(2024, 2, 10, 9, 0),
    end: new Date(2024, 2, 10, 10, 0),
    type: "upcoming",
  },
];
const CustomToolbar = ({ label, onNavigate, onView, view }) => {
  return (
    <div className="flex items-center justify-between mb-10 bg-white text-[#353F4F]">
      <div className="flex items-center space-x-5 ">
        <div className="flex border border-[#3E3E3E80] rounded-sm ">
          <button
            onClick={() => onNavigate("PREV")}
            className="p-2 px-2  border-r text-[#3E3E3E80] border-[#3E3E3E80]"
          >
            <ArrowBackIosIcon/>
          </button>
          <button
            onClick={() => onNavigate("NEXT")}
            className="p-2 px-2 text-[#3E3E3E80]"
          >
           <ArrowForwardIosIcon/>
          </button>
        </div>
        <button
          onClick={() => onNavigate("TODAY")}
          className=" min-w-[138px] bg-[#2260D9] font-medium text-[16px] h-[43px] text-white rounded-[3px] "
        >
          Today
        </button>
      </div>
      <div className="text-[20px] font-medium">{label}</div>
      <div className="flex items-center space-x-4 text-[#353F4F] font-medium text-[16px]">
        <button
          onClick={() => onView("day")}
          className={`p-2 ${view === "day" ? "text-[#1D5BBF] underline" : ""}`}
        >
          Day
        </button>
        <button
          onClick={() => onView("week")}
          className={`p-2 ${view === "week" ? "text-[#1D5BBF] underline" : ""}`}
        >
          Week
        </button>
        <button
          onClick={() => onView("month")}
          className={`p-2 ${
            view === "month" ? "text-[#1D5BBF] underline" : ""
          }`}
        >
          Month
        </button>
      </div>
    </div>
  );
};

const CustomEvent = ({ event }) => (
  <div className="rounded-md">
    <div
      style={{
        fontWeight: "normal",
        backgroundColor: "#2260D9",
        color: "white",
        fontSize: "12px",
        padding: "3px",
      }}
    >
      {event.title}
    </div>
    <div className="text-[12px] text-[#000] px-1">Admin: {event.title}</div>
    <div className="text-[10px] text-[#000] px-1">
      Time: {moment(event.startDateTime).format("h:mm")} -{" "}
      {moment(event.endDateTime).format("h:mm A")}
    </div>
  </div>
);

const MyCalendar = () => {
  const navigate = useNavigate();
  const [selectedMeeting, setSelectedMeeting] = useState("");
  const { currentData, loading, details } = useSelector(
    (state) => state.meetingInfo
  );
  const calendarRef = React.useRef(null);
  const [view, setView] = React.useState("month");
  const [date, setDate] = useState(new Date());

  const {
    formats: { dayFormat, dayRangeHeaderFormat },
  } = React.useMemo(
    () => ({
      formats: {
        dayFormat: (date) => moment(date).format("DD MMM dddd"),
        dayRangeHeaderFormat: ({ start, end }) =>
          `${moment(start).format("Do MMMM")} to ${moment(end).format(
            "Do MMMM, YYYY"
          )}`,
      },
    }),
    []
  );

  const [open, setOpen] = useState(false);
  const [activeEvent, setActiveEvent] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [gmeet, setGmeet] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = () => {
    dispatch(deleteMeeting(activeEvent?.id)).then(() => {
      setIsModalOpen(false);
      dispatch(getMeetings(selectedMeeting));
    });
  };

  useEffect(() => {
    dispatch(getMeetings(selectedMeeting));
  }, [selectedMeeting]);

  const events = useMemo(() => {
    if (currentData) {
      return currentData.map((ele) => {
        const startDate = moment(ele.start_date).toDate();
        const dateStart = moment(ele.start_date);
        const startTime = moment(ele.start, "HH:mm:ss"); // Adjust format as needed
        const startDateTime = dateStart
          .set({
            hour: startTime.hour(),
            minute: startTime.minute(),
            second: startTime.second(),
          })
          .toDate();

        const dateEnd = moment(ele.end_date);
        const endDate = moment(ele.end_date).toDate();
        const endTime = moment(ele.end, "HH:mm:ss"); // Adjust format as needed
        const endDateTime = dateEnd
          .set({
            hour: endTime.hour(),
            minute: endTime.minute(),
            second: endTime.second(),
          })
          .toDate();

        return {
          ...ele,
          title: ele.title,
          start: startDate,
          end: endDate,
          startDateTime,
          endDateTime,
          type: ele.meeting_type,
        };
      });
    }
  }, [currentData]);

  console.log({ events });

  const handleOnChangeView = (newView) => setView(newView);

  useEffect(() => {
    if (calendarRef) {
      calendarRef?.current?.handleViewChange(view);
    }
  }, [calendarRef, view]);

  const handleShowMoreClick = useCallback(
    (events) => {
      // dispatch(setCalendarEventState({ key: 'meetings', value: events }));
      setMeetings(events);
      setOpen(true);
    },
    [dispatch]
  );

  console.log({ view });

  const handleSelect = (event) => {
    setActiveEvent(event);
    setIsOpen(true);
  };

  function filterDate(date) {
    const startDate = moment(date);
    if (startDate) {
      setDate(startDate.toDate());
    }
  }

  const handleSelectSlot = (slotInfo) => {
    const selectedDate = new Date(slotInfo.start);
    const adjustedDate = moment(selectedDate).format("YYYY-MM-DD");
    navigate(`/create-meeting?date=${adjustedDate}`);
  };

  return (
    <div className="px-8">
      <div className="font-sans text-[#5975A2] font-medium text-[16px] py-8">
        Calendar
      </div>

      <div className="flex flex-col lg:flex-row gap-[1.5rem] custom-shadow rounded-[5px]  text-[#353F4F] CalendarStylesWrapper">
        {/* Sidebar */}
        <MeetingList
          setSelectedMeeting={setSelectedMeeting}
          selectedMeeting={selectedMeeting}
        />

        {/* Calendar */}
        <div className="lg:w-3/4 w-full bg-white border-[#DBE0E5] border shadow-xl rounded-[6px] p-6">
          <h3 className="text-[#353F4F] text-lg font-semibold mt-2 mb-10">My Scheduler</h3>
          <Calendar
            selectable
            date={date}
            view={view}
            timeslots={1}
            ref={calendarRef}
            events={events || []}
            defaultView={"month"}
            selected={activeEvent}
            onNavigate={filterDate}
            onView={handleOnChangeView}
            onSelectEvent={handleSelect}
            onSelectSlot={handleSelectSlot}
            localizer={localizer}
            onShowMore={handleShowMoreClick}
            components={{
              toolbar: CustomToolbar, // Use the custom toolbar
              event: CustomEvent,
            }}
            style={{ height: 800, overflowY: "auto" }}
            messages={{ showMore: (total) => `${total}` }}
            formats={{
              dayFormat, // day labels
              dayRangeHeaderFormat,
            }}
            startAccessor={(event) => {
              return new Date(event.start);
            }}
            endAccessor={(event) => {
              return new Date(event.end);
            }}
            dayNameRowStyle={{ height: '100px' }} 
          />
        </div>
        <MeetingsPopup
          open={open}
          onClose={() => setOpen(false)}
          meetings={meetings}
          setActiveEvent={setActiveEvent}
          setIsModalOpen={setIsModalOpen}
        />

        <EventDetailModal
          activeEvent={activeEvent}
          setActiveEvent={setActiveEvent}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          setIsModalOpen={setIsModalOpen}
          setGmeet={setGmeet}
        />

        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleDelete}
          title="Are you sure want to delete this meeting?"
        />

        <GMeet
          open={gmeet}
          handleClose={() => setGmeet(false)}
          activeEvent={activeEvent}
        />
      </div>
    </div>
  );
};

export default MyCalendar;
