import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import BreadCrumIcon from '../../assets/breadcrumb-right-icon.svg'
import SearchIcon from '../../assets/input-search-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import AgentCategory from './AgentCategory'
import ConsumerCategory from './ConsumerCategory'
import { getAllCategoryList } from '../../services/category'
import useDebounce from '../../utills/debounce'


export default function Category() {
    const navigate = useNavigate()
    const { userType } = useSelector(state => state.userInfo)
    const dispatch = useDispatch()
    const [search, setSearch] = useState("")
    const searchValue = useDebounce(search, 500);

    useEffect(() => {
        dispatch(getAllCategoryList(searchValue))
    }, [searchValue])

    return (
        <div className="px-8 mt-10 dashboard-content">

            <div className='mb-6'>
                <nav class="flex" aria-label="Breadcrumb">
                    <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                        <li class="inline-flex items-center">
                            <span class="inline-flex items-center text-sm text-gray-700 hover:text-blue-600 dark:text-gray-400 cursor-pointer dark:hover:text-white"
                                onClick={() => navigate('/')}>
                                Dashboard
                            </span>
                        </li>
                        <li aria-current="page">
                            <div class="flex items-center">
                                <img src={BreadCrumIcon} alt="BreadCrumIcon" />
                                <span class="ms-1 text-sm  md:ms-2 text-primary-blue">Programs</span>
                            </div>
                        </li>
                    </ol>
                </nav>

            </div>



            <div className='px-3 py-5 mb-5' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
                <div className='flex items-center justify-between px-5 pb-4 mb-8' style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>
                    <div className='flex items-center gap-5 '>
                        <p>All Programs</p>
                    </div>
                    <div className='flex items-center gap-8'>
                        <div className="relative">
                            <input type="text" id="search-navbar" className="block w-full p-2 text-sm text-gray-900 border-none rounded focus:outline-none"
                                placeholder="Search" style={{
                                    border: '1px solid rgba(34, 96, 217, 1)',
                                    height: '50px',
                                    width: '300px'
                                }}

                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
                                <img src={SearchIcon} alt='SearchIcon' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mx-5'>
                    {
                        userType === 'consumer' &&
                        <ConsumerCategory searchValue={searchValue} />
                    }

                    {
                        userType === 'agent' &&
                        <AgentCategory />
                    }

                </div>
            </div>


        </div>
    )
}
