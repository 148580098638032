import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import LocationIcon from "../../../src/assets/location-icon.svg";
import { Button } from "../../shared/Button/index.jsx";
import { AddNewPartnerFields } from "../../utills/formFields.js";
import { OrganizationContactPersonFields } from "../../utills/formFields.js";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";

import { Alert, Checkbox, InputLabel, Snackbar } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  createBgVerificationRequest,
  createNewPartnerRequest,
  getParterDetailsbyId,
  updatePartnerRequest,
} from "../../services/partner";
import { getAllCategoryList } from "../../services/category.js";
import { getAllPartnerList } from "../../services/partner.js";
import BreadCumbs from "../BreadCumbs/BreadCumbs.jsx";
import { partnerstatus } from "../../utills/formFields.js";
import SuccessDialog from "../SuccessDialog.jsx";
import SuccessIcon from "../../assets/BlueSuccessIcon.svg";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

const AddNewPartner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const partnerId = queryParams.get("id");
  const partnerInfo = useSelector((state) => state.partnerInfo.details);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [sections, setSections] = useState({
    partnerPrimary: true,
    organizationContact: true,
    categoryAssignment: true,
    additionalInformation: true,
  });
  const [showVerificationPopup, setShowVerificationPopup] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpenforApplications, setDialogOpenForApplicaton] =
    useState(false);

  const toggleSection = (section) => {
    setSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prev) => {
      if (prev.includes(category)) {
        return prev.filter((item) => item !== category);
      }
      return [...prev, category];
    });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    trigger,
  } = useForm();

  const {
    state,
    loading,
    error: reduxError,
    status,
  } = useSelector((state) => state.partnerInfo);

  const categoryList = useSelector((state) => {
    return state.categoryInfo?.data?.data;
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    dispatch(getAllCategoryList());
  }, [dispatch]);

  useEffect(() => {
    if (partnerId) {
      dispatch(getParterDetailsbyId(partnerId));
    }
  }, [dispatch]);

  const handleClose = () => {
    setOpenError(false);
  };

  useEffect(() => {
    if (Object.keys(partnerInfo)?.length > 0) {
      reset(partnerInfo);

      if (categoryList?.length > 0) {
        const categoryLookup = Object.fromEntries(
          categoryList?.map((item) => [item.id, item.name])
        );

        const selectedCategoryNames = partnerInfo?.categories?.map(
          (category) => categoryLookup[category] || null
        );
        setSelectedCategories(selectedCategoryNames || []);
      }
    }
  }, [partnerInfo, categoryList]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchPartnerDetails = useCallback(() => {
    if (partnerInfo) {
      reset({
        organization_name: partnerInfo.organization_name,
        email: partnerInfo.email,
        contact_number: partnerInfo.contact_number,
        alternative_number: partnerInfo.alternative_number,
        total_org_employee: partnerInfo.total_org_employee || 0,
        org_address: partnerInfo.org_address,
        city: partnerInfo.city,
        state: partnerInfo.state,
        postal_code: partnerInfo.postal_code,
        country: partnerInfo.country,
        status: partnerInfo.status,
        reason: partnerInfo.status === "inactive" ? partnerInfo.reason : "",
        organization: {
          first_name: partnerInfo.first_name,
          last_name: partnerInfo.last_name,
          email: partnerInfo.email,
          org_phone_number: partnerInfo.org_phone_number,
        },
        // categories: selectedCategoryIds,
      });
    }
  }, [partnerId, partnerInfo, reset]);

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const categoryLookup = Object.fromEntries(
        categoryList.map((item) => [item.name, item.id])
      );

      const selectedCategoryIds = selectedCategories
        .map((category) => categoryLookup[category] || null)
        .filter((id) => id !== null);

      if (data.status === "inactive" && !data.reason) {
        console.error("Reason is required when deactivating the agent.");
        setIsSubmitting(false);
        return;
      }

      const formData = {
        organization_name: data.organization_name,
        email: data.email1,
        contact_number: data.contact_number,
        alternative_number: data.alternative_number,
        total_org_employee: data.total_org_employee,
        org_address: data.org_address,
        city: data.city,
        state: data.state,
        postal_code: data.postal_code,
        country: data.country,
        reason: data.status === "inactive" ? data.reason : "",
        organization: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          org_phone_number: data.org_phone_number,
        },
        categories: selectedCategoryIds,
      };

      if (partnerId) {
        const { organization, ...rest } = formData;
        await dispatch(
          updatePartnerRequest({ partner_id: partnerId, data: rest })
        );
      } else {
        await dispatch(createNewPartnerRequest(formData));
      }
      setDialogMessage("Partner Information Updated");
      setIsSuccess(true);
      dispatch(getAllPartnerList({}));
      setDialogOpen(true);
      // setTimeout(() => {
      //   setDialogOpen(false);
      //   setShowVerificationPopup(true);
      // }, 2000);
    } catch (err) {
      setDialogMessage("An error occurred while submitting the form.");
      setError("An error occurred while submitting the form.");
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBgverification = async () => {
    const values = getValues();
    const payload = {
      action: "candidate",
      first_name: values?.organization?.first_name || "",
      last_name: values?.organization?.last_name || "",
      email: values?.organization?.email || "",
      dob: values?.organization?.dob || "",
      zipcode: values.postal_code || "",
      middle_name: values.middle_name || "",
      copy_requested: values.copy_requested || false,
      work_location_country: values.country || "",
      work_location_state: values.state || "",
      work_location_city: values.city || "",
    };

    try {
      const response = await dispatch(
        createBgVerificationRequest({
          formData: payload,
          actiontype: "candidate",
        })
      ).unwrap();

      if (response.status === 400) {
        setOpenError(true);
        setErrorMsg(response?.response?.data?.errors?.error);
      }

      if (response.status === 200 || response.status === "success") {
        const concatenatedResult = response.result.join("");
        const parsedResult = JSON.parse(concatenatedResult);

        if (parsedResult) {
          const invitationPayload = {
            candidate_id: parsedResult.id,
            package: "test_pro_criminal_and_mvr",
            work_locations: [
              {
                country: values.country,
                state: values.state,
                city: values.city,
              },
            ],
          };
          const responseInvitation = await dispatch(
            createBgVerificationRequest({
              formData: invitationPayload,
              actiontype: "invitation",
            })
          ).unwrap();

          const concatenatedResultInvitation =
            responseInvitation.result.join("");
          const parsedResultInvitation = JSON.parse(
            concatenatedResultInvitation
          );

          if (responseInvitation.status === "success") {
            setShowVerificationPopup(false);
            setDialogOpenForApplicaton(true);
            window.open(parsedResultInvitation.invitation_url, "_blank");
          }
        }
      }
    } catch (error) {
      console.error("Error during background verification:", error);
    }
  };

  useEffect(() => {
    if (status === "Partner Created Successfully") {
      navigate("/partners");
    } else if (status === "error") {
      console.error("Error:", error);
    }
    if (partnerInfo) {
      console.log(partnerInfo);
      fetchPartnerDetails();
    }
  }, [status, error, navigate, partnerInfo, fetchPartnerDetails]);

  const [imageFiles, setImageFiles] = useState([]);
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const updatedFiles = [...imageFiles, ...files];
    setImageFiles(updatedFiles);
  };
  const handleDelete = (fileToDelete) => {
    setImageFiles(imageFiles.filter((file) => file !== fileToDelete));
  };
  const payload = {
    status: "active", // Correct value based on API documentation
  };
  if (!["active", "inactive", "pending"].includes(payload.status)) {
    throw new Error("Invalid status value.");
  }

  return (
    <>
      <SuccessDialog open={open} onClose={() => setOpen(false)} />
      {loading && <p>Loading...</p>}
      {error && typeof error === "object" ? (
        <p className="error">{error.message || "An error occurred"}</p>
      ) : (
        <p className="error">{error}</p>
      )}
      <div className="dashboard-content px-8 mt-10">
        {partnerId ? (
          <BreadCumbs
            firstCumbName="Partner"
            secoundCumbName="Partner Status"
            thirdCumbName="Partner View (Edit)"
            pathToRedirect="addnewPartner"
            pathToRedirectSecond="partners"
          />
        ) : (
          <BreadCumbs
            firstCumbName="Dashboard"
            secoundCumbName="Add new partner"
            pathToRedirect=""
          />
        )}

        <div className="mb-5 mt-8 px-3 border border-[#2260D9] rounded-[5px]">
          <div>
            <div className="flex items-start justify-between pt-4 pb-3 title ">
              {partnerId ? (
                <div className="flex items-center gap-5 pt-2 pl-6">
                  <h1 className="text-[#1D5BBF] font-semibold text-[28px]">
                    ABCD Welfare And Awareness
                  </h1>
                  <div className="border-r border-[#2260D9] h-10" />
                  <div className="flex items-center gap-2 font-medium text-[#353F4F] text-lg">
                    <span>ID Number : </span>
                    <span>{partnerId ? `#${partnerId}` : ""}</span>
                  </div>
                  <div className="bg-[#F2F7FF] border border-[#2260D9] font-semibold text-[#2260D9] text-[22px] rounded-[3px] px-10 py-3 ml-5">
                    EXTERNAL PARTNER
                  </div>
                </div>
              ) : (
                <div className="w-full pl-6 pb-3 pt-5 font-medium text-base text-[#353F4F] border-b border-[#D3D3D3]">
                  New Partner
                </div>
              )}

              {partnerId ? (
                <button
                  className="border-[#353F4F] border-transparent rounded-[3.9px] w-[145px] h-11 bg-[#2260D9] font-medium text-white text-sm"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              ) : null}
            </div>
          </div>

          {partnerId && (
            <div className="flex flex-row w-full items-start justify-start px-6 pt-4">
              <div className="flex flex-col w-[20%] space-y-6">
                {partnerstatus.map((field, index) => (
                  <div className={`relative mb-6 ${field.width}`} key={index}>
                    <label
                      className="block mb-2 text-xs font-normal tracking-wide text-gray-700"
                      htmlFor={field.label}
                    >
                      {field.label}{" "}
                      <span style={{ color: "red" }}>
                        {field?.inputRules?.required ? "*" : ""}
                      </span>
                    </label>
                    <div className="py-2 text-yellow-700 bg-yellow-200 rounded">
                      {field.type === "dropdown" ? (
                        <>
                          <Controller
                            name={field.name}
                            control={control}
                            defaultValue={field.options[0]?.value || ""}
                            rules={{
                              validate: (value) =>
                                value === "inactive" && !getValues("reason")
                                  ? "Reason is required when status is 'inactive'."
                                  : true,
                            }}
                            render={({ field: controllerField }) => (
                              <Listbox
                                value={controllerField.value}
                                onChange={(value) =>
                                  controllerField.onChange(value)
                                }
                              >
                                <div className="relative">
                                  <ListboxButton
                                    className={` flex justify-between items-center px-3 input-bg border-none 
                          focus-visible:outline-none text-[14px] h-[60px] text-left bg-white rounded-md ${
                            controllerField.value === "active"
                              ? "text-[#25861D]"
                              : controllerField.value === "inactive"
                              ? "text-[#D22735]"
                              : "text-gray-900"
                          }`}
                                  >
                                    <span className="flex items-center">
                                      {controllerField.value && (
                                        <span
                                          className={`w-3 h-3 rounded-full mr-2 ${
                                            controllerField.value === "active"
                                              ? "bg-[#25861D]"
                                              : controllerField.value ===
                                                "inactive"
                                              ? "bg-[#D22735]"
                                              : "bg-gray-400"
                                          }`}
                                        />
                                      )}
                                      {controllerField.value
                                        ? field.options.find(
                                            (option) =>
                                              option.value ===
                                              controllerField.value
                                          )?.name
                                        : "Select"}
                                    </span>
                                    <ArrowDropDownIcon />
                                  </ListboxButton>
                                  <ListboxOptions className="absolute z-10 mt-1  bg-white rounded-md shadow-lg max-h-60 overflow-auto focus:outline-none">
                                    {field.options.map((option, idx) => (
                                      <ListboxOption
                                        key={idx}
                                        value={option.value}
                                        className={({ active }) =>
                                          `cursor-pointer relative py-2 px-4 flex items-center ${
                                            active
                                              ? "bg-blue-600"
                                              : option.value === "active"
                                              ? "text-[#25861D]"
                                              : option.value === "inactive"
                                              ? "text-[#D22735]"
                                              : "text-gray-900"
                                          }`
                                        }
                                      >
                                        <span
                                          className={`w-3 h-3 rounded-full mr-2 ${
                                            option.value === "active"
                                              ? "bg-[#25861D]"
                                              : option.value === "inactive"
                                              ? "bg-[#D22735]"
                                              : "bg-gray-400"
                                          }`}
                                        />
                                        {option.name}
                                      </ListboxOption>
                                    ))}
                                  </ListboxOptions>
                                </div>
                              </Listbox>
                            )}
                          />
                          {errors[field.name] && (
                            <p className="error" role="alert">
                              {errors[field.name].message}
                            </p>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col w-full md:w-1/2 gap-3.5">
                {watch("status") === "inactive" && (
                  <>
                    <label
                      htmlFor="Comment"
                      className="text-[#353F4F] text-[12px] flex items-center"
                    >
                      Reason{" "}
                      <span className=" ml-1" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <textarea
                      id="reason"
                      name="reason"
                      rows={6}
                      {...register("reason", {
                        required:
                          "Reason is required when deactivating the Partner.",
                      })}
                      className="block w-full rounded-md h-[60px] bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-[#D6E5EE] placeholder:text-gray-400 focus:outline-2 focus:outline-indigo-600 sm:text-sm"
                      placeholder="Enter your reason for changing the status"
                    />
                    {errors.reason && (
                      <p className="error" role="alert">
                        {errors.reason.message}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              {loading && <p>Loading...</p>}
              {error && <p className="error">{error}</p>}
              {isSuccess && !loading && !error && (
                <div className="success-message">
                  <p>Partner successfully added!</p>
                </div>
              )}
              <div className="flex items-center justify-between pt-8 pb-3 px-6 border-b-[1.3px] border-[#D3D3D3]">
                <div className="font-medium text-[#2260D9] text-base">
                  Partner Primary Information
                </div>
                <div
                  className="bg-[#F1F6FF] rounded-full text-center cursor-pointer"
                  onClick={() => toggleSection("partnerPrimary")}
                >
                  {sections.partnerPrimary ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </div>
              </div>
              {sections.partnerPrimary && (
                <div className="flex flex-wrap gap-4 p-6">
                  {AddNewPartnerFields.map((field, index) => {
                    return (
                      <div
                        className={`relative mb-6 ${field.width}`}
                        key={index}
                      >
                        <label
                          className="block tracking-wide text-[#353F4F] text-xs font-normal mb-2"
                          htmlFor={field.label}
                        >
                          {field.label}{" "}
                          <span style={{ color: "red" }}>
                            {field?.inputRules?.required ? "*" : ""}
                          </span>
                        </label>
                        {field.type === "input" ? (
                          <div className="relative">
                            <input
                              {...register(field.name, {
                                ...field.inputRules,
                                validate: (value) => {
                                  if (
                                    field.name === "total_org_employee" ||
                                    field.name === "postal_code"
                                  ) {
                                    // Check if value is negative
                                    if (value < 0) {
                                      return "Value cannot be negative";
                                    }
                                  }
                                  return true;
                                },
                              })}
                              // {...register(field.name, field.inputRules)}
                              type={field.fieldType}
                              className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                              placeholder={field.placeholder}
                              style={{
                                color: "#232323",
                                borderRadius: "3px",
                              }}
                              aria-invalid={!!errors[field.name]}
                              onBlur={() => trigger(field.name)}
                            />
                            {field.icon && field.icon === "location" && (
                              <img
                                className="absolute top-4 right-4"
                                src={LocationIcon}
                                alt="LocationIcon"
                              />
                            )}

                            {errors[field.name] && (
                              <p className="error" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </div>
                        ) : field.type === "tel" ? (
                          <div className="relative">
                            <input
                              {...register(field.name, field.inputRules)}
                              type={field.fieldType}
                              className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                      focus-visible:outline-none text-[14px] h-[60px]"
                              placeholder={field.placeholder}
                              style={{
                                color: "#232323",
                                borderRadius: "3px",
                              }}
                              aria-invalid={!!errors[field.name]}
                              onChange={(e) => {
                                const input = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
                                const formatted = input
                                  .replace(
                                    /(\d{3})(\d{3})(\d{4})/,
                                    "($1) $2-$3"
                                  ) // Format with parentheses and dash
                                  .replace(/(\d{3})(\d{3})/, "($1) $2") // Handle intermediate formatting
                                  .substring(0, 14); // Limit to the required format
                                e.target.value = formatted;
                              }}
                            />
                            {errors[field.name] && (
                              <p className="error" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="flex items-center justify-between  pb-3 px-6 mt-5 border-b-[1.3px] border-[#D3D3D3]">
                <div className="text-[#2260D9] font-medium text-base">
                  Organization Contact Person
                </div>
                <div
                  className="bg-[#F1F6FF] rounded-full text-center cursor-pointer"
                  onClick={() => toggleSection("organizationContact")}
                >
                  {sections.organizationContact ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </div>
              </div>
              {sections.organizationContact && (
                <div className="flex flex-wrap gap-4 p-6">
                  {OrganizationContactPersonFields.map((field, index) => {
                    return (
                      <div
                        className={`relative mb-6  ${field.width}`}
                        key={index}
                      >
                        <label
                          className="block tracking-wide text-[#353F4F] text-xs font-normal mb-2"
                          htmlFor={field.label}
                        >
                          {field.label}{" "}
                          <span style={{ color: "red" }}>
                            {field?.inputRules?.required ? "*" : ""}
                          </span>
                        </label>
                        {field.type === "input" ? (
                          <div className="relative">
                            <input
                              {...register(field.name, field.inputRules)}
                              type={field.fieldType}
                              className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                              placeholder={field.placeholder}
                              style={{
                                color: "#232323",
                                borderRadius: "3px",
                              }}
                              aria-invalid={!!errors[field.name]}
                            />
                            {field.icon && field.icon === "location" && (
                              <img
                                className="absolute top-4 right-4"
                                src={LocationIcon}
                                alt="LocationIcon"
                              />
                            )}

                            {errors[field.name] && (
                              <p className="error" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </div>
                        ) : field.type === "tel" ? (
                          <div className="relative">
                            <input
                              {...register(field.name, field.inputRules)}
                              type={field.fieldType}
                              className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                      focus-visible:outline-none text-[14px] h-[60px]"
                              placeholder={field.placeholder}
                              style={{
                                color: "#232323",
                                borderRadius: "3px",
                              }}
                              aria-invalid={!!errors[field.name]}
                              onChange={(e) => {
                                const input = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
                                const formatted = input
                                  .replace(
                                    /(\d{3})(\d{3})(\d{4})/,
                                    "($1) $2-$3"
                                  ) // Format with parentheses and dash
                                  .replace(/(\d{3})(\d{3})/, "($1) $2") // Handle intermediate formatting
                                  .substring(0, 14); // Limit to the required format
                                e.target.value = formatted;
                              }}
                              
                            />
                            {errors[field.name] && (
                              <p className="error" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="w-full">
                <div className="flex items-center justify-between  pb-3 px-6 mt-5 border-b-[1.3px] border-[#D3D3D3]">
                  <div className="text-[#2260D9] font-medium text-base">
                    Program Assignment
                  </div>
                  <div
                    className="bg-[#F1F6FF] rounded-full text-center cursor-pointer"
                    onClick={() => toggleSection("categoryAssignment")}
                  >
                    {sections.categoryAssignment ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </div>
                </div>
                {sections.categoryAssignment && (
                  <div className="p-6">
                    <label
                      className="block tracking-wide text-[#353F4F] text-xs font-normal mb-2"
                      htmlFor="categories"
                    >
                      Programs{" "}
                      <span style={{ color: "red" }}>{true ? "*" : ""}</span>
                    </label>
                    <div>
                      <Listbox
                        value={selectedCategories}
                        onChange={handleCategoryChange}
                      >
                        {({ open }) => (
                          <div className="relative mt-2">
                            <ListboxButton className="relative flex justify-between items-center w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]">
                              <span className="flex items-center">
                                {selectedCategories?.length === 0 && (
                                  <InputLabel
                                    className="text-gray-500 "
                                    shrink={false}
                                  >
                                    Select a Program
                                  </InputLabel>
                                )}
                                <Stack direction="row" spacing={1}>
                                  {selectedCategories?.map(
                                    (category, index) => (
                                      <Chip
                                        key={index}
                                        label={category}
                                        onDelete={() => {
                                          setSelectedCategories((prev) =>
                                            prev.filter(
                                              (item) => item !== category
                                            )
                                          );
                                        }}
                                        sx={{
                                          backgroundColor: "#F0F0F0",
                                          color: "#2260D9",
                                          ".MuiChip-deleteIcon": {
                                            color: "#FF0000",
                                          },
                                        }}
                                      />
                                    )
                                  )}
                                </Stack>
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                {open ? (
                                  <ArrowDropUpIcon
                                    aria-hidden="true"
                                    className="h-5 w-5 text-gray-400"
                                  />
                                ) : (
                                  <ArrowDropDownIcon
                                    aria-hidden="true"
                                    className="h-5 w-5 text-gray-400"
                                  />
                                )}
                              </span>
                            </ListboxButton>

                            <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                              {(categoryList?.map((e) => e.name) || [])?.map(
                                (category) => (
                                  <ListboxOption
                                    key={category}
                                    value={category}
                                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 "
                                  >
                                    <div className="flex items-center">
                                      <Checkbox
                                        checked={
                                          selectedCategories.indexOf(category) >
                                          -1
                                        }
                                      />
                                      <span className="ml-3 block truncate">
                                        {category}
                                      </span>
                                    </div>
                                  </ListboxOption>
                                )
                              )}
                            </ListboxOptions>
                          </div>
                        )}
                      </Listbox>
                    </div>
                  </div>
                )}
              </div>

              <div className="w-full">
                <div className="flex items-center justify-between  pb-3 px-6 mt-5 border-b-[1.3px] border-[#D3D3D3]">
                  <div className="text-primary-blue text-[18px]">
                    Additional Informations
                  </div>
                  <div
                    className="bg-[#F1F6FF] rounded-full text-center cursor-pointer"
                    onClick={() => toggleSection("additionalInformation")}
                  >
                    {sections.additionalInformation ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </div>
                </div>
                {sections.additionalInformation && (
                  <div className="w-full p-6">
                    <label className="block tracking-wide text-[#353F4F] text-xs font-normal mb-2">
                      Documents (Optional)
                    </label>
                    <div className="relative flex justify-between items-center w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]">
                      <Stack direction="row" spacing={1}>
                        {imageFiles.map((file, index) => (
                          <Chip
                            key={index}
                            label={file.name}
                            onDelete={() => handleDelete(file)}
                            sx={{
                              backgroundColor: "#F0F0F0",
                              color: "#2260D9",
                              ".MuiChip-deleteIcon": {
                                color: "#FF0000",
                              },
                            }}
                          />
                        ))}
                      </Stack>
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        className={`bg-transparent w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px]`}
                        id="image-upload"
                        style={{
                          color: "#232323",
                          borderRadius: "3px",
                          display: "none",
                        }}
                        onChange={handleImageChange}
                      />
                      <button
                        className="bg-[#2260D9] flex items-center gap-1 text-white font-medium text-sm rounded-sm p-2.5 pr-4"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          document.getElementById("image-upload").click();
                        }}
                      >
                        <AddIcon />
                        Upload Image
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex w-full justify-center gap-10 mt-8 pb-20 font-medium text-sm">
                <button className="border-[#353F4F] border-[1.3px] text-[#353F4F] rounded-[3.9px] w-[195px] h-[45px]">
                  Cancel
                </button>
                <button
                  type="submit"
                  className="border-transparent rounded-[3.9px] w-[195px] h-[45px] bg-[#2260D9] text-white"
                  // onClick={() => navigate("/partners")}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="first-dialog-title"
        aria-describedby="first-dialog-description"
        maxWidth="xl"
      >
        <DialogContent
          style={{ width: "450px", height: "180px", borderRadius: "10px" }}
          className="flex flex-col justify-center items-center"
        >
          {/* <img src={SuccessIcon} alt="SuccessIcon" /> */}
          <Typography variant="h6" align="center" style={{ color: "#2260D9" }}>
            {dialogMessage}
          </Typography>
        </DialogContent>
      </Dialog>

      {/* Verification Dialog */}
      <Dialog
        open={showVerificationPopup}
        // onClose={() => setShowVerificationPopup(false)}
        aria-labelledby="verification-dialog-title"
        aria-describedby="verification-dialog-description"
        className="flex items-center justify-center max-w-none rounded-xl"
        maxWidth="xl"
      >
        <div className="bg-white flex flex-col gap-5 justify-center items-center  shadow-lg lg:py-20  px-3 sm:px-8 lg:px-20 py-6 sm:py-8 md:py-16 ">
          <img src={SuccessIcon} alt="SuccessIcon" className="w-28 h-28" />
          <DialogTitle
            className="text-center !text-4xl"
            style={{ color: "#2260D9" }}
          >
            Information Updated
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="verification-dialog-description"
              style={{ color: "#353F4F" }}
              className="!text-xl"
            >
              Do you want to send this application to Background Verification?
            </DialogContentText>
          </DialogContent>
          <div className="flex justify-center gap-4">
            <button
              onClick={() => {
                setShowVerificationPopup(false);
                navigate("/");
              }}
              style={{
                border: "1.3px solid #2260D9",
                color: "#2260D9",
                borderRadius: "3px",
                width: "150px",
                height: "50px",
              }}
            >
              Later
            </button>
            <button
              onClick={handleBgverification}
              style={{
                backgroundColor: "#2260D9",
                color: "white",
                borderRadius: "3.9px",
                width: "150px",
                height: "50px",
              }}
            >
              Send Now
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={dialogOpenforApplications}
        onClose={() => {
          setDialogOpenForApplicaton(false);
          navigate("/");
        }}
        maxWidth="xl"
      >
        <DialogContent>
          <div className="bg-white flex flex-col gap-5 justify-center items-center lg:py-20  px-3 sm:px-8 lg:px-20 py-6 sm:py-8 md:py-16 ">
            <img src={SuccessIcon} alt="SuccessIcon" className="w-28 h-28" />
            <Typography
              variant="h6"
              className="!text-2xl !mt-10"
              align="center"
              style={{ color: "#2260D9" }}
            >
              Application Sent for Background verification
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddNewPartner;
