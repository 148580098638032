import React, { useState, useEffect } from "react";

import SearchIcon from "../../assets/input-search-icon.svg";
import DataTable from "../../shared/DataGrid";
import {
  TotalPartnerColumn,
  TotalRequestColumn,
} from "../../utills/tableFields";
import { TotalAgentColumn } from "../../utills/tableFields";
import { useDispatch, useSelector } from "react-redux";
import { getAllAgentList } from "../../services/agent";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreIcon from "../../assets/more-icon.svg";
import { useNavigate } from "react-router";

export default function AgentView() {
  const [actionTab, setActiveTab] = useState("total-request");
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const actionTabFilter = [
    {
      key: "total-request",
      name: "Total Request",
    },
    {
      key: "total-agent",
      name: "Total Staff",
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(data);
  };

  const state = useSelector((state) => state);
  console.log("state", state);

  const agentInfo = useSelector((state) => state.agentInfo.details.data);
  console.log("abd", agentInfo);

  useEffect(() => {
    dispatch(getAllAgentList());
  }, []);

  const TotalAgentsColumn = [
    ...TotalAgentColumn,
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      id: 5,
      renderCell: (params) => {
        const statusStyles = {
          active: { color: "#29B473" },
          inactive: { bg: "#f8d7da", color: "#D22735" },
          // pending: { bg: "#fff3cd", color: "#856404" },
        };

        const status = params.row.status;
        const { bg, color } = statusStyles[status] || { color: "#6c757d" };

        return (
          <div
            className=""
            style={{
              color: color,
              borderRadius: "10px",
              textAlign: "center",
              minWidth: "80px",
            }}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      id: 4,
      renderCell: (params) => {
        return (
          <>
            <div
              className="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer bg-slate-300 hover:bg-slate-400"
              onClick={(e) => handleClick(e, params.row)}
            >
              <img src={MoreIcon} alt="MoreIcon" />
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              className="w-full h-full shadow-none"
            >
              <MenuItem
                onClick={() => navigate("/agentview")}
                className="!text-[12px]"
              >
                View
              </MenuItem>
              <MenuItem
                // onClick={() => navigate('/agentview')}
                className="!text-[12px]"
              >
                Edit
              </MenuItem>
              <MenuItem
                // onClick={() => navigate('/agentview')}
                className="!text-[12px]"
              >
                Deactivate
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const rows =
    agentInfo && agentInfo.length > 0
      ? agentInfo.map((agent) => ({
          id: agent.id,
          agent_id: agent.id,
          agent_name: `${agent.first_name} ${agent.last_name}`,
          email: agent.email,
          last_update_date: agent.created_at,
          status: agent.status,
        }))
      : [];


  return (
    <div
      className="px-5 py-5 mb-5 details-info"
      style={{
        boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
        borderRadius: "5px",
      }}
    >
      <div
        className="flex items-center gap-4 pb-4"
        style={{ borderBottom: "1px solid rgba(228, 230, 239, 1)" }}
      >
        <p
          style={{
            borderRight: "1px solid rgba(34, 96, 217, 1)",
            paddingRight: "20px",
          }}
          className="text-primary-blue font-medium text-[22px]"
        >
          Emergency Relief Aid
        </p>
        <p>Program Id: #HCA00012345</p>
      </div>
      <div className="text-[14px] py-8">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop.
      </div>

      <div
        className="flex items-center justify-between mb-8"
        style={{ borderBottom: "1px solid rgba(211, 211, 211, 1)" }}
      >
        <ul className="tab-list">
          {actionTabFilter.map((discussion, index) => (
            <li
              className={`${
                actionTab === discussion.key ? "active" : ""
              } relative !w-[110px]`}
              key={index}
              onClick={() => setActiveTab(discussion.key)}
            >
              <div className="text-[13px]"> {`${discussion.name}`}</div>
              {actionTab === discussion.key && <span></span>}
            </li>
          ))}
        </ul>
      </div>

      <div className="flex items-center justify-end">
        <div className="relative">
          <input
            type="text"
            id="search-navbar"
            className="block w-full p-2 text-sm text-gray-900 border-none"
            placeholder="Search here..."
            style={{
              border: "1px solid rgba(34, 96, 217, 1)",
              height: "50px",
              width: "300px",
            }}
          />
          <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
            <img src={SearchIcon} alt="SearchIcon" />
          </div>
        </div>
      </div>

      <div className="py-8">
        <DataTable
          rows={actionTab === "total-request" ? [] : rows}
          columns={
            actionTab === "total-request"
              ? TotalRequestColumn
              : TotalAgentsColumn
          }
          hideCheckbox
        />
      </div>
    </div>
  );
}
