import React, { useEffect, useMemo, useState } from "react";
import DataTable from "../../shared/DataGrid";
import { getCurrentRequest } from "../../services/currentRequest";
import { useDispatch, useSelector } from "react-redux";
import MoreIcon from "../../assets/more-icon.svg";
import { useNavigate } from "react-router";
import SearchIcon from "../../assets/input-search-icon.svg";
import OptIcon from "../../assets/opt.svg";
import ViaIcon from "../../assets/via.svg";
import moment from "moment";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import GreenOpt from "../../assets/green-opt.svg";
import RedOpt from "../../assets/red-opt.svg";
import AddPartnersInCategories from "../Popups/AddPartnersInCategories";
import AddRequest from "../Popups/AddRequest";
import MoreHorizIcon from "../../assets/moreHorizIcon.svg";
import CancelRequest from "../Request/AllRequest/CancelRequest";
import ChangeInReview from "../Request/AllRequest/ChangeIntoInReview";
import { titleCase } from "../../utills/castle";
import { Popover } from "@mui/material";
import useDebounce from "../../utills/debounce";

const CurrentRequest = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [openPopup, setOpen] = useState(false);
  const [partnerId, setPartnerId] = useState("");
  const [filterOption, setFilterOption] = useState(null);
  const searchValue = useDebounce(searchQuery, 500);

  const [anchorEl1, setAnchorEl1] = useState(null);
  const { data, userType } = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const [pages, setPage] = useState({ page: 0, pageSize: 10 });
  const handlePageChange = (page) => {
    setPage(page);
  };
  const [openReview, setOpenReview] = useState({
    open: false,
    id: null,
    details: null,
  });
  const [detailAction, setDetailAction] = useState({
    cancelModal: false,
    id: null,
    reject: false,
  });
  const [openPartnerPopup, setPopupOpen] = useState(false);

  const currentData = useSelector((state) => state.currentRequest.currentData);
  const total = useSelector(
    (state) => state.currentRequest?.details?.total_pages
  );

  console.log("total", total);

  const closeModal = () => {
    setDetailAction({ cancelModal: false, id: null, reject: false });
  };
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterChange = (option) => {
    setFilterOption((prevOption) => (prevOption === option ? null : option));
  };

  const requestColumnn = [
    {
      field: "opt",
      headerName: "OPT",
      flex: 1,
      headerAlign: "center",
      renderHeader: () => (
        <div className="">
          <div
            onClick={handleHeaderClick}
            className="flex items-center gap-[5px]"
          >
            <p className="font-sans text-[#18283D] font-semibold">OPT</p>
            <img src={OptIcon} alt="opt" />
          </div>
        </div>
      ),
      renderCell: (params) => {
        const via = params.row?.communication_preference?.toLowerCase();
        const optImage = via === "opt_in" ? GreenOpt : RedOpt;
        return (
          <div className="flex items-center justify-center h-full">
            <img src={optImage} alt="star icon" className="w-4 h-4" />
          </div>
        );
      },
    },

    {
      field: "name",
      headerName: "Client Name",
      flex: 1,
      renderCell: (params) => {
        return <div className="capitalize">{params.row.name}</div>;
      },
    },
    {
      field: "category",
      headerName: "Program",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>{params.row.category ? titleCase(params.row.category) : ""}</div>
        );
      },
    },
    // {
    //   field: "contact",
    //   headerName: "Contact",
    //   flex: 1,
    // },

    {
      field: "via",
      headerName: "Via",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-3">
          <p className="font-sans text-[#18283D] font-semibold">Via</p>
          <img src={ViaIcon} alt="via" />
        </div>
      ),
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "intakeDate",
      headerName: "Intake Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.intakeDate).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "service_option",
      headerName: "Service Option",
      flex: 1,
      renderCell: (params) => {
        const isPaid = params.row.cost === 0;
        return (
          <div className="flex justify-center items-center w-full h-full">
            {isPaid ? <img src={MoreHorizIcon} alt="More" /> : "Paid"}
          </div>
        );
      },
    },
    {
      field: "isPaid",
      headerName: "Payment Status",
      flex: 1,
      renderCell: (params) => {
        const isPaid = params.row.category_is_premium === "True";

        return (
          <div className="capitalize">
            {isPaid ? (
              params.row.is_paid ? (
                <div className="text-[#278E1D]">Paid</div>
              ) : (
                <div className="text-[#FFCD11]">Pending</div>
              )
            ) : (
              "..."
            )}
          </div>
        );
      },
    },
    {
      field: "level",
      headerName: "Level",
      flex: 1,
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
    ...(userType === "agent"
      ? [
          {
            field: "description",
            headerName: "Description",
            flex: 1,
          },
        ]
      : []),
    {
      field: "submittedDate",
      headerName: "Submitted Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.submittedDate).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          completed: "text-[#278E1D]",
          resolved: "text-[#278E1D]",
          "in review": "text-[#E26313]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF]",
        };
        return (
          <div className={`capitalize ${colors[params.value] || ""}`}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "partners_names",
      headerName: "Partner",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="capitalize flex justify-center items-center w-full h-full">
            {params.row.partners_names?.length > 0 ? (
              params.row.partners_names
            ) : (
              <img src={MoreHorizIcon} alt="More" className="w-6 h-6" />
            )}
          </div>
        );
      },
    },
    {
      field: "partner_status",
      headerName: "Partner Response",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          "in review": "text-[#E26313]",
          accepted: "text-[#278E1D]",
          completed: "text-[#278E1D]",
          resolved: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF]",
        };

        const isPartnerNamesNull =
          !params.row.partners_names || params.row.partners_names.length === 0;

        return (
          <div
            className={`capitalize flex justify-center items-center w-full h-full ${
              colors[params.row.partner_status] || ""
            }`}
          >
            {isPartnerNamesNull ? (
              <img src={MoreHorizIcon} alt="More" className="w-6 h-6" />
            ) : params.row.partner_status === "wait" ? (
              "waiting"
            ) : (
              params.row.partner_status
            )}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,

      renderCell: (params) => {
        return (
          <Menu as="div" className="inline-block text-left mt-2">
            <div>
              <MenuButton className="cursor-pointer relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900">
                <img
                  src={MoreIcon}
                  alt="MoreIcon"
                  onClick={(e) => handleClick(e, params.row)}
                />
              </MenuButton>
            </div>
            <MenuItems
              transition
              className="menu-before absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                {params.row.status === "new" && (
                  <MenuItem
                    onClick={() => {
                      setOpenReview({
                        open: true,
                        id: params.id,
                        details: params.row,
                      });
                    }}
                  >
                    <a className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                      Mark as In Review
                    </a>
                  </MenuItem>
                )}

                {(params.row.partner_status === "wait" ||
                  params.row.partners_names.length === 0) && (
                  <MenuItem
                    onClick={() => {
                      setOpen(true);
                      setPartnerId(params.id);
                      setCategoryId(params?.row?.categoryId);
                    }}
                  >
                    <a className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                      Assign
                    </a>
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() => {
                    navigate(`view-request/${params.row.id}`);
                  }}
                >
                  <a
                    href="#"
                    className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                  >
                    View Details
                  </a>
                </MenuItem>
                {params.row.partner_status === "rejected" && (
                  <MenuItem
                    onClick={() => {
                      setOpen(true);
                      setPartnerId(params.id);
                      setCategoryId(params?.row?.categoryId);
                    }}
                  >
                    <a className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                      Reassign
                    </a>
                  </MenuItem>
                )}
                {/* {params.row.partners_names.length === 0 && (
                  <>
                    <MenuItem
                      onClick={() => {
                        setPopupOpen(true);
                        setCategoryId(params?.row?.categoryId);
                        setPartnerId(params?.id);
                      }}
                    >
                      <a className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                        Assign
                      </a>
                    </MenuItem>
                  </>
                )} */}

                {params.row.partner_status !== "completed" && (
                  <MenuItem
                    onClick={() => {
                      const status =
                        params?.row?.partner_status === "wait"
                          ? "new"
                          : params?.row?.partner_status === "accepted"
                          ? "in progress"
                          : params?.row?.partner_status;
                      navigate(
                        `/request-tracking?id=${params?.row?.id}&status=${status}`
                      );
                    }}
                  >
                    <a className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                      Track Request
                    </a>
                  </MenuItem>
                )}

                {params.row.partner_status === "waiting" && (
                  <MenuItem
                    onClick={() => {
                      setDetailAction({
                        cancelModal: true,
                        id: params.row.id,
                        reject: true,
                      });
                    }}
                  >
                    <a className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                      Reject Request
                    </a>
                  </MenuItem>
                )}

                {params.row.partner_status === "completed" && (
                  <MenuItem
                    onClick={() => {
                      navigate(`/create-request?id=${params?.row?.id}`);
                      setAnchorEl(null);
                    }}
                  >
                    <a className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                      Request Again
                    </a>
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() =>
                    navigate(`/request-history/${params?.row?.id}`)
                  }
                >
                  <a className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                    History
                  </a>
                </MenuItem>
                {params.row.partner_status !== "rejected" &&
                  params.row.partner_status !== "completed" && (
                    <MenuItem
                      onClick={() => {
                        setDetailAction({
                          cancelModal: true,
                          id: params.row.id,
                          reject: true,
                        });
                      }}
                    >
                      <a className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                        Reject Request
                      </a>
                    </MenuItem>
                  )}
              </div>
            </MenuItems>
          </Menu>
        );
      },
    },
  ];

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(
      getCurrentRequest({
        request_via: "",
        status: "",
        search: searchValue
      })
    );
  }, [dispatch, searchValue]);


  const rows =
    currentData && currentData.length > 0
      ? currentData.map((item) => ({
          id: item.id,
          requestId: item.id,
          name: item.first_name,
          category: item.category_name,
          categoryId: item.category,
          contact: item.contact,
          via: item.request_via,
          intakeDate: item.created_at,
          level: item.urgency_level,
          description: item.description,
          submittedDate: item.updated_at,
          status: item.request_status,
          partners_names: item.partners_names,
          partner_status: item.partner_status,
          category_image: item.category_image,
          communication_preference: item.communication_preference,
        }))
      : [];

  const searchFilteredRows = useMemo(() => {
    if (!filterOption) {
      return rows;
    }

    let modifiedRows = rows;
    if (filterOption) {
      modifiedRows = modifiedRows.filter(
        (row) => row.communication_preference === filterOption
      );
    }

    return modifiedRows;
  }, [rows, filterOption]);

  const handleHeaderClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl1(null);
  };

  return (
    <div>
      <div className="bg-[#FFFFFF] custom-shadow px-6 py-2 mb-5 rounded-[5px]">
        <div
          className="flex justify-between items-center mb-6 py-3"
          style={{ borderBottom: "1px solid rgba(211, 211, 211, 1)" }}
        >
          <p className=" pt-3 text-[#353F4F] font-medium text-lg mb-3">
            Current Request Status
          </p>

          <div className="flex gap-8 items-center">
            <div className="relative">
              <input
                type="text"
                id="search-navbar"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="block w-full p-2 text-sm text-gray-900 border-none rounded-[3px] focus:outline-none"
                placeholder="Search here..."
                style={{
                  border: "1px solid rgba(34, 96, 217, 1)",
                  height: "50px",
                  width: "300px",
                }}
              />
              <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
            </div>
            <div
              style={{
                background: "rgba(210, 228, 255, 1)",
                color: "rgba(34, 96, 217, 1)",
                borderRadius: "3px",
                width: "100px",
              }}
              onClick={() => navigate("all-request")}
              className="py-3 px-3 text-center cursor-pointer"
            >
              View all
            </div>
          </div>
        </div>
        {/* <DataTable rows={rows} columns={requestColumnn} hideCheckbox hideFooter    pagination
            page={page}
            total={total}
            rowsPerPage={limit}
            onPageChange={handlePageChange}
            onRowsPerPageChange={(newLimit) => setLimit(newLimit)}/> */}
        <DataTable
          rows={searchFilteredRows || []}
          //  rows={filteredRows ||  []}
          columns={requestColumnn}
          hideCheckbox
          height={450}
          pages={pages}
          totalRows={total}
          handlePageChange={handlePageChange}
        />

        <Popover
          open={Boolean(anchorEl1)}
          anchorEl={anchorEl1}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className=" z-10  w-36 bg-white border border-[#D9D9D9] rounded-[3px]">
            <ul className="p-3 space-y-3">
              <li className="flex items-center">
                <input
                  type="checkbox"
                  id="opt-in"
                  name="opt-in"
                  className="mr-2"
                  // onChange={handleOptionChange}
                  checked={filterOption === "opt_in"}
                  onClick={() => handleFilterChange("opt_in")}
                />
                <label htmlFor="opt-in" className="text-sm text-black">
                  Opt In
                </label>
              </li>
              <li className="flex items-center">
                <input
                  type="checkbox"
                  id="opt-out"
                  name="opt-out"
                  className="mr-2"
                  // onChange={handleOptionChange}
                  checked={filterOption === "opt_out"}
                  onClick={() => handleFilterChange("opt_out")}
                />
                <label htmlFor="opt-out" className="text-sm text-black">
                  Opt Out
                </label>
              </li>
            </ul>
          </div>
        </Popover>
      </div>
      <AddPartnersInCategories
        open={openPartnerPopup}
        setOpen={setPopupOpen}
        id={partnerId}
        fromRequest={true}
        setId={setPartnerId}
        categoryId={categoryId}
      />
      <AddRequest
        open={openPopup}
        setOpen={setOpen}
        id={partnerId}
        fromRequest={false}
        setId={setPartnerId}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        refetch={() =>
          dispatch(
            getCurrentRequest({
              request_via: "",
              status: "",
              search:searchValue
            })
          )
        }
      />
      <CancelRequest
        open={detailAction.cancelModal}
        id={detailAction.id}
        closeModal={closeModal}
        actionTab="rejected"
        // isRejectPopup={detailAction.reject}
      />
      <ChangeInReview
        open={openReview.open}
        closeModal={() =>
          setOpenReview({ open: false, id: null, details: null })
        }
        id={openReview.id}
        details={openReview.details}
        refetch={() =>
          dispatch(
            getCurrentRequest({
              request_via: "",
              status: "",
              search:searchValue
            })
          )
        }
      />
    </div>
  );
};

export default CurrentRequest;
