import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "./api";

export const getNotification = createAsyncThunk(
  "getNotification",
  async ({ page }) => {
    const queryParams = new URLSearchParams();
    if (page) queryParams.append("page", page);
    const getNotificationInfo = await api.get(
      `/agent/request-notification/?${queryParams.toString()}`
    );
    if (getNotificationInfo.status === 200 && getNotificationInfo.data) {
      const result = getNotificationInfo.data.result;
      return result;
    }
    return getNotificationInfo;
  }
);

export const ignoreNotification = createAsyncThunk(
  "notifications/ignore",
  async ({ request_id, request_status_id }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/agent/request-notification/`, {
        request_id,
        request_status_id,
      });

      if (response.status === 200 && response.data) {
        return response.data;
      }

      throw new Error("Failed to ignore notification");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notifications/delete",
  async ({ userType }, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/agent/request-notification/`, {
        headers: {
          Authorization: `Bearer ${userType}`,
        },
      });

      if (response.status === 200 && response.data) {
        return {
          message: response.data.result.message,
        };
      }

      throw new Error("Failed to delete notifications");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const viewNotification = createAsyncThunk(
  "notifications/view",
  async ({ request_id, request_status_id }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/agent/request-notification/`, {
        request_id,
        request_status_id,
      });

      if (response.status === 200 && response.data) {
        return response.data;
      }

      throw new Error("Failed to view notification");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
