import {
    createAction,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import {
    jwtDecode
  } from "jwt-decode";

import api from "./api";


export const updateUserInfo = createAction('update/updateUserInfo')

export const updateUserType = createAction('update/updateUserType')


export const loginSocialMedia = createAsyncThunk(
    "loginSocialMedia",
    async (data) => {
        const loginSocial = await api.post('/user/social-login/', data);
        if (loginSocial.status === 200 && loginSocial.data) {
            let accessToken = loginSocial.data?.result?.access_token || ''

            if(accessToken !== '' && accessToken !== undefined){
                localStorage.setItem("access_token", loginSocial.data.result.access_token);
                let decoded = jwtDecode(loginSocial.data.result.access_token);
                return { status: loginSocial.status, userResponse: decoded };
            }

        }
        return loginSocial
    }
);

export const facebookLogin = createAsyncThunk(
  'auth/facebookLogin',
  async (accessToken, { rejectWithValue }) => {
    try {
      const facebookLogin = await api.post('/user/facebook-login/', { access_token: accessToken });
      console.log("facebooklogin",facebookLogin)

      if (facebookLogin.status === 200 && facebookLogin.data) {
        const accessToken = facebookLogin.data?.result?.access_token || '';

        if (accessToken) {
          localStorage.setItem('access_token', accessToken);
          const decoded = jwtDecode(accessToken);
          return { status: facebookLogin.status, userResponse: decoded };
        }
      }

      return rejectWithValue(facebookLogin.data?.error || 'Login failed');
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || error.message);
    }
  }
);


export const updateInfo = createAsyncThunk(
    "updateInfo",
    async () => {
      const userToken = localStorage.getItem("access_token");
      if (userToken) {
        let decoded = jwtDecode(userToken);
        return decoded;
      }
      return {};
    }
  );


export const loginWithPhoneNumber = createAsyncThunk(
  "loginWithPhoneNumber",
  async (data, { rejectWithValue }) => {
      try {
          const response = await api.post(`/user/send-otp/`, data); 
          if (response.status === 200 && response.data) {
              return response.data;
          }
          throw new Error("Failed to create agent");
      } catch (error) {
          return rejectWithValue(error.response?.data || error.message); 
      }
  }
);

export const optVerified = createAsyncThunk(
  "optVerified",
  async (data) => {
    const otpConfirm = await api.post('/user/verify-otp/', data);
      if (otpConfirm.status === 200 && otpConfirm.data) {
          let accessToken = otpConfirm.data?.result?.access_token || ''

          if(accessToken !== '' && accessToken !== undefined){
              localStorage.setItem("access_token", otpConfirm.data.result.access_token);
              let decoded = jwtDecode(otpConfirm.data.result.access_token);
              return { status: otpConfirm.status, userResponse: decoded };
          }

      }
      return otpConfirm
  }
);
