import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "../../assets/input-search-icon.svg";
import EmployeeImage from "../../assets/employeeimage.svg";
import EmployeeDelete from "../../assets/employedelete.svg";
import EmployeeEdit from "../../assets/employeeedit.svg";
import { getEmployeesDetails } from "../../services/employees";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { deleteEmployee } from "../../services/employees";
import InfiniteScroll from "react-infinite-scroll-component";

export default function AllEmployee() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState({});
  const userData = useSelector((state) => state.userInfo.data);
  const id = userData.id;
  const [searchTerm, setSearchTerm] = useState("");

  const state = useSelector((state) => state);
  console.log("state", state);

  const getemployee = useSelector((state) => state.employeeInfo.details);

  useEffect(() => {
    if (id) {
      dispatch(getEmployeesDetails(id));
    }
  }, [dispatch, id]);

  const handleDeleteEmployee = (employeeId) => {
    dispatch(deleteEmployee({ employee_id: employeeId, is_deleted: true }))
      .then(() => {
        // Refresh the employee list after deletion
        dispatch(getEmployeesDetails(id));
      })
      .catch((error) => {
        console.error("Failed to delete employee:", error);
      });
  };

  const fetchMore = () => {
    // Ensure the data has page and total_count properties
    if (getemployee.page && getemployee.total_count) {
      dispatch(
        getEmployeesDetails({
          page: getemployee.page + 1,
        })
      )
        .unwrap()
        .then((response) => {
          setEmployeeData((prevData) => [...prevData, ...response.data]);
        })
        .catch((error) => {
          console.error("Error fetching more employees:", error);
        });
    }
  };

  const filteredEmployees =
  getemployee?.filter((employee) =>
    employee.first_name.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  return (
    <>
      <div className="flex items-center justify-between border-b border-[#D9D9D9] pb-3 ">
        <h5 className="text-[#353F4F] font-medium text-[20px]">Employees</h5>
        <div className="flex items-center gap-4">
          <div className="relative">
            <input
              type="text"
              id="search-navbar"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} 
              className="block w-full p-2 text-sm text-gray-900 border-none focus:outline-none"
              placeholder="Search"
              style={{
                border: "1px solid rgba(34, 96, 217, 1)",
                height: "50px",
                width: "300px",
              }}
            />
            <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
            {/* <div className="text-[13px]">{`${discussion.name}`}</div> */}
          </div>
          <button
            className="bg-[#2260D9] text-white rounded-[2px] p-3 focus:outline-none"
            onClick={() => {
              navigate("/addnewemployee");
            }}
          >
            <AddIcon className="" />
            Add Employee
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-10 py-7">
        <InfiniteScroll
           dataLength={filteredEmployees.length || 0}
          next={fetchMore}
          hasMore={getemployee?.total_count > filteredEmployees.length}
          loader={<h4>Loading...</h4>}
        >
          {filteredEmployees.map((employee) => (
            <div className="border-b border-[#D9D9D9] px-3 py-8 flex items-center justify-between">
              <div className="flex items-center gap-4">
                <img src={EmployeeImage} alt="" />
                <div className="flex flex-col gap-2 justify-start text-[#18283D] text-[14px] font-normal">
                  <p className="text-[#18283D] text-[14px] font-normal">
                    {employee.first_name} {employee.last_name}{" "}
                    <span>#{employee.id}</span>
                  </p>
                  <p className="text-[#18283D] text-[14px] font-normal">
                    {employee.contact_number && `${employee.contact_number}`}
                    {employee.contact_number && employee.email && ", "}
                    {employee.email && <span>{employee.email}</span>}
                  </p>
                  <p className="text-[#18283D] text-[14px] font-normal">
                    {employee.state && `${employee.state}`}
                    {employee.state && employee.country && ", "}
                    {employee.country && <span>{employee.country}</span>}
                  </p>
                </div>
              </div>

              <div className="flex gap-8 items-center">
                <img
                  src={EmployeeDelete}
                  alt="EmployeeDelete"
                  onClick={() => handleDeleteEmployee(employee.id)}
                  style={{ cursor: "pointer" }}
                />
                <img
                  src={EmployeeEdit}
                  alt="EmployeeEdit"
                  onClick={() => {
                    navigate(`/addnewemployee?id=${employee.id}`);
                  }}
                />
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </>
  );
}
