import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router";
import DataTable from "../../shared/DataGrid";
import { UnregisteredPartnersTab } from "../../utills/formFields";
import SearchIcon from "../../assets/input-search-icon.svg";
import MoreIcon from "../../assets/more-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  docuSign,
  getAllPartnerList,
  getPartnerList,
  getRegisterPartnerCount,
  getRegisterPartnerList,
} from "../../services/partner";
import { Menu } from "@headlessui/react";
import { MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import moment from "moment";
import RightIcon from "../../assets/check-icon.svg";
import BlockIcon from "../../assets/block-icon.svg";
import OptIcon from "../../assets/opt.svg";
import Unregistered from "../../assets/unregistered.svg";

export default function UnregisteredPartner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [actionTab, setActiveTab] = useState("unregistered");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [pages, setPage] = useState({ page: 0, pageSize: 10 });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const partnerInfo = useSelector(
    (state) => state.partnerInfo.registerPartnerList
  );

  const totalCount = useSelector(
    (state) => state.partnerInfo.registerPartnerCount
  );

  useEffect(() => {
    dispatch(
      getRegisterPartnerList({ status: actionTab, page: pages.page + 1 })
    );
  }, [actionTab, pages]);

  useEffect(() => {
    dispatch(getRegisterPartnerCount());
  }, []);

  const handleAction = (action, id) => {
    // setConfirmAction({ action, id });
    dispatch(docuSign()).then((ele) => {
      window.open(ele.payload.url, "_blank");
    });
    // if (action === "active") {
    //   setActivateDialogOpen(true);
    // } else if (action === "delete") {
    //   handleDeleteDialogOpen(); // Open Delete Dialog
    // } else {
    //   setConfirmDialogOpen(true);
    // }
    // handleClose();
  };

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(data);
  };

  const getPartnersColumns = () => {
    const allColumns = [
      {
        field: "partner_id",
        headerName: "Partner ID",
        flex: 1,
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        renderCell: (params) => params?.row?.full_name,
        renderCell: (params) => {
          return (
            <div className="capitalize">
              {params.row.full_name}
            </div>
          );
        },
      },
      {
        field: "full_name",
        headerName: "Organization Name",
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="capitalize">
              {params.row.full_name}
            </div>
          );
        },
      },
      {
        field: "partner_type",
        flex: 1,
        renderHeader: () => (
          <div className="flex items-center gap-[5px]">
            <p className="font-sans text-[#18283D] font-semibold">
              Type
            </p>
            <img src={OptIcon} alt="opt" />
          </div>
        ),
        renderCell: (params) => (
          <div className="capitalize">{params.value}</div>
        ),
      },
      {
        field: "totle_employees",
        headerName: "Total Employees",
        headerAlign: "center",
        align: "center",
        flex: 1,
      },
      {
        field: "managed_category",
        headerName: "Managed Program",
        headerAlign: "center",
        align: "center",
        flex: 1,
      },
      {
        field: "add_date",
        headerName: "Added Date & Time",
        flex: 2,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          return moment(params?.row?.add_date).format("MM/DD/YYYY | h:mm A");
        },
      },
      {
        field: "status",
        headerName: "Status",
        headerAlign: "center",
        align: "center",
        flex: 1,
        renderCell: (params) => {
          const statusStyles = {
            active: "text-[#29B473]",
            inactive: "text-[#D22735]",
          };
          const status = params.row.status;
          const { color } = statusStyles[status] || { color: "#6C757D" };
          return (
            <div
              className={`flex items-center justify-center gap-2 ${
                statusStyles[params.value]
              }`}
            >
              {status === "active" ? (
                <img src={RightIcon} />
              ) : (
                <img src={BlockIcon} />
              )}
              <span className="capitalize">{status}</span>
            </div>
          );
        },
      },
       {
              field: "unregistered_status",
              headerName: "Status",
              headerAlign: "center",
              align: "center",
              flex: 1,
              renderCell: (params) => {
                const statusStyles = {
                   unregistered: {
                    textClass: "text-[#2260D9]",
                    icon: Unregistered,
                    label: "Unregistered",
                  },
                  declined: {
                    textClass: "text-[#D22735]",
                    icon: BlockIcon,
                    label: "Inactive",
                  },
                  default: {
                    textClass: "text-[#D22735]",
                    icon: null,
                    label: "Unknown",
                  },
                };
      
                const status = params.row.unregistered_status
                || "default";
                const { textClass, icon, label } =
                  statusStyles[status] || statusStyles.default;
      
                return (
                  <div className={`flex items-center gap-2 ${textClass}`}>
                    {icon && <img src={icon} alt={`${status} icon`} />}
                    <span className="capitalize">{label}</span>
                  </div>
                );
              },
            },
      {
        field: "action",
        headerName: "Action",
        headerAlign: "center",
        align: "center",
        flex: 1,
        renderCell: (params) => {
          const status = params.row.status;
          return (
            <>
              <Menu>
                <MenuButton
                  onClick={(e) => handleClick(e, params.row)}
                  className="cursor-pointer relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900"
                >
                  <img src={MoreIcon} alt="MoreIcon" />
                </MenuButton>
                <MenuItems
                  transition
                  className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <MenuItem
                    as="button"
                    onClick={() => navigate(`/partner/${params.id}`)}
                    className="cursor-pointer block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-[#EEF4FF]"
                  >
                    View
                  </MenuItem>
                  <MenuItem
                    as="button"
                    onClick={() =>
                      navigate(`/addnewpartner?id=${selectedRow.id}`)
                    }
                    className="cursor-pointer block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-[#EEF4FF]"
                  >
                    Edit
                  </MenuItem>
                  {actionTab === "unregistered" && (
                    <>
                      <MenuItem
                        as="button"
                        onClick={() => handleAction("delete")}
                        className="cursor-pointer block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-[#EEF4FF]"
                      >
                        Delete
                      </MenuItem>
                      <MenuItem
                        as="button"
                        onClick={() => handleAction("docuSign")}
                        className="cursor-pointer block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-[#EEF4FF]"
                      >
                        DocuSign
                      </MenuItem>
                    </>
                  )}
                </MenuItems>
              </Menu>
            </>
          );
        },
      },
    ];

    if (actionTab === "unregistered") {
      return allColumns.filter((col) =>
        [
          "name",
          "partner_type",
          "totle_employees",
          "add_date",
          // "status",
          "unregistered_status",
          "action",
        ].includes(col.field)
      );
    } else if (actionTab === "declined") {
      return allColumns.filter((col) =>
        [
          "partner_id",
          "full_name",
          "partner_type",
          "totle_employees",
          "managed_category",
          "add_date",
          "status",
          "action",
        ].includes(col.field)
      );
    }

    return allColumns; // Default to all columns if no actionTab is specified.
  };

  // Example usage
  const columns = getPartnersColumns(actionTab);

  const safePartnerInfo = Array.isArray(partnerInfo.data)
    ? partnerInfo.data
    : [];

  const activeTabData = useMemo(() => {
    if (actionTab === "unregistered") {
      return safePartnerInfo?.filter(
        (c) => c?.register_status === "unregistered"
      );
    } else if (actionTab === "declined") {
      return safePartnerInfo?.filter((c) => c?.register_status === "declined");
    }

    return safePartnerInfo;
  }, [actionTab, safePartnerInfo]);

  const rows =
    activeTabData?.map((partner) => ({
      id: partner.id,
      partner_id: partner.id,
      full_name: partner.organization_name,
      badge: partner.badge,
      partner_type: partner.partner_type,
      last_update_date: partner.updated_at,
      totle_employees: partner.total_org_employee,
      managed_category: partner.categories,
      unregistered_status: partner.register_status,
      status: partner.status,
    })) || [];

    console.log('rows',rows)

  const searchFilteredRows = rows.filter((row) =>
    row.full_name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <div className="px-8 mt-10">
      <BreadCumbs
        firstCumbName="Partner"
        secoundCumbName="Partner Status"
        pathToRedirect="addnewpartner"
      />
      <div className="font-sans text-[#353F4F] font-medium text-lg pb-3 pt-5">
        Partner
      </div>
      <div className="py-7 mb-5 custom-shadow rounded-[5px]">
        <div className="pl-3 details-info mr-7">
          <div
            className="flex items-start justify-between pl-4"
            style={{ borderBottom: "1px solid #D3D3D3" }}
          >
            <ul className="flex tab-list">
              {UnregisteredPartnersTab.map((discussion, index) => (
                <li
                  className={`${
                    actionTab === discussion.key ? "active" : ""
                  } relative`}
                  key={index}
                  onClick={() => setActiveTab(discussion.key)}
                >
                  {console.log(
                    discussion,
                    discussion?.key?.length,
                    "discussion"
                  )}
                  <div className="flex justify-center pb-1">
                    <div
                      className={`total-proram-count relative ${
                        actionTab === discussion.key ? "active" : ""
                      }`}
                    >
                      {totalCount[discussion.key]}
                    </div>
                  </div>
                  <div className="text-[13px]">{`${discussion.name}`}</div>
                  {actionTab === discussion.key && <span></span>}
                </li>
              ))}
            </ul>
            <div className="relative">
              <input
                type="text"
                id="search-navbar"
                className="block w-full p-2 text-sm placeholder:text-[#C8C9C9] focus:outline-none rounded-[3px]"
                placeholder="Search here..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  border: "1px solid #2260D9",
                  height: "50px",
                  width: "390px",
                }}
              />
              <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
        <div className="p-7">
          <DataTable
            rows={searchFilteredRows || []}
            columns={getPartnersColumns()}
            hideCheckbox
            height={450}
            pages={pages}
            totalRows={partnerInfo?.total_count || 0}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}
