import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "./api";

// Create Request
export const createNewPartnerRequest = createAsyncThunk(
  "createNewRequest",
  async (formData, { rejectWithValue }) => {
    try {
      const createRequest = await api.post(`/agent/partner/`, formData);
      if (createRequest.status === 200 && createRequest.data) {
        return createRequest.data;
      }
      throw new Error("Failed to create agent");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const createBgVerificationRequest = createAsyncThunk(
  "createBgVerificationRequest",
  async ({ formData, actiontype }) => {
    try {
      const createRequest = await api.post(
        `/user/background-verification/${
          actiontype ? `?action=${actiontype}` : ""
        }`,
        formData
      );
      if (createRequest.status === 200 && createRequest.data) {
        return createRequest.data;
      }
    } catch (error) {
      return error;
    }
  }
);

export const getRegisterPartnerCount = createAsyncThunk(
  "getRegisterPartnerCount",
  async (status) => {
    const getTotalEmployeesColumnInfo = await api.get(
      "/agent/register-status-count/?entity=partners"
    );

    if (
      getTotalEmployeesColumnInfo.status === 200 &&
      getTotalEmployeesColumnInfo.data
    ) {
      const result = getTotalEmployeesColumnInfo.data.result;
      return result;
    }
    return getTotalEmployeesColumnInfo;
  }
);

export const getRegisterStaffCount = createAsyncThunk(
  "getRegisterStaffCount",
  async (searchValue) => {
    const queryParams = new URLSearchParams();
    if (searchValue) queryParams.append("search", searchValue);
    const getTotalEmployeesColumnInfo = await api.get(
      `/agent/register-status-count/?entity=agents&${queryParams.toString()}`
    );

    if (
      getTotalEmployeesColumnInfo.status === 200 &&
      getTotalEmployeesColumnInfo.data
    ) {
      const result = getTotalEmployeesColumnInfo.data.result;
      return result;
    }
    return getTotalEmployeesColumnInfo;
  }
);
export const getAllPartnerList = createAsyncThunk(
  "getAllPartnerList",
  async ({ status, category_id, search, page }) => {
    const queryParams = new URLSearchParams();
    if (status) queryParams.append("status", status);
    if (category_id) queryParams.append("category_id", category_id);
    if (search) queryParams.append("search", search);
    if (page) queryParams.append("page", page);

    const getAllPartnerInfo = await api.get(
      `/agent/partner/${
        status ? `?partner_status=${status}` : ""
      }?${queryParams.toString()}`
    );
    if (getAllPartnerInfo.status === 200 && getAllPartnerInfo.data) {
      const result = getAllPartnerInfo.data.result;
      return result;
    }
    return getAllPartnerInfo;
  }
);

export const getPartnerList = createAsyncThunk(
  "getPartnerList",
  async (status) => {
    const getAllPartnerInfo = await api.get(
      `/agent/partner/${status ? `?partner_status=${status}` : ""}`
    );
    if (getAllPartnerInfo.status === 200 && getAllPartnerInfo.data) {
      const result = getAllPartnerInfo.data.result;
      return result;
    }
    return getAllPartnerInfo;
  }
);

export const getPartner = createAsyncThunk(
  "getPartner",
  async (id) => {
    const getPartnerInfo = await api.get(
      `/agent/partner/?user_id=${id}`
    );
    if (getPartnerInfo.status === 200 && getPartnerInfo.data) {
      const result = getPartnerInfo.data.result;
      return result;
    }
    return getPartnerInfo;
  }
);

export const getRegisterPartnerList = createAsyncThunk(
  "getUnregisterPartnerList",
  async ({ status, page }) => {
    const queryParams = new URLSearchParams();
    if (status) queryParams.append("register_status", status);
    if (page) queryParams.append("page", page);
    const getAllPartnerInfo = await api.get(
      `/agent/partner/?${queryParams.toString()}`
    );
    if (getAllPartnerInfo.status === 200 && getAllPartnerInfo.data) {
      const result = getAllPartnerInfo.data.result;
      return result;
    }
    return getAllPartnerInfo;
  }
);

export const getRegisterStaffList = createAsyncThunk(
  "getUnregisterStaffList",
  async ({ status, page , search}) => {
    const queryParams = new URLSearchParams();
    if (status) queryParams.append("register_status", status);
    if (page) queryParams.append("page", page);
    if (search) queryParams.append("search", search);

    const getAllPartnerInfo = await api.get(
      `/agent/agent/?${queryParams.toString()}`
    );
    if (getAllPartnerInfo.status === 200 && getAllPartnerInfo.data) {
      const result = getAllPartnerInfo.data.result;
      return result;
    }
    return getAllPartnerInfo;
  }
);

export const updatePartnerStatus =
  ({ status, partner_id, reason }) =>
  async (dispatch) => {
    console.log({ status, partner_id, reason })
    try {
      dispatch({ type: "UPDATE_PARTNER_REQUEST" });
      const response = await api.put(`/agent/partner/`, {
        status,
        partner_id,
        reason,
      });
      dispatch({ type: "UPDATE_PARTNER_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "UPDATE_PARTNER_FAILURE", payload: error.message });
    }
  };

export const getTotalEmployeesColumnList = createAsyncThunk(
  "getTotalEmployeesColumnList",
  async () => {
    const getTotalEmployeesColumnInfo = await api.get("/agent/employee/");

    if (
      getTotalEmployeesColumnInfo.status === 200 &&
      getTotalEmployeesColumnInfo.data
    ) {
      const result = getTotalEmployeesColumnInfo.data.result;
      return result;
    }
    return getTotalEmployeesColumnInfo;
  }
);

export const getParterDetailsbyId = createAsyncThunk(
  "getParterDetailsbyId",
  async (id) => {
    const getParterDetailsInfo = await api.get(
      `/agent/partner/?partner_id=${id}`
    );
    if (getParterDetailsInfo.status === 200 && getParterDetailsInfo.data) {
      const result = getParterDetailsInfo.data.result;
      return result;
    }
    return getParterDetailsInfo;
  }
);

// Update Request
// export const updatePartnerRequest = createAsyncThunk(
//   "updatePartner",
//   async (formData, { rejectWithValue }) => {
//     try {
//       const createRequest = await api.put(`/agent/partner/`, formData);
//       if (createRequest.status === 200 && createRequest.data) {
//         return createRequest.data;
//       }
//       throw new Error("Failed to create agent");
//     } catch (error) {
//       return rejectWithValue(error.response?.data || error.message);
//     }
//   }
// );

export const updatePartnerRequest =
  ({ data, partner_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_PARTNER_REQUEST" });
      const response = await api.put(`/agent/partner/`, {
        ...data,
        partner_id,
      });
      dispatch({ type: "UPDATE_PARTNER_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "UPDATE_PARTNER_FAILURE", payload: error.message });
    }
  };

export const submitPartner = createAsyncThunk(
  "submitPartner",
  async (formData, { rejectWithValue }) => {
    try {
      const createRequest = await api.put(
        `/agent/?request_id=${formData.request_id}`,
        formData
      );
      if (createRequest.status === 200 && createRequest.data) {
        return createRequest.data;
      }
      throw new Error("Failed to create agent");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const addPartnersInCategory = createAsyncThunk(
  "category/update",
  async ({ category_id, partners_ids }) => {
    try {
      const response = await api.put(`/agent/partner-categories/`, {
        partners_ids,
        category_id,
      });

      if (response.status === 200 && response.data.result) {
        return response.data.result;
      }

      throw new Error("Failed to update category");
    } catch (error) {
      console.error("Error updating category:", error);
      throw error.response?.data || new Error("Something went wrong");
    }
  }
);

export const addPartnersRequest = createAsyncThunk(
  "addPartnersRequest",
  async ({ partners, request_id, request_status }) => {
    try {
      const response = await api.put(`/agent/`, {
        partners,
        request_id,
        ...(request_status ? { request_status } : {}),
      });

      if (response.status === 200 && response.data.result) {
        return response.data.result;
      }

      throw new Error("Failed to update category");
    } catch (error) {
      console.error("Error updating category:", error);
      throw error.response?.data || new Error("Something went wrong");
    }
  }
);
export const updateStatusByRequestId = createAsyncThunk(
  "updateStatusByRequestId",
  async (formData, { rejectWithValue }) => {
    try {
      const createRequest = await api.put(
        `/agent/?request_id=${formData.request_id}`,
        formData
      );
      if (createRequest.status === 200 && createRequest.data) {
        return createRequest.data;
      }
      throw new Error("Failed to change status ");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchPartnerCategories = createAsyncThunk(
  "fetchPartnerCategories",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/agent/partner-categories/?partner_id=${id}`
      );

      if (response.status === 200 && response.data.results) {
        return response.data.results;
      }
      throw new Error("Invalid response structure from API");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const docuSign = createAsyncThunk("docuSign", async () => {
  const documentSign = await api.get(`/docusign/auth/`);

  console.log(documentSign, "documentSign");
  if (documentSign.status === 200 && documentSign.data) {
    const result = documentSign.data;
    return result;
  }
  return documentSign;
});
