import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router";
import DataTable from "../../shared/DataGrid";
import { PartnersTab } from "../../utills/formFields";
import BreadCrumIcon from "../../assets/breadcrumb-right-icon.svg";
import SearchIcon from "../../assets/input-search-icon.svg";
import MoreIcon from "../../assets/more-icon.svg";
import { PartnerColumn } from "../../utills/tableFields";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPartnerList,
  getPartnerList,
  updatePartnerStatus,
} from "../../services/partner";
import { Menu } from "@headlessui/react";
import { MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import Crossimg from "../../assets/crossimg.svg";
import Checkicon from "../../assets/checkicon.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Crossicon from "../../assets/crossicon.svg";
import Deleteicon from "../../assets/deleteicon.svg";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import moment from "moment";
import RightIcon from "../../assets/check-icon.svg";
import BlockIcon from "../../assets/block-icon.svg";
import SilverBadge from "../../assets/silver-badge.svg";
import GoldenBadge from "../../assets/golden-badge.svg";
import BronzeBadge from "../../assets/bronze-badge.svg";
import OptIcon from "../../assets/opt.svg";
import NoBadge from "../../assets/nobadge.svg";
import useDebounce from "../../utills/debounce";

export default function Partner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [actionTab, setActiveTab] = useState("all");

  const [selectedRow, setSelectedRow] = useState({});

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [activateDialogOpen, setActivateDialogOpen] = useState(false); // New state for Activate dialog
  const [dialogMessage, setDialogMessage] = useState("");
  const [confirmAction, setConfirmAction] = useState({ action: "", id: "" });

  const [cancelButtonActive, setCancelButtonActive] = useState(false);
  const [deactivateButtonActive, setDeactivateButtonActive] = useState(false);
  const [activateButtonActive, setActivateButtonActive] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogImage, setDialogImage] = useState("");
  const [comment, setComment] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const searchValue = useDebounce(searchQuery, 500);
  const [pages, setPage] = useState({ page: 0, pageSize: 10 });

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmAction = () => {
    if (confirmAction.action === "inactive" && !comment.trim()) {
      setDialogMessage("Reason is required when status is 'deactivated'.");
      setSuccessDialogOpen(true);
      window.location.reload();
      return;
    }

    let message = "";
    let imageSrc = "";

    switch (confirmAction.action) {
      case "inactive":
        message = `Partner successfully deactivated.`;
        imageSrc = Crossicon;
        break;
      case "active":
        message = `Partner successfully activated.`;
        imageSrc = Checkicon;
        break;
      case "delete":
        message = `Partner successfully deleted.`;
        imageSrc = Deleteicon;
        break;
      default:
        break;
    }

    dispatch(
      updatePartnerStatus({
        partner_id: confirmAction.id,
        status: confirmAction.action,
        reason: comment.trim(),
      })
    );
    setTimeout(() => {
      dispatch(getAllPartnerList({ search: searchValue }));
    }, 50);

    setConfirmAction({ action: "", id: "" });
    setDialogMessage(message);
    setDialogImage(imageSrc);
    setConfirmDialogOpen(false);
    setActivateDialogOpen(false);
    setSuccessDialogOpen(true);
    setDeleteDialogOpen(false);
    setComment("");
    handleClose();

    setTimeout(() => {
      setSuccessDialogOpen(false);
      window.location.reload();
    }, 2000);
  };

  const partnerInfo = useSelector((state) => state.partnerInfo?.partnerData);
  const total = useSelector((state) => state.partnerInfo?.partnerData?.total_count);
  console.log({total})

  useEffect(() => {
    dispatch(getAllPartnerList({ search: searchValue, page: pages.page + 1 }));
  }, [searchValue, pages.page]);

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(data);
  };

  const PartnersColumn = [
    {
      field: "partner_id",
      headerName: "Partner ID",
      flex: 1,
    },
    {
      field: "full_name",
      headerName: "Organization Name",
      flex: 1,
      renderCell: (params) => {
        return <div className="capitalize">{params.row.full_name}</div>;
      },
    },
    {
      field: "badge",
      headerName: "Badge",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const badgeIcons = {
          Silver: SilverBadge,
          Gold: GoldenBadge,
          Bronze: BronzeBadge,
          // "No Badge":NoBadge
        };

        const badge = params.value;
        const badgeIcon = badgeIcons[badge];

        return (
          <div className="flex items-center justify-center h-full">
            {badgeIcon ? (
              <img src={badgeIcon} alt={`${badge} badge`} className="w-6 h-6" />
            ) : (
              <span>{badge}</span>
            )}
          </div>
        );
      },
    },

    {
      field: "partner_type",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-[5px]">
          <p className="font-sans text-[#18283D] font-semibold">Type</p>
          <img src={OptIcon} alt="opt" />
        </div>
      ),
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "totle_employees",
      headerName: "Total Employees",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "managed_category",
      headerName: "Managed Program",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "last_update_date",
      headerName: "Last Update Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.last_update_date).format(
          "MM/DD/YYYY | h:mm A"
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        const statusStyles = {
          active: "text-[#29B473]",
          inactive: "text-[#D22735]",
        };
        const status = params.row.status;
        const { color } = statusStyles[status] || { color: "#6C757D" };
        return (
          <div
            className={`flex items-center gap-2 ${statusStyles[params.value]}`}
          >
            {status === "active" ? (
              <img src={RightIcon} />
            ) : (
              <img src={BlockIcon} />
            )}
            <span className="capitalize">{status}</span>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const status = params.row.status;
        const isInactive = status === "inactive";
        const isActive = status === "active";
        return (
          <>
            <Menu as="div" className="inline-block mt-2 text-left">
              <div>
                <MenuButton
                  onClick={(e) => handleClick(e, params.row)}
                  className="relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900"
                >
                  <img src={MoreIcon} alt="MoreIcon" />
                </MenuButton>
              </div>
              <MenuItems
                transition
                className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem
                    onClick={() => {
                      navigate(`/partner/${params.id}`);
                    }}
                  >
                    <p className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                      View
                    </p>
                  </MenuItem>
                  {/* <MenuItem
                    onClick={() =>
                      navigate(`/addnewpartner?id=${selectedRow.id}`)
                    }
                  >
                    <p
                      className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                    >
                      Edit
                    </p>
                  </MenuItem> */}
                  {status === "active" ? (
                    <>
                      {/* <MenuItem
                        onClick={() => handleAction("inactive", params.id)}
                        className=""
                      >
                        <p
                          
                          className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                        >
                          Deactivate
                        </p>
                      </MenuItem> */}

                      <MenuItem
                        // onClick={() => handleAction("inactive", params.id)}
                        className=""
                      >
                        <p className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                          DocuSign
                        </p>
                      </MenuItem>
                    </>
                  ) : (
                    <>
                      {/* <MenuItem
                        onClick={() => handleAction("active", params.id)}
                      >
                        <p
                          
                          className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                        >
                          Activate
                        </p>
                      </MenuItem>

                      <MenuItem onClick={() => handleAction("delete")}>
                        <p
                          
                          className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                        >
                          Delete
                        </p>
                      </MenuItem> */}
                    </>
                  )}
                </div>
              </MenuItems>
            </Menu>
          </>
        );
      },
    },
  ];
  // const safePartnerInfo = Array.isArray(partnerInfo) ? partnerInfo : [];

  console.log({ partnerInfo });

  const activeTabData = useMemo(() => {
    if (actionTab === "all") {
      if (isOpen) {
        return partnerInfo?.data?.filter((c) => c?.status === "archive");
      }
      return partnerInfo?.data?.filter(
        (c) => c?.status === "active" || c?.status === "inactive"
      );
    } else if (actionTab === "active") {
      return partnerInfo?.data?.filter((c) => c?.status === "active");
    } else if (actionTab === "inactive") {
      if (isOpen) {
        return partnerInfo?.data?.filter((c) => c?.status === "archive");
      }
      return partnerInfo?.data?.filter((c) => c?.status === "inactive");
    }

    return partnerInfo || [];
  }, [actionTab, partnerInfo, isOpen]);

  const rows = Array.isArray(activeTabData)
    ? activeTabData.map((partner) => ({
        id: partner.id,
        partner_id: partner.id,
        full_name: partner.organization_name,
        badge: partner.badge,
        partner_type: partner.partner_type,
        last_update_date: partner.updated_at,
        totle_employees: partner.total_org_employee,
        managed_category: partner.categories,
        status: partner.status,
      }))
    : [];

  const tabCount = useMemo(() => {
    const obj = {
      all: 0,
      active: 0,
      inactive: 0,
    };

    obj.active = partnerInfo?.data?.filter(
      (c) => c?.status === "active"
    )?.length;
    obj.inactive = partnerInfo?.data?.filter(
      (c) => c?.status === "inactive"
    )?.length;
    obj.all = obj.active + obj.inactive;
    return obj;
  }, [partnerInfo]);

  const handleDialogClose = () => setSuccessDialogOpen(false);
  const handleConfirmDialogClose = () => setConfirmDialogOpen(false);
  const handleActivateDialogClose = () => setActivateDialogOpen(false);
  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };
  // const handleDeleteConfirm = () => {
  //   // Simulate delete logic
  //   const message = `Staff ${selectedRow.full_name} has been successfully deleted.`;
  //   setDialogMessage(message);
  //   setDeleteDialogOpen(false); // Close Delete Dialog
  //   setSuccessDialogOpen(true); // Show Success Dialog
  // };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <div className="px-8 mt-10">
      <BreadCumbs
        firstCumbName="Partner"
        secoundCumbName="Partner Status"
        pathToRedirect="addnewpartner"
      />
      <div className="font-sans text-[#353F4F] font-medium text-lg pb-3 pt-5">
        Partner
      </div>
      <div className="py-7 mb-5 custom-shadow rounded-[5px]">
        <div className="pl-3 details-info mr-7">
          <div
            className="flex items-start justify-between pl-4"
            style={{ borderBottom: "1px solid #D3D3D3" }}
          >
            <ul className="flex tab-list">
              {PartnersTab.map((discussion, index) => (
                <li
                  className={`${
                    actionTab === discussion.key ? "active" : ""
                  } relative`}
                  key={index}
                  onClick={() => setActiveTab(discussion.key)}
                >
                  <div className="flex justify-center pb-1">
                    <div
                      className={`total-proram-count relative ${
                        actionTab === discussion.key ? "active" : ""
                      }`}
                    >
                      {tabCount[discussion?.key]}
                    </div>
                  </div>
                  <div className="text-[13px]">{`${discussion.name}`}</div>
                  {actionTab === discussion.key && <span></span>}
                </li>
              ))}
            </ul>
            <div className="relative">
              <input
                type="text"
                id="search-navbar"
                className="block w-full p-2 text-sm placeholder:text-[#C8C9C9] focus:outline-none rounded-[3px]"
                placeholder="Search here..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  border: "1px solid #2260D9",
                  height: "50px",
                  width: "390px",
                }}
              />
              <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
              {/* <div className="text-[13px]">{`${discussion.name}`}</div> */}
            </div>
          </div>
        </div>
        <div className="p-7">
          <DataTable
            rows={rows || []}
            columns={PartnersColumn}
            pages={pages}
            totalRows={total}
            handlePageChange={handlePageChange}
            hideCheckbox
            // hideFooter
          />
        </div>
      </div>

      <Dialog
        open={successDialogOpen}
        onClose={handleDialogClose} // Close on outside click
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{ width: "450px", height: "180px", borderRadius: "10px" }}
        >
          <img
            src={dialogImage}
            alt="Success"
            style={{ display: "block", margin: "0 auto" }}
          />
          <Typography variant="h6" align="center" style={{ marginTop: "20px" }}>
            {dialogMessage}
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        className="flex items-center justify-center max-w-none rounded-xl"
      >
        <div className="bg-white  shadow-lg w-[600px] sm:max-w-sm md:max-w-md lg:max-w-xl xl:max-w-2xl px-4 sm:px-8 md:px-16 lg:px-20 py-6 sm:py-8 md:py-10 relative">
          {/* Header with Icons */}
          <div className="flex items-center justify-center mb-4">
            <img src={Checkicon} alt="Check" className="mx-2 " />
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              --
              <ArrowForwardIcon />
              --
            </Typography>
            <img src={Crossicon} alt="Cross" className="mx-2 " />
          </div>
          {/* Dialog Content */}
          <DialogContent className="mb-4 text-center">
            <Typography className="text-[#353F4F] text-base">
              Are you sure you want to change the status?
            </Typography>
          </DialogContent>
          {/* Comment Input */}
          <div className="mb-4">
            <label
              htmlFor="Comments"
              className="text-[#353F4F] font-medium flex items-center"
            >
              Comments <span className="ml-1 text-red-600">*</span>
            </label>
            <textarea
              id="Comment"
              name="Comment"
              rows={6}
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-[#D6E5EE] placeholder:text-gray-400 focus:outline-2 focus:outline-indigo-600 sm:text-sm"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter your reason for changing the status"
              required
            />
          </div>
          {/* Dialog Actions */}
          <div className="flex justify-center">
            <button
              onClick={handleConfirmDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none "
              style={{
                backgroundColor: cancelButtonActive ? "#DC3545" : "white",
                color: cancelButtonActive ? "white" : "#353F4F",
                border: cancelButtonActive ? "none" : "1px solid #18283D80",
              }}
              onMouseDown={() => setCancelButtonActive(true)}
              onMouseUp={() => setCancelButtonActive(false)}
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: "#DC3545",
                color: "white",
                border: "none",
              }}
              onMouseDown={() => setDeactivateButtonActive(true)}
              onMouseUp={() => setDeactivateButtonActive(false)}
            >
              Deactivate
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={activateDialogOpen}
        onClose={handleActivateDialogClose}
        className="flex items-center justify-center overflow-hidden max-w-none rounded-xl"
      >
        <div className="bg-white shadow-lg w-[600px] overflow-hidden sm:max-w-sm md:max-w-md lg:max-w-xl xl:max-w-2xl px-4 sm:px-8 md:px-16 lg:px-20 py-6 sm:py-8 md:py-10 relative">
          <div className="flex items-center justify-center mb-4">
            <img src={Crossimg} alt="Cross" className="mx-2 " />
            {/* Text added here */}
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              --
              <ArrowForwardIcon />
              --
            </Typography>
            <img src={Checkicon} alt="Check" className="mx-2 " />
          </div>
          <DialogContent className="mb-4 text-center">
            <Typography className="text-[#353F4F] text-base font-medium">
              Are you sure you want to change the status?
            </Typography>
          </DialogContent>
          <div className="flex justify-center">
            {/* Cancel Button */}
            <button
              onClick={handleActivateDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: cancelButtonActive ? "#278E1D" : "white",
                color: cancelButtonActive ? "white" : "#353F4F",
                border: cancelButtonActive ? "none" : "1px solid #18283D80",
              }}
              onMouseDown={() => setCancelButtonActive(true)}
              onMouseUp={() => setCancelButtonActive(false)}
            >
              Cancel
            </button>
            {/* Activate Button */}
            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#28A745] hover:text-white hover:border-none"
              style={{
                backgroundColor: "#278E1D",
                color: "white",
                border: "none",
              }}
              onMouseDown={() => setActivateButtonActive(true)}
              onMouseUp={() => setActivateButtonActive(false)}
            >
              Activate
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        className="flex items-center justify-center"
      >
        <div className="relative w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
          {/* Header */}
          <div className="flex items-center justify-center mb-4">
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              <img src={Deleteicon} alt="" />
            </Typography>
          </div>
          {/* Dialog Content */}
          <DialogContent className="mb-4">
            <Typography className="text-[#353F4F] text-base text-center">
              Are you sure want to delete this Partner?
            </Typography>
          </DialogContent>
          {/* Dialog Actions */}
          <div className="flex justify-center">
            <button
              onClick={handleDeleteDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white"
              style={{
                border: "1px solid #18283D80",
              }}
            >
              No
            </button>
            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white"
              style={{
                border: "1px solid #18283D80",
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
