"use client";

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { cancelRequest } from "../../../services/request";

export default function StatusDialog({ open, onClose, id, refetch }) {
  const [value, setValue] = React.useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [comments, setComments] = useState("");
  const dispatch = useDispatch();
  //   const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
        request_id: id,
        partner_status: value,
        reason: selectedReason,
        comment: comments,
      };
      dispatch(
        cancelRequest(
          value === "complete"
            ? {
                request_id: id,
                partner_status: 'completed',
              }
            : payload
        )
      ).then(() => {
        refetch();
      });
      onClose();
      setValue("");
      setSelectedReason("");
      setComments("");
  };
  const handleChange = (value) => {
    setValue(value);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setValue("");
        setSelectedReason("");
        setComments("");
      }}
      maxWidth="unset"
    >
      {value === "complete" ? (
        <div class="flex items-center justify-center bg-gray-200 px-6">
          <div class="bg-white rounded-lg shadow-lg p-6">
            <h2 class="text-[32px] font-semibold text-center text-blue-600 mb-4 text-[#2260D9]">
              Confirmation request status
            </h2>
            <p class="text-[#353F4F] text-center mb-6 text-[20px]">
              Are you sure you want to mark this request as{" "}
              <strong className="capitalize">{value}</strong>?
            </p>
            <div class="flex justify-center mt-12 text-[16px]">
              <button
                class="w-full mr-2 py-2 px-4 border border-[#2260D9] text-[#2260D9] rounded max-w-[150px]"
                onClick={() => setValue("")}
              >
                Cancel
              </button>
              <button
                class="w-full ml-2 py-2 px-4 bg-[#2260D9] text-white rounded max-w-[150px] "
                onClick={handleSubmit}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      ) : value === "in progress" ? (
        <>
          <div className="bg-white rounded-lg w-full">
            {/* Header */}
            <div className="flex justify-between items-center p-6 border-b">
              <h2 className="text-xl font-semibold">
                Are you sure want to change the status?
              </h2>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Status Change Indicator */}
            <div className="p-6 flex gap-2 justify-center items-center">
              <p className="text-gray-600">
                This action is about to change the request status from:
              </p>
              <div className="flex items-center justify-center gap-4">
                <span className="px-4 py-1 rounded-full bg-[#F1FFF0] text-[16px] text-[#2CB420] border border-[#2CB420]">
                  Resolved
                </span>
                <span className="text-gray-400">- - → - -</span>
                <span className="px-4 py-1 rounded-full bg-[#FFFBE9] text-[16px] text-[#FFCD11] border border-[#FFCD11]">
                  In Progress
                </span>
              </div>
            </div>

            <form
              onSubmit={handleSubmit}
              className="p-6 flex flex-col text-[#353F4F]"
            >
              {/* Reason Selection */}
              <div className="grid grid-cols-3 w-full h-full">
                <div className="mb-6 ">
                  <h3 className="text-[18px] font-medium mb-4">
                    Select Reason
                  </h3>
                  <div className="space-y-3">
                    {[
                      "Client Complaint",
                      "Program not completed",
                      "Client not satisfied",
                      "Defect in Program",
                      "Other",
                    ].map((reason) => (
                      <label
                        key={reason}
                        className="flex items-start gap-3 cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          value={reason}
                          name="reason"
                          checked={selectedReason === reason}
                          onChange={(e) => setSelectedReason(e.target.value)}
                          className="h-[30px] w-[30px] border border-[#D6E5EE]"
                          //   style={{ border: "1px solid rgba(210, 226, 255, 1)" }}
                        />
                        <span className="text-gray-700 text-[16px]">
                          {reason}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>

                {/* Comments */}
                <div className="mb-6 col-span-2">
                  <label className="block font-medium mb-2 text-[18px]">
                    Comments<span className="text-[#FF0000]">*</span>
                  </label>
                  <textarea
                    required
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    placeholder="Enter your valid reason for Cancelling Request"
                    className="w-full h-full px-3 py-2 border border-[#D6E5EE] text-[16px] "
                  />
                </div>
              </div>

              {/* Actions */}
              <div className="flex justify-center gap-4 mt-16">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-6 py-2 border rounded"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="px-6 py-2 bg-[#2260D9] text-white rounded"
                >
                  Change Status
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          <DialogTitle
            sx={{
              color: "#2260D9",
              fontSize: "28px",
              fontWeight: 500,
              py: 3,
              borderBottom: "1px solid #D1D1D1",
            }}
          >
            Update request status
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="h6"
              sx={{ my: 2, color: "#353F4F", fontSize: "20px" }}
            >
              Select one to proceed with the request
            </Typography>
            <Box>
              <Box
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid",
                  borderColor: value === "in_progress" ? "#2260D9" : "grey.300",
                  borderRadius: 1,
                  cursor: "pointer",
                  "&:hover": {
                    borderColor: "#2260D9",
                  },
                }}
                onClick={() => handleChange("in progress")}
              >
                <Typography
                  variant="subtitle1"
                  color="#FBD15B"
                  fontWeight={600}
                  fontSize={18}
                >
                  In Progress
                </Typography>
                <Typography variant="body2" color="#A9A9A9" fontSize={12}>
                  Changing to in-progress state will send this Request back to
                  ABC Corp(Partner) to satisfy the provided requirement.
                </Typography>
              </Box>

              <Box
                sx={{
                  p: 2,
                  border: "1px solid",
                  borderColor:
                    value === "complete" ? "primary.main" : "grey.300",
                  borderRadius: 1,
                  cursor: "pointer",
                  "&:hover": {
                    borderColor: "primary.main",
                  },
                }}
                onClick={() => handleChange("complete")}
              >
                <Typography
                  variant="subtitle1"
                  color="#25861D"
                  fontWeight={600}
                  fontSize={18}
                >
                  Complete
                </Typography>
                <Typography variant="body2" color="#A9A9A9" fontSize={12}>
                  Request will be marked as completed.
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
