import React, { useEffect, useState } from "react";
import locationIcon from "../../../assets/location-red-icon.svg";
import Satisfied from "../../../assets/satisfied.svg";
import Notsatisfied from "../../../assets/not-satisfied.svg";
import Tick from "../../../assets/true.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { createNewCase, getCaseById } from "../../../services/request";
import ForwardIcon from "@mui/icons-material/Forward";
import { Alert, Snackbar } from "@mui/material";
const FeedbackForm = ({ id, actionTab }) => {
  const [feedback, setFeedback] = useState("Satisfied");
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const { data, userType } = useSelector((state) => state.userInfo);
  const currentCaseData = useSelector(
    (state) => state.requestInfo?.caseDetails.result
  );
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };
  const [details, setDetails] = useState({
    location: "",
    consumer_memo: "",
  });
  const [currentDate, setCurrentDate] = useState(moment().format("DD/MM/YYYY"));
  const [currentTime, setCurrentTime] = useState(moment().format("hh:mm A"));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(moment().format("DD/MM/YYYY"));
      setCurrentTime(moment().format("hh:mm A"));
    }, 60000); // Update every minute

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    if (actionTab === "resolved" && !feedback) {
      handleClick();
      return;
    }

    dispatch(
      createNewCase({
        memo: actionTab !== "resolved" ? null : feedback,
        consumer_memo:
          userType === "consumer" ? comment || details.consumer_memo : null,
        agent_memo: userType === "agent" ? details.consumer_memo : null,
        partner_memo: userType === "partner" ? details.consumer_memo : null,
        admin_memo: userType === "admin" ? details.consumer_memo : null,
        request_status: null,
        location: details.location,
        request: id,
        user_name: data?.username,
        user_role: userType,
      })
    ).then(() => {
      dispatch(getCaseById(id));
    });
    setDetails({
      location: "",
      consumer_memo: "",
    });
    setComment("")
    setFeedback("");
  };

  const groupData = (data) => {
    const items = Array.isArray(data) ? data : [];

    const result = [];
    let group = { updates: [] };
    let userName = null;

    (Array.isArray(data) ? data : [])?.forEach((item) => {
      if (
        item.memo ||
        item.consumer_memo ||
        item.agent_memo ||
        item.partner_memo ||
        item.admin_memo
      ) {
        if (item.memo) {
          userName = item?.user_name;
          // If group exists and has memo, add the current item to this group
          if (group) {
            result.push(group);
            group = null;
          }
          group = {
            ...item,
            updates: [
              {
                value: item.consumer_memo,
                name: userType === "consumer" ? `${item?.user_name} (you)` : `${item?.user_name} (Consumer)`,
                created_at: item.created_at,
              },
              {
                value: item.agent_memo,
                name: userType === "agent" ? `${item?.user_name} (you)` : `${item?.user_name} (Agent)`,
                created_at: item.created_at,
              },
              {
                value: item.partner_memo,
                name: userType === "partner" ? `${item?.user_name} (you)` : `${item?.user_name} (Partner)`,
                created_at: item.created_at,
              },
              {
                value: item.admin_memo,
                name: userType === "admin" ? `${item?.user_name} (you)` : `${item?.user_name} (Admin)`,
                created_at: item.created_at,
              },
            ],
          };
        } else {
          // If no group exists, create a new group with the current item
          group = {
            ...group,
            updates: [
              ...(group.updates || {}),
              {
                value: item.consumer_memo,
                name: userType === "consumer" ? `${item?.user_name} (you)` : `${item?.user_name} (Consumer)`,
                created_at: item.created_at,
              },
              {
                value: item.agent_memo,
                name: userType === "agent" ? `${item?.user_name} (you)` : `${item?.user_name} (Agent)`,
                created_at: item.created_at,
              },
              {
                value: item.partner_memo,
                name: userType === "partner" ? `${item?.user_name} (you)` : `${item?.user_name} (Partner)`,
                created_at: item.created_at,
              },
              {
                value: item.admin_memo,
                name: userType === "admin" ? `${item?.user_name} (you)` : `${item?.user_name} (Admin)`,
                created_at: item.created_at,
              },
            ],
          };
        }
      }
    });

    if (group) {
      result.push(group);
    }

    return result;
  };

  const groupedData = groupData(currentCaseData || []);

  console.log(groupedData, "groupedData");

  return (
    <div className="flex flex-col  bg-white rounded-md text-[#353F4F] ">
      {actionTab === "resolved" && userType !== "partner" && (
        <>
          <h2 className="text-[20px] font-medium mb-4 px-8">Feedback</h2>
          <div className="flex items-center gap-4 mb-6 px-8">
            <button
              onClick={() => setFeedback("Satisfied")}
              className={`flex items-center px-4 py-2  rounded ${
                feedback === "Satisfied"
                  ? "border border-[#2260D9] bg-[#FAFAFA]"
                  : ""
              }`}
            >
              <span
                className={`mr-2 text-2xl ${
                  feedback === "Satisfied" ? "text-yellow-500" : "text-gray-400"
                }`}
              >
                <img src={Satisfied} alt="satisfied" />
              </span>
              <span className="flex gap-2">
                Satisfied
                {feedback === "Satisfied" && <img src={Tick} alt="Tick" />}
              </span>
            </button>
            <button
              onClick={() => setFeedback("Not satisfied")}
              className={`flex items-center px-4 py-2 rounded-md ${
                feedback === "Not satisfied"
                  ? " border border-[#2260D9] bg-[#FAFAFA]"
                  : ""
              }`}
            >
              <span
                className={`mr-2 text-2xl ${
                  feedback === "Not satisfied"
                    ? "text-red-500"
                    : "text-gray-400"
                }`}
              >
                <img src={Notsatisfied} alt="Notsatisfied" />
              </span>
              <span className="flex gap-2">
                Not satisfied
                {feedback === "Not satisfied" && <img src={Tick} alt="Tick" />}
              </span>
            </button>
          </div>
          {feedback === "Not satisfied" && (
            <form onSubmit={handleSubmit} className="space-y-2 px-8">
              <label className="block text-sm font-medium text-gray-700">
                Provide feedback
                <span className="text-[#FF0000]">*</span>
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Enter your feedback"
                  required
                  className="w-full px-4 py-2 bg-[#F8F9FF] rounded-lg pr-12 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className="absolute right-2 top-1/2 -translate-y-1/2 p-2 hover:bg-gray-100 rounded-full transition-colors"
                  aria-label="Submit feedback"
                >
                  <ForwardIcon className="w-5 h-5 text-gray" />
                </button>
              </div>
            </form>
          )}
        </>
      )}
      {/* <div className="border-t border-[#D3D3D3]  p-8"> */}
      <div
        className={`${
          actionTab === "new" ? "border-t border-[#D3D3D3]" : ""
        } p-8`}
      >
        <div className="grid grid-cols-1 gap-2 mb-4 text-[14px]">
          <div>
            <label className="block font-medium text-xl text-[#353F4F] mb-1">
              Comment
            </label>
          </div>
        
        <textarea
          placeholder="Enter your comment"
          rows={3}
          name="consumer_memo"
          value={details.consumer_memo}
          onChange={handleChange}
          className="w-full px-3 py-2 text-[16px] bg-[#F1F6FF] rounded-[3px] mb-4 "
        ></textarea>
        </div>
        <button
          className="w-full bg-[#2260D9]  max-w-[160px] float-end  text-white py-2.5 text-[16px] font-medium rounded-[3px]"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      <div className="px-8 mb-14">
        <p className="text-[#353F4F] font-medium text-xl mb-4">
          Response (
          {groupedData.reduce(
            (total, ele) =>
              total +
              (ele.updates.filter((update) => update.value)?.length || 0),
            0
          )}
          )
        </p>
        <div className="flex flex-col gap-2">
          {groupedData?.map((ele) => {
            return (
              <div className="flex flex-col gap-2">
                {ele?.updates
                  ?.filter((ele) => ele.value)
                  ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by date descending
                  ?.map((ele) => {
                    const formattedDate = ele.created_at
                      ? moment(ele.created_at).format("MM/DD/YYYY | h:mm A")
                      : null;
                    return (
                      <>
                        <label className="block font-semibold text-[#353F4F] text-lg ">
                          <div className="flex justify-between">
                            <div>{ele?.name}</div>
                            <div className="text-[10.55px]">
                              {formattedDate}
                            </div>
                          </div>
                        </label>
                        <textarea
                          type="deacription"
                          rows={3}
                          disabled
                          value={ele.value}
                          className="w-full px-3 min-h-[65px] bg-[#D9D9D926] text-[#18283DBF] text-sm rounded-[3px] outline-none"
                        />
                      </>
                    );
                  })}
              </div>
            );
          })}
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Please Select Feedback field!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FeedbackForm;
