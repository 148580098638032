import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const createMeeting = createAsyncThunk(
  "createMeeting",
  async (agentData, { rejectWithValue }) => {
    try {
      const createNewMeeting = await api.post(`/calendar/meeting/`, agentData);
      if (createNewMeeting.status === 200 && createNewMeeting.data) {
        return createNewMeeting.data;
      }
      throw new Error("Failed to create agent");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getMeetings = createAsyncThunk("getMeetings", async (status) => {
  const queryParams = new URLSearchParams();

  if (status) queryParams.append("status", status);
  const getMeetings = await api.get(
    `/calendar/meeting/?${queryParams.toString()}`
  );
  if (getMeetings.status === 200 && getMeetings) {
    return getMeetings;
  }
  return getMeetings;
});

export const getCurrentMeeting = createAsyncThunk(
  "getCurrentMeeting",
  async (id) => {
    const queryParams = new URLSearchParams();

    if (id) queryParams.append("id", id);
    const currentMeeting = await api.get(
      `/calendar/meeting/?${queryParams.toString()}`
    );
    if (currentMeeting.status === 200 && currentMeeting) {
      return currentMeeting;
    }
    return currentMeeting;
  }
);

export const deleteMeeting = createAsyncThunk(
  "deleteMeeting",
  async (id, { rejectWithValue }) => {
    try {
      const deleteMeet = await api.delete(`/calendar/meeting/?id=${id}&option=this_event`);
      if (deleteMeet.status === 200 && deleteMeet.data) {
        return deleteMeet.data;
      }
      throw new Error("Failed to create agent");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const editMeeting = createAsyncThunk(
  "editMeeting",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const editMeet = await api.put(`/calendar/meeting/?id=${id}`, data);
      if (editMeet.status === 200 && editMeet.data) {
        return editMeet.data;
      }
      throw new Error("Failed to create agent");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getCurrentRequest = createAsyncThunk(
  "getCurrentRequest",
  async ({ status, request_via, user_id, page }, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams();

      if (status) queryParams.append("status", status);
      if (request_via) queryParams.append("request_via", request_via);
      if (user_id) queryParams.append("user_id", user_id);
      if (page) queryParams.append("page", page);
      const response = await api.get(`/agent/?${queryParams.toString()}`);

      if (response.status === 200 && response.data.status === "success") {
        const result = response.data.result.data;
        return {
          data: result,
          total_count: response.data.result.total_count,
          page: response.data.result.page,
          records_per_page: response.data.result.records_per_page,
          total_pages: response.data.result.total_pages,
        };
      } else {
        throw new Error("Unexpected API response");
      }
    } catch (error) {
      console.error("API Error:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
