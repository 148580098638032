import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import SearchIcon from "../../../assets/input-search-icon.svg";
import listIcon from "../../../assets/list-icon.svg";
import InReview from "../../../assets/in-review.svg";
import CancelRequest from "./CancelRequest";
import Box from "@mui/material/Box";
import Completed from "../../../assets/completed.svg";

import RejectedRequest from "../../../assets/rejectedrequest.svg";
import HoldRequest from "../../../assets/holdrequest.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BreadCumbs from "../../BreadCumbs/BreadCumbs";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentRequest } from "../../../services/currentRequest";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getCaseById,
  getPantnerRequestDetail,
} from "../../../services/request";
import PaymentModal from "./PaymentModal";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import FeedbackForm from "./CreateCase";
import ThreeDotMenu from "./ThreeDotMenu";
import { updateStatusByRequestId } from "../../../services/partner";
import DownloadInvoice from "../../../assets/downloadinvoice.svg";
import SuccessIcon from "../../../assets/BlueSuccessIcon.svg";
import AcceptRequest from "./AcceptRequest";
import MuiModal from "../../../shared/Modal";
import UpdateStatusDialog from "./UpdateStatusDialog";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import AddRequest from "../../Popups/AddRequest";
import StatusDialog from "./StatusDialog";
import ChangeInReview from "./ChangeIntoInReview";
import CustomStepper from "./CustomStepper";
import EditRequestModal from "./EditRequestModal";
import useDebounce from "../../../utills/debounce";

const RequestCard = ({
  icon,
  title,
  requestId,
  status,
  description,
  onViewRequest,
  onCardClick,
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between w-full gap-12">
      <div className="flex w-full gap-6" onClick={onCardClick}>
        <div
          className="flex items-center gap-9 w-[480px]"
          style={{ borderRight: "1px solid rgba(200, 219, 255, 1)" }}
        >
          <div
            className="flex items-center justify-center bg-white custom-shadow w-[100px] h-[100px]"
            style={{
              borderRadius: "5px",
            }}
          >
            <img
              src={`https://crrmsbackend.dataterrain-dev.net/media/${icon}`}
              alt="Request Icon"
              style={{
                width: "45px",
                height: "45px",
                objectFit: "contain", // Ensures the image maintains aspect ratio
              }}
            />
          </div>
          <div className="flex flex-col gap-1 text-start ">
            <h3 className="text-[#353F4F] text-[20px] font-medium capitalize">
              {title}
            </h3>
            <div
              style={{
                color: `${
                  {
                    "in progress": "#FFCD11",
                    accepted: "#278E1D",
                    wait: "#1D5BBF",
                    rejected: "#DC3545",
                    "in review": "#E26313",
                    "on hold": "#E26313",
                    new: "#2260D9",
                    completed: "#278E1D",
                  }[status] || "#000000"
                }`,
              }}
              className="capitalize text-[16px] font-normal"
            >
              {status}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center w-full gap-4">
          <p className="text-[#353F4F] font-medium text-base">
            Request ID : <span className="font-normal">{requestId}</span>
          </p>
          <div className="flex justify-between gap-4">
            <p className="text-[#353F4F] capitalize">{description}</p>
            {/* {userType === "consumer" && (
              <img
                src={ArrowDownIcon}
                className="cursor-pointer"
                alt="ArrowDownIcon"
              />
            )} */}
          </div>
        </div>
      </div>

      <div className="flex items-center gap-4">
        <button
          className="bg-[#2260D9] text-white py-4 px-5 w-[200px] rounded-[3px]"
          onClick={() => navigate(`/view-request/${requestId}`)}
        >
          View Request Detail
        </button>
      </div>
    </div>
  );
};

export default function RequestDetails({ handleView }) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const status = queryParams.get("status");

  const [actionTab, setActiveTab] = useState("new");
  const [detailAction, setDetailAction] = useState({
    cancelModal: false,
    id: "",
    reject: false,
  });
  const [openReview, setOpenReview] = useState({
    open: false,
    id: null,
    details: null,
  });
  const [selectedRequest, setSelectedRequest] = useState(null);
  const dispatch = useDispatch();
  const currentData = useSelector((state) => state.currentRequest);
  const { data, userType } = useSelector((state) => state.userInfo);
  const [rows, setRows] = useState([]);
  const currentRequestData = useSelector(
    (state) =>
      state.requestInfo?.requestDetail?.result?.data?.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      )?.[0]
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isAcceptDialog, setIsAcceptDialog] = useState(false);
  const [isUpdateStatusDialog, setIsUpdateStatusDialog] = useState(false);
  const [showSuccess, setShowSuccess] = useState({ model: false, status: "" });
  const scrollToDivRef = useRef(null);
  const [isAgginPartnerDialog, setIsAgginPartnerDialog] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [expanded, setExpanded] = React.useState(0);
  const accordionRefs = useRef([]);
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isEditRequestOpen, setIsEditRequestOpen] = useState(false);
  const [selectedEditRequestId, setSelectedEditRequestId] = useState(null);
  // Modal or Popup component
  const searchValue = useDebounce(searchQuery, 500);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase()); // Convert to lowercase for case-insensitive matching
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleScroll = () => {
    if (scrollToDivRef.current) {
      scrollToDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      console.error("scrollToDivRef is null");
    }
  };

  useEffect(() => {
    if (selectedRequest?.id) {
      setSelectedId(selectedRequest.id);
      setExpanded(selectedRequest.id.toString());
    }
  }, [selectedRequest?.id]);

  useEffect(() => {
    if (data.id) {
      dispatch(
        getCurrentRequest({
          status: actionTab,
          ...{
            ...(userType === "consumer"
              ? { user_id: data.id }
              : userType === "partner"
              ? { partner_id: data.id }
              : {}),
            page: 1,
          },
          search: searchValue,
        })
      );
      setRows([]);
    }

    return () => {
      setRows([]);
    };
  }, [dispatch, actionTab, data, userType, searchValue]);

  useEffect(() => {
    if (id) {
      dispatch(getPantnerRequestDetail(id));
      dispatch(getCaseById(id));
      setExpanded(id);
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (status) {
      if (currentRequestData) {
        setRows((prev) => [...prev, currentRequestData]);
        handleCardClick(currentRequestData, false);
        setActiveTab(status);
      }
    }
  }, [currentRequestData]);

  useEffect(() => {
    if (id && rows?.length > 0 && firstTimeLoad) {
      const scrollToAccordion = () => {
        const accordionRef = accordionRefs.current[id];

        if (accordionRef) {
          accordionRef.scrollIntoView({ behavior: "smooth", block: "start" });
          setTimeout(handleScroll, 200); // Combine timeouts for efficiency
          setFirstTimeLoad(false);
        }
      };

      const timer = setTimeout(scrollToAccordion, 500);

      return () => clearTimeout(timer); // Cleanup timeout on unmount or dependency change
    }
  }, [id, rows, firstTimeLoad]);

  useEffect(() => {
    if (!id && !status && currentData?.currentData?.[0]) {
      handleCardClick(currentData?.currentData?.[0], false);
    }
  }, [currentData?.currentData, id, status]);

  useEffect(() => {
    if (currentData?.currentData?.length > 0) {
      setRows((prevRows) => {
        const newRows = currentData.currentData.filter(
          (row) => !prevRows.some((prevRow) => prevRow.id === row.id)
        );
        return [...prevRows, ...newRows];
      });
    } else {
      setRows([]);
    }
  }, [currentData?.currentData]);

  const handleChange = (tab) => {
    setActiveTab(tab);
    setSelectedRequest("");
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("id");
    searchParams.delete("status");
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const closeModal = () => {
    setDetailAction({ cancelModal: false, id: "" });
  };

  const allSteps = [
    {
      label: userType === "consumer" ? "Request Sent" : "Request Received",
      description:
        userType === "consumer"
          ? "Request sent to ACC Corp"
          : "Request received from client",
      icon: Completed,
      partner_status: "new",
    },
    {
      label: "Request On Hold",
      description: "Request is been put on Hold by partner",
      icon: HoldRequest,
      partner_status: "on hold",
    },
    {
      label: "Request Rejected",
      description: "Your request has been Rejected",
      icon: RejectedRequest,
      partner_status: "rejected",
    },
    {
      label: "Request Accepted",
      description: "You accepted this Request",
      icon: Completed,
      partner_status: "accepted",
    },
    {
      // label: "Request Completed",
      label: userType === "partner" ? "Request Resolved" : "Request Completed",
      description: "Request has been successfully completed",
      icon: Completed,
      partner_status: "completed",
    },
    {
      label: "In Review",
      description: "Request is marked as In Review by ACC Corp",
      icon: actionTab === "in review" ? InReview : Completed,
      partner_status: "in review",
    },
    {
      label: "Request In Progress",
      description: "",
      icon: Completed,
      partner_status: "in progress",
    },
  ];

  const statusOptions = {
    new: [
      { id: 0, name: "Waiting", key: "waiting" },
      { id: 2, name: "Accept", key: "accepted" },
      { id: 4, name: "On Hold", key: "on hold" },
      { id: 5, name: "Reject", key: "rejected" },
    ],
    accepted: [
      { id: 2, name: "Accept", key: "accepted" },
      { id: 3, name: "Resolve", key: "resolved" },
      { id: 4, name: "On Hold", key: "on hold" },
      { id: 5, name: "Reject", key: "rejected" },
    ],
    "in progress": [
      { id: 3, name: "Resolve", key: "resolved" },
      { id: 4, name: "On Hold", key: "on hold" },
      { id: 5, name: "Reject", key: "rejected" },
    ],
    "on hold": [
      { id: 4, name: "On Hold", key: "on hold" },
      { id: 2, name: "Accept", key: "accepted" },
      { id: 5, name: "Reject", key: "rejected" },
    ],
    completed: [],
    rejected: [],
  };

  useEffect(() => {
    if (actionTab === "new") {
      setSelected(statusOptions.new[0]);
    } else if (
      actionTab === "accepted" ||
      (actionTab === "in progress" && userType === "partner")
    ) {
      // setSelected(
      setSelected(statusOptions.accepted[0]);
      //   statusOptions.accepted.find((status) => status.name === "Accept")
      // );
    } else if (actionTab === "on hold") {
      setSelected(statusOptions["on hold"][0]);
    }
  }, [actionTab]);

  const filteredStatusOptions = statusOptions[actionTab]?.filter(
    (option) => option.name !== "In Progress" && option.name !== "Rejected"
  );

  const [selected, setSelected] = useState("");

  const actionTabFilter = [
    { key: "new", name: "New" },
    ...(userType !== "partner"
      ? [{ key: "in review", name: "In Review" }]
      : []),
    ...(userType !== "partner"
      ? [{ key: "in progress", name: "In-Progress" }]
      : []),
    ...(userType === "consumer" || userType === "admin" || userType === "agent"
      ? [{ key: "resolved", name: "Resolved" }]
      : []),
    // { key: "in progress", name: "In-Progress" },
    ...(userType === "partner"
      ? [
          { key: "accepted", name: "In Progress" },
          { key: "resolved", name: "Resolved" },
        ]
      : []),
    { key: "completed", name: "Completed" },
    { key: "on hold", name: "On hold" },
    { key: "rejected", name: "Rejected" },
  ];

  // useEffect(() => {
  //   if (status === "rejected" && actionTab !== "rejected") {
  //     setActiveTab("rejected");
  //   }
  // }, [status, actionTab]);

  const [activeStep, setActiveStep] = useState(0);

  const filteredSteps =
    {
      new: [
        allSteps[0],
        ...(userType !== "partner" ? [allSteps[5]] : []),
        allSteps[3],
        ...(userType !== "partner" ? [allSteps[6]] : []),
        allSteps[4],
      ],
      "in review": [
        allSteps[0],
        ...(userType !== "partner" ? [allSteps[5]] : []),
        allSteps[3],
        ...(userType !== "partner" ? [allSteps[6]] : []),
        allSteps[4],
      ],
      accepted: [
        allSteps[0],
        ...(userType !== "partner" ? [allSteps[5]] : []),
        allSteps[3],
        ...(userType !== "partner" ? [allSteps[6]] : []),
        allSteps[4],
      ],
      resolved: [
        allSteps[0],
        ...(userType !== "partner" ? [allSteps[5]] : []),
        allSteps[3],
        ...(userType !== "partner" ? [allSteps[6]] : []),
        allSteps[4],
      ],
      "in progress": [
        allSteps[0],
        ...(userType !== "partner" ? [allSteps[5]] : []),
        allSteps[3],
        ...(userType !== "partner" ? [allSteps[6]] : []),
        allSteps[4],
      ],
      completed: [
        allSteps[0],
        ...(userType !== "partner" ? [allSteps[5]] : []),
        allSteps[3],
        ...(userType !== "partner" ? [allSteps[6]] : []),
        allSteps[4],
      ],
      "on hold": [
        allSteps[0],
        ...(userType !== "partner" ? [allSteps[5]] : []),
        allSteps[1],
        allSteps[3],
        allSteps[4],
      ],
      rejected: [
        allSteps[0],
        ...(userType !== "partner" ? [allSteps[5]] : []),
        allSteps[2],
        ...(userType !== "partner" ? [allSteps[6]] : []),
        allSteps[4],
      ],
    }[actionTab] || [];

  const handleCardClick = (request, replaceQuery = true) => {
    setActiveStep(
      filteredSteps?.findIndex(
        (el) => el.partner_status === request?.partner_status
      )
    );
    setSelectedRequest(request);
    dispatch(getCaseById(request.id));
    console.log("called++++");
    if (replaceQuery && request?.id && request?.request_status) {
      navigate(
        `?id=${request.id}&status=${
          userType === "partner" && request.request_status === "in progress"
            ? "accepted"
            : request.request_status
        }`,
        {
          replace: true,
        }
      );
    }
  };

  useEffect(() => {
    if (actionTab === "new") {
      setActiveStep(0);
    } else if (actionTab === "in review") {
      const stepIndex = filteredSteps.findIndex(
        (step) => step.partner_status === selectedRequest?.request_status
      );
      setActiveStep(stepIndex !== -1 ? stepIndex : 0);
    } else if (actionTab === "in progress") {
      if (selectedRequest?.partner_status === "accepted") {
        setActiveStep(2);
      } else if (selectedRequest?.partners?.length > 0) {
        setActiveStep(1.5);
      } else if (
        selectedRequest?.partner_status === "accepted" &&
        selectedRequest?.is_paid
      ) {
        setActiveStep(2.5);
      }
    } else if (actionTab === "resolved") {
      setActiveStep(3.5);
    } else {
      const stepIndex = filteredSteps.findIndex(
        (step) => step.partner_status === selectedRequest?.partner_status
      );
      setActiveStep(stepIndex !== -1 ? stepIndex : 0);
    }
  }, [actionTab, selectedRequest, filteredSteps]);

  const handleSelectStatus = async ({ id, status }) => {
    console.log("called++++");
    if (status.id === 2) {
      setIsAcceptDialog(true);
    } else if (status.id === 3) {
      // Resolve status
      const response = await dispatch(
        updateStatusByRequestId({ request_id: +id, partner_status: status.key })
      ).unwrap();
      if (response.status === "success") {
        setShowSuccess({ model: true, status: status.key });
        setTimeout(() => {
          setShowSuccess({ model: false, status: "" });
          navigate(`/request-tracking?id=${id}&status=${status.key}`);
        }, 2000);
      }
    } else if (status.id === 4) {
      // On hold status
      const response = await dispatch(
        updateStatusByRequestId({ request_id: +id, partner_status: status.key })
      ).unwrap();
      if (response.status === "success") {
        setShowSuccess({ model: true, status: status.key });
        setTimeout(() => {
          setShowSuccess({ model: false, status: "" });
          navigate(`/request-tracking?id=${id}&status=${status.key}`);
        }, 2000);
      }
    } else if (status.id === 5) {
      // Reject status
      setDetailAction({
        cancelModal: true,
        id: selectedRequest?.id,
        reject: true,
      });

      // Change actionTab to "rejected" after rejection
      setActiveTab("rejected");
    }
  };

  const handleAcceptRequest = async () => {
    // const status = userType === "partner" ? "completed" : "accepted";
    const status = "accepted";

    try {
      const response = await dispatch(
        updateStatusByRequestId({
          request_id: +selectedRequest.id,
          partner_status: status,
        })
      ).unwrap();

      if (response.status === "success") {
        setIsAcceptDialog(false);
        setShowSuccess({ model: true, status });
        setTimeout(() => {
          setShowSuccess({ model: false, status: "" });
          navigate(
            `/request-tracking?id=${+selectedRequest.id}&status=${status}`
          );
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to update request status:", error);
    }
  };
  const fetchMore = () => {
    dispatch(
      getCurrentRequest({
        status: actionTab,
        ...{
          ...(userType === "consumer"
            ? { user_id: data.id }
            : userType === "partner"
            ? { partner_id: data.id }
            : {}),
          page: currentData?.page + 1,
        },
        search: searchValue,
      })
    );
  };

  const [loading, setLoading] = useState(false);
  // Initialize Stripe with the public key
  const stripePromise = loadStripe(
    "pk_test_51QWWMODbesCIeDQDwiq4XaO71Xn1FsEZILhcY6UCPF8R29BZsoWU8L0ShJQHxb1BHRymn8xJOu12dp2fZvAoZ1Na000FcYityk"
  );
  const handlePayment = async () => {
    try {
      setLoading(true);

      // Replace with your actual API endpoint
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}agent/create-checkout-session/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any authentication headers if required
            // 'Authorization': `Bearer ${your_auth_token}`
          },
          body: JSON.stringify({
            request_id: selectedRequest.id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (!data.sessionId) {
        throw new Error("No session ID received from the server");
      }

      // Initialize Stripe
      const stripe = await stripePromise;

      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });

      if (error) {
        console.error("Stripe redirect error:", error);
        throw error;
      }
    } catch (err) {
      console.error("Payment error:", err);
      // You might want to show an error message to the user
      alert("Payment initialization failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const menuOptions = [
    ...(actionTab === "new" && userType !== "consumer" && userType !== "partner"
      ? [
          {
            key: "inReview",
            name: "Mark as In Review",
            onClick: () => {
              setOpenReview({
                open: true,
                id: selectedRequest.id,
                details: selectedRequest,
              });
            },
          },
        ]
      : []),
    ...(actionTab === "new" && userType === "consumer"
      ? [
          {
            key: "editrequest",
            name: "Edit Request",
            onClick: () => {
              setSelectedEditRequestId(selectedRequest.id); // Set the selected request ID
              setIsEditRequestOpen(true); // Open the Edit Request modal
            },
          },
        ]
      : []),
    ...(actionTab === "completed" ||
    (actionTab === "rejected" && userType === "consumer" && actionTab !== "new")
      ? [
          {
            key: "requestAgain",
            name: "Request Again",
            onClick: () =>
              navigate(`/create-request?id=${selectedRequest?.id}`),
          },
        ]
      : []),
    ...(actionTab === "new" &&
    userType === "admin" &&
    selectedRequest?.partners?.length === 0
      ? [
          {
            key: "assign_partner",
            name: "Assign",
            onClick: () => setIsAgginPartnerDialog(true),
          },
        ]
      : []),
    ...(actionTab === "new" &&
    userType === "agent" &&
    selectedRequest?.partners?.length === 0
      ? [
          {
            key: "select_partner",
            name: "Assign",
            onClick: () => setIsAgginPartnerDialog(true),
          },
        ]
      : []),

    {
      key: "viewDetails",
      name:
        actionTab === "new" || actionTab === "accepted"
          ? "View Request Detail"
          : "View Details",
      onClick: () => navigate(`/view-request/${selectedRequest?.id}`),
    },
    ...(selectedRequest?.is_paid
      ? [
          {
            key: "viewReceipt",
            name: "View Receipt",
            onClick: () => {},
          },
        ]
      : []),
    {
      key: "history",
      name: "History",
      onClick: () => navigate(`/request-history/${selectedRequest.id}`),
    },

    ...((actionTab === "new" || actionTab === "in progress") &&
    userType !== "admin" &&
    userType !== "agent" &&
    userType !== "partner" &&
    !selectedRequest?.is_paid
      ? [
          {
            key: "cancelRequest",
            name: "Cancel Request",
            onClick: () => {
              if (userType === "consumer" || userType === "partner") {
                setDetailAction({
                  ...detailAction,
                  cancelModal: true,
                  id: selectedRequest.id,
                });
              } else {
              }
            },
          },
        ]
      : []),
    ...(actionTab === "new" && (userType === "admin" || userType === "agent")
      ? [
          {
            key: "rejectRequest",
            name: "Reject Request",
            onClick: () => {
              if (userType === "admin" || userType === "agent") {
                setDetailAction({
                  ...detailAction,
                  cancelModal: true,
                  id: selectedRequest.id,
                });
              } else {
              }
            },
          },
        ]
      : []),

    ...(actionTab === "accepted"
      ? [
          {
            key: "rejectionrequest",
            name: "Reject Request",
            onClick: () =>
              setDetailAction({
                ...detailAction,
                cancelModal: true,
                id,
              }),
          },
        ]
      : []),
    ...(actionTab === "rejected" &&
    (userType === "admin" || userType === "agent")
      ? [
          {
            key: "reassign",
            name: "Reassign",
            onClick: () => setIsAgginPartnerDialog(true),
          },
        ]
      : []),
  ];

  // const handleAccordionChange = (index) => (_, isExpanded) => {
  //   setExpanded(isExpanded ? index : false);
  //   if (isExpanded && accordionRefs.current[index]) {
  //     setTimeout(() => {
  //       accordionRefs.current[index].scrollIntoView({
  //         behavior: "smooth",
  //         block: "start",
  //       });
  //     }, 800);
  //     setTimeout(() => {
  //       handleScroll();
  //     }, 1000);
  //   }
  // };

  const handleAccordionChange = (index) => (_, isExpanded) => {
    setExpanded(isExpanded ? index : false);

    if (isExpanded) {
      const accordionRef = accordionRefs.current[index];

      if (accordionRef) {
        setTimeout(() => {
          accordionRef.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 800);

        setTimeout(() => {
          handleScroll();
        }, 1000);
      } else {
        console.error(`Accordion ref at index ${index} is null`);
      }
    }
  };

  return (
    <>
      <div className="px-8 pt-8">
        {userType !== "consumer" && userType !== "admin" && (
          <BreadCumbs
            firstCumbName="Request"
            secoundCumbName="View Tracking Page"
            // secoundCumbName="Request Status"
            // thirdCumbName="Track Request"
            pathToRedirect=""
            pathToRedirectSecond="all-request"
          />
        )}

        {(userType === "consumer" || userType === "admin") && (
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
              <li class="inline-flex items-center">
                <span class="inline-flex items-center text-sm text-[#353F4F] hover:text-blue-600 dark:text-gray-400 cursor-pointer">
                  My Request
                </span>
              </li>
            </ol>
          </nav>
        )}

        {userType === "consumer" && (
          <div
            className="flex cursor-pointer justify-end"
            onClick={() => {
              navigate(`/all-request`);
              handleView();
            }}
          >
            <div className="flex items-center pointer-events-none end-0 pe-2">
              <img src={listIcon} alt="detailsIcon" />
            </div>
            <div className="text-[#2260D9] text-[18px] font-normal">
              List View
            </div>
          </div>
        )}
        <div
          className="px-6 pb-2 details-info rounded-[3px]"
          style={{
            border: "1px solid rgba(228, 237, 255, 1)",
          }}
          ref={scrollToDivRef}
        >
          <div className="flex items-start justify-between px-5 mt-8 mb-8 border-b border-[#D3D3D3] rounded-[3px]">
            <ul className="pt-2 tab-list">
              {actionTabFilter.map((discussion, index) => (
                <li
                  className={`${
                    actionTab === discussion.key ? "active" : ""
                  } relative`}
                  key={index}
                  onClick={() => handleChange(discussion.key)}
                >
                  <div className="text-[13px]">{discussion.name}</div>
                  {actionTab === discussion.key && <span></span>}
                </li>
              ))}
            </ul>
            {userType === "partner" && (
              <div
                className="flex cursor-pointer"
                onClick={() => navigate(`/requests?status=all&id=${data.id}`)}
              >
                <div className="flex items-center pointer-events-none end-0 pe-2">
                  <img src={listIcon} alt="SearchIcon" />
                </div>
                <div className="text-[#2260D9] text-[18px] font-normal">
                  List View
                </div>
              </div>
            )}
          </div>
          <div className="relative flex justify-end mb-8">
            <input
              type="text"
              id="search-navbar"
              value={searchQuery} // Bind the input value to the state
              onChange={handleSearchChange} // Call the handler when the input changes
              className="block w-full placeholder:text-[#D6E4FF] p-2 text-sm text-gray-900 border-none focus:outline-none rounded-[3px]"
              placeholder="Search"
              style={{
                border: "1px solid #2260D9",
                height: "50px",
                width: "300px",
              }}
            />
            <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
          <div className="border border-[#E4EDFF] rounded-[3px]">
            <div className="flex flex-col pt-4 cursor-pointer gap-7 pb-9">
              <InfiniteScroll
                dataLength={rows.length} // Use filtered rows here
                next={fetchMore}
                hasMore={rows.length < currentData?.total}
                loader={
                  !(currentData?.page === currentData?.total_pages) && (
                    <h4>Loading...</h4>
                  )
                }
                height={700}
                className="hide-scrollbar"
              >
                {[...rows]
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((request, index) => {
                    return (
                      <Accordion
                        key={request.id}
                        ref={(el) =>
                          (accordionRefs.current[request.id.toString()] = el)
                        }
                        elevation={0}
                        expanded={expanded.toString() === request.id.toString()}
                        onChange={handleAccordionChange(request.id.toString())}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${request.id}-content`}
                          id={`panel${request.id}-header`}
                          className="w-full"
                        >
                          {expanded.toString() !== request.id.toString() && (
                            <RequestCard
                              icon={request.category_image}
                              title={request.category_name}
                              requestId={request.id}
                              status={request.request_status}
                              description={request.description}
                              onViewRequest={request.onViewRequest}
                              onCardClick={() => handleCardClick(request)}
                            />
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className=" border-b border-[#D3D3D3]">
                            {request ? (
                              <div>
                                <div className="flex justify-between px-8">
                                  <div className="flex flex-col">
                                    <div className="flex items-center justify-center gap-10">
                                      <div className="px-8 py-8 bg-white rounded-[5px] custom-shadow">
                                        <img
                                          src={`https://crrmsbackend.dataterrain-dev.net/media/${request?.category_image}`}
                                          alt="CallIcon"
                                        />
                                      </div>
                                      <div className="flex flex-col">
                                        <div className="flex items-center gap-6">
                                          <h3 className="text-primary-black text-[38px] font-semibold capitalize">
                                            {userType === "partner"
                                              ? request?.category_name
                                              : request?.category_name}
                                          </h3>
                                          {/* {request.category_is_premium === "True" &&
                                userType !== "admin" &&
                                userType !== "consumer" &&
                                userType !== "agent" && (
                                  <div
                                    style={{
                                      border: "1px solid rgba(255, 205, 17, 1)",
                                    }}
                                    className="px-7 py-2 flex gap-2 rounded-full border-[#D1A639] text-[#353F4F]"
                                  >
                                    {request.cost}data
                                      <img src={DollarSymbol} alt="" />
                                    </div>
                                  )} */}
                                          <div
                                            style={{
                                              border: `1px solid ${
                                                {
                                                  "in progress": "#FFCD11",
                                                  accepted: "#278E1D",
                                                  wait: "#1D5BBF",
                                                  rejected: "#DC3545",
                                                  "in review": "#E26313",
                                                  "on hold": "#E26313",
                                                  new: "#1D5BBF",
                                                  completed: "#278E1D",
                                                  resolved: "#278E1D",
                                                  "in review": "#FF6711",
                                                }[request.request_status] ||
                                                "#CCCCCC"
                                              }`,

                                              backgroundColor: `${
                                                {
                                                  "in progress": "#FFF4D4",
                                                  accepted: "#EAF9EA",
                                                  wait: "#DCE8FF",
                                                  rejected: "#FDE7E9",
                                                  "on hold": "#FDEEE5",
                                                  new: "#DCE8FF",
                                                  completed: "#EAF9EA",
                                                  resolved: "#EAF9EA",
                                                  "in review": "#FFF8F4",
                                                }[request.request_status] ||
                                                "#FFFFFF"
                                              }`,
                                              color: `${
                                                {
                                                  "in progress": "#FFCD11",
                                                  accepted: "#278E1D",
                                                  wait: "#1D5BBF",
                                                  rejected: "#DC3545",
                                                  "in review": "#E26313",
                                                  "on hold": "#E26313",
                                                  new: "#2260D9",
                                                  completed: "#278E1D",
                                                  resolved: "#278E1D",
                                                  "in review": "#FF6711",
                                                }[request.request_status] ||
                                                "#000000"
                                              }`,
                                            }}
                                            className="flex justify-center w-[135px] py-2 capitalize rounded-full text-sm"
                                          >
                                            {request.request_status}
                                          </div>
                                        </div>
                                        {request.category_is_premium ===
                                          "True" &&
                                        (userType === "consumer" ||
                                          userType === "partner" ||
                                          userType === "admin") ? (
                                          <h4 className="text-[#D1A639] text-[12px] font-medium">
                                            ${request.category_per_cost}
                                          </h4>
                                        ) : null}
                                        <div className="flex font-medium text-[14px] mt-1">
                                          <div className="text-[#353F4F] border-r border-black pr-3">
                                            Request ID :{" "}
                                            <span className="text-[#2260D9] underline underline-offset-2">
                                              {request?.id}
                                            </span>
                                          </div>
                                          <div className="text-[#353F4F] pl-3 pr-3 ">
                                            Request date & Time :{" "}
                                            <span className="text-[#2260D9] underline underline-offset-2">
                                              {request?.created_at
                                                ? moment(
                                                    request.created_at
                                                  ).format(
                                                    "MM/DD/YYYY | h:mm A"
                                                  )
                                                : null}
                                            </span>
                                          </div>
                                          {userType === "partner" &&
                                            actionTab === "accepted" && (
                                              <div className="text-[#353F4F] border-l border-black pl-3 pr-3 ">
                                                Accepted date & Time :{" "}
                                                <span className="text-[#2260D9] underline underline-offset-2">
                                                  {request?.created_at
                                                    ? moment(
                                                        request.created_at
                                                      ).format(
                                                        "MM/DD/YYYY | h:mm A"
                                                      )
                                                    : null}
                                                </span>
                                              </div>
                                            )}
                                          {userType === "partner" &&
                                            actionTab === "completed" && (
                                              <div className="text-[#353F4F] border-r border-black pl-3 pr-3 ">
                                                Resolved date & Time:{" "}
                                                <span className="text-[#2260D9] underline underline-offset-2">
                                                  {request?.created_at
                                                    ? moment(
                                                        request.created_at
                                                      ).format(
                                                        "MM/DD/YYYY | h:mm A"
                                                      )
                                                    : null}
                                                </span>
                                              </div>
                                            )}
                                          {userType === "admin" &&
                                            actionTab !== "new" &&
                                            actionTab !== "resolved" && (
                                              <div className="text-[#353F4F] border-l border-black pl-3">
                                                {[
                                                  "in progress",
                                                  "on hold",
                                                  "rejected",
                                                ].includes(actionTab) &&
                                                  "Last Updated date & Time :"}
                                                {actionTab === "completed" &&
                                                  "Completed date & Time :"}
                                                {request?.updated_at && (
                                                  <span className="text-[#2260D9] underline underline-offset-2">
                                                    {moment(
                                                      request.updated_at
                                                    ).format(
                                                      "MM/DD/YYYY | h:mm A"
                                                    )}
                                                  </span>
                                                )}
                                              </div>
                                            )}

                                          {(userType === "agent" ||
                                            userType === "consumer") &&
                                            (actionTab === "in progress" ||
                                              actionTab === "completed" ||
                                              actionTab === "on hold" ||
                                              actionTab === "rejected") && (
                                              <div className="pl-2 pr-2 border-l border-black text-[#353F4F]">
                                                {actionTab === "completed"
                                                  ? "Completed"
                                                  : "Last Updated"}{" "}
                                                date & Time :{" "}
                                                <span className="text-[#2260D9] underline underline-offset-2">
                                                  {request?.updated_at
                                                    ? moment(
                                                        request.updated_at
                                                      ).format(
                                                        "MM/DD/YYYY | h:mm A"
                                                      )
                                                    : null}
                                                </span>
                                              </div>
                                            )}
                                        </div>
                                        {/* {request.category_is_premium === "True" &&
                      userType === "consumer" ? (
                        <div
                          className="inline-flex items-center h-8 px-8 py-1 rounded-lg"
                          style={{
                            border: "1px solid #D1A639",
                            borderRadius: "15px",
                          }}
                        >
                          <span className="text-sm font-medium">
                            <span className="mr-1" style={{ color: "#D1A639" }}>
                              $
                            </span>
                            {request.category_per_cost}
                          </span>
                        </div>
                      ) : null} */}
                                        {/* <div
                      style={{
                        border: "1px solid rgba(255, 205, 17, 1)",
                        borderRadius: "15px",
                      }}
                      className="px-8 py-1 bg-light-yellow text-yellow"
                    >
                      {request.request_status}
                    </div> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 mt-11">
                                    {/* {userType !== "admin" &&
                                    userType !== "partner" &&
                                    userType !== "consumer" &&
                                    userType !== "partner" &&
                                   
                                    (actionTab === "new" ||
                                      actionTab === "onHold") && (
                                      <button
                                        style={{
                                          border:
                                            "1px solid rgba(220, 53, 69, 1)",
                                        }}
                                        className="py-3 px-5 w-[200px] rounded-[3px] text-[16px] font-medium text-primary-red"
                                        onClick={() =>
                                          setDetailAction({
                                            ...detailAction,
                                            cancelModal: true,
                                            id: request.id,
                                          })
                                        }
                                      >
                                        Reject Request
                                      </button>
                                    )} */}
                                    {request.category_is_premium === "True" &&
                                    userType !== "admin" &&
                                    userType !== "partner" &&
                                    userType !== "agent" &&
                                    request.partner_status === "accepted" ? (
                                      <button
                                        className="text-white py-3 px-5 w-[200px] cursor-pointer"
                                        style={{ backgroundColor: "#35A462" }}
                                        // onClick={() => setIsOpen(true)}
                                        onClick={
                                          request.is_paid === false
                                            ? handlePayment
                                            : null
                                        }
                                        disabled={
                                          request.is_paid === false
                                            ? loading
                                            : request.is_paid
                                        }
                                      >
                                        {request.is_paid === true
                                          ? "Paid"
                                          : loading
                                          ? "Processing..."
                                          : `USD ${request.cost || 0}`}
                                      </button>
                                    ) : null}

                                    <PaymentModal
                                      isOpen={isOpen}
                                      onClose={() => setIsOpen(false)}
                                      amount={request.cost}
                                    />

                                    {/* {userType !== "partner" &&
                                      userType !== "admin" &&
                                      userType !== "consumer" &&
                                      actionTab === "rejected" && (
                                        <button className="py-3 px-5 w-[200px] rounded-[4px] border-primary-blue border text-primary-blue">
                                          Reassign Request
                                        </button>
                                      )} */}

                                    {/* {actionTab !== "rejected" &&
                                      userType !== "partner" &&
                                      userType !== "agent" &&
                                      userType !== "admin" &&
                                      userType !== "consumer" && (
                                        <button
                                          className="bg-[#2260D9] rounded-[3px] border border-[#2260D9] text-white px-5 py-3 w-[200px]"
                                          onClick={() =>
                                            navigate(
                                              `/view-request/${request?.id}`
                                            )
                                          }
                                        >
                                          View Request Detail
                                        </button>
                                      )} */}
                                    {userType === "partner" && (
                                      <div className="relative">
                                        {actionTab !== "completed" &&
                                          actionTab !== "rejected" &&
                                          actionTab !== "resolved" && (
                                            <Listbox
                                              value={selected}
                                              onChange={(newValue) => {
                                                handleSelectStatus({
                                                  id: request?.id,
                                                  status: newValue,
                                                });
                                                setSelected(newValue);
                                              }}
                                            >
                                              <div className="relative">
                                                <ListboxButton className="relative rounded-[4px] w-[200px] py-3.5 cursor-default bg-primary-blue pl-5 text-left text-white shadow-md focus:outline-none sm:text-sm">
                                                  <span className="text-sm font-medium">
                                                    {selected?.name ||
                                                      "Accepted"}
                                                  </span>
                                                  <span className="absolute inset-y-0 right-0 flex items-center pr-5">
                                                    <KeyboardArrowDownSharpIcon className="w-5 h-5 text-white" />
                                                  </span>
                                                </ListboxButton>

                                                <ListboxOptions className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                  {filteredStatusOptions
                                                    ?.filter(
                                                      (status) =>
                                                        status.id !==
                                                        selected?.id
                                                    )
                                                    .map((status) => (
                                                      <ListboxOption
                                                        key={status.id}
                                                        value={status}
                                                        className={({
                                                          active,
                                                        }) =>
                                                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                            active
                                                              ? "bg-primary-blue text-white"
                                                              : "text-[#353F4F] bg-white"
                                                          }`
                                                        }
                                                      >
                                                        {({ selected }) => (
                                                          <>
                                                            <span
                                                              className={`block truncate ${
                                                                selected
                                                                  ? "font-semibold"
                                                                  : "font-normal"
                                                              }`}
                                                            >
                                                              {status.name}
                                                            </span>
                                                          </>
                                                        )}
                                                      </ListboxOption>
                                                    ))}
                                                </ListboxOptions>
                                              </div>
                                            </Listbox>
                                          )}
                                      </div>
                                    )}
                                    {(userType === "agent" ||
                                      userType === "admin") &&
                                      actionTab === "resolved" && (
                                        <button
                                          className="bg-[#2260D9] text-white px-6 py-3 text-[16px]"
                                          onClick={handleOpen}
                                        >
                                          Update Status
                                        </button>
                                      )}

                                    <ThreeDotMenu menuOptions={menuOptions} />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {request === "" ? (
                              <div className="p-4">No Requests Found</div>
                            ) : (
                              <Box
                                sx={{
                                  width: "100%",
                                  padding: "20px",
                                  marginTop: "50px",
                                  marginBottom: "30px",
                                }}
                              >
                                {/* <Stepper
                                  alternativeLabel
                                  // activeStep={activeStep}
                                  activeStep={
                                    activeStep === 0.5 ? 1 : activeStep
                                  }
                                  connector={<QontoConnector />}
                                  sx={{
                                    "& .MuiStepConnector-line": {},
                                    "& .MuiStepLabel-label": {
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      color: "#555",
                                    },
                                    "& .MuiStepConnector-line": {
                                      borderRadius: "unset !important",
                                    },
                                    "& .MuiStepConnector-alternativeLabel": {
                                      top: "19% !important",
                                    },
                                    "& .MuiStepConnector-line.Mui-active": {},
                                    "& .MuiStepIcon-root.Mui-completed": {
                                      color: "#28a745",
                                    },
                                  }}
                                >
                                  {filteredSteps.map((step, index) => (
                                    <Step key={index}>
                                      <StepLabel
                                        icon={
                                          activeStep === index ||
                                          index < activeStep ? (
                                            <img
                                              src={step.icon}
                                              alt={step.label}
                                              style={{
                                                width: "40px",
                                                height: "40px",
                                              }}
                                            />
                                          ) : (
                                            <div className="border-[6px] border-[#C8DBFF] w-[40px] h-[40px] rounded-full"></div>
                                          )
                                        }
                                      >
                                        <Typography
                                          sx={{
                                            fontWeight: 500,
                                            color: "#353F4F",
                                            fontFamily: "'DM Sans', sans-serif",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {step.label}
                                        </Typography>
                                        {(activeStep === index ||
                                          index < activeStep) && (
                                          <Typography
                                            sx={{
                                              fontWeight: 400,
                                              color: "#848484",
                                              fontFamily:
                                                "'DM Sans', sans-serif",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {step.description}
                                          </Typography>
                                        )}
                                      </StepLabel>
                                    </Step>
                                  ))}
                                </Stepper> */}

                                <CustomStepper
                                  filteredSteps={filteredSteps}
                                  activeStep={activeStep}
                                />
                              </Box>
                            )}

                            {/* {userType === "partner" &&
                          actionTab === "rejected" ? (
                            <div className="border border-[#E50027] m-4 bg-[#FFF9F9] rounded-[5px] mx-8">
                              <h2 className="border-b border-[#E50027] text-[#E50027] text-[20px] font-medium p-4">
                                Rejected Reason
                              </h2>
                              <div className="flex flex-col gap-4 px-4 py-2">
                                <p className="font-normal text-[14px] text-[#848484] flex gap-2 items-center">
                                  Commented on{" "}
                                  <div className="w-1.5 h-1.5 bg-[#848484] rounded-full"></div>{" "}
                                  12/04/2024
                                </p>
                                <p className="text-[#353F4F] text-[12px] font-normal">
                                  {request?.reason}
                                </p>
                              </div>
                            </div>
                          ) : null} */}

                            {userType === "partner" && actionTab === "rejected"}
                            {filteredSteps[activeStep]?.label ===
                            "Request Rejected" ? (
                              <div className="border border-[#E50027] m-4 bg-[#FFF9F9] rounded-[5px]">
                                <h2 className="border-b border-[#E50027] text-[#E50027] text-[20px] font-medium p-4">
                                  Rejected Reason
                                </h2>
                                <div className="flex flex-col gap-4 px-4 py-2">
                                  <p className="font-normal text-[14px] text-[#848484] flex gap-2 items-center">
                                    Commented on{" "}
                                    <div className="w-1.5 h-1.5 bg-[#848484] rounded-full"></div>{" "}
                                    {request?.created_at
                                      ? moment(request.updated_at).format(
                                          "MM/DD/YYYY | h:mm A"
                                        )
                                      : null}
                                  </p>
                                  <p className="text-[#353F4F] text-[12px] font-normal">
                                    {request?.reason ||
                                      request?.request_status_desc}
                                  </p>
                                </div>
                              </div>
                            ) : null}

                            {userType === "consumer" &&
                              actionTab === "in progress" &&
                              request && (
                                <div className="mx-6">
                                  <div className="flex items-center justify-end w-full gap-2">
                                    <img src={DownloadInvoice} alt="" />
                                    <p className="text-[#2260D9] text-[16px] font-medium">
                                      Download Invoice
                                    </p>
                                  </div>

                                  <div
                                    style={{
                                      border:
                                        "1px solid rgba(228, 237, 255, 1)",
                                    }}
                                    className="mt-8 mb-16"
                                  >
                                    <div className="flex">
                                      {/* First Header */}
                                      <div className="flex-1 py-3 pl-6 font-bold bg-light-blue text-primary-blue">
                                        {userType === "partner"
                                          ? "Summary"
                                          : "Summary"}
                                      </div>

                                      {/* Second Header */}
                                      <div className="flex-1 py-3 pl-6 font-bold bg-light-blue text-primary-blue">
                                        {userType === "partner"
                                          ? "Price"
                                          : "Count"}
                                      </div>
                                    </div>
                                    <table className="w-full request-view-table">
                                      <tr>
                                        <td>Number of Beneficiaries</td>
                                        <td>{request?.beneficiaries || 0}</td>
                                      </tr>
                                      <tr>
                                        <td className="font-bold text-primary-blue">
                                          Total Cost
                                        </td>
                                        <td className="text-primary-blue">
                                          {`$ ${(
                                            (request?.beneficiaries || 0) *
                                            parseFloat(
                                              request?.category_per_cost || 0
                                            )
                                          ).toFixed(2)}`}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              )}

                            {actionTab !== "completed" &&
                              request && (
                                <FeedbackForm
                                  id={request.id}
                                  actionTab={actionTab}
                                />
                              )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </InfiniteScroll>
            </div>
          </div>
        </div>
        <EditRequestModal
          isOpen={isEditRequestOpen}
          onClose={() => setIsEditRequestOpen(false)}
          selectedRequestId={selectedEditRequestId}
        />
        <CancelRequest
          open={detailAction.cancelModal}
          id={detailAction.id}
          closeModal={closeModal}
          actionTab={actionTab}
          isRejectPopup={detailAction.reject}
        />
        <AcceptRequest
          open={isAcceptDialog}
          handleClose={() => setIsAcceptDialog(false)}
          confirmAccept={handleAcceptRequest}
        />

        <UpdateStatusDialog
          open={isUpdateStatusDialog}
          handleClose={() => setIsUpdateStatusDialog(false)}
          request={selectedRequest}
        />

        <AddRequest
          open={isAgginPartnerDialog}
          setOpen={setIsAgginPartnerDialog}
          RequestFromTracking={true}
          id={selectedId}
          setId={setSelected}
        />

        {showSuccess.model && (
          <MuiModal
            modalSize="sm"
            modalOpen={showSuccess.model}
            modalClose={() => setShowSuccess({ model: false, status: "" })}
            noheader
          >
            <div className="flex flex-col items-center justify-center p-4">
              <img
                src={SuccessIcon}
                alt="SuccessIcon"
                className="w-20 h-20 p-4"
              />
              <p className="text-[18px]">
                {showSuccess.status === "accepted"
                  ? `Request Accepted`
                  : showSuccess.status === "on hold"
                  ? `This request has been put to hold`
                  : showSuccess.status === "resolve"
                  ? `Your request has been resolved successfully`
                  : `Request ${showSuccess.status}`}
              </p>
            </div>
          </MuiModal>
        )}
      </div>
      <ChangeInReview
        open={openReview.open}
        closeModal={() =>
          setOpenReview({ open: false, id: null, details: null })
        }
        id={openReview.id}
        details={openReview.details}
        refetch={() => {
          dispatch(
            getCurrentRequest({
              status: actionTab,
              ...{
                ...(userType === "consumer"
                  ? { user_id: data.id }
                  : userType === "partner"
                  ? { partner_id: data.id }
                  : {}),
                page: 1,
              },
              search: searchValue,
            })
          );
        }}
      />
      <StatusDialog
        open={open}
        onClose={handleClose}
        id={selectedRequest?.id}
        refetch={() => {
          dispatch(
            getCurrentRequest({
              status: actionTab,
              ...{
                ...(userType === "consumer"
                  ? { user_id: data.id }
                  : userType === "partner"
                  ? { partner_id: data.id }
                  : {}),
                page: 1,
              },
              search: searchValue,
            })
          );
        }}
      />
      <ChangeInReview
        open={openReview.open}
        closeModal={() =>
          setOpenReview({ open: false, id: null, details: null })
        }
        id={openReview.id}
        details={openReview.details}
        refetch={() =>
          dispatch(
            getPantnerRequestDetail({
              request_via: "",
              status: "",
            })
          )
        }
      />
    </>
  );
}
