import { createSlice } from "@reduxjs/toolkit";
import { createCurrentRequest, getCurrentRequest, getSingleRequest } from "../../services/currentRequest";
import { createMeeting, getCurrentMeeting, getMeetings } from "../../services/createMeeting";

const createMeetingSlice = createSlice({
  name: "meetingInfo",
  initialState: {
    details: {},
    currentData: [],
    total_pages: 0,
    
    total: 0,
    page: 1,
    limit: 10,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMeetings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMeetings.fulfilled, (state, action) => {
        state.loading = false;
        state.currentData = action.payload.data;
      })
      .addCase(getMeetings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
    
    builder
      .addCase(createMeeting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createMeeting.fulfilled, (state, action) => {
        state.loading = false;
        state.allData = action.payload.result || [];
      })
      .addCase(createMeeting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
    
    builder
      .addCase(getCurrentMeeting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCurrentMeeting.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload || [];
      })
      .addCase(getCurrentMeeting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
    
  },
});

export default createMeetingSlice.reducer;
