import React, { useState, useEffect, useRef } from "react";
import { ExternalCategoryTab } from "../../utills/formFields";
import SearchIcon from "../../assets/input-search-icon.svg";
import DataTable from "../../shared/DataGrid";
import { ExternalCategoryColumn } from "../../utills/tableFields";
import MoreIcon from "../../assets/more-icon.svg";
import { useNavigate, useParams } from "react-router";
import { getCategoryById } from "../../services/category";
import { useDispatch, useSelector } from "react-redux";
import Breadcumbs from "../BreadCumbs/BreadCumbs";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import moment from "moment";
import RightIcon from "../../assets/check-icon.svg";
import BlockIcon from "../../assets/block-icon.svg";
import ViaIcon from "../../assets/via.svg";
import OptIcon from "../../assets/opt.svg";
import { Dialog, DialogContent, Typography } from "@mui/material";
import Crossicon from "../../assets/crossicon.svg";
import Checkimg from "../../assets/checkimg.svg";
import Crossimg from "../../assets/crossimg.svg";
import Checkicon from "../../assets/checkicon.svg";
import Archiveicon from "../../assets/archivedicon.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  getAllCategoryList,
  updateCategoryStatus,
} from "../../services/category";
import GreenOpt from "../../assets/green-opt.svg";
import RedOpt from "../../assets/red-opt.svg";
import {
  Popper,
  MenuList,
  MenuItem as MuiItem,
  Paper,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelRequest from "../Request/AllRequest/CancelRequest";
import StatusDialog from "../Request/AllRequest/StatusDialog";
import AddPartnersInCategories from "../Popups/AddPartnersInCategories";
import AddRequest from "../Popups/AddRequest";
import ChangeInReview from "../Request/AllRequest/ChangeIntoInReview";
import SilverBadge from "../../assets/silver-badge.svg";
import GoldenBadge from "../../assets/golden-badge.svg";
import BronzeBadge from "../../assets/bronze-badge.svg";
import NoBadge from "../../assets/nobadge.svg";
import Tooltip from "../../shared/Tooltip";

const ExternalCategory = () => {
  const { id: paramId } = useParams();
  const [actionTab, setActiveTab] = useState("totalrequest");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchPartnerQuery, setSearPartnerchQuery] = useState("");
  const navigate = useNavigate();
  const { userType } = useSelector((state) => state.userInfo);

  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [activateDialogOpen, setActivateDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const handleDialogClose = () => setSuccessDialogOpen(false);
  const [comment, setComment] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [cancelButtonActive, setCancelButtonActive] = useState(false);
  const [deactivateButtonActive, setDeactivateButtonActive] = useState(false);
  const [activateButtonActive, setActivateButtonActive] = useState(false);
  const [dialogImage, setDialogImage] = useState("");
  const buttonRef = useRef(null);

  const [activeRowId, setActiveRowId] = React.useState(null);
  const popoverRef = useRef(null);
  const [detailAction, setDetailAction] = useState({
    cancelModal: false,
    id: null,
    reject: false,
  });
  const [openReview, setOpenReview] = useState({
    open: false,
    id: null,
    details: null,
  });
  const [categoryId, setCategoryId] = useState("");

  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [openPopup, setPopupOpen] = useState(false);
  const [isUpdateStatusDialog, setIsUpdateStatusDialog] = useState(false);

  const closeModal = () => {
    setDetailAction({ cancelModal: false, id: null, reject: false });
  };
  const actionOpen = Boolean(anchorEl);

  const [confirmAction, setConfirmAction] = useState({
    action: "",
    id: "",
  });
  const handleOpen = () => {
    setId(id);
    setIsUpdateStatusDialog(true);
  };
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuOpenClick = (event, rowId) => {
    if (activeRowId === rowId) {
      // Close if the same row is clicked
      setAnchorEl(null);
      setActiveRowId(null);
    } else {
      // Open for a new row
      setAnchorEl(event.currentTarget);
      setActiveRowId(rowId);
    }
  };

  const openMenu = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoryById(paramId));
  }, [paramId, dispatch]);

  const categoryData = useSelector((state) => state.categoryInfo.details);
  // console.log("categoryData", categoryData);

  const requestData = useSelector(
    (state) => state.categoryInfo.details.request
  );

  const partnerData = useSelector(
    (state) => state.categoryInfo.details.partners
  );

  const CurrentStatusColumn = [
    {
      field: "opt",
      headerName: "OPT",
      flex: 1,
      headerAlign: "center",
      renderHeader: () => (
        <div className="flex items-center gap-[5px]">
          <p className="font-sans text-[#18283D] font-semibold">OPT</p>
          <img src={OptIcon} alt="opt" />
        </div>
      ),
      renderCell: (params) => {
        const status = params.row?.status?.toLowerCase();
        const optImage =
          status === "in progress" || status === "rejected" ? RedOpt : GreenOpt;
        return (
          <div className="flex items-center justify-center h-full">
            <img src={optImage} alt="star icon" className="w-4 h-4" />
          </div>
        );
      },
    },
    {
      field: "requestId",
      headerName: "Request ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Client Name",
      flex: 1,
    },

    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
    },

    {
      field: "via",
      headerName: "Via",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-3">
          <p className="font-sans text-[#18283D] font-semibold">Via</p>
          <img src={ViaIcon} alt="via" />
        </div>
      ),
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "intakeDate",
      headerName: "Intake Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.intakeDate).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "level",
      headerName: "Level",
      flex: 1,
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "submittedDate",
      headerName: "Submitted Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.submittedDate).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          completed: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF]",
        };
        return (
          <div className={`capitalize ${colors[params.value] || ""}`}>
            {params.value}
          </div>
        );
      },
    },

    {
      field: "partner_names",
      headerName: "Partner",
      flex: 1,
    },

    {
      field: "response",
      headerName: "Partner Response",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          accepted: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF],",
        };
        return (
          <div className={`capitalize ${colors[params.value] || ""}`}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        console.log(params.row);
        return (
          <>
            <IconButton
              onClick={(e) => handleMenuOpenClick(e, params.row.id)}
              ref={buttonRef}
              aria-controls={actionOpen ? "action-menu" : undefined}
              aria-haspopup="true"
            >
              <MoreVertIcon />
            </IconButton>

            <Popper
              open={actionOpen && activeRowId === params.row.id}
              ref={popoverRef}
              anchorEl={anchorEl}
              placement="bottom"
              disablePortal
              className="menu-before"
            >
              <Paper className="absolute right-5 z-50 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5">
                <MenuList>
                  {params.row.status === "new" && (
                    <MuiItem
                      onClick={() => {
                        setOpenReview({
                          open: true,
                          id: params.id,
                          details: params.row,
                        });
                      }}
                    >
                      {/* <a className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"> */}
                      Mark as In Review
                      {/* </a> */}
                    </MuiItem>
                  )}

                  {params.row.partner_status === "resolved" && (
                    <MuiItem
                      onClick={() => {
                        handleOpen(params.id);
                        setId(params.id);
                      }}
                      className="cursor-pointer"
                    >
                      Update Status
                    </MuiItem>
                  )}

                  {/* {(params.row.partner_status === "wait" ||
                    params.row.partner_names.length === 0) && (
                    <MuiItem
                      onClick={() => {
                        setOpen(params.id);
                        setId(params.id);
                        setAnchorEl(null);
                      }}
                    >
                      Assign Partner
                    </MuiItem>
                  )} */}
                  <MuiItem
                    onClick={() => {
                      navigate(`/view-request/${params.row.id}`);
                      setAnchorEl(null);
                    }}
                    className="cursor-pointer"
                  >
                    View Details
                  </MuiItem>

                  {params.row.partner_status === "rejected" &&
                    params.row.partner_names.length !== 0 &&
                    userType !== "consumer" && (
                      <MuiItem
                        onClick={() => {
                          setOpen(params.id);
                          setId(params.id);
                          setAnchorEl(null);
                          setCategoryId(params.row.category);
                        }}
                        className="cursor-pointer"
                      >
                        Reassign
                      </MuiItem>
                    )}

                  {/* {params.row.partner_status === "rejected" &&
                    userType !== "consumer" && (
                      <MuiItem
                        onClick={() => {
                          setOpen(params.id);
                          setId(params.id);
                          setAnchorEl(null);
                          setCategoryId(params.row.category);

                        }}
                      >
                        Reassign
                      </MuiItem>
                    )} */}

                  {userType !== "consumer" &&
                    params.row.partner_names.length === 0 && (
                      <MuiItem
                        onClick={() => {
                          setPopupOpen(params.id);
                          setId(params.id);
                          setCategoryId(params.row.category);
                          setAnchorEl(null);
                        }}
                        className="cursor-pointer"
                      >
                        Assign
                      </MuiItem>
                    )}

                  {params.row.partner_status !== "completed" && (
                    <MuiItem
                      onClick={() => {
                        navigate(
                          `/request-tracking?id=${params?.row?.id}&status=${params?.row?.status}`
                        );
                        setAnchorEl(null);
                      }}
                      className="cursor-pointer"
                    >
                      Track Request
                    </MuiItem>
                  )}

                  {(params.row.partner_status === "wait" ||
                    params.row.partner_names.length === 0) &&
                    userType !== "consumer" && (
                      <MuiItem
                        onClick={() => {
                          setDetailAction({
                            cancelModal: true,
                            id: params.row.id,
                            reject: true,
                          });
                          setAnchorEl(null);
                        }}
                        className="cursor-pointer"
                      >
                        Reject Request
                      </MuiItem>
                    )}

                  {params.row.partner_status === "completed" && (
                    <MuiItem
                      onClick={() => setAnchorEl(null)}
                      className="cursor-pointer"
                    >
                      Request Again
                    </MuiItem>
                  )}

                  <MuiItem
                    onClick={() => {
                      navigate(`/request-history/${params?.row?.id}`);
                      setAnchorEl(null);
                    }}
                    className="cursor-pointer"
                  >
                    History
                  </MuiItem>
                </MenuList>
              </Paper>
            </Popper>
          </>
        );
      },
    },
  ];

  const topCategoryRows =
    requestData && requestData.length > 0
      ? requestData.map((item) => ({
          id: item.id,
          requestId: item.id,
          name: item.first_name,
          contact: item.contact,
          via: item.request_via,
          intakeDate: item.created_at,
          level: item.urgency_level,
          description: item.request_status_desc,
          submittedDate: item.updated_at,
          status: item.request_status,
          response: item.partner_status,
          partner_names: item.partners_names,
        }))
      : [];
  const searchFilteredRows = topCategoryRows.filter((row) =>
    row.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const topPartnersRows =
    partnerData && partnerData.length > 0
      ? partnerData.map((item) => ({
          id: item.id,
          partnerId: item.id,
          name: item.organization_name,
          manageCategory: item.categories.length,
          lastUpdateDate: item.updated_at,
          status: item.status,
          badge: item.badge,
          total_org_employee: item.total_org_employee,
        }))
      : [];

  const searchPartnerFilteredRows = topPartnersRows.filter((row) =>
    row.name?.toLowerCase().includes(searchPartnerQuery.toLowerCase())
  );

  const TopPartnerColumns = [
    ...ExternalCategoryColumn,
    {
      field: "badge",
      headerName: "Badge",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const badgeIcons = {
          Silver: SilverBadge,
          Gold: GoldenBadge,
          Bronze: BronzeBadge,
          // "No Badge": NoBadge,
        };

        const badge = params.value;
        const badgeIcon = badgeIcons[badge];

        return (
          <div className="flex items-center justify-center h-full">
            {badgeIcon ? (
              <Tooltip title={badge} arrow>
                <img
                  src={badgeIcon}
                  alt={`${badge} badge`}
                  className="w-6 h-6"
                />
              </Tooltip>
            ) : (
              badge
            )}
          </div>
        );
      },
    },
    {
      field: "total_org_employee",
      headerName: "Total Employees",
      flex: 1,
      id: 7,
      renderCell: (params) => {
        console.log({ params });
      },
    },
    {
      field: "manageCategory",
      headerName: "Managed Program",
      flex: 1,
      id: 5,
    },
    {
      field: "lastUpdateDate",
      headerName: "Last Update Date & Time",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return moment(params?.row?.lastUpdateDate).format(
          "MM/DD/YYYY | h:mm A"
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const statusStyles = {
          active: "text-[#29B473]",
          inactive: "text-[#D22735]",
        };
        const status = params.row.status;
        return (
          <div
            className={`flex items-center justify-center gap-2 ${
              statusStyles[params.value]
            }`}
          >
            {status === "active" ? (
              <img src={RightIcon} />
            ) : (
              <img src={BlockIcon} />
            )}
            <span className="capitalize">{status}</span>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      id: 4,
      renderCell: (params) => {
        return (
          <>
            <Menu as="div" className="inline-block text-left mt-2">
              <div>
                <MenuButton className="cursor-pointer relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900">
                  <img
                    src={MoreIcon}
                    alt="MoreIcon"
                    onClick={(e) => handleClick(e, params.row)}
                  />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem
                    onClick={() => {
                      navigate(`/partner/${params.id}`);
                    }}
                  >
                    <a
                      href="#"
                      className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                    >
                      View
                    </a>
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate(`/addnewpartner?id=${params.id}`)}
                  >
                    <a
                      href="#"
                      className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                    >
                      Edit
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="#"
                      className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                    >
                      Deactivate
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </>
        );
      },
    },
  ];

  const onAddParterClick = () => navigate("/addnewpartner");

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleConfirmAction = () => {
    // Validate comment for "deactivated" status
    if (confirmAction.action === "inactive" && !comment.trim()) {
      setDialogMessage("Reason is required when status is 'deactivated'.");
      setSuccessDialogOpen(true);
      return;
    }

    let message = "";
    let imageSrc = "";

    switch (confirmAction.action) {
      case "inactive":
        message = `Program successfully deactivated.`;
        imageSrc = Crossicon;
        break;
      case "active":
        message = `Program successfully activated.`;
        imageSrc = Checkicon;
        break;
      case "archive":
        message = `Program successfully archived.`;
        imageSrc = Archiveicon;
        break;
      default:
        break;
    }

    dispatch(
      updateCategoryStatus({
        category_id: confirmAction.id,
        status: confirmAction.action,
      })
    );

    setTimeout(() => {
      dispatch(getAllCategoryList());
    }, 50);

    setConfirmAction({ action: "", id: "" });
    setDialogMessage(message);
    setDialogImage(imageSrc);
    setConfirmDialogOpen(false);
    setActivateDialogOpen(false);
    setSuccessDialogOpen(true);
    setDeleteDialogOpen(false);
    setComment("");
    handleClose();

    setTimeout(() => {
      setSuccessDialogOpen(false);
      window.location.reload();
    }, 2000);
  };

  const handleAction = (action, id) => {
    setConfirmAction({ action, id });
    if (action === "active") {
      setActivateDialogOpen(true);
    } else if (action === "archive") {
      handleDeleteDialogOpen();
    } else {
      setConfirmDialogOpen(true);
    }
    handleClose();
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const handleConfirmDialogClose = () => setConfirmDialogOpen(false);
  const handleActivateDialogClose = () => setActivateDialogOpen(false);

  return (
    <>
      <div className="px-8 pt-10 bg-[#F9F9F9]">
        <div className="mb-6">
          <Breadcumbs
            firstCumbName="program"
            secoundCumbName="Program Status"
            thirdCumbName="Program View"
            pathToRedirect="addnewprogram"
            pathToRedirectSecond="allcategory"
          />
        </div>
        <div className="px-6 py-10 mb-5 border border-[#E4EDFF] rounded-[5px] bg-white">
          <div className="flex justify-between">
            <div className="border border-[#2260D9] bg-[#F2F7FF] rounded-[3px] text-[#2260D9] font-semibold text-2xl px-5 mx-5 py-2 w-fit uppercase">
              {categoryData.category_type} Program
            </div>
            {userType !== "agent" && (
              <div>
                <Menu
                  as="div"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleMenuClose}
                >
                  <div>
                    <MenuButton
                      className="relative cursor-pointer inline-flex w-full justify-center py-2 px-4 border border-[#D9D9D9] rounded-md"
                      onClick={(e) => handleMenuClick(e, categoryData.id)}
                    >
                      <img src={MoreIcon} alt="MoreIcon" />
                    </MenuButton>

                    <MenuItems
                      transition
                      className="absolute right-20 z-10 w-[150px] mt-2 px-4  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      <div>
                        <MenuItem
                          as="button"
                          className=" cursor-pointer py-2 block !text-[12px] text-[#353F4F] data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                        >
                          Edit
                        </MenuItem>
                        {categoryData.status === "active" ? (
                          <div>
                            <MenuItem
                              as="button"
                              onClick={() =>
                                handleAction("inactive", categoryData.id)
                              }
                              className=" cursor-pointer py-2 block !text-[12px] text-[#353F4F] data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                            >
                              Deactivate
                            </MenuItem>
                          </div>
                        ) : (
                          <div>
                            <MenuItem
                              as="button"
                              onClick={() =>
                                handleAction("active", categoryData.id)
                              }
                              // className="!text-[12px] text-green-600 hover:bg-[#D1FAE5] hover:text-green-800"
                            >
                              <p className=" cursor-pointer py-2 block !text-[12px] text-[#353F4F] data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                                Activate
                              </p>
                            </MenuItem>

                            <MenuItem
                              as="button"
                              onClick={() =>
                                handleAction("archive", categoryData.id)
                              }
                            >
                              <button
                                type="archive"
                                className=" cursor-pointer py-2 block !text-[12px] text-[#353F4F] data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                              >
                                Add To Archived
                              </button>
                            </MenuItem>
                          </div>
                        )}
                      </div>
                    </MenuItems>
                  </div>
                </Menu>
              </div>
            )}
          </div>
          <div className="flex items-center px-5 pt-14 pb-6 border-b border-[#E4E6EF]">
            <p className="text-[#1D5BBF] font-semibold text-3xl border-r border-[#2260D9] pr-7 py-1 uppercase">
              {categoryData.name}
            </p>
            <p className="text-[#353F4F] text-xl font-medium pl-7 ">
              Program Id: {categoryData.id}
            </p>
            {/* <p
              className={`text-base font-medium px-5 py-1 ml-7 rounded-full capitalize ${
                categoryData.status === "inactive"
                  ? "bg-[#FFEDED] border border-[#FF4D4F] text-[#FF4D4F]"
                  : "bg-[#EDFFF4] border border-[#35A462] text-[#35A462]"
              }`}
            >
              {categoryData.status}
            </p> */}
          </div>
          <div className="px-5">
            <div className="py-10 mb-6 text-[#353F4F] text-sm">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.It has survived
              not only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged. It was popularised
              in the 1960s with the release of Letraset sheets containing Lorem
              Ipsum passages, and more recently with desktop.
            </div>
            <div className="details-info mb-8">
              <div>
                <ul className="tab-list border-b border-[#E4E6EF]">
                  {ExternalCategoryTab.map((discussion, index) => (
                    <li
                      className={`${
                        actionTab === discussion.key ? "active" : ""
                      } relative `}
                      style={{ width: "150px" }}
                      key={index}
                      onClick={() => setActiveTab(discussion.key)}
                    >
                      <div className="text-[13px]"> {`${discussion.name}`}</div>
                      {actionTab === discussion.key && <span></span>}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex justify-end items-center my-10">
                <div className="flex items-stretch gap-7">
                  {actionTab === "totalpartners" && (
                    <>
                      <div className="relative">
                        <input
                          type="text"
                          id="search-navbar"
                          value={searchPartnerQuery}
                          onChange={(e) =>
                            setSearPartnerchQuery(e.target.value)
                          }
                          className="block w-full p-2 text-sm text-gray-900 border-none rounded-[3px] focus:outline-none"
                          placeholder="Search here..."
                          style={{
                            border: "1px solid rgba(34, 96, 217, 1)",
                            height: "50px",
                            width: "300px",
                          }}
                        />
                        <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                          <img src={SearchIcon} alt="SearchIcon" />
                        </div>
                      </div>
                      {userType !== "agent" && (
                        <button
                          className="bg-[#2260D9] font-medium text-white text-base rounded-[3.9px] px-12"
                          onClick={onAddParterClick}
                        >
                          Add Partners
                        </button>
                      )}
                    </>
                  )}
                  {actionTab === "totalrequest" && (
                    <div className="relative">
                      <input
                        type="text"
                        id="search-navbar"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="block w-full p-2 text-sm text-gray-900 border-none rounded-[3px] focus:outline-none"
                        placeholder="Search here..."
                        style={{
                          border: "1px solid rgba(34, 96, 217, 1)",
                          height: "50px",
                          width: "300px",
                        }}
                      />
                      <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                        <img src={SearchIcon} alt="SearchIcon" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {actionTab === "totalpartners" && (
                <div>
                  <DataTable
                    rows={searchPartnerFilteredRows || []}
                    columns={TopPartnerColumns}
                    hideCheckbox
                    hideFooter
                  />
                </div>
              )}
              {actionTab === "totalrequest" && (
                <div>
                  <DataTable
                    rows={searchFilteredRows || []}
                    columns={CurrentStatusColumn}
                    hideCheckbox
                    hideFooter
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={successDialogOpen}
        onClose={handleDialogClose} // Close on outside click
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{ width: "450px", height: "180px", borderRadius: "10px" }}
        >
          <img
            src={dialogImage}
            alt="Success"
            style={{ display: "block", margin: "0 auto" }}
          />
          <Typography variant="h6" align="center" style={{ marginTop: "20px" }}>
            {dialogMessage}
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        className="flex items-center justify-center max-w-none rounded-xl"
      >
        <div className="bg-white  shadow-lg w-[600px] sm:max-w-sm md:max-w-md lg:max-w-xl xl:max-w-2xl px-4 sm:px-8 md:px-16 lg:px-20 py-6 sm:py-8 md:py-10 relative">
          <div className="flex items-center justify-center mb-4">
            <img src={Checkimg} alt="Check" className="mx-2" />
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              --
              <ArrowForwardIcon />
              --
            </Typography>
            <img src={Crossicon} alt="Cross" className="mx-2" />
          </div>

          <DialogContent className="mb-4 text-center">
            <Typography className="text-[#353F4F] text-base font-medium">
              Are you sure you want to change the status?
            </Typography>
          </DialogContent>
          <div className="mb-4">
            <label
              htmlFor="Comments"
              className="text-[#353F4F] font-medium flex items-center"
            >
              Comments <span className="ml-1 text-red-600">*</span>
            </label>
            <textarea
              id="Comment"
              name="Comment"
              rows={6}
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-[#D6E5EE] placeholder:text-gray-400 focus:outline-2 focus:outline-indigo-600 sm:text-sm"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter your reason for changing the status"
              required
            />
          </div>

          <div className="flex justify-center gap-4">
            <button
              onClick={handleConfirmDialogClose}
              className="text-[#353F4F] px-6 py-3 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: cancelButtonActive ? "#DC3545" : "white",
                color: cancelButtonActive ? "white" : "#353F4F",
                border: cancelButtonActive ? "none" : "1px solid #18283D80",
              }}
              onMouseDown={() => setCancelButtonActive(true)}
              onMouseUp={() => setCancelButtonActive(false)}
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmAction}
              className="text-[#353F4F] px-6 py-3 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: "#DC3545",
                color: "white",
                border: "none",
              }}
              onMouseDown={() => setDeactivateButtonActive(true)}
              onMouseUp={() => setDeactivateButtonActive(false)}
            >
              Deactivate
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={activateDialogOpen}
        onClose={handleActivateDialogClose}
        className="flex items-center justify-center overflow-hidden max-w-none rounded-xl"
      >
        <div className="bg-white shadow-lg w-[600px] overflow-hidden sm:max-w-sm md:max-w-md lg:max-w-xl xl:max-w-2xl px-4 sm:px-8 md:px-16 lg:px-20 py-6 sm:py-8 md:py-10 relative">
          <div className="flex items-center justify-center mb-4">
            <img src={Crossimg} alt="Cross" className="mx-2 " />

            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              --
              <ArrowForwardIcon />
              --
            </Typography>
            <img src={Checkicon} alt="Check" className="mx-2 " />
          </div>
          <DialogContent className="mb-4 text-center">
            <Typography className="text-[#353F4F] text-base font-medium">
              Are you sure you want to change the status?
            </Typography>
          </DialogContent>
          <div className="flex justify-center">
            <button
              onClick={handleActivateDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: cancelButtonActive ? "#278E1D" : "white",
                color: cancelButtonActive ? "white" : "#353F4F",
                border: cancelButtonActive ? "none" : "1px solid #18283D80",
              }}
              onMouseDown={() => setCancelButtonActive(true)}
              onMouseUp={() => setCancelButtonActive(false)}
            >
              Cancel
            </button>

            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#28A745] hover:text-white hover:border-none"
              style={{
                backgroundColor: "#278E1D",
                color: "white",
                border: "none",
              }}
              onMouseDown={() => setActivateButtonActive(true)}
              onMouseUp={() => setActivateButtonActive(false)}
            >
              Activate
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        className="flex items-center justify-center"
      >
        <div className="relative w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-center mb-4">
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              <img src={Archiveicon} alt="" />
            </Typography>
          </div>

          <DialogContent className="mb-4">
            <Typography className="text-[#353F4F] text-base text-center">
              Are you sure you want to move this Program to Archive?
            </Typography>
          </DialogContent>

          <div className="flex justify-center">
            <button
              onClick={handleDeleteDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#FF6711] hover:text-white"
              style={{
                border: "1px solid #18283D80",
              }}
            >
              No
            </button>
            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#FF6711] hover:text-white"
              style={{
                border: "1px solid #18283D80",
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Dialog>

      <StatusDialog
        open={isUpdateStatusDialog}
        onClose={handleClose}
        id={id}
        refetch={() => dispatch(getCategoryById(paramId))}
      />
      <CancelRequest
        open={detailAction.cancelModal}
        id={detailAction.id}
        closeModal={closeModal}
        actionTab={actionTab}
        isRejectPopup={detailAction.reject}
      />
      <AddPartnersInCategories
        open={openPopup}
        setOpen={setPopupOpen}
        id={id}
        fromRequest={true}
        setId={setId}
        categoryId={categoryId}
        getRequest={() => dispatch(getCategoryById(paramId))}
      />
      <AddRequest
        open={open}
        setOpen={setOpen}
        id={id}
        fromRequest={true}
        setId={setId}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        refetch={() => dispatch(getCategoryById(paramId))}
      />
      <ChangeInReview
        open={openReview.open}
        closeModal={() =>
          setOpenReview({ open: false, id: null, details: null })
        }
        id={openReview.id}
        details={openReview.details}
        refetch={() => dispatch(getCategoryById(paramId))}
      />
    </>
  );
};

export default ExternalCategory;
