import React, { useEffect, useState } from "react";
import { Modal, Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllAgentList } from "../../services/agent";
import CloseIcon from "../../assets/close-red-icon.svg";

const AgentListPopup = ({
  open,
  onClose,
  setSelectedAgents,
  selectedAgentNames,
  setSelectedAgentNames,
  selectedAgentIds,
  setSelectedAgentIds,
}) => {
  const dispatch = useDispatch();
  const agentInfo = useSelector((state) => state.agentInfo?.details?.data);

  const [selectedRows, setSelectedRows] = useState([]); // Temporary table selection

  useEffect(() => {
    if (open) {
      dispatch(
        getAllAgentList({
          status: null,
          category_id: null,
        })
      );
      setSelectedRows([]); // Reset temporary selection on modal open
    }
  }, [open, dispatch]);

  const handleRowClick = (params) => {
    const { id, first_name } = params.row;
    const isSelected = selectedRows.some((row) => row.id === id);

    if (isSelected) {
      setSelectedRows((prev) => prev.filter((row) => row.id !== id));
    } else {
      setSelectedRows((prev) => [...prev, params.row]);
    }
  };

  const handleAddClick = () => {
    // Add selected rows to final selections
    const newIds = selectedRows.map((row) => row.id);
    const newNames = selectedRows.map((row) => row.first_name);

    setSelectedAgentIds((prev) => Array.from(new Set([...prev, ...newIds])));
    setSelectedAgentNames((prev) => Array.from(new Set([...prev, ...newNames])));

    setSelectedAgents([...newNames]); // Update parent component
    onClose(); // Close modal
  };

  const handleCancel = () => {
    setSelectedRows([]); // Clear temporary selections
    onClose();
  };

  const columns = [
    {
      field: "partners",
      headerName: "",
      maxWidth: 80,
      renderCell: (params) => {
        const isChecked =
          selectedRows.some((row) => row.id === params.row.id) ||
          selectedAgentIds.includes(params.row.id); 

        return (
          <div className="flex items-center justify-center h-full">
            <input
              className="rounded-sm w-4 h-4 border-[#6F6F6F] text-[#607274] focus:ring-[#C6D8DA]"
              type="checkbox"
              checked={isChecked}
              onChange={() => handleRowClick(params)} // Handle click on checkbox
            />
          </div>
        );
      },
    },
    {
      field: "first_name",
      headerName: "Agent Name",
      flex: 1,
    },
  ];

  const rows = agentInfo;

  return (
    <Modal
      open={open}
      onClose={handleCancel}
      className="flex items-center justify-center"
    >
      <Box className="bg-white rounded-lg shadow-lg w-[800px] p-4 py-7">
        <div className="flex items-center justify-between border-b border-[#D3D3D3] pb-3 mb-4 px-4">
          <h2 className="text-[#353F4F] text-lg font-semibold">
            Manage Request
          </h2>
          <img
            src={CloseIcon}
            onClick={handleCancel}
            className="cursor-pointer"
          />
        </div>
        <div className="h-[400px] mb-4">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            hideFooter
            onRowClick={handleRowClick}
          />
        </div>
        <div className="flex justify-center space-x-7">
          <Button
            variant="outlined"
            sx={{
              textTransform: "capitalize",
              width: "150px",
              color: "#353F4F",
              border: "1px solid #353F4F",
              borderRadius: "3.9px",
              paddingY: "8px",
              fontWeight: 500,
              fontSize: "16px",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "white",
              width: "150px",
              background: "#2260D9",
              borderRadius: "3.9px",
              textTransform: "capitalize",
              fontWeight: 500,
              fontSize: "16px",
            }}
            onClick={handleAddClick}
          >
            Add
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AgentListPopup;


