import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center bg-white p-4">
      <div className="text-center space-y-6">
        <h1 className="text-[rgb(34,96,217)] text-9xl font-bold">404</h1>
        <div className="space-y-2">
          <h2 className="text-2xl font-semibold text-[rgb(34,96,217)]">
            Page Not Found
          </h2>
          <p className="text-gray-300 max-w-md mx-auto pt-2">
            We couldn't find the page you're looking for. It might have been
            removed, renamed, or doesn't exist.
          </p>
        </div>
      </div>
    </div>
  );
}
