import React, { useState } from "react";
import MoreIcon from "../../assets/more-icon.svg";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import UserImage from "../../assets/images/user.png";
import { useNavigate, useParams } from "react-router";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import Crossimg from "../../assets/crossimg.svg";
import Checkicon from "../../assets/checkicon.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Crossicon from "../../assets/crossicon.svg";
import Deleteicon from "../../assets/deleteicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllAgentList, updateAgentStatus } from "../../services/agent";
import Checkimg from "../../assets/checkimg.svg";
import Unregistered from "../../assets/unregistered.svg";
import { useSearchParams } from "react-router-dom";


const AgentProfile = ({ agentInfo }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [cancelButtonActive, setCancelButtonActive] = useState(false);
  const [deactivateButtonActive, setDeactivateButtonActive] = useState(false);
  const [activateButtonActive, setActivateButtonActive] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dialogImage, setDialogImage] = useState("");
  const [comment, setComment] = useState("");
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [activateDialogOpen, setActivateDialogOpen] = useState(false); // New state for Activate dialog
  const [dialogMessage, setDialogMessage] = useState("");
  const [confirmAction, setConfirmAction] = useState({
    action: "",
    id: "",
  });
  const dispatch = useDispatch();
  const { id } = useParams;
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAction = (action, id) => {
    setConfirmAction({ action, id });
    if (action === "active") {
      setActivateDialogOpen(true);
    } else if (action === "delete") {
      handleDeleteDialogOpen();
    } else {
      setConfirmDialogOpen(true);
    }
    handleClose();
  };

  const handleConfirmAction = () => {
    // Validate comment for "deactivated" status
    if (confirmAction.action === "inactive" && !comment.trim()) {
      setDialogImage(Crossicon);
      setDialogMessage("Reason is required when status is 'deactivated'.");
      setSuccessDialogOpen(true);
      return;
    }

    let message = "";
    let imageSrc = "";

    switch (confirmAction.action) {
      case "inactive":
        message = `Agent successfully deactivated.`;
        imageSrc = Crossicon;
        break;
      case "active":
        message = `Agent successfully activated.`;
        imageSrc = Checkicon;
        break;
      case "delete":
        message = `Agent successfully deleted.`;
        imageSrc = Deleteicon;
        break;
      default:
        break;
    }

    dispatch(
      updateAgentStatus({
        agent_id: confirmAction.id,
        status: confirmAction.action,
        reason: comment.trim(), // Ensure reason is non-empty and trimmed
      })
    );

    // setTimeout(() => {
    //   dispatch(getAllAgentList());
    // }, 50);

    setConfirmAction({ action: "", id: "" });
    setDialogMessage(message);
    setDialogImage(imageSrc);
    setConfirmDialogOpen(false);
    setActivateDialogOpen(false);
    setSuccessDialogOpen(true);
    setDeleteDialogOpen(false);
    setComment("");
    handleClose();

    setTimeout(() => {
      setSuccessDialogOpen(false);
    }, 2000);
  };

  const handleDialogClose = () => setSuccessDialogOpen(false);
  const handleConfirmDialogClose = () => setConfirmDialogOpen(false);
  const handleActivateDialogClose = () => setActivateDialogOpen(false);
  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <>
      <div className="mb-20">
        <div className="flex items-center justify-between w-full">
          <h2 className="text-[22px] font-medium text-[#353F4F]">
            Agent Profile
          </h2>

          <div>
            <Menu
              as="div"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <div>
                <MenuButton
                  className="cursor-pointer relative inline-flex w-full justify-center py-2 px-4 border border-[#D9D9D9] rounded-md"
                  onClick={(e) => handleClick(e, agentInfo.id)}
                >
                  <img src={MoreIcon} alt="MoreIcon" />
                </MenuButton>

                <MenuItems
                  transition
                  className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div>
                    <MenuItem
                      as="div"
                      className=" cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-[#EEF4FF] hover:text-gray-900"
                      onClick={() =>
                        navigate(`/addnewagent?id=${agentInfo.id}`)
                      }
                    >
                      Edit
                    </MenuItem>
                    {agentInfo.status === "active" ? (
                      <div>
                        <MenuItem
                          as="div"
                          onClick={() => handleAction("inactive", agentInfo.id)}
                          className=" cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-[#EEF4FF] hover:text-gray-900"
                        >
                          Deactivate
                        </MenuItem>
                      </div>
                    ) : (
                      <div>
                        <MenuItem
                          as="div"
                          onClick={() => handleAction("active", agentInfo.id)}
                          // className="!text-[12px] text-green-600 hover:bg-[#D1FAE5] hover:text-green-800"
                        >
                          <p className=" cursor-pointer py-2 block !text-[12px] text-[#353F4F] data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                            Activate
                          </p>
                        </MenuItem>

                        <MenuItem
                          onClick={() => handleAction("delete", agentInfo.id)}
                          className="text-red-600 hover:bg-[#FEE2E2] hover:text-red-800"
                        >
                          <p className=" cursor-pointer py-2 block !text-[12px] text-[#353F4F] data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                            Delete
                          </p>
                        </MenuItem>
                      </div>
                    )}
                  </div>
                </MenuItems>
              </div>
            </Menu>
          </div>
        </div>
        <div className="flex flex-col gap-12">
          <div className="grid grid-cols-12 items-center py-5 px-6 mt-6 border border-[#D9D9D9] rounded-md">
            <div className="flex items-center w-full col-span-2">
              <img
                className="rounded-full h-36 w-36 p-2 border border-[#D9D9D9]"
                src={UserImage}
                alt="UserImage"
              />
            </div>
            <div className="col-span-7 pl-8">
              <p className="text-[34px] font-medium text-[#353F4F] capitalize">
                {" "}
                {`${agentInfo?.first_name} ${agentInfo?.last_name}`}
              </p>
              <p className="text-base font-medium text-[#353F4F]">Agent</p>
            </div>
            <div className="flex items-center justify-end w-full h-full col-span-3 pr-3 font-medium text-base">
              {status ? (
                <button className="h-10 rounded-[30px] py-2 px-8 gap-2 flex items-center">
                  <img src={Unregistered} alt="" />
                  <p className="text-[#2260D9]">Unregistered</p>
                </button>
              ) : agentInfo?.status === "active" ? (
                <button className="h-10 rounded-[30px] py-2 px-8 border border-[#35A462] text-[#35A462] bg-[#EDFFF4]">
                  Active
                </button>
              ) : agentInfo?.status === "inactive" ? (
                <button className="h-10 rounded-[30px] py-2 px-8 border border-[#DC3545] text-[#DC3545] bg-[#FFF3F4]">
                  Inactive
                </button>
              ) : null}
              <div></div>
            </div>
          </div>

          <div className="flex flex-col w-full px-6 pt-5 pb-10 border border-[#D9D9D9] rounded-md">
            <div className="text-[22px] font-medium text-[#353F4F]">
              Personal Information
            </div>
            <div className="grid grid-cols-12 gap-8 mt-8 text-base">
              <div className="col-span-3">
                <div className=" font-normal text-[#6C6C6C]">First Name</div>
                <div className=" font-semibold text-[#353F4F] capitalize">
                  {agentInfo?.first_name}
                </div>
              </div>
              <div className="col-span-9">
                <div className=" font-normal text-[#6C6C6C]">Last Name</div>
                <div className=" font-semibold text-[#353F4F] capitalize">
                  {agentInfo?.last_name}
                </div>
              </div>
              <div className="col-span-3">
                <div className=" font-normal text-[#6C6C6C]">Email</div>
                <div className="text-base font-semibold text-[#2260D9]">
                  {agentInfo?.email}
                </div>
              </div>
              <div className="col-span-3">
                <div className=" font-normal text-[#6C6C6C]">Phone</div>
                <div className=" font-semibold text-[#353F4F]">
                  {agentInfo?.contact_number}
                </div>
              </div>
              <div className="col-span-6">
                <div className="font-normal text-[#353F4F]">
                  Secondary Contact Number
                </div>
                <div className="font-semibold text-[#353F4F]">
                  {agentInfo?.alternative_number}
                </div>
              </div>
              <div className="col-span-3">
                <div className="font-normal text-[#6C6C6C]">Bio</div>
                <div className="font-semibold text-[#353F4F]">ABCD agent</div>
              </div>
              <div className="col-span-9">
                <div className=" font-normal text-[#6C6C6C]">Description</div>
                <div className=" font-semibold text-[#353F4F]">
                  The purpose of lorem ipsum is to create a natural looking
                  block of text (sentence, paragraph, page, etc.)
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full px-6 pt-5 pb-10 border border-[#D9D9D9] rounded-md">
            <div className="text-[22px] font-medium text-[#353F4F]">
              Address
            </div>
            <div className="grid grid-cols-12 gap-8 mt-8 text-base">
              <div className="col-span-3">
                <div className="font-normal text-[#6C6C6C]">Country</div>
                <div className="font-semibold text-[#353F4F]">
                  {agentInfo?.country}
                </div>
              </div>
              <div className="col-span-9">
                <div className="font-normal text-[#6C6C6C]">City/State</div>
                <div className="font-semibold text-[#353F4F]">
                  {agentInfo?.city},{agentInfo?.state}
                </div>
              </div>
              <div className="col-span-3">
                <div className="font-normal text-[#6C6C6C]">Postal Code</div>
                <div className="font-semibold text-[#353F4F]">
                  {agentInfo?.postal_code}
                </div>
              </div>
              <div className="col-span-9">
                <div className="font-normal text-[#6C6C6C]">Phone</div>
                <div className="font-semibold text-[#353F4F]">
                  {agentInfo?.alternative_number}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full px-6 pt-5 pb-10 border border-[#D9D9D9] rounded-md">
            <div className="text-[22px] font-medium text-[#353F4F]">
              Organization Contact Information
            </div>
            <div className="grid grid-cols-12 gap-8 mt-8 text-base">
              <div className="col-span-3">
                <div className="font-normal text-[#6C6C6C]">First Name</div>
                <div className="font-semibold text-[#353F4F]">
                  {agentInfo?.organization?.first_name}
                </div>
              </div>
              <div className="col-span-9">
                <div className="font-normal text-[#6C6C6C]">Last Name</div>
                <div className="font-semibold text-[#353F4F]">
                  {agentInfo?.organization?.last_name}
                </div>
              </div>
              <div className="col-span-3">
                <div className="font-normal text-[#6C6C6C]">Email</div>
                <div className="font-semibold text-[#353F4F]">
                  {agentInfo?.organization?.email}
                </div>
              </div>
              <div className="col-span-9">
                <div className="font-normal text-[#353F4F]">
                  Primary Contact Number
                </div>
                <div className="font-semibold text-[#353F4F]">
                  {agentInfo?.organization?.org_phone_number}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={successDialogOpen}
        onClose={handleDialogClose} // Close on outside click
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{ width: "450px", height: "180px", borderRadius: "10px" }}
        >
          <img
            src={dialogImage}
            alt="Success"
            style={{ display: "block", margin: "0 auto" }}
          />
          <Typography variant="h6" align="center" style={{ marginTop: "20px" }}>
            {dialogMessage}
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        className="flex items-center justify-center max-w-none rounded-xl"
      >
        <div className="bg-white  shadow-lg w-[600px] sm:max-w-sm md:max-w-md lg:max-w-xl xl:max-w-2xl px-4 sm:px-8 md:px-16 lg:px-20 py-6 sm:py-8 md:py-10 relative">
          <div className="flex items-center justify-center mb-4">
            <img src={Checkimg} alt="Check" className="mx-2" />
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              --
              <ArrowForwardIcon />
              --
            </Typography>
            <img src={Crossicon} alt="Cross" className="mx-2" />
          </div>

          <DialogContent className="mb-4 text-center">
            <Typography className="text-[#353F4F] text-base font-medium">
              Are you sure you want to change the status?
            </Typography>
          </DialogContent>
          <div className="mb-4">
            <label
              htmlFor="Comments"
              className="text-[#353F4F] font-medium flex items-center"
            >
              Comments <span className="ml-1 text-red-600">*</span>
            </label>
            <textarea
              id="Comment"
              name="Comment"
              rows={6}
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-[#D6E5EE] placeholder:text-gray-400 focus:outline-2 focus:outline-indigo-600 sm:text-sm"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter your reason for changing the status"
              required
            />
          </div>

          <div className="flex justify-center gap-4">
            <button
              onClick={handleConfirmDialogClose}
              className="text-[#353F4F] px-6 py-3 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: cancelButtonActive ? "#DC3545" : "white",
                color: cancelButtonActive ? "white" : "#353F4F",
                border: cancelButtonActive ? "none" : "1px solid #18283D80",
              }}
              onMouseDown={() => setCancelButtonActive(true)}
              onMouseUp={() => setCancelButtonActive(false)}
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmAction}
              className="text-[#353F4F] px-6 py-3 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: "#DC3545",
                color: "white",
                border: "none",
              }}
              onMouseDown={() => setDeactivateButtonActive(true)}
              onMouseUp={() => setDeactivateButtonActive(false)}
            >
              Deactivate
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={activateDialogOpen}
        onClose={handleActivateDialogClose}
        className="flex items-center justify-center overflow-hidden max-w-none rounded-xl"
      >
        <div className="bg-white shadow-lg w-[600px] overflow-hidden sm:max-w-sm md:max-w-md lg:max-w-xl xl:max-w-2xl px-4 sm:px-8 md:px-16 lg:px-20 py-6 sm:py-8 md:py-10 relative">
          <div className="flex items-center justify-center mb-4">
            <img src={Crossimg} alt="Cross" className="mx-2 " />

            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              --
              <ArrowForwardIcon />
              --
            </Typography>
            <img src={Checkicon} alt="Check" className="mx-2 " />
          </div>
          <DialogContent className="mb-4 text-center">
            <Typography className="text-[#353F4F] text-base font-medium">
              Are you sure you want to change the status?
            </Typography>
          </DialogContent>
          <div className="flex justify-center">
            <button
              onClick={handleActivateDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: cancelButtonActive ? "#278E1D" : "white",
                color: cancelButtonActive ? "white" : "#353F4F",
                border: cancelButtonActive ? "none" : "1px solid #18283D80",
              }}
              onMouseDown={() => setCancelButtonActive(true)}
              onMouseUp={() => setCancelButtonActive(false)}
            >
              Cancel
            </button>

            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#28A745] hover:text-white hover:border-none"
              style={{
                backgroundColor: "#278E1D",
                color: "white",
                border: "none",
              }}
              onMouseDown={() => setActivateButtonActive(true)}
              onMouseUp={() => setActivateButtonActive(false)}
            >
              Activate
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        className="flex items-center justify-center"
      >
        <div className="relative w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-center mb-4">
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              <img src={Deleteicon} alt="" />
            </Typography>
          </div>

          <DialogContent className="mb-4">
            <Typography className="text-[#353F4F] text-base text-center">
              Are you sure want to delete this Agent?
            </Typography>
          </DialogContent>

          <div className="flex justify-center">
            <button
              onClick={handleDeleteDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white"
              style={{
                border: "1px solid #18283D80",
              }}
            >
              No
            </button>
            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white"
              style={{
                border: "1px solid #18283D80",
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AgentProfile;
