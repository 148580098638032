import { createSlice } from "@reduxjs/toolkit";
import {
  createNewRequest,
  getAllCurrentPartnerRequestList,
  getAllCurrentRequestList,
  getAllPartnerRequestList,
  getCaseById,
  getPantnerRequestDetail,
  markImportant,
} from "../../services/request";

const initialState = {
  data: [],
  details: {},
  partners : [],
  requestDetail: {},
  loading: false,
  status: "",
  error: "",
  caseDetails: []
};

export const requestSlice = createSlice({
  name: "requestInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNewRequest.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(createNewRequest.fulfilled, (state, action) => {
        return {
          ...state,
          details: action.payload,
          // status: categoryApiResponse.details,
          loading: false,
        };
      })
      .addCase(createNewRequest.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });
    
    
    builder
      .addCase(getAllCurrentRequestList.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllCurrentRequestList.fulfilled, (state, action) => {
        return {
          ...state,
          data: action.payload,
          // status: categoryApiResponse.details,
          loading: false,
        };
      })
      .addCase(getAllCurrentRequestList.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

      builder
      .addCase(getAllCurrentPartnerRequestList.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllCurrentPartnerRequestList.fulfilled, (state, action) => {
        return {
          ...state,
          partners: action.payload,
          // status: categoryApiResponse.details,
          loading: false,
        };
      })
      .addCase(getAllCurrentPartnerRequestList.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

    builder
      .addCase(getPantnerRequestDetail.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getPantnerRequestDetail.fulfilled, (state, action) => {
        return {
          ...state,
          requestDetail: action.payload,
          loading: false,
        };
      })
      .addCase(getPantnerRequestDetail.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

    builder
      .addCase(getAllPartnerRequestList.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllPartnerRequestList.fulfilled, (state, action) => {
        return {
          ...state,
          data: action.payload,
          // status: categoryApiResponse.details,
          loading: false,
        };
      })
      .addCase(getAllPartnerRequestList.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });
      builder
      .addCase(markImportant.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(markImportant.fulfilled, (state, action) => {
        return {
          ...state,
          data: action.payload.results,
          loading: false,
        };
      })
      .addCase(markImportant.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

      builder
      .addCase(getCaseById.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getCaseById.fulfilled, (state, action) => {
        return {
          ...state,
          caseDetails: action.payload,
          loading: false,
        };
      })
      .addCase(getCaseById.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });
  },
});

export default requestSlice.reducer;
