import React, { useState } from "react";
// import { CategoryColumn } from '../../utills/tableFields'
import DataTable from "../../shared/DataGrid";
import OptIcon from "../../assets/opt.svg";
import MoreIcon from "../../assets/more-icon.svg";
import { useSelector } from "react-redux";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { useNavigate } from "react-router";
import AddPartnersInCategories from "../Popups/AddPartnersInCategories";

export default function AgentCategory() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const { data } = useSelector((state) => state.categoryInfo.data);
  const [openPopup, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(data);
  };

  const CategoriesColumn = [
    {
      field: "id",
      headerName: "Program ID",
      flex: 1,
      id: 0,
    },
    {
      field: "name",
      headerName: "Program",
      flex: 2,
      id: 1,
      renderCell: (params) => {
        return (
          <div className="capitalize">
            {params.row.name}
          </div>
        );
      },
    },
    {
      field: "category_type",
      headerName: "Program Type",
      flex: 1,
      id: 2,
      renderHeader: () => (
        <div className="flex items-center gap-[5px]">
          <p className="font-sans text-[#18283D] font-semibold">Program Type</p>
          <img src={OptIcon} alt="opt" />
        </div>
      ),
      renderCell: (params) => {
        return (
          <div className="capitalize">
            {params.row.category_type}
          </div>
        );
      },
    },
    {
      field: "partner_count",
      headerName: "Total Partners",
      headerAlign: "center",
      align: "center",
      flex: 1,
      id: 3,
    },
    {
      field: "request_count",
      headerName: "Total Managed Request",
      headerAlign: "center",
      align: "center",
      flex: 1,
      id: 4,
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "center",
      flex: 1,
      id: 4,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      id: 4,
      renderCell: (params) => {
        return (
          <>
            <Menu as="div" className="inline-block mt-2 text-left">
              <div>
                <MenuButton className="cursor-pointer relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900">
                  <img
                    src={MoreIcon}
                    alt="MoreIcon"
                    onClick={(e) => handleClick(e, params.row)}
                  />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem
                    onClick={() => {
                      navigate(`/externalcategory/${params.row.id}`);
                    }}
                  >
                    <div className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                      View
                    </div>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpen(params.id);
                    }}
                  >
                    <div className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                      Assign
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </>
        );
      },
    },
  ];
  return (
    <>
      <DataTable
        rows={data || []}
        columns={CategoriesColumn}
        hideCheckbox
        hideFooter
      />
      <AddPartnersInCategories
        open={openPopup}
        setOpen={setOpen}
        id={openPopup}
        fromRequest={false}
        setId={setOpen}
      />
    </>
  );
}
