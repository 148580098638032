import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { frequentlySearchCategory } from "../../services/consumerCategory";
export default function ConsumerCategory() {
  const navigate = useNavigate();
  const { data, loading } = useSelector((state) => state.categoryInfo.data);
  const dispatch = useDispatch();

  const requestData = useSelector(
    (state) => state.consumerCategoryInfo.frequentlySearchCategory.data
  );

  useEffect(() => {
    dispatch(frequentlySearchCategory(9));
  }, [dispatch]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div class="grid grid-cols-4 gap-4 capitalize">
        {Array.from(
          { length: Math.ceil((data || []).length / 7) },
          (_, index) => {
            const start = index * 7;
            const end = start + 7;
            const chunk = data.slice(start, end);

            return (
              <div
                key={index}
                style={{
                  border: "1px solid rgba(34, 96, 217, 1)",
                  borderRadius: "3px",
                  marginBottom: "1rem",
                }}
                className="py-4 pl-6"
              >
                <ul className="category-list">
                  {chunk.map((category) => (
                    <li
                      key={category.id}
                      onClick={() => navigate(`/category/${category.id}`)}
                    >
                      {category.name}
                    </li>
                  ))}
                </ul>
              </div>
            );
          }
        )}
      </div>

      <div className="my-5">
        <div
          className="flex items-center justify-between py-3 title"
          style={{ borderBottom: "1px solid #2260D9" }}
        >
          <div className="flex">
            <h4 className="text-[18px]">Recent Program</h4>
          </div>
        </div>
        <div
          className="grid gap-6 px-4 py-7"
          style={{
            gridTemplateColumns: "repeat(9, minmax(100px, 1fr))",
          }}
        >
          {requestData?.map((freqCat) => {
            return (
              <div className="flex flex-col items-center gap-3 capitalize">
                <div
                  style={{
                    borderRadius: "5px",
                    padding: "25px 15px",
                    textAlign: "center",
                    width: "100px",
                    height: "100px",
                  }}
                  className="flex flex-col gap-4 cursor-pointer border border-[#2260D9]"
                  key={freqCat.key}
                >
                  <img
                    src={`https://crrmsbackend.dataterrain-dev.net${freqCat.image}`}
                    className="h-9"
                    alt="DonationIcon"
                  />
                </div>
                <p className="text-center">{freqCat.name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
