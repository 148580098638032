import React, { useEffect, useState } from "react";
import { Modal, Box, Tabs, Tab, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getAllAgentList } from "../../services/agent";
import { getAllPartnerList, getPartnerList } from "../../services/partner";
import { useDispatch, useSelector } from "react-redux";
import RightIcon from "../../assets/check-icon.svg";
import BlockIcon from "../../assets/block-icon.svg";
import SilverBadge from "../../assets/silver-badge.svg";
import GoldenBadge from "../../assets/golden-badge.svg";
import BronzeBadge from "../../assets/bronze-badge.svg";
import OptIcon from "../../assets/opt.svg";
import CloseIcon from "../../assets/close-red-icon.svg";
import { useNavigate } from "react-router";
import NoBadge from "../../assets/nobadge.svg";
const SelectMemberPopup = ({
  setOpenAgent,
  openAgent,
  selected,
  setSelected,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    setSelected([]);
    setOpenAgent(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const agentInfo = useSelector((state) => state.agentInfo.details.data);
  // console.log("aInfo", agentInfo);
  const partnerInfo = useSelector((state) => state.partnerInfo.partnerData);
  // console.log("pInfo", partnerInfo);

  useEffect(() => {
    dispatch(getAllAgentList());
  }, []);

  useEffect(() => {
    dispatch(getAllPartnerList({}));
  }, []);

  const onChangeHandle = (isChecked, parterId) => {
    if (isChecked) {
      setSelected([...selected, parterId]);
    } else {
      const updatedIds = selected.filter((id) => id !== parterId);
      setSelected(updatedIds);
    }
  };

  const columns = [
    {
      field: "partners",
      headerName: "",
      maxWidth: 80,
      id: 4,
      renderCell: (params) => {
        return (
          <>
            <div className="flex items-center justify-center h-full">
              <input
                className="rounded-sm w-4 h-4 border-[#6F6F6F] text-[#607274] focus:ring-[#C6D8DA]"
                type="checkbox"
                // checked={(e) => onChangeHandle(e.target.checked, params?.id)}
                onChange={(e) =>
                  onChangeHandle(e.target.checked, params?.row?.email)
                }
              />
            </div>
          </>
        );
      },
    },
    { field: "first_name", headerName: "Agent Name", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "contact_number", headerName: "Contact", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <span className="text-green-500">
          {params.row.status === "active" ? "✓ Active" : "Inactive"}
        </span>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <button
          onClick={() => navigate(`/view-agent/${params?.row?.id}`)}
          className="text-[#2260D9] underline underline-offset-2"
        >
          View Profile
        </button>
      ),
      sortable: false,
    },
  ];

  const PartnerColumn = [
    {
      field: "partners",
      headerName: "",
      maxWidth: 80,
      id: 4,
      renderCell: (params) => {
        return (
          <>
            <div className="flex items-center justify-center h-full">
              <input
                className="rounded-sm w-4 h-4 border-[#6F6F6F] text-[#607274] focus:ring-[#C6D8DA]"
                type="checkbox"
                // checked={(e) => onChangeHandle(e.target.checked, params?.id)}
                onChange={(e) =>
                  onChangeHandle(e.target.checked, params?.row?.email)
                }
              />
            </div>
          </>
        );
      },
    },
    {
      field: "organization_name",
      headerName: "Organization Name",
      flex: 1,
    },
    {
      field: "badge",
      headerName: "Badge",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const badgeIcons = {
          Silver: SilverBadge,
          Gold: GoldenBadge,
          Bronze: BronzeBadge,
          // "No Badge":NoBadge
        };

        const badge = params.value;
        const badgeIcon = badgeIcons[badge];

        return (
          <div className="flex items-center justify-center h-full">
            {badgeIcon ? (
              <img src={badgeIcon} alt={`${badge} badge`} className="w-6 h-6" />
            ) : (
              <span>{badge}</span>
            )}
          </div>
        );
      },
    },

    {
      field: "partner_type",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-[5px]">
          <p className="font-sans text-[#18283D] font-semibold">Type</p>
          <img src={OptIcon} alt="opt" />
        </div>
      ),
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        const statusStyles = {
          active: "text-[#29B473]",
          inactive: "text-[#D22735]",
        };
        const status = params.row.status;
        const { color } = statusStyles[status] || { color: "#6C757D" };
        return (
          <div
            className={`flex items-center gap-2 ${statusStyles[params.value]}`}
          >
            {status === "active" ? (
              <img src={RightIcon} />
            ) : (
              <img src={BlockIcon} />
            )}
            <span className="capitalize">{status}</span>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <button
          onClick={() => {
            navigate(`/partner/${params?.row?.id}`);
          }}
          className="text-[#2260D9] underline underline-offset-2"
        >
          View Profile
        </button>
      ),
      sortable: false,
    },
  ];

  const dataGridRows = tabIndex === 0 ? agentInfo || [] : partnerInfo || [];
  const dataGridColumns = tabIndex === 0 ? columns : PartnerColumn;

  return (
    <Modal
      open={openAgent}
      onClose={handleClose}
      className="flex items-center justify-center"
    >
      <Box className="bg-white rounded-lg shadow-lg w-[800px] p-4 py-7">
        <div className="flex items-center justify-between border-b border-[#D3D3D3] pb-3 mb-4 px-4">
          <h2 className="text-[#353F4F] text-lg font-semibold">
            Select Member
          </h2>
          <img
            src={CloseIcon}
            onClick={handleClose}
            className="cursor-pointer"
          />
        </div>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          className="mb-4 text-[#2260D9]"
        >
          <Tab label="Agent" />
          <Tab label="Partner" />
        </Tabs>

        <div className="h-[400px] mb-4">
          <DataGrid
            rows={dataGridRows}
            columns={dataGridColumns}
            hideCheckbox
            pageSize={5}
            rowsPerPageOptions={[5]}
            hideFooter
          />
        </div>

        {/* Footer */}
        <div className="flex justify-center space-x-7">
          <Button
            variant="outlined"
            sx={{
              textTransform: "capitalize",
              width: "150px",
              color: "#353F4F",
              border: "1px solid #353F4F",
              borderRadius: "3.9px",
              paddingY: "8px",
              fontWeight: 500,
              fontSize: "16px",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "white",
              width: "150px",
              background: "#2260D9",
              borderRadius: "3.9px",
              textTransform: "capitalize",
              fontWeight: 500,
              fontSize: "16px",
            }}
            onClick={() => setOpenAgent(false)}
          >
            Add
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default SelectMemberPopup;
