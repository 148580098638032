import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "./api";

export const getEmployeesDetails = createAsyncThunk(
  "getEmployeeDetails",
  async (id) => {
    const getAllEmployeeInfo = await api.get(`/agent/employee/?partner=${id}`);
    if (getAllEmployeeInfo.status === 200 && getAllEmployeeInfo.data) {
      const result = getAllEmployeeInfo.data.result;
      return result;
    }
    return getAllEmployeeInfo;
  }
);

export const createEmployee = createAsyncThunk(
  "createEmployee",
  async (employeeData, { rejectWithValue }) => {
    try {
      const response = await api.post(`/agent/employee/`, employeeData);
      if (response.status === 200 && response.data) {
        return response.data;
      }
      throw new Error("Failed to create agent");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateEmployee = createAsyncThunk(
  "updateEmployee",
  async (employeeData, { rejectWithValue }) => {
    try {
      const response = await api.put(`/agent/employee/`, employeeData);
      if (response.status === 200 && response.data) {
        return response.data;
      }
      throw new Error("Failed to Update agent");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteEmployee = createAsyncThunk(
    "deleteEmployee",
    async ({ employee_id, is_deleted }, { rejectWithValue }) => {
      try {
        const response = await api.put(`/agent/employee/`, { employee_id, is_deleted });
  
        if (response.status === 200 && response.data) {
          return response.data;
        }
  
        throw new Error("Failed to delete employee");
      } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  );
