import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import CallIcon from "../../../assets/request-phone-icon.svg";
import BreadCrumIcon from "../../../assets/breadcrumb-right-icon.svg";
import { Button } from "../../../shared/Button";
import { getSingleRequest } from "../../../services/currentRequest";
import { useDispatch, useSelector } from "react-redux";
import CancelRequest from "../AllRequest/CancelRequest";
import moment from "moment";
import DollarIcon from "../../../assets/dollarsymbol.svg";
import ThreeDotMenu from "../AllRequest/ThreeDotMenu";
import DownloadInvoice from "../../../assets/downloadinvoice.svg";
import AddRequest from "../../Popups/AddRequest";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { updateStatusByRequestId } from "../../../services/partner";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import AcceptRequest from "../AllRequest/AcceptRequest";
import MuiModal from "../../../shared/Modal";
import SuccessIcon from "../../../assets/BlueSuccessIcon.svg";
import { loadStripe } from "@stripe/stripe-js";
import StatusDialog from "../AllRequest/StatusDialog";
import EditRequestModal from "../AllRequest/EditRequestModal";
export default function ViewRequest() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const details = useSelector(
    (state) => state.currentRequest.details?.data?.[0]
  );
  const [openPopup, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [isAcceptDialog, setIsAcceptDialog] = useState(false);
  const [showSuccess, setShowSuccess] = useState({ model: false, status: "" });
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [detailAction, setDetailAction] = useState({
    cancelModal: false,
    id: "",
  });
  const { userType } = useSelector((state) => state.userInfo);
  const userRole = useSelector(
    (state) => state.requestInfo?.caseDetails.result?.[1]?.user_role
  );
  console.log("role", userRole);
  const [open, setUpdateOpen] = React.useState(false);
  const [isUpdateStatusDialog, setIsUpdateStatusDialog] = useState(false);
  const [isEditRequestOpen, setIsEditRequestOpen] = useState(false);
  const [selectedEditRequestId, setSelectedEditRequestId] = useState(null);
  const handleOpen = () => {
    setUpdateOpen(true);
  };

  const handleClose = () => {
    setUpdateOpen(false);
  };
  useEffect(() => {
    if (id) {
      dispatch(getSingleRequest(id));
    }
  }, [dispatch, id]);
  const closeModal = () => {
    setDetailAction({ cancelModal: false, id: "" });
    // setShowSuccess({ model: true, status: "rejected" });

    setTimeout(() => {
      setShowSuccess({ model: false, status: "" });
    }, 2000);
  };

  const handleSelectStatus = async ({ id, status }) => {
    if (status.id === 2) {
      setIsAcceptDialog(true);
    } else if (status.id === 3) {
      //resolve status
      const response = await dispatch(
        updateStatusByRequestId({ request_id: +id, partner_status: status.key })
      ).unwrap();
      if (response.status === "success") {
        setShowSuccess({ model: true, status: status.key });
        setTimeout(() => {
          setShowSuccess({ model: false, status: "" });
          navigate(`/request-tracking?id=${id}&status=${status.key}`);
        }, 2000);
      }
    } else if (status.id === 4) {
      // on hold status
      const response = await dispatch(
        updateStatusByRequestId({ request_id: +id, partner_status: status.key })
      ).unwrap();
      if (response.status === "success") {
        setShowSuccess({ model: true, status: status.key });
        setTimeout(() => {
          setShowSuccess({ model: false, status: "" });
          navigate(`/request-tracking?id=${id}&status=${status.key}`);
        }, 2000);
      }
    } else if (status.id === 5) {
      // reject status
      setDetailAction({
        cancelModal: true,
        id: details.id,
        reject: true,
      });
    }
  };

  const statusOptions = {
    new: [
      { id: 0, name: "Waiting", key: "waiting" },
      { id: 2, name: "Accept", key: "accepted" },
      { id: 4, name: "On Hold", key: "on hold" },
      { id: 5, name: "Reject", key: "rejected" },
    ],
    accepted: [
      { id: 2, name: "Accept", key: "accepted" },
      { id: 3, name: "Resolve", key: "resolve" },
      { id: 4, name: "On Hold", key: "on hold" },
      { id: 5, name: "Reject", key: "rejected" },
    ],
    "in progress": [
      { id: 3, name: "Resolve", key: "resolve" },
      { id: 4, name: "On Hold", key: "on hold" },
      { id: 5, name: "Reject", key: "rejected" },
    ],
    "on hold": [
      { id: 4, name: "On Hold", key: "on hold" },
      { id: 2, name: "Accept", key: "accepted" },
      { id: 5, name: "Reject", key: "rejected" },
    ],
    completed: [],
    rejected: [],
    "in review": [
      { id: 0, name: "Waiting", key: "waiting" },
      { id: 2, name: "Accept", key: "accepted" },
      { id: 4, name: "On Hold", key: "on hold" },
      { id: 5, name: "Reject", key: "rejected" },
    ],
  };

  useEffect(() => {
    if (details?.request_status === "new") {
      setSelected(statusOptions.new[0]);
    } else if (details?.request_status === "accepted") {
      // setSelected(
      setSelected(statusOptions.accepted[0]);
      //   statusOptions.accepted.find((status) => status.name === "Accept")
      // );
    } else if (details?.request_status === "on hold") {
      setSelected(statusOptions["on hold"][0]);
    }
  }, [details?.request_status]);

  const filteredStatusOptions = statusOptions[details?.request_status]?.filter(
    (option) => option.name !== "In Progress" && option.name !== "Rejected"
  );

  const handleAcceptRequest = async () => {
    // const status = userType === "partner" ? "completed" : "accepted";
    const status = "accepted";
    try {
      const response = await dispatch(
        updateStatusByRequestId({
          request_id: +selectedRequest.id,
          partner_status: status,
        })
      ).unwrap();

      if (response.status === "success") {
        setIsAcceptDialog(false);
        setShowSuccess({ model: true, status });
        setTimeout(() => {
          setShowSuccess({ model: false, status: "" });
          navigate(
            `/request-tracking?id=${+selectedRequest.id}&status=${status}`
          );
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to update request status:", error);
    }
  };

  const menuOptions = [
    ...(details?.request_status === "new" && userType === "consumer"
      ? [
          {
            key: "edit",
            name: "Edit Request",
            onClick: () => {
              setSelectedEditRequestId(details.id); // Set the selected request ID
              setIsEditRequestOpen(true); // Open the Edit Request modal
            },
          },
        ]
      : []),
    ...(details?.request_status === "completed" ||
    (details?.request_status === "rejected" &&
      userType === "consumer" &&
      details?.request_status !== "new")
      ? [
          {
            key: "requestAgain",
            name: "Request Again",
            onClick: () => navigate(`/create-request?id=${details?.id}`),
          },
        ]
      : []),
    ...(details?.request_status === "new" &&
    (userType === "admin" || userType === "agent") &&
    details?.partners?.length === 0
      ? [
          {
            key: "assignpartner",
            name: "Assign Request",
            onClick: () => setOpen(details.id),
          },
        ]
      : []),
    ...(details?.request_status === "rejected" &&
    details?.request_status !== "completed" &&
    (userType === "admin" || userType === "agent")
      ? [
          {
            key: "reassignpartner",
            name: "Reassign",
            onClick: () => setOpen(details.id),
          },
        ]
      : []),
    {
      key: "trackRequest",
      name: "Track Request",
      onClick: () =>
        navigate(
          `/request-tracking?id=${details?.id}&status=${details?.request_status}`
        ),
    },
    {
      key: "history",
      name: "History",
      onClick: () => navigate(`/request-history/${details?.id}`),
    },

    ...(details?.request_status !== "rejected" &&
    details?.request_status !== "completed" &&
    details?.request_status !== "resolved"
      ? [
          {
            key: "rejectRequest",
            name: "Cancel Request",
            onClick: () =>
              setDetailAction({
                ...detailAction,
                cancelModal: true,
                id,
              }),
          },
        ]
      : []),
  ];

  const [loading, setLoading] = useState(false);
  // Initialize Stripe with the public key
  const stripePromise = loadStripe(
    "pk_test_51QWWMODbesCIeDQDwiq4XaO71Xn1FsEZILhcY6UCPF8R29BZsoWU8L0ShJQHxb1BHRymn8xJOu12dp2fZvAoZ1Na000FcYityk"
  );
  const handlePayment = async () => {
    try {
      setLoading(true);

      // Replace with your actual API endpoint
      const response = await fetch(
        "https://crrmsbackend.dataterrain-dev.net/api/agent/create-checkout-session/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any authentication headers if required
            // 'Authorization': `Bearer ${your_auth_token}`
          },
          body: JSON.stringify({
            request_id: details.id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (!data.sessionId) {
        throw new Error("No session ID received from the server");
      }

      // Initialize Stripe
      const stripe = await stripePromise;

      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });

      if (error) {
        console.error("Stripe redirect error:", error);
        throw error;
      }
    } catch (err) {
      console.error("Payment error:", err);
      // You might want to show an error message to the user
      alert("Payment initialization failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="px-8 mt-10">
      <div className="mb-6">
        <nav class="flex" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            <li class="inline-flex items-center">
              <span
                class="inline-flex items-center text-sm text-gray-700 hover:text-blue-600 dark:text-gray-400 cursor-pointer dark:hover:text-white"
                onClick={() => navigate("/")}
              >
                Dashboard
              </span>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <img src={BreadCrumIcon} alt="BreadCrumIcon" />
                <span class="ms-1 text-sm  md:ms-2 text-primary-blue">
                  View Request Detail
                </span>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div
        style={{
          border: "1px solid rgba(228, 237, 255, 1)",
          marginTop: "30px",
        }}
        className="px-10 pt-5"
      >
        <div>
          <div className="flex justify-between mt-8">
            <div className="flex items-center justify-center gap-10">
              <div
                className="px-4 py-4 bg-white"
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.15)",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
                }}
              >
                <img src={CallIcon} alt="CallIcon" />
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-8">
                  <h3 className="text-primary-black text-[40px] font-semibold flex flex-col capitalize">
                    {details?.category_name} Request
                  </h3>
                  {/* {details?.category_is_premium === "True" ? (
                    <h4 className="border-[#D1A639] text-primary-black flex gap-3 px-10 text-[14px] py-2 font-medium border rounded-[18px]">
                      <img src={DollarIcon} alt="" />
                      {details.category_per_cost}
                    </h4>
                  ) : null} */}
                  <div
                    style={{
                      border: `1px solid ${
                        {
                          "in progress": "#FFCD11",
                          accepted: "#278E1D",
                          wait: "#1D5BBF",
                          completed: "#35A462",
                          resolved: "#278E1D",
                          rejected: "#DC3545",
                          "on hold": "#E26313",
                          new: "#2260D9",
                          "in review": "#FF6711",
                        }[details?.request_status] || "#CCCCCC"
                      }`,
                      borderRadius: "18px",
                      backgroundColor: `${
                        {
                          "in progress": "#FFF4D4",
                          accepted: "#EAF9EA",
                          completed: "#EDFFF4",
                          resolved: "#EDFFF4",
                          wait: "#DCE8FF",
                          rejected: "#FDE7E9",
                          "on hold": "#FDEEE5",
                          new: "#F4F8FF",
                          "in review": "#FFF8F4",
                        }[details?.request_status] || "#FFFFFF"
                      }`,
                      color: `${
                        {
                          "in progress": "#FFCD11",
                          accepted: "#278E1D",
                          wait: "#1D5BBF",
                          completed: "#35A462",
                          resolved: "#278E1D",
                          rejected: "#DC3545",
                          "on hold": "#E26313",
                          new: "#2260D9",
                          "in review": "#FF6711",
                        }[details?.request_status] || "#000000"
                      }`,
                    }}
                    className="px-10 text-[14px] py-2 bg-light-yellow text-yellow capitalize"
                  >
                    {details?.request_status === "in review" &&
                    userType === "partner"
                      ? "new"
                      : details?.request_status}
                  </div>
                </div>
                {details?.category_is_premium === "True" ? (
                  <h4 className="text-[#D1A639] text-[12px] font-medium">
                    $ {details.category_per_cost}
                  </h4>
                ) : null}
                <div className="request-info flex gap-4 text-[12px] mt-2 ">
                  <p className="pr-5 border-r border-black">
                    Request ID :{" "}
                    <span className="underline text-primary-blue">
                      #{details?.id}
                    </span>
                  </p>
                  <p className="">
                    Request Date & Time :{" "}
                    <span className="text-[#2260D9] underline underline-offset-2">
                      {details?.updated_at
                        ? moment(details?.created_at).format(
                            "MM/DD/YYYY | h:mm A"
                          )
                        : null}
                    </span>
                  </p>
                  {/* {details?.request_status === "new" &&
                    userType !== "consumer" && (
                      <div className="pl-2 border-l border-black text-[#353F4F]">
                        Submitted date & Time :{" "}
                        <span className="text-[#2260D9] underline underline-offset-2">
                          {details?.updated_at
                            ? moment(details.updated_at).format(
                                "MM/DD/YYYY | h:mm A"
                              )
                            : null}
                        </span>
                      </div>
                    )} */}
                  {details?.request_status === "in progress" &&
                  userType === "partner" ? (
                    <div className="pl-4 border-l border-black text-[#353F4F]">
                      Accepted date & Time :{" "}
                      <span className="text-[#2260D9] underline underline-offset-2">
                        {details?.created_at
                          ? moment(details.created_at).format(
                              "MM/DD/YYYY | h:mm A"
                            )
                          : null}
                      </span>
                    </div>
                  ) : (
                    (details?.request_status === "in progress" ||
                      details?.request_status === "on hold" ||
                      details?.request_status === "in review") && (
                      <div className="pl-4 border-l border-black text-[#353F4F]">
                        Last Updated date & Time :{" "}
                        <span className="text-[#2260D9] underline underline-offset-2">
                          {details?.updated_at
                            ? moment(details.updated_at).format(
                                "MM/DD/YYYY | h:mm A"
                              )
                            : null}
                        </span>
                      </div>
                    )
                  )}
                  {details?.request_status === "completed" && (
                    <div className="pl-2 border-l border-black text-[#353F4F]">
                      Completed date & Time :{" "}
                      <span className="text-[#2260D9] underline underline-offset-2">
                        {details?.updated_at
                          ? moment(details.updated_at).format(
                              "MM/DD/YYYY | h:mm A"
                            )
                          : null}
                      </span>
                    </div>
                  )}
                  {details?.request_status === "rejected" && (
                    <div className="pl-2 border-l border-black text-[#353F4F]">
                      Rejected date & Time :{" "}
                      <span className="text-[#2260D9] underline underline-offset-2">
                        {details?.updated_at
                          ? moment(details.created_at).format(
                              "MM/DD/YYYY | h:mm A"
                            )
                          : null}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-12 flex items-center gap-4">
              {/* <button
                style={{ border: "1px solid rgba(220, 53, 69, 1)" }}
                className="py-3 px-5 w-[200px] text-primary-red"
                onClick={() =>
                  setDetailAction({
                    ...detailAction,
                    cancelModal: true,
                    id,
                  })
                }
              >
                Reject Request
              </button> */}
              {userType === "consumer" &&
                details?.request_status === "resolved" && (
                  <button
                    className="active:bg-primary-blue active:text-white border border-primary-blue text-primary-blue py-3 px-5 w-[200px]"
                    onClick={() => navigate(`/request-history/${details?.id}`)}
                  >
                    View History
                  </button>
                )}

              {((userType === "partner" &&
                details?.request_status === "completed") ||
                (["partner", "consumer"].includes(userType) &&
                  details?.request_status === "resolved")) && (
                <button
                  className="active:bg-primary-blue active:text-white border border-primary-blue text-primary-blue py-3 px-5 w-[200px]"
                  onClick={() =>
                    navigate(
                      `/request-tracking?id=${details?.id}&status=${details?.request_status}`
                    )
                  }
                >
                  Track Request
                </button>
              )}

              {!(
                userType === "partner" &&
                details?.request_status === "completed"
              ) && (
                <div className="flex gap-10">
                  {userType === "partner" && (
                    <div className="relative">
                      {details?.request_status !== "completed" &&
                        details?.request_status !== "rejected" &&
                        details?.request_status !== "resolved" && (
                          <Listbox
                            value={selected}
                            onChange={(newValue) => {
                              handleSelectStatus({
                                id: details?.id,
                                status: newValue,
                              });
                              setSelected(newValue);
                            }}
                          >
                            <div className="relative">
                              <ListboxButton className="relative rounded-[4px] w-[200px] py-3.5 cursor-default bg-primary-blue pl-5 text-left text-white shadow-md focus:outline-none sm:text-sm">
                                <span className="text-sm font-medium">
                                  {selected?.name || "Accepted"}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-5">
                                  <KeyboardArrowDownSharpIcon className="w-5 h-5 text-white" />
                                </span>
                              </ListboxButton>

                              <ListboxOptions className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredStatusOptions
                                  ?.filter(
                                    (status) => status.id !== selected?.id
                                  )
                                  .map((status) => (
                                    <ListboxOption
                                      key={status.id}
                                      value={status}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                          active
                                            ? "bg-primary-blue text-white"
                                            : "text-[#353F4F] bg-white"
                                        }`
                                      }
                                    >
                                      {({ selected }) => (
                                        <>
                                          <span
                                            className={`block truncate ${
                                              selected
                                                ? "font-semibold"
                                                : "font-normal"
                                            }`}
                                          >
                                            {status.name}
                                          </span>
                                        </>
                                      )}
                                    </ListboxOption>
                                  ))}
                              </ListboxOptions>
                            </div>
                          </Listbox>
                        )}
                    </div>
                  )}
                  {details?.category_is_premium === "True" &&
                  userType !== "admin" &&
                  userType !== "agent" &&
                  userType !== "partner" &&
                  details?.partner_status === "accepted" ? (
                    <button
                      className="text-white py-3 px-5 w-[200px]"
                      style={{ backgroundColor: "#35A462" }}
                      // onClick={() => setIsOpen(true)}
                      onClick={
                        details?.is_paid === false ? handlePayment : null
                      }
                      disabled={
                        details?.is_paid === false ? loading : details?.is_paid
                      }
                    >
                      {details?.is_paid === true
                        ? "Paid"
                        : loading
                        ? "Processing..."
                        : `Pay USD ${details?.cost || 0}`}
                    </button>
                  ) : null}
                  {(userType === "agent" || userType === "admin") &&
                    details?.request_status === "resolved" && (
                      <button
                        className="bg-[#2260D9] text-white px-6 py-3 text-[16px]"
                        onClick={handleOpen}
                      >
                        Update Status
                      </button>
                    )}
                  {(userType !== "consumer" ||
                    details?.request_status !== "resolved") && (
                    <ThreeDotMenu menuOptions={menuOptions} />
                  )}
                </div>
              )}
            </div>
          </div>
          {(details?.request_status === "rejected" && details?.reason) ||
          details?.request_status_desc ? (
            <div className="border border-[#E50027] my-20 bg-[#FFF9F9] rounded-[5px]">
              <h2 className="border-b border-[#E50027] text-[#E50027] text-[20px] font-medium p-4">
                Rejected Reason
              </h2>
              <div className="flex flex-col gap-4 px-4 py-2">
                <p className="font-normal text-[14px] text-[#848484] flex gap-2 items-center">
                  Commented on{" "}
                  <div className="w-1.5 h-1.5 bg-[#848484] rounded-full"></div>{" "}
                  {details?.created_at
                    ? moment(details.updated_at).format("MM/DD/YYYY | h:mm A")
                    : null}
                </p>

                <p className="text-[#353F4F] text-[12px] font-normal">
                  {details?.reason || details?.request_status_desc}
                </p>
              </div>
            </div>
          ) : null}
          {details?.request_status === "in review" ? (
            <div className="border border-[#FF6711] my-20 bg-[#FFF9F9] rounded-[5px]">
              <h2 className="border-b border-[#FF6711] text-[#FF6711] text-[20px] font-medium p-4">
                Comment from ACC Corp (You)
                {/* {userType === userRole
                  ? "(You)"
                  : userType === "agent"
                  ? "(Agent)"
                  : userType === "admin"
                  ? "(Admin)"
                  : "Unknown"} */}
              </h2>
              <div className="flex flex-col gap-4 px-4 py-2">
                <p className="text-[#353F4F] text-[12px] font-normal">
                  {details?.description}
                </p>
              </div>
            </div>
          ) : null}
          {/* <div className="border border-[#E50027] my-20 bg-[#FFF9F9] rounded-[5px]">
            <h2 className="border-b border-[#E50027] text-[#E50027] text-[20px] font-medium p-4">
              Rejected Reason
            </h2>
            <div className="flex flex-col gap-4 px-4 py-2">
              <p className="font-normal text-[14px] text-[#848484] flex gap-2 items-center">
                Commented on{" "}
                <div className="w-1.5 h-1.5 bg-[#848484] rounded-full"></div>{" "}
                {details?.created_at
                  ? moment(details.created_at).format("MM/DD/YYYY | h:mm A")
                  : null}
              </p>
              <p className="text-[#353F4F] text-[12px] font-normal">
                {details?.reason || details?.request_status_desc}
              </p>
            </div>
          </div> */}
          {/* <div className="px-40">
            <div className="request-info flex gap-4 text-[12px]">
              <p>
                Request ID :{" "}
                <span className="underline text-primary-blue">
                  #{details?.id}
                </span>
              </p>
              <p>
                Request Date :{" "}
                <span className="underline text-primary-blue">
                  {details?.created_at}
                </span>
              </p>
            </div>
          </div> */}
          {userType === "consumer" &&
            details?.request_status !== "new" &&
            (details?.request_status !== "rejected" ||
              details?.request_status !== "rejected") && (
              <div className="mt-8 flex w-full justify-end gap-2 items-center">
                <img src={DownloadInvoice} alt="" />
                <p className="text-[#2260D9] text-[16px] font-medium">
                  Download Invoice
                </p>
              </div>
            )}

          {userType === "agent" &&
            (details?.request_status === "resolved" ||
              details?.request_status === "rejected" ||
              details?.request_status === "on hold" ||
              details?.request_status === "completed") && (
              <div className="mt-8 flex w-full justify-end gap-2 items-center">
                <img src={DownloadInvoice} alt="" />
                <p className="text-[#2260D9] text-[16px] font-medium">
                  Download Invoice
                </p>
              </div>
            )}

          {(userType === "partner" ||
            userType === "agent" ||
            (userType === "consumer" &&
              (details?.request_status === "in progress" ||
                details?.request_status === "completed" ||
                details?.request_status === "rejected"))) &&
            details?.request_status !== "new" &&
            details?.request_status !== "in review" && (
              <div
                style={{ border: "1px solid rgba(228, 237, 255, 1)" }}
                className="mt-8 mb-16"
              >
                <div className="flex">
                  {/* First Header */}
                  <div className="flex-1 py-3 pl-6 font-bold bg-light-blue text-primary-blue">
                    Summary
                  </div>

                  {/* Second Header */}
                  <div className="flex-1 py-3 pl-6 font-bold bg-light-blue text-primary-blue">
                    {userType === "partner" ? "Price" : "Count"}
                  </div>
                </div>
                <table className="w-full request-view-table">
                  <tr>
                    <td>Number of Beneficiaries</td>
                    <td>{details?.beneficiaries || 0}</td>
                  </tr>
                  <tr>
                    <td className="font-bold text-primary-blue">Total Cost</td>
                    <td className="text-primary-blue">
                      {`$ ${(
                        (details?.beneficiaries || 0) *
                        parseFloat(details?.category_per_cost || 0)
                      ).toFixed(2)}`}
                    </td>
                  </tr>
                </table>
              </div>
            )}

          <div
            style={{ border: "1px solid rgba(228, 237, 255, 1)" }}
            className="mt-10 mb-16"
          >
            <p className="py-3 pl-6 font-bold bg-light-blue text-primary-blue">
              {details?.request_status === "in review"
                ? "Primary Information"
                : userType === "partner"
                ? "Requester Details"
                : "Client Information"}
            </p>
            <table className="w-full request-view-table capitalize">
              <tr>
                <td>First Name</td>
                <td>{details?.first_name}</td>
              </tr>
              <tr>
                <td>Last Name</td>
                <td>{details?.last_name}</td>
              </tr>
              <tr>
                <td>Primary Contact Number</td>
                <td>{details?.contact}</td>
              </tr>
              <tr>
                <td>Secondary Contact Number</td>
                <td>{details?.alternative_number}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>{details?.address}</td>
              </tr>
              {details?.address_line && (
                <tr>
                  <td>Address 2</td>
                  <td>{details?.address_line}</td>
                </tr>
              )}
            </table>
          </div>

          <div
            style={{ border: "1px solid rgba(228, 237, 255, 1)" }}
            className="mt-10 mb-16"
          >
            <p className="py-3 pl-6 font-bold bg-light-blue text-primary-blue">
              Request Details
            </p>
            <table className="w-full request-view-table capitalize">
              <tr>
                <td>Request Via</td>
                <td>{details?.request_via}</td>
              </tr>
              <tr>
                <td>Request Program</td>
                <td>{details?.category_name}</td>
              </tr>
              <tr>
                <td>Number of Beneficiaries</td>
                <td>{details?.beneficiaries}</td>
              </tr>
              <tr>
                <td>Urgency Level</td>
                <td>{details?.urgency_level}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{details?.request_status_desc || details?.description}</td>
              </tr>
            </table>
          </div>

          {userType === "consumer" &&
            details?.partner_status === "accepted" && (
              <div
                style={{ border: "1px solid rgba(228, 237, 255, 1)" }}
                className="mt-10 mb-16"
              >
                <p className="py-3 pl-6 font-bold bg-light-blue text-primary-blue">
                  Agent Details
                </p>
                <table className="w-full request-view-table">
                  <tr>
                    <td>Name</td>
                    <td>
                      {details?.agent?.first_name} {details?.agent?.last_name}
                    </td>
                  </tr>
                </table>
              </div>
            )}

          {userType !== "consumer" &&
            details?.request_status !== "new" &&
            details?.request_status !== "in review" && (
              <div
                style={{ border: "1px solid rgba(228, 237, 255, 1)" }}
                className="mt-10 mb-16"
              >
                <p className="py-3 pl-6 font-bold bg-light-blue text-primary-blue">
                  Agent Details
                </p>
                <table className="w-full request-view-table capitalize">
                  <tr>
                    <td>Name</td>
                    <td>
                      {details?.agent?.first_name} {details?.agent?.last_name}
                    </td>
                  </tr>
                  <tr>
                    <td>Contact</td>
                    <td>{details?.agent?.contact_number}</td>
                  </tr>
                  <tr>
                    <td>Email Id</td>
                    <td>{details?.agent?.email}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>{details?.agent?.address}</td>
                  </tr>
                </table>
              </div>
            )}

          {(userType === "agent" || userType === "admin") &&
            details?.request_status !== "on hold" &&
            details?.request_status !== "new" &&
            details?.partners_details?.map((ele) => {
              return (
                <div
                  style={{ border: "1px solid rgba(228, 237, 255, 1)" }}
                  className="mt-10 mb-16"
                >
                  <p className="py-3 pl-6 font-bold bg-light-blue text-primary-blue">
                    Partner Details
                  </p>
                  <table className="w-full request-view-table capitalize">
                    <tr>
                      <td>Name</td>
                      <td>{ele?.organization_name}</td>
                    </tr>
                    <tr>
                      <td>Reffered</td>

                      <td>
                        {details?.agent?.first_name} {details?.agent?.last_name}
                      </td>
                    </tr>
                    <tr>
                      <td>Contact</td>
                      <td className="lowercase text-primary-blue">
                        {ele?.contact_number} {ele?.email}
                      </td>
                    </tr>

                    <tr>
                      <td>Badge</td>
                      <td>---</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>---</td>
                    </tr>
                  </table>
                </div>
              );
            })}
          {userType === "partner" && (
            <div
              style={{ border: "1px solid rgba(228, 237, 255, 1)" }}
              className="mt-10 mb-16"
            >
              <p className="py-3 pl-6 font-bold bg-light-blue text-primary-blue">
                Client Additional Details
              </p>
              <table className="w-full request-view-table">
                <tr>
                  <td>Documents</td>
                  <td>{details?.agent?.attachment}</td>
                </tr>
                <tr>
                  <td>Availability</td>
                  <td>{details?.agent?.availability}</td>
                </tr>
              </table>
            </div>
          )}
          {details?.request_status !== "new" &&
            details?.request_status !== "completed" &&
            details?.request_status !== "rejected" &&
            details?.request_status !== "in progress" &&
            userType !== "consumer" &&
            userType !== "agent" &&
            userType !== "admin" && (
              <div className="flex justify-center gap-6 my-10 align-middle">
                <Button
                  btnName="Request Again"
                  btnCategory="secondary"
                  btnCls="w-[200px]"
                  btnStyle={{ border: "1px solid rgba(34, 96, 217, 1)" }}
                  onClick={() => navigate("/")}
                />
                <Button
                  btnType="submit"
                  id={"program-submit"}
                  btnCls="w-[200px]"
                  onClick={() => navigate("/request-tracking")}
                  btnName={"Track Request"}
                  btnCategory="primary"
                />
              </div>
            )}
          {details?.request_status === "new" &&
            details?.request_status !== "rejected" &&
            userType === "partner" && (
              <div className="flex justify-center gap-6 my-10 align-middle">
                <Button
                  btnName="Reject"
                  btnCategory="secondary"
                  btnCls="w-[200px]"
                  btnStyle={{ border: "1px solid rgba(34, 96, 217, 1)" }}
                  onClick={() => {
                    setDetailAction({ cancelModal: true, id: details?.id });
                  }}
                />
                <Button
                  btnType="Accept"
                  id="program-submit"
                  btnCls="w-[200px]"
                  btnName="Accept"
                  btnCategory="primary"
                  onClick={() => {
                    setShowSuccess({ model: true, status: "accepted" });
                    setTimeout(() => {
                      setShowSuccess({ model: false, status: "" });
                    }, 2000);
                  }}
                />
              </div>
            )}
        </div>
      </div>

      {showSuccess.model && (
        <MuiModal
          modalSize="sm"
          modalOpen={showSuccess.model}
          modalClose={() => setShowSuccess({ model: false, status: "" })}
          noheader
        >
          <div className="flex flex-col items-center justify-center p-4">
            <img
              src={SuccessIcon}
              alt="SuccessIcon"
              className="w-20 h-20 p-4"
            />
            <p className="text-[18px]">
              {showSuccess.status === "accepted"
                ? `Request Accepted`
                : showSuccess.status === "on hold"
                ? `This request has been put to hold`
                : showSuccess.status === "resolve"
                ? `Your request has been resolved successfully`
                : `Request ${showSuccess.status}`}
            </p>
          </div>
        </MuiModal>
      )}
      <EditRequestModal
        isOpen={isEditRequestOpen}
        onClose={() => setIsEditRequestOpen(false)}
        selectedRequestId={selectedEditRequestId}
      />
      <AcceptRequest
        open={isAcceptDialog}
        handleClose={() => setIsAcceptDialog(false)}
        confirmAccept={handleAcceptRequest}
      />
      <CancelRequest
        open={detailAction.cancelModal}
        id={detailAction.id}
        closeModal={closeModal}
        isRejectPopup={userType === "partner"}
        actionTab={""}
      />
      <AddRequest
        open={openPopup}
        setOpen={setOpen}
        id={openPopup}
        fromRequest={false}
        setId={setOpen}
      />
      <StatusDialog
        open={open}
        onClose={handleClose}
        id={details?.id}
        // refetch={() => {
        //   dispatch(
        //     getCurrentRequest({
        //       status: actionTab,
        //       ...{
        //         ...(userType === "consumer"
        //           ? { user_id: data.id }
        //           : userType === "partner"
        //           ? { partner_id: data.id }
        //           : {}),
        //         page: 1,
        //       },
        //     })
        //   );
        // }}
      />
    </div>
  );
}
